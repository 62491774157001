import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    ChatContentRequest,
    ChatHistory,
    deleteAiAssistanceChatConversation,
    DeleteConversationRequest,
    getAiAssistanceChatConversationContent,
    getAiAssistanceChatConversations,
    rateAiAssistanceResponse,
    renameAiAssistanceChatConversationTitle,
    RenameTitleRequest,
    sendMessageToAIAssistance,
    updateAiAssistanceOnboardingCompletion,
} from '../data/ai-assistance';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { InformativeError } from '../utils/error';
import { ulid } from 'ulid';
import { pendoAnalyticsTrack } from '../data/pendo-analytics';
import { loggedInUserDetails$ } from './user';
import { getOrganizationUsage } from '../data/organization';
import { AiChatResponseRating } from '@avst-stitch/repository-lib/lib/models';
import { Role } from '@avst-stitch/repository-lib/src/utils/chatGPT';
import { unsavedScriptDetails$ } from './workspace/script';

export const aiAssistanceOpen$ = monitor('aiAssistanceOpen$', new BehaviorSubject(false));
export const loadingAiAssistanceDialog$ = monitor('loadingAiAssistanceDialog$', new BehaviorSubject(false));
export const aiAssistanceChat$ = monitor(
    'aiAssistanceChat$',
    new BehaviorSubject<{ role: Role; message: string; uid?: string; rating?: AiChatResponseRating }[]>([])
);
export const aiAssistanceGeneratedMessage$ = monitor(
    'aiAssistanceGeneratedMessage$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceGeneratedMessagePieces$ = monitor(
    'aiAssistanceGeneratedMessagePieces$',
    new BehaviorSubject<{ message: string; index: number; isLast?: boolean }[]>([])
);
export const aiAssistanceGeneratedMessageLength$ = monitor(
    'aiAssistanceGeneratedMessageLength$',
    new BehaviorSubject<number | undefined>(undefined)
);
export const aiAssistanceAnswering$ = monitor('aiAssistanceAnswering$', new BehaviorSubject(false));
export const aiAssistanceSendMessageLocation$ = monitor(
    'aiAssistanceSendMessageLocation$',
    new BehaviorSubject<string>('')
);
export const aiAssistanceStreamAbortedAction$ = monitor('aiAssistanceStreamAbortedAction$', new Subject<void>());
export const aiAssistanceOnboardingCompletedAction$ = monitor(
    'aiAssistanceOnboardingCompletedAction$',
    new Subject<void>()
);
export const aiAssistanceGetUsageDetailsAction$ = monitor('aiAssistanceGetUsageDetailsAction$', new Subject<string>());
export const aiAssistanceGetChatHistoryAction$ = monitor('aiAssistanceGetChatHistoryAction$', new Subject<void>());
export const aiAssistanceRateMessageAction$ = monitor(
    'aiAssistanceRateMessageAction$',
    new Subject<{ uid: string; rating: AiChatResponseRating }>()
);
export const aiAssistanceRenameChatHistoryConversationTitleAction$ = monitor(
    'aiAssistanceRenameChatHistoryConversationTitleAction$',
    new Subject<RenameTitleRequest>()
);
export const aiAssistanceDeleteChatHistoryConversationAction$ = monitor(
    'aiAssistanceDeleteChatHistoryConversationAction$',
    new Subject<DeleteConversationRequest>()
);
export const aiAssistanceGetConversationContentAction$ = monitor(
    'aiAssistanceGetConversationContentAction$',
    new Subject<ChatContentRequest>()
);
export const aiAssistanceChatHistory$ = monitor('aiAssistanceChatHistory$', new BehaviorSubject<ChatHistory>([]));
export const aiAssistanceChatHistoryLoading$ = monitor(
    'aiAssistanceChatHistoryLoading$',
    new BehaviorSubject<boolean>(false)
);
export const aiAssistanceRenameOrDeleteLoading$ = monitor(
    'aiAssistanceRenameOrDeleteLoading$',
    new BehaviorSubject<boolean>(false)
);
export const aiAssistanceExplainFeaturePending$ = monitor(
    'aiAssistanceExplainFeaturePending$',
    new BehaviorSubject<boolean>(false)
);
export const aiAssistanceExplainFeaturePendingMessage$ = monitor(
    'aiAssistanceExplainFeaturePendingMessage$',
    new BehaviorSubject<{ message: string; organizationUid: string } | null>(null)
);
export const aiAssistanceStreamAborted$ = monitor('aiAssistanceStreamAborted$', new BehaviorSubject<boolean>(false));
export const aiAssistanceConversationUid$ = monitor(
    'aiAssistanceConversationUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceIsLongResponse$ = monitor('aiAssistanceIsLongResponse$', new BehaviorSubject<boolean>(false));
export const aiAssistanceAvailableCredits$ = monitor('aiAssistanceAvailableCredits$', new BehaviorSubject<number>(0));
export const aiAssistanceResponseMessageUid$ = monitor(
    'aiAssistanceResponseMessageUid$',
    new BehaviorSubject<string>('')
);

export const openAiAssistanceAction$ = monitor('openAiAssistanceAction$', new Subject<void>());
export const closeAiAssistanceAction$ = monitor('closeAiAssistanceAction$', new Subject<void>());
export const aiAssistanceExplainErrorAction$ = monitor(
    'aiAssistanceExplainErrorAction$',
    new Subject<{
        error: string;
        scriptName: string;
        organizationUid: string;
    }>()
);

export const aiAssistanceExplainHighlightedCodeAction$ = monitor(
    'aiAssistanceExplainHighlightedCodeAction$',
    new Subject<{
        code: string;
        language: string;
        organizationUid: string;
    }>()
);

export const sendMessageToAiAssistanceAction$ = monitor(
    'sendMessageToAiAssistanceAction$',
    new Subject<{
        message: string;
        workspaceUid?: string;
        environmentUid?: string;
        organizationUid: string;
    }>()
);
export const createAiAssistanceGeneratedMessageAction$ = monitor(
    'createAiAssistanceGeneratedMessageAction$',
    new Subject<{
        message: string;
        index: number;
        isLast?: boolean;
        id: string;
        finish_reason?: string;
    }>()
);
export const resetAiAssistanceChatAction$ = monitor('resetAiAssistanceChatAction$', new Subject<void>());
export const copyAiAssistanceCodeSnippetAction$ = monitor('copyAiAssistanceCodeSnippetAction$', new Subject<void>());

openAiAssistanceAction$.subscribe(() => {
    aiAssistanceOpen$.next(true);
});

closeAiAssistanceAction$.subscribe(() => {
    aiAssistanceOpen$.next(false);
});

resetAiAssistanceChatAction$.subscribe(() => {
    loadingAiAssistanceDialog$.next(false);
    aiAssistanceAnswering$.next(false);
    aiAssistanceGeneratedMessage$.next(undefined);
    aiAssistanceGeneratedMessagePieces$.next([]);
    aiAssistanceGeneratedMessageLength$.next(undefined);
    aiAssistanceChat$.next([]);
    aiAssistanceIsLongResponse$.next(false);
    pendoAnalyticsTrack('New conversation started', {
        userId: loggedInUserDetails$.value?.uid,
        userOrigin: loggedInUserDetails$.value?.userOrigin,
    });
});

copyAiAssistanceCodeSnippetAction$.subscribe(() => {
    pendoAnalyticsTrack('Code copied', {
        userId: loggedInUserDetails$.value?.uid,
        userOrigin: loggedInUserDetails$.value?.userOrigin,
    });
});

sendMessageToAiAssistanceAction$
    .pipe(
        map(async (event) => {
            loadingAiAssistanceDialog$.next(true);
            aiAssistanceAnswering$.next(true);
            aiAssistanceGeneratedMessage$.next(undefined);
            aiAssistanceGeneratedMessagePieces$.next([]);
            aiAssistanceGeneratedMessageLength$.next(undefined);
            aiAssistanceIsLongResponse$.next(false);
            const chat = aiAssistanceChat$.value;
            try {
                const isFirst = chat.length === 0;
                if (isFirst) {
                    aiAssistanceConversationUid$.next(ulid(new Date().getTime()));
                }
                await sendMessageToAIAssistance({
                    uid: ulid(),
                    message: event.message,
                    workspaceUid: event.workspaceUid,
                    first: isFirst,
                    conversationUid: aiAssistanceConversationUid$.value ?? '',
                    environmentUid: event.environmentUid,
                    organizationUid: event.organizationUid,
                });
                aiAssistanceChat$.next([...chat, { role: 'user', message: event.message }]);
                pendoAnalyticsTrack('New question asked', {
                    userId: loggedInUserDetails$.value?.uid,
                    userOrigin: loggedInUserDetails$.value?.userOrigin,
                });
                aiAssistanceSendMessageLocation$.next(window.location.pathname);
            } catch (e) {
                aiAssistanceAnswering$.next(false);
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while getting AI generated response.', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to get a response from AI assistant.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            loadingAiAssistanceDialog$.next(false);
        })
    )
    .subscribe();

createAiAssistanceGeneratedMessageAction$
    .pipe(
        map(async (event) => {
            if (event.isLast) {
                aiAssistanceGeneratedMessageLength$.next(event.index);
                aiAssistanceAnswering$.next(false);
            }

            if (event.finish_reason === 'length') {
                aiAssistanceIsLongResponse$.next(true);
            }

            const currentPieces = aiAssistanceGeneratedMessagePieces$.value;
            const length = aiAssistanceGeneratedMessageLength$.value;
            const sortedPieces = [...currentPieces, event].sort((pieceA, pieceB) => pieceA.index - pieceB.index);
            const message = sortedPieces.map((piece) => piece.message).join('');
            aiAssistanceGeneratedMessagePieces$.next(sortedPieces);
            aiAssistanceGeneratedMessage$.next(message);

            if (currentPieces.length === length) {
                const chat = aiAssistanceChat$.value;
                aiAssistanceChat$.next([
                    ...chat,
                    { role: 'assistant', message, uid: aiAssistanceResponseMessageUid$.value },
                ]);
                aiAssistanceGeneratedMessagePieces$.next([]);
                aiAssistanceGeneratedMessage$.next(undefined);
            }
        })
    )
    .subscribe();

aiAssistanceOnboardingCompletedAction$
    .pipe(
        map(async () => {
            try {
                await updateAiAssistanceOnboardingCompletion({ completed: true });
                if (loggedInUserDetails$.value) {
                    loggedInUserDetails$.next({
                        ...loggedInUserDetails$.value,
                        aiAssistanceOnboardingCompleted: true,
                    });
                }
                if (aiAssistanceExplainFeaturePending$.value && aiAssistanceExplainFeaturePendingMessage$.value) {
                    sendMessageToAiAssistanceAction$.next(aiAssistanceExplainFeaturePendingMessage$.value);
                    aiAssistanceExplainFeaturePending$.next(false);
                }
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error saving AI assistant onboarding progress',
                });
                console.error('Error saving AI assistant onboarding progress', e);
            }
        })
    )
    .subscribe();

aiAssistanceGetUsageDetailsAction$
    .pipe(
        map(async (uid) => {
            try {
                const usage = await getOrganizationUsage(uid);
                aiAssistanceAvailableCredits$.next(usage.aiAssistanceAvailableCredits);
            } catch (e) {
                console.error('Error retrieving organization usage for AI assistant', e);
            }
        })
    )
    .subscribe();

aiAssistanceRateMessageAction$
    .pipe(
        map(async (request) => {
            try {
                await rateAiAssistanceResponse({
                    uid: request.uid,
                    rating: request.rating,
                    conversationUid: aiAssistanceConversationUid$.value ?? '',
                });
                aiAssistanceChat$.next([
                    ...aiAssistanceChat$.value.map((message) => {
                        if (message.uid === request.uid) {
                            return {
                                rating: request.rating,
                                ...message,
                            };
                        }
                        return message;
                    }),
                ]);
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error rating response from AI assistant',
                });
                console.error('Error rating response from AI assistant', e);
            }
        })
    )
    .subscribe();

// TODO: redo
aiAssistanceStreamAbortedAction$.subscribe(() => {
    aiAssistanceStreamAborted$.next(true);
});

aiAssistanceGetChatHistoryAction$
    .pipe(
        map(async () => {
            aiAssistanceChatHistoryLoading$.next(true);
            try {
                const chatHistory = await getAiAssistanceChatConversations();
                aiAssistanceChatHistory$.next(chatHistory);
            } catch (e) {
                console.error('Error retrieving chat history for AI assistant', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error retrieving chat history for AI assistant',
                });
            }
            aiAssistanceChatHistoryLoading$.next(false);
        })
    )
    .subscribe();

aiAssistanceRenameChatHistoryConversationTitleAction$
    .pipe(
        map(async (update) => {
            aiAssistanceRenameOrDeleteLoading$.next(true);
            try {
                await renameAiAssistanceChatConversationTitle(update);
                aiAssistanceGetChatHistoryAction$.next();
            } catch (e) {
                console.error('Error renaming chat title for AI assistant', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error renaming chat title for AI assistant',
                });
            }
            aiAssistanceRenameOrDeleteLoading$.next(false);
        })
    )
    .subscribe();

aiAssistanceDeleteChatHistoryConversationAction$
    .pipe(
        map(async (data) => {
            aiAssistanceRenameOrDeleteLoading$.next(true);
            try {
                await deleteAiAssistanceChatConversation(data);
                aiAssistanceGetChatHistoryAction$.next();
                if (data.uid === aiAssistanceConversationUid$.value) {
                    resetAiAssistanceChatAction$.next();
                }
            } catch (e) {
                console.error('Error deleting the chat for AI assistant', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error deleting the chat for AI assistant',
                });
            }
            aiAssistanceRenameOrDeleteLoading$.next(false);
        })
    )
    .subscribe();

aiAssistanceGetConversationContentAction$
    .pipe(
        map(async (data) => {
            aiAssistanceChatHistoryLoading$.next(true);
            try {
                aiAssistanceChat$.next([]);
                const content = await getAiAssistanceChatConversationContent(data);
                aiAssistanceChat$.next(content);
                aiAssistanceConversationUid$.next(data.uid);
                aiAssistanceGeneratedMessage$.next(undefined);
                aiAssistanceGeneratedMessagePieces$.next([]);
                aiAssistanceGeneratedMessageLength$.next(undefined);
                aiAssistanceIsLongResponse$.next(false);
            } catch (e) {
                console.error('Error retrieving chat content for AI assistant', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error retrieving chat content for AI assistant',
                });
            }
            aiAssistanceChatHistoryLoading$.next(false);
        })
    )
    .subscribe();

aiAssistanceExplainErrorAction$
    .pipe(
        map(async ({ error, scriptName, organizationUid }) => {
            let script = '';
            const scripts = unsavedScriptDetails$.value;
            for (const scr in scripts) {
                const scriptObj = scripts[scr];
                if (scriptObj && scriptObj.name === scriptName) {
                    script = scriptObj.content;
                    break;
                }
            }

            const errorMessagePayload = {
                message: `**Please explain the error:**\n\n ${error}.\n\n **The following code caused the error:**\n\n \`\`\`typescript\n${script}\n\`\`\``,
                organizationUid,
            };

            if (aiAssistanceSendMessageLocation$.value !== window.location.pathname) {
                resetAiAssistanceChatAction$.next();
            }

            aiAssistanceOpen$.next(true);

            if (!loggedInUserDetails$.value?.aiAssistanceOnboardingCompleted) {
                aiAssistanceExplainFeaturePending$.next(true);
                aiAssistanceExplainFeaturePendingMessage$.next(errorMessagePayload);
            } else {
                sendMessageToAiAssistanceAction$.next(errorMessagePayload);
            }
        })
    )
    .subscribe();

aiAssistanceExplainHighlightedCodeAction$
    .pipe(
        map(async ({ code, language, organizationUid }) => {
            const codeMessagePayload = {
                message: `**Please explain the following ${language
                    .substring(0, 1)
                    .toLocaleUpperCase()}${language.substring(1)} code:**\n\n \`\`\`${language}\n${code}\n\`\`\``,
                organizationUid,
            };

            resetAiAssistanceChatAction$.next();
            aiAssistanceOpen$.next(true);

            if (!loggedInUserDetails$.value?.aiAssistanceOnboardingCompleted) {
                aiAssistanceExplainFeaturePending$.next(true);
                aiAssistanceExplainFeaturePendingMessage$.next(codeMessagePayload);
            } else {
                sendMessageToAiAssistanceAction$.next(codeMessagePayload);
            }
        })
    )
    .subscribe();
