import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { Button } from '../../common/buttons/Button';
import { ProductIcon } from '../../icons/ProductIcon';
import { IconCircle } from '../../common/IconCircle';
import { StyledBorderBox, StyledBorderBoxContent } from '../../layout/BorderBoxComponents';
import { StyledOnboardingBox, StyledOnboardingBoxHeader } from '../OnboardingComponents';
import { APP } from '@avst-stitch/repository-lib/constants';

export interface OnboardingFlowSelectorProps {
    onExploreTemplates: () => void;
    onQuickstart: () => void;
    onStartFromScratch: () => void;
    isSimpleTemplate: boolean;
}

const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(0.5),
}));

const StyledCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    padding: theme.spacing(3),
}));

export const OnboardingFlowSelector: React.FC<OnboardingFlowSelectorProps> = ({
    onExploreTemplates,
    onQuickstart,
    onStartFromScratch,
    isSimpleTemplate,
}) => {
    const templateBox = isSimpleTemplate ? (
        <StyledBorderBox maxWidth={268} width="100%">
            <StyledBorderBoxContent>
                <StyledIconContainer>
                    <IconCircle icon={<ProductIcon name={APP.GENERIC.NAME} />} size="medium" />
                </StyledIconContainer>
                <Typography variant="h6">Quickstart tutorial</Typography>
                <Typography color="text.secondary">
                    Build a simple Wikipedia integration using our setup guide.
                </Typography>
                <Button data-pendo="startFromTutorial" onClick={onQuickstart}>
                    Get started
                </Button>
            </StyledBorderBoxContent>
        </StyledBorderBox>
    ) : (
        <StyledBorderBox maxWidth={268} width="100%">
            <StyledBorderBoxContent>
                <StyledIconContainer>
                    <IconCircle icon={<ProductIcon name={APP.JIRA_CLOUD.NAME} />} size="medium" />
                    <AddIcon sx={{ color: 'text.secondary' }} />
                    <IconCircle icon={<ProductIcon name={APP.CONFLUENCE_CLOUD.NAME} />} size="medium" />
                </StyledIconContainer>
                <Typography variant="h6">Quickstart tutorial</Typography>
                <Typography color="text.secondary">
                    Build a simple Jira and Confluence integration using our setup guide.
                </Typography>
                <Button data-pendo="startFromTutorial" onClick={onQuickstart}>
                    Get started
                </Button>
            </StyledBorderBoxContent>
        </StyledBorderBox>
    );

    return (
        <StyledOnboardingBox sx={{ maxWidth: 900 }}>
            <StyledOnboardingBoxHeader>
                <Typography component="h2" variant="h5">
                    What do you want to build today?
                </Typography>
                <Typography color="text.secondary" fontWeight="normal" variant="subtitle1">
                    Start with a template or create a custom integration to fit your exact needs.
                </Typography>
            </StyledOnboardingBoxHeader>
            <Divider />
            <StyledCardContainer>
                {templateBox}
                <StyledBorderBox maxWidth={256}>
                    <StyledBorderBoxContent>
                        <StyledIconContainer>
                            <IconCircle icon={<LocalLibraryOutlinedIcon />} size="medium" />
                        </StyledIconContainer>
                        <Typography variant="h6">Use a template</Typography>
                        <Typography color="text.secondary">Build an integration using our setup guide.</Typography>
                        <Button data-pendo="startFromTemplates" onClick={onExploreTemplates}>
                            Explore templates
                        </Button>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
                <StyledBorderBox maxWidth={268}>
                    <StyledBorderBoxContent>
                        <StyledIconContainer>
                            <IconCircle icon={<AddIcon />} size="medium" />
                        </StyledIconContainer>
                        <Typography component="h3" variant="h6">
                            Start from scratch
                        </Typography>
                        <Typography color="text.secondary">
                            Already a pro? Want to explore? Build a new integration.
                        </Typography>
                        <Button data-pendo="startFromScratch" onClick={onStartFromScratch}>
                            Start from scratch
                        </Button>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </StyledCardContainer>
        </StyledOnboardingBox>
    );
};
