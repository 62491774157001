import { useObservableState } from 'observable-hooks';
import { OnboardingTemplateSelector } from '../../../components/onboarding/wizard/template-selector/OnboardingTemplateSelector';
import { templates$ } from '../../../store/templates';
import { navigateToOnboardingStepAction$ } from '../../../store/onboarding';
import { apps$ } from '../../../store/apps';

export const OnboardingTemplateSelectorContainer: React.FC = () => {
    const templates = useObservableState(templates$);
    const apps = useObservableState(apps$);

    const remappedPublishedTemplates = templates
        .filter((t) => !t.draft)
        .map((t) => ({
            apps: Array.from(new Set([...t.incomingApps, ...t.outgoingApps])),
            name: t.name,
            uid: t.uid,
        }));

    return (
        <OnboardingTemplateSelector
            apps={apps.map((app) => app.name)}
            templates={remappedPublishedTemplates}
            onGoBack={() => navigateToOnboardingStepAction$.next({ route: 'flow' })}
            onSelectTemplate={(uid) => navigateToOnboardingStepAction$.next({ route: 'template', templateUid: uid })}
        />
    );
};
