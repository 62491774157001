/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import { LinkProps } from 'react-location';
import { styled } from '@mui/material';
import { ThemeMode } from '../../../theme';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { SidenavOrganizationSelector } from './SideNavOrganizationSelector';
import { SidenavOrganizationSettingsNavigator } from './SidenavOrganizationSettingsNavigator';
import { SidenavReportingNavigator } from './SidenavReportingNavigator';
import { SidenavThemeModeSelector } from './SidenavThemeModeSelector';
import { SidenavUserSettingsNavigator } from './SidenavUserSettingsNavigator';
import { SRConnectIcon } from '../../icons/SRConnectIcon';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';
import { UserNameDetails } from '../AppMain';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import { InformationChip } from '../../common/chips/InformationChip';
import { RouterLink } from '../../common/RouterLink';
import { ListItemButton } from '../../common/buttons/ListItemButton';
import { IconButton } from '../../common/buttons/IconButton';

export type SidenavPath =
    | 'Home'
    | 'Connectors'
    | 'Workspaces'
    | 'Reporting'
    | 'Templates'
    | 'Upgrade'
    | 'Organizations';

interface SidenavProps {
    basePath: string;
    displayApiKeys: boolean;
    helpAndSupportOpen?: boolean;
    isLogOutBusy?: boolean;
    organizations?: SidenavOrganization[];
    selectedOrganizationUid?: string;
    selectedPath: string;
    showUpgradePlan?: boolean;
    themeMode: ThemeMode;
    userCredentials: UserNameDetails;
    useRouter?: boolean;
    aiAssistantOpen?: boolean;
    onChangeThemeMode(mode: ThemeMode): void;
    onCreateNewOrganization(): void;
    onLogOut(): void;
    onManageAllOrganizations(): void;
    onOpenHelpAndSupport(): void; // Design indicates navigation to a page, but existing solution is a modal- further changes have to be coordinated with the Monday team
    onToggleAiAssistant(): void;
    onSelectOrganization(uid: string): void;
}

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{ collapsed: boolean }>(
    ({ collapsed, theme }) => ({
        '& .MuiPaper-root': {
            position: 'relative',
            transition: theme.transitions.create(['width', 'justify-content'], {
                easing: theme.transitions.easing.sharp,
                duration: 200,
            }),
            width: collapsed ? 57 : 211,
        },
    })
);

const StyledDrawerHeader = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
}));

const StyledDrawerHeaderTop = styled(Box, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
    collapsed: boolean;
}>(({ collapsed, theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 42,
    justifyContent: collapsed ? 'center' : 'space-between',
    marginBottom: theme.spacing(2),
    '&>.MuiBox-root': collapsed
        ? {
              display: 'none',
          }
        : undefined,
}));

const StyledAppName = styled(ButtonBase)<LinkProps & { component?: React.ElementType }>(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    textAlign: 'start',
    '& .MuiTypography-root': {
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '19.2px', // In order to get the text to the app icon size
    },
    '& .MuiSvgIcon-root': {
        height: 42,
        width: 42,
    },
}));

const StyledDrawerMain = styled(Box)(() => ({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
}));

const StyledList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
}));

const StyledSubheader = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    height: 36,
    padding: theme.spacing(1, 1, 0),
    textTransform: 'uppercase',
}));

export const Sidenav: React.FC<SidenavProps> = ({
    basePath,
    displayApiKeys,
    helpAndSupportOpen = false,
    isLogOutBusy,
    organizations,
    selectedOrganizationUid,
    selectedPath,
    showUpgradePlan,
    themeMode,
    userCredentials,
    useRouter = false,
    aiAssistantOpen = false,
    onChangeThemeMode,
    onCreateNewOrganization,
    onLogOut,
    onManageAllOrganizations,
    onOpenHelpAndSupport,
    onSelectOrganization,
    onToggleAiAssistant,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const homePath = '/dashboard';

    const selectedOrganization = organizations?.find((org) => org.uid === selectedOrganizationUid);

    return (
        <StyledDrawer collapsed={collapsed} variant="permanent">
            <StyledDrawerHeader>
                <StyledDrawerHeaderTop collapsed={collapsed}>
                    {!collapsed && (
                        <StyledAppName
                            component={RouterLink}
                            data-pendo="homeLogo"
                            to={useRouter ? basePath + homePath : null}
                        >
                            <SRConnectIcon />
                            <Typography>
                                ScriptRunner
                                <br />
                                Connect
                            </Typography>
                        </StyledAppName>
                    )}
                    <IconButton
                        aria-label={collapsed ? 'Expand side navigation' : 'Collapse side navigation'}
                        icon={
                            <KeyboardTabOutlinedIcon
                                sx={{ color: 'text.primary', transform: collapsed ? undefined : 'rotate(180deg)' }}
                            />
                        }
                        tooltip={collapsed ? 'Expand side navigation' : 'Collapse side navigation'}
                        onClick={() => setCollapsed(!collapsed)}
                    />
                </StyledDrawerHeaderTop>
                <SidenavOrganizationSelector
                    collapsed={collapsed}
                    organizations={organizations}
                    selectedOrganizationUid={selectedOrganizationUid}
                    onCreateNewOrganization={onCreateNewOrganization}
                    onManageAllOrganizations={onManageAllOrganizations}
                    onSelectOrganization={onSelectOrganization}
                />
            </StyledDrawerHeader>
            <StyledDrawerMain>
                <StyledList>
                    <ListItemButton
                        collapsed={collapsed}
                        data-pendo="home"
                        label="Home"
                        link={{ href: homePath, type: 'router' }}
                        selected={selectedPath === 'dashboard'}
                        startIcon={
                            <Tooltip title="Home">
                                <CottageOutlinedIcon />
                            </Tooltip>
                        }
                        useRouter={useRouter}
                    />
                    <StyledSubheader>
                        {!collapsed && (
                            <Typography color="text.secondary" variant="body2">
                                Integrate
                            </Typography>
                        )}
                    </StyledSubheader>
                    <ListItemButton
                        collapsed={collapsed}
                        data-pendo="connectors"
                        label="Connectors"
                        link={{ href: basePath + '/connectors', type: 'router' }}
                        selected={selectedPath === 'connectors'}
                        startIcon={
                            <Tooltip title="Connectors">
                                <PowerOutlinedIcon />
                            </Tooltip>
                        }
                        useRouter={useRouter}
                    />
                    <ListItemButton
                        collapsed={collapsed}
                        data-pendo="workspaces"
                        label="Workspaces"
                        link={{ href: basePath + '/workspaces', type: 'router' }}
                        selected={selectedPath === 'workspaces'}
                        startIcon={
                            <Tooltip title="Workspaces">
                                <Inventory2OutlinedIcon />
                            </Tooltip>
                        }
                        useRouter={useRouter}
                    />
                    <SidenavReportingNavigator
                        basePath={basePath}
                        collapsed={collapsed}
                        data-pendo="reporting"
                        isSelected={selectedPath === 'reporting' || selectedPath === 'auditLogs'}
                        useRouter={useRouter}
                    />
                    <StyledSubheader>
                        {!collapsed && (
                            <Typography color="text.secondary" variant="body2">
                                Explore
                            </Typography>
                        )}
                    </StyledSubheader>
                    <ListItemButton
                        collapsed={collapsed}
                        data-pendo="templates"
                        label="Templates"
                        link={{ href: basePath + '/templates', type: 'router' }}
                        selected={selectedPath === 'templates'}
                        startIcon={
                            <Tooltip title="Templates">
                                <LocalLibraryOutlinedIcon />
                            </Tooltip>
                        }
                        useRouter={useRouter}
                    />
                </StyledList>
                <Box>
                    <StyledList>
                        <ListItemButton
                            endIcons={[<InformationChip label="NEW" severity="success" size="small" />]}
                            collapsed={collapsed}
                            data-pendo="aiAssistantAccessed"
                            label="AI assistant"
                            selected={aiAssistantOpen}
                            startIcon={
                                <Tooltip title="Templates">
                                    <AssistantOutlinedIcon />
                                </Tooltip>
                            }
                            onClick={onToggleAiAssistant}
                        />
                        <ListItemButton
                            collapsed={collapsed}
                            data-pendo="helpAccessed"
                            label="Help & support"
                            selected={helpAndSupportOpen}
                            startIcon={
                                <Tooltip title="Help & support">
                                    <HelpOutlineOutlinedIcon />
                                </Tooltip>
                            }
                            onClick={onOpenHelpAndSupport}
                        />
                        <StyledSubheader>
                            {!collapsed && (
                                <Typography color="text.secondary" variant="body2">
                                    Settings
                                </Typography>
                            )}
                        </StyledSubheader>
                        {showUpgradePlan && (
                            <ListItemButton
                                collapsed={collapsed}
                                data-pendo="upgradeFromNav"
                                label="Upgrade plan"
                                link={{ href: `${basePath}/team/${selectedOrganizationUid}/plan`, type: 'router' }}
                                startIcon={
                                    <Tooltip title="Templates">
                                        <ArrowCircleUpOutlinedIcon />
                                    </Tooltip>
                                }
                                useRouter={useRouter}
                            />
                        )}
                        {selectedOrganization && (
                            <SidenavOrganizationSettingsNavigator
                                basePath={basePath}
                                collapsed={collapsed}
                                isSelected={selectedPath === 'team'}
                                organizationName={selectedOrganization.name}
                                organizationUid={selectedOrganizationUid ?? ''}
                                ownedDefaultOrganization={selectedOrganization.ownedDefaultOrganization}
                                useRouter={useRouter}
                            />
                        )}
                        <SidenavThemeModeSelector
                            collapsed={collapsed}
                            selectedThemeMode={themeMode}
                            onSelectThemeMode={onChangeThemeMode}
                        />
                    </StyledList>
                    <SidenavUserSettingsNavigator
                        basePath={basePath}
                        collapsed={collapsed}
                        displayApiKeys={displayApiKeys}
                        isLogOutBusy={isLogOutBusy}
                        userCredentials={userCredentials}
                        useRouter={useRouter}
                        onLogOut={onLogOut}
                    />
                </Box>
            </StyledDrawerMain>
        </StyledDrawer>
    );
};
