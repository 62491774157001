import { styled } from '@mui/material';
import { Button } from '../../common/buttons/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { plansAndBillingDocumentationUrl } from '../../../utils/documentation';
import { OrganizationPlan } from '../../../data/organization';
import { IconCircle } from '../../common/IconCircle';
import { Alert } from '../../common/alerts/Alert';
import { InformationChip } from '../../common/chips/InformationChip';

interface BillingDetailsCardPlanOverviewProps {
    name: string;
    plan: OrganizationPlan;
    membersCount: number;
    billingEntity?: {
        contactName: string;
        contactEmail: string;
    };
    loading?: boolean;
    errors?: string;
    onManageBilling?: () => void;
    onReactivate?: () => void;
    onOpenSupport?: () => void;
    onChangePlan?: () => void;
    onUpdateBillingContact?: () => void;
    onLearnAboutInvoice?: () => void;
    onCancelPlan?: () => void;
}

const StyledTeamAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.text.primary,
    borderRadius: theme.constants.borderRadius,
    fontSize: theme.typography.body2.fontSize,
    height: 32,
    width: 32,
}));

const StyledTeamAvatarContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.action.hover,
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
}));

const StyledHeaderButton = styled(Button)(() => ({
    maxHeight: 40,
}));

const StyledBodyContainer = styled(Box)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledBillingInfoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
}));

const StyledBillingInfoHeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
}));

const StyledInvoicesButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.constants.borderRadius,
    border: `1px dashed ${theme.palette.divider}`,
}));

export const BillingDetailsOverviewV2: React.FC<BillingDetailsCardPlanOverviewProps> = ({
    name,
    plan,
    membersCount,
    billingEntity,
    loading = false,
    errors,
    onManageBilling,
    onReactivate,
    onOpenSupport,
    onChangePlan,
    onUpdateBillingContact,
    onLearnAboutInvoice,
    onCancelPlan,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const isCustomPlan = !['free', 'basic', 'advanced', 'pro'].includes(plan.tier);
    const endDateString = new Date(plan.endDate ?? '').toLocaleDateString(undefined, {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    });
    const startDateString = new Date(plan.startDate ?? '').toLocaleDateString(undefined, {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    });

    return (
        <Box display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column" gap={4} width={864}>
                <StyledContainer>
                    <Box display="flex" padding={2} gap={1.5}>
                        <StyledTeamAvatarContainer>
                            <StyledTeamAvatar>{name.slice(0, 2).toLocaleUpperCase()}</StyledTeamAvatar>
                        </StyledTeamAvatarContainer>
                        <Box display="flex" width="100%" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6">{name}</Typography>
                                <Typography variant="body1">{`${membersCount} member${
                                    membersCount > 1 ? 's' : ''
                                }`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </StyledContainer>
                {errors && <Alert severity="error" title={errors} />}
                {(plan.subscriptionStatus === 'SUSPENDED' || plan.subscriptionStatus === 'CANCELLED') && (
                    <Alert
                        severity={plan.subscriptionStatus === 'SUSPENDED' ? 'error' : 'warning'}
                        title={
                            plan.subscriptionStatus === 'SUSPENDED'
                                ? 'Team plan is cancelled'
                                : 'Team plan is suspended'
                        }
                        text={
                            plan.subscriptionStatus === 'SUSPENDED'
                                ? 'Reactivate your team plan to resume event processing for all affected workspaces.'
                                : 'Resume your team plan to resume event processing for all affected workspaces.'
                        }
                        action={
                            <Button
                                variant="outlined"
                                busy={loading}
                                color={plan.subscriptionStatus === 'SUSPENDED' ? 'error' : 'warning'}
                                onClick={onReactivate}
                            >
                                {plan.subscriptionStatus === 'SUSPENDED' ? 'Reactivate plan' : 'Resume plan'}
                            </Button>
                        }
                    />
                )}
                <StyledContainer>
                    <StyledBillingInfoHeaderContainer>
                        <Typography variant="h6">Plan details</Typography>
                        {onChangePlan && (
                            <StyledHeaderButton busy={loading} variant="contained" onClick={onChangePlan}>
                                Change plan
                            </StyledHeaderButton>
                        )}
                    </StyledBillingInfoHeaderContainer>
                    <StyledBodyContainer>
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Your plan
                            </Typography>
                            <Typography variant="body1" textTransform="capitalize">
                                {isCustomPlan ? 'Custom' : plan.tier}
                            </Typography>
                        </StyledBillingInfoContainer>
                        <Divider />
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Status
                            </Typography>
                            <InformationChip
                                size="small"
                                severity={
                                    plan.subscriptionStatus === 'SUSPENDED'
                                        ? 'error'
                                        : plan.subscriptionStatus === 'CANCELLED'
                                        ? 'warning'
                                        : 'success'
                                }
                                label={
                                    plan.subscriptionStatus === 'SUSPENDED'
                                        ? `Cancelled - Your subscription was cancelled on ${endDateString}`
                                        : plan.subscriptionStatus === 'CANCELLED'
                                        ? `Suspended - Your subscription will be cancelled on ${endDateString}`
                                        : 'Active'
                                }
                            />
                        </StyledBillingInfoContainer>
                        <Divider />
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Billing period
                            </Typography>
                            <Typography variant="body1">{plan.period === 'ANNUALLY' ? 'Yearly' : 'Monthly'}</Typography>
                        </StyledBillingInfoContainer>
                        <Divider />
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Start date
                            </Typography>
                            <Typography variant="body1">{startDateString}</Typography>
                        </StyledBillingInfoContainer>
                        <Divider />
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Next payment
                            </Typography>
                            <Typography variant="body1">
                                {plan.subscriptionStatus === 'RENEW' && plan.paymentType !== 'FREE'
                                    ? endDateString
                                    : 'N/A'}
                            </Typography>
                        </StyledBillingInfoContainer>
                        <Divider />
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Upcoming amount
                            </Typography>
                            <Typography variant="body1">
                                {plan.subscriptionStatus === 'RENEW' && plan.paymentType !== 'FREE'
                                    ? `$${plan.cost.toLocaleString('en-us')}/${
                                          plan.period === 'ANNUALLY' ? 'year' : 'month'
                                      }`
                                    : 'N/A'}
                            </Typography>
                        </StyledBillingInfoContainer>
                        {plan.subscriptionStatus === 'CANCELLED' && (
                            <Alert
                                color="info"
                                text="Refunds are carried out on a case by case basis. You can change your plan at any time."
                                title="Refund Request"
                            />
                        )}
                    </StyledBodyContainer>
                </StyledContainer>
                <StyledContainer>
                    <StyledBillingInfoHeaderContainer>
                        <Typography variant="h6">Billing contact</Typography>
                        {onUpdateBillingContact && (
                            <StyledHeaderButton busy={loading} variant="outlined" onClick={onUpdateBillingContact}>
                                Update billing contact
                            </StyledHeaderButton>
                        )}
                    </StyledBillingInfoHeaderContainer>
                    <StyledBodyContainer>
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Billing name
                            </Typography>
                            <Typography variant="body1">{billingEntity?.contactName ?? 'N/A'}</Typography>
                        </StyledBillingInfoContainer>
                        <Divider />
                        <StyledBillingInfoContainer>
                            <Typography flexBasis="20%" variant="subtitle2">
                                Billing email
                            </Typography>
                            <Typography variant="body1">{billingEntity?.contactEmail ?? 'N/A'}</Typography>
                        </StyledBillingInfoContainer>
                    </StyledBodyContainer>
                </StyledContainer>
                {plan.paymentType !== 'FREE' && (
                    <StyledContainer>
                        <StyledBillingInfoHeaderContainer>
                            {plan.paymentType === 'CARD' ? (
                                <Box>
                                    <Typography variant="h6">Payment details</Typography>
                                    <Box display="flex" gap={0.5} alignItems="center">
                                        <Typography variant="body1">
                                            All transactions are secure and encrypted by Stripe
                                        </Typography>
                                        <HttpsOutlinedIcon sx={{ height: 16, width: 16 }} />
                                    </Box>
                                </Box>
                            ) : (
                                <Typography variant="h6">Payment details</Typography>
                            )}
                            {plan.paymentType === 'CARD' && onManageBilling && (
                                <StyledHeaderButton busy={loading} variant="outlined" onClick={onManageBilling}>
                                    Manage billing
                                </StyledHeaderButton>
                            )}
                        </StyledBillingInfoHeaderContainer>
                        <StyledBodyContainer>
                            <StyledBillingInfoContainer>
                                <Typography flexBasis="20%" variant="subtitle2">
                                    Payment method
                                </Typography>
                                {plan.paymentType === 'INVOICE' ? (
                                    <Box display="flex" width="85%" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body1">Invoice</Typography>
                                        <Link underline="always" onClick={onLearnAboutInvoice}>
                                            <Typography variant="body1">Learn more about invoice payment</Typography>
                                        </Link>
                                    </Box>
                                ) : (
                                    <Typography variant="body1">Credit card</Typography>
                                )}
                            </StyledBillingInfoContainer>
                        </StyledBodyContainer>
                    </StyledContainer>
                )}
                {plan.paymentType !== 'FREE' && (
                    <StyledContainer>
                        <StyledBillingInfoHeaderContainer>
                            <Typography variant="h6">Invoices</Typography>
                        </StyledBillingInfoHeaderContainer>
                        <StyledBodyContainer>
                            <StyledBillingInfoContainer>
                                <Typography flexBasis="100%" variant="subtitle2">
                                    Invoice no.
                                </Typography>
                                <Typography flexBasis="100%" variant="subtitle2">
                                    Date
                                </Typography>
                                <Typography flexBasis="100%" variant="subtitle2">
                                    Amount
                                </Typography>
                                <Typography flexBasis="100%" variant="subtitle2">
                                    Method
                                </Typography>
                                <Typography flexBasis="100%" variant="subtitle2">
                                    Status
                                </Typography>
                                <Typography flexBasis="100%" variant="subtitle2">
                                    From
                                </Typography>
                            </StyledBillingInfoContainer>
                            <Divider />
                            <StyledBillingInfoContainer>
                                <StyledInvoicesButtonContainer>
                                    <Button
                                        variant="outlined"
                                        busy={loading}
                                        onClick={plan.paymentType === 'INVOICE' ? onOpenSupport : onManageBilling}
                                    >
                                        {plan.paymentType === 'INVOICE' ? 'Request invoice' : 'View invoices'}
                                    </Button>
                                </StyledInvoicesButtonContainer>
                            </StyledBillingInfoContainer>
                        </StyledBodyContainer>
                    </StyledContainer>
                )}
                <StyledContainer>
                    <Box display="flex" padding={2} gap={1.5}>
                        <IconCircle icon={<QuestionAnswerOutlinedIcon />} size="medium" />
                        <Box display="flex" width="100%" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6">Questions?</Typography>
                                <Typography variant="body1">
                                    Check out our{' '}
                                    <Link underline="always" target="_blank" href={plansAndBillingDocumentationUrl}>
                                        billing FAQ
                                    </Link>{' '}
                                    or contact us for more information.
                                </Typography>
                            </Box>
                            <StyledHeaderButton busy={loading} variant="outlined" onClick={onOpenSupport}>
                                Contact support
                            </StyledHeaderButton>
                        </Box>
                    </Box>
                </StyledContainer>
                <Box display="flex" justifyContent="flex-end">
                    <Button busy={loading} variant="outlined" onClick={onCancelPlan} color="error">
                        Cancel plan
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
