/* eslint-disable sonarjs/no-duplicate-string */
import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { OrganizationSelector } from '../../components/organizations/organization-selector';
import {
    deleteOrganizationAction$,
    loggedInUserOrganizations$,
    openCreateOrganizationWizardDialogAction$,
    organizationCreatedAction$,
    organizationDeletedAction$,
} from '../../store/organizations';
import { getBasePath } from '../../utils/path';
import { OrganizationTab } from './OrganizationContainer';
import { promptQuestion } from '../../store/confirm';
import { organizationsDocumentationUrl } from '../../utils/documentation';

export const OrganizationsContainer: React.FC = () => {
    const navigate = useNavigate();

    const organizations = useObservableState(loggedInUserOrganizations$);

    const navigateToOrganization = (organizationUid: string, tab?: OrganizationTab): void => {
        navigate({ to: `${getBasePath()}team/${organizationUid}/${tab}` });
    };

    useSubscription(organizationCreatedAction$, (uid) => {
        navigateToOrganization(uid, 'usage');
    });

    useSubscription(organizationDeletedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}teams` }), 100);
    });

    return (
        <OrganizationSelector
            docsUrl={organizationsDocumentationUrl}
            organizations={organizations}
            onCreateNew={() => openCreateOrganizationWizardDialogAction$.next()}
            onOpen={(orgUid) => navigateToOrganization(orgUid, 'usage')}
            onDelete={(uid) => {
                promptQuestion({
                    title: 'Are you sure you want to delete the team?',
                    proceedButton: {
                        label: 'Delete team',
                        severity: 'error',
                        onClick: () => deleteOrganizationAction$.next(uid),
                    },
                });
            }}
        />
    );
};
