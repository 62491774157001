import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Editor from '@monaco-editor/react';
import { Alert } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import Box from '@mui/material/Box';

export interface ReplayInvocationDialogProps {
    content: string;
    loading?: boolean;
    open?: boolean;
    workspaceLocked?: boolean;
    onAssumeWorkspaceEditControl(): void;
    onClose(): void;
    onTrigger(payload: string): void;
}

export const ReplayInvocationDialog: React.FC<ReplayInvocationDialogProps> = ({
    content,
    loading = false,
    open = false,
    workspaceLocked = false,
    onAssumeWorkspaceEditControl,
    onClose,
    onTrigger,
}) => {
    const [currentContent, setCurrentContent] = useState(content);
    const darkTheme = useTheme().palette.mode === 'dark';

    useEffect(() => setCurrentContent(content), [content]);

    const buttons = [
        <Button key="cancel-button" variant="outlined" onClick={onClose}>
            Cancel
        </Button>,
        ...(workspaceLocked
            ? [
                  <Button key="assume-edit-control-button" onClick={onAssumeWorkspaceEditControl}>
                      Assume Edit Control
                  </Button>,
              ]
            : [
                  <Button key="trigger-button" onClick={() => onTrigger(currentContent)}>
                      Trigger
                  </Button>,
              ]),
    ];

    return (
        <Dialog
            buttons={buttons}
            loading={loading}
            open={open}
            size="medium"
            title="Invocation payload review"
            onClose={onClose}
        >
            <>
                {!workspaceLocked && (
                    <Alert
                        severity="info"
                        title="Review and optionally modify the event payload you are about to re-trigger"
                    />
                )}
                {workspaceLocked && (
                    <Alert
                        severity="info"
                        title="Workspace already in use."
                        text="Looks like you have opened the same workspace from a different tab or you are sharing an account and someone else is working on this workspace right now. You can assume edit control over the workspace immediately by clicking on 'Assume Edit Control' button."
                    />
                )}
                {content === '{}' && (
                    <Alert
                        severity="warning"
                        title="Empty payload."
                        text="There's no payload for this invocation. Please input a payload manually."
                    />
                )}
                <Box flexBasis={400}>
                    <Editor
                        path="replayInvocationPayload"
                        theme={darkTheme ? 'vs-dark' : 'vs'}
                        options={{
                            minimap: { enabled: false },
                            readOnly: workspaceLocked,
                        }}
                        language="json"
                        defaultValue=""
                        value={currentContent}
                        onChange={(editorContent) => setCurrentContent(editorContent ?? '')}
                    />
                </Box>
            </>
        </Dialog>
    );
};
