import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, Outlet } from 'react-location';
import useResizeObserver from 'use-resize-observer';
import { AppBanners } from './AppBanners';
import { Sidenav } from './side-navigation';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';
import { getBasePath } from '../../utils/path';
import { ThemeMode } from '../../theme';
import { AiAssistanceDialog } from './ai-assistance-new';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { AiChatResponseRating } from '@avst-stitch/repository-lib/lib/models';
import {
    ChatContentRequest,
    ChatHistory,
    DeleteConversationRequest,
    RenameTitleRequest,
} from '../../data/ai-assistance';
import { Role } from '@avst-stitch/repository-lib/src/utils/chatGPT';

export interface UserNameDetails {
    email: string;
    firstName?: string;
    lastName?: string;
}

export interface AppMainProps {
    basePath: string;
    displayApiKeys: boolean;
    displaySidenav?: boolean;
    helpAndSupportOpen?: boolean;
    impersonating?: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    isConnectedToFeedback?: boolean;
    isLogOutBusy?: boolean;
    notificationBanner?: {
        message: string;
        level: 'info' | 'warning' | 'error';
    };
    onboardingCompleted?: boolean;
    aiAssistanceOnboardingCompleted?: boolean;
    organizations?: SidenavOrganization[];
    roleMissing?: boolean;
    rootPath: string;
    scriptingFamiliarityMissing?: boolean;
    selectedOrganizationUid?: string;
    selectedPath: string;
    sessionExpired?: boolean;
    showSessionExpiredWarning?: boolean;
    showUpgradePlan?: boolean;
    themeMode: ThemeMode;
    userCredentials: UserNameDetails;
    useRouter?: boolean;
    aiAssistanceOpen?: boolean;
    aiAssistanceLoading: boolean;
    aiGeneratedAnswer?: string;
    aiAssistanceIsLongResponse?: boolean;
    aiAssistanceAnswering: boolean;
    aiAssistanceChat?: {
        role: Role;
        message: string;
    }[];
    aiAssistanceAvailableCredits: number;
    aiAssistanceChatHistory: ChatHistory;
    aiAssistanceChatHistoryLoading: boolean;
    aiAssistanceRenameOrDeleteLoading: boolean;
    onSetLoadTokens(loadTokens: boolean): void;
    onStopImpersonation?(): void;
    onChangeThemeMode(mode: ThemeMode): void;
    onCreateNewOrganization(): void;
    onLogOut(): void;
    onManageAllOrganizations(): void;
    onOpenHelpAndSupport(): void;
    onSelectOrganization(uid: string): void;
    onToggleAiAssistant(): void;
    onCompleteAiOnboarding(): void;
    onAskAiAssistance?(message: string): void;
    onCloseAiAssistance(): void;
    onResetAiAssistanceChat?(): void;
    onCopyAiCodeSnippet?(): void;
    onStopAiAssistanceResponse(): void;
    onRateAiAssistanceMessage(request: { uid: string; rating: AiChatResponseRating }): void;
    onAiAssistanceChatHistoryOpen(): void;
    onAiAssistanceChatHistoryConversationTitleRename(update: RenameTitleRequest): void;
    onAiAssistanceChatHistoryConversationDelete(update: DeleteConversationRequest): void;
    onGetAiAssistanceChatHistoryConversationContent(data: ChatContentRequest): void;
}

const aiAssistanceExampleQuestions = [
    {
        prompt: 'Explain scheduled triggers',
        promptType: 'Documentation',
        icon: <MenuBookOutlinedIcon />,
    },
    {
        prompt: `When a Bitbucket Cloud pull request created event is triggered, add a comment to the Jira Cloud issue "PROJ-20" with the request's title`,
        promptType: 'Coding',
        icon: <CodeOutlinedIcon />,
    },
    {
        prompt: 'Is there a template to connect Confluence and Jira Cloud?',
        promptType: 'Templates',
        icon: <LocalLibraryOutlinedIcon />,
    },
];

const StyledWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'bannersHeight',
})<{ bannersHeight: number }>(({ theme, bannersHeight }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: `calc(100% - ${bannersHeight}px)`,
    position: 'relative',
}));

const StyledInnerContent = styled('main')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    overflowX: 'hidden',
    position: 'relative',
    width: '100%',
}));

export const AppMain: React.FC<AppMainProps> = ({
    basePath,
    displayApiKeys,
    displaySidenav = true,
    helpAndSupportOpen = false,
    impersonating,
    isConnectedToFeedback = true,
    isLogOutBusy = false,
    notificationBanner,
    onboardingCompleted = false,
    aiAssistanceOnboardingCompleted = false,
    organizations,
    roleMissing,
    rootPath,
    scriptingFamiliarityMissing,
    selectedOrganizationUid,
    selectedPath,
    sessionExpired,
    showSessionExpiredWarning,
    showUpgradePlan,
    themeMode,
    userCredentials,
    useRouter = true,
    aiAssistanceOpen = false,
    aiAssistanceAnswering,
    aiAssistanceChat = [],
    aiAssistanceLoading,
    aiGeneratedAnswer,
    aiAssistanceIsLongResponse = false,
    aiAssistanceAvailableCredits,
    aiAssistanceChatHistory,
    aiAssistanceChatHistoryLoading,
    aiAssistanceRenameOrDeleteLoading,
    onChangeThemeMode,
    onCreateNewOrganization,
    onLogOut,
    onManageAllOrganizations,
    onOpenHelpAndSupport,
    onSelectOrganization,
    onSetLoadTokens,
    onStopImpersonation,
    onToggleAiAssistant,
    onCompleteAiOnboarding,
    onAskAiAssistance,
    onCloseAiAssistance,
    onResetAiAssistanceChat,
    onCopyAiCodeSnippet,
    onStopAiAssistanceResponse,
    onRateAiAssistanceMessage,
    onAiAssistanceChatHistoryOpen,
    onAiAssistanceChatHistoryConversationTitleRename,
    onAiAssistanceChatHistoryConversationDelete,
    onGetAiAssistanceChatHistoryConversationContent,
}) => {
    const [bannersHeight, setBannersHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useResizeObserver({
        ref: containerRef,
        onResize: () => {
            if (containerRef.current) {
                setBannersHeight(containerRef.current.clientHeight);
            }
        },
    });

    const getRedirect = (): string | undefined => {
        if (useRouter) {
            if (!onboardingCompleted) {
                if (roleMissing) {
                    return `${getBasePath()}onboarding/role`;
                } else if (scriptingFamiliarityMissing) {
                    return `${getBasePath()}onboarding/scripting`;
                } else if (!window.location.pathname.split('/').includes('onboarding')) {
                    return `${getBasePath()}onboarding/flow`;
                }
            } else if (window.location.pathname === rootPath) {
                return `${getBasePath()}dashboard`;
            }
        }
    };

    return (
        <>
            <AppBanners
                isConnectedToFeedback={isConnectedToFeedback}
                impersonating={impersonating}
                onStopImpersonation={onStopImpersonation}
                notificationBanner={notificationBanner}
                showRefreshSessionWarning={showSessionExpiredWarning && sessionExpired}
                onSetLoadTokens={onSetLoadTokens}
            />
            <StyledWrapper bannersHeight={bannersHeight}>
                <CssBaseline />
                {displaySidenav && (
                    <Sidenav
                        helpAndSupportOpen={helpAndSupportOpen}
                        isLogOutBusy={isLogOutBusy}
                        organizations={organizations}
                        selectedOrganizationUid={selectedOrganizationUid}
                        selectedPath={selectedPath}
                        showUpgradePlan={showUpgradePlan}
                        themeMode={themeMode}
                        userCredentials={userCredentials}
                        useRouter={useRouter}
                        basePath={basePath}
                        displayApiKeys={displayApiKeys}
                        onChangeThemeMode={onChangeThemeMode}
                        onCreateNewOrganization={onCreateNewOrganization}
                        onLogOut={onLogOut}
                        onManageAllOrganizations={onManageAllOrganizations}
                        onOpenHelpAndSupport={onOpenHelpAndSupport}
                        onSelectOrganization={onSelectOrganization}
                        onToggleAiAssistant={onToggleAiAssistant}
                        aiAssistantOpen={aiAssistanceOpen}
                    />
                )}
                <StyledInnerContent>
                    {useRouter && (
                        <>
                            <Outlet />
                            <Navigate to={getRedirect()} />
                        </>
                    )}
                </StyledInnerContent>
                <AiAssistanceDialog
                    open={aiAssistanceOpen}
                    examples={aiAssistanceExampleQuestions}
                    asking={aiAssistanceLoading}
                    answering={aiAssistanceAnswering}
                    aiGeneratedAnswer={aiGeneratedAnswer}
                    chat={aiAssistanceChat}
                    aiAssistanceOnboardingCompleted={aiAssistanceOnboardingCompleted}
                    onSend={(msg) => {
                        onAskAiAssistance?.(msg);
                    }}
                    onClose={() => onCloseAiAssistance?.()}
                    onReset={() => onResetAiAssistanceChat?.()}
                    onCopy={onCopyAiCodeSnippet}
                    aiAssistanceIsLongResponse={aiAssistanceIsLongResponse}
                    onCompleteOnboarding={onCompleteAiOnboarding}
                    userCredentials={userCredentials}
                    onStopAiAssistanceResponse={onStopAiAssistanceResponse}
                    availableCredits={aiAssistanceAvailableCredits}
                    onRateAiAssistanceMessage={onRateAiAssistanceMessage}
                    chatHistory={aiAssistanceChatHistory}
                    renameOrDeleteLoading={aiAssistanceRenameOrDeleteLoading}
                    onChatHistoryOpen={onAiAssistanceChatHistoryOpen}
                    chatHistoryLoading={aiAssistanceChatHistoryLoading}
                    onChatHistoryConversationTitleRename={onAiAssistanceChatHistoryConversationTitleRename}
                    onChatHistoryConversationDelete={onAiAssistanceChatHistoryConversationDelete}
                    onGetChatHistoryConversationContent={onGetAiAssistanceChatHistoryConversationContent}
                />
            </StyledWrapper>
        </>
    );
};
