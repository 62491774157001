import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import { Badge } from '../common/Badge';
import { Button } from '../common/buttons/Button';
import { IconCircle } from '../common/IconCircle';
import { InfoIcon } from '../icons/InfoIcon';
import {
    StyledBadgeContainer,
    StyledOrganizationName,
    StyledSectionContainer,
    StyledSectionHeader,
    StyledUsageBlockContainer,
    StyledUsageBlockSubtitleContainer,
    StyledUsageDataText,
} from './DashboardComponents';

export interface DashboardUsageProps {
    plan: string;
    organizationName: string;
    usage: {
        lastUpdated: string;
        connectors: {
            limit?: number | string;
            consumed?: number;
        };
        invocations: {
            limit?: number;
            consumed?: number;
        };
        workspaces: {
            limit: number | string;
            consumed?: number;
        };
    };
    onViewPlans: () => void;
}

export const DashboardUsage: React.FC<DashboardUsageProps> = ({
    plan,
    organizationName,
    usage: { lastUpdated, connectors, invocations, workspaces },
    onViewPlans,
}) => {
    return (
        <StyledSectionContainer>
            <StyledSectionHeader>
                <Typography variant="h6">Usage</Typography>
                <StyledBadgeContainer>
                    <Badge
                        icon={<FolderSharedOutlinedIcon />}
                        label={<StyledOrganizationName>{organizationName}</StyledOrganizationName>}
                        size="small"
                    />
                    <Badge label={`Last updated: ${lastUpdated}`} severity="paper" size="small" />
                    <Button data-pendo={'viewPlans'} size="small" variant="outlined" onClick={onViewPlans}>
                        View plans
                    </Button>
                </StyledBadgeContainer>
            </StyledSectionHeader>
            <Divider />
            <Box display="flex">
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<StyleOutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Current plan</Typography>
                        <InfoIcon tooltip="The current plan type of your team." />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>{plan}</StyledUsageDataText>
                </StyledUsageBlockContainer>
                <Divider orientation="vertical" flexItem />
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<PowerOutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Connectors</Typography>
                        <InfoIcon
                            size="large"
                            tooltip="Connectors link ScriptRunner Connect to third-party services. The limit is based on the plan type of your team."
                        />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>
                        {connectors.consumed} / {connectors.limit}
                    </StyledUsageDataText>
                </StyledUsageBlockContainer>
                <Divider orientation="vertical" flexItem />
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<AllInclusiveOutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Script invocations</Typography>
                        <InfoIcon
                            size="large"
                            tooltip="Script invocations are triggered scripts that perform tasks. They can be run manually or automatically."
                        />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>
                        {invocations?.consumed?.toLocaleString()} / {invocations?.limit?.toLocaleString()}
                    </StyledUsageDataText>
                </StyledUsageBlockContainer>
                <Divider orientation="vertical" flexItem />
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<Inventory2OutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Workspaces</Typography>
                        <InfoIcon size="large" tooltip="Workspaces are where all of your integrations live and run." />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>
                        {workspaces.consumed} / {workspaces.limit}
                    </StyledUsageDataText>
                </StyledUsageBlockContainer>
            </Box>
        </StyledSectionContainer>
    );
};
