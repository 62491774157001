import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/error';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface ZenDeskConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface ZenDeskAppSetupSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export const zenDeskManageConnectionOpenDialogAction$ = monitor(
    'zenDeskManageConnectionOpenDialogAction$',
    new Subject<ZenDeskConnectionDetails>()
);
export const zenDeskManageConnectionCloseDialogAction$ = monitor(
    'zenDeskManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const zenDeskAppSetupOpenDialogAction$ = monitor(
    'zenDeskAppSetupOpenDialogAction$',
    new Subject<ZenDeskConnectionDetails>()
);
export const zenDeskAppSetupCloseDialogAction$ = monitor('zenDeskAppSetupCloseDialogAction$', new Subject<void>());

export const zenDeskAppSetupDialogOpen$ = monitor('zenDeskAppSetupDialogOpen$', new BehaviorSubject(false));
export const zenDeskAppSetupAuthorizeAction$ = monitor(
    'zenDeskAppSetupAuthorizeAction$',
    new Subject<ZenDeskAppSetupSaveProps>()
);
export const zenDeskAppSetupDialogErrors$ = monitor(
    'zenDeskAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const zenDeskSaveConnectionAction$ = monitor('zenDeskSaveConnectionAction$', new Subject<string>());
export const zenDeskConnectionCreatedAction$ = monitor('zenDeskConnectionCreatedAction$', new Subject<string>());
export const zenDeskConnectionSavedAction$ = monitor(
    'zenDeskConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const zenDeskManageConnectionInitiateSetupAction$ = monitor(
    'zenDeskManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const zenDeskManageConnectionDialogOpen$ = monitor(
    'zenDeskManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const zenDeskConnectionSaving$ = monitor('zenDeskConnectionSaving$', new BehaviorSubject(false));
export const zenDeskManageConnectionAuthorizeLoading$ = monitor(
    'zenDeskManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const zenDeskManageConnectionDetails$ = monitor(
    'zenDeskManageConnectionDetails$',
    new BehaviorSubject<ZenDeskConnectionDetails | undefined>(undefined)
);
export const zenDeskManageConnectionDialogErrors$ = monitor(
    'zenDeskManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const zenDeskAppSetupDialogStage$ = monitor('zenDeskAppSetupDialogStage$', new BehaviorSubject(0));

zenDeskAppSetupOpenDialogAction$.subscribe((details) => {
    zenDeskAppSetupDialogErrors$.next(undefined);
    zenDeskManageConnectionDialogErrors$.next(undefined);
    zenDeskAppSetupDialogOpen$.next(true);
    zenDeskAppSetupDialogStage$.next(0);
    zenDeskManageConnectionDetails$.next(details);
});

zenDeskAppSetupCloseDialogAction$.subscribe(() => {
    zenDeskAppSetupDialogOpen$.next(false);
    zenDeskManageConnectionAuthorizeLoading$.next(false);
});

zenDeskManageConnectionOpenDialogAction$.subscribe((details) => {
    zenDeskAppSetupDialogErrors$.next(undefined);
    zenDeskManageConnectionDialogErrors$.next(undefined);
    zenDeskManageConnectionDialogOpen$.next(true);
    zenDeskManageConnectionDetails$.next(details);
});

zenDeskManageConnectionCloseDialogAction$.subscribe(() => {
    zenDeskManageConnectionDialogOpen$.next(false);
    zenDeskAppSetupDialogOpen$.next(false);
    zenDeskManageConnectionDetails$.next(undefined);
});

zenDeskConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

zenDeskAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            zenDeskConnectionSaving$.next(true);
            zenDeskAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = zenDeskManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('ZenDesk Connector type not defined');
                }
                if (!uid) {
                    throw Error('ZenDesk Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.zenDesk?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No ZenDesk Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'zendesk',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, zenDeskManageConnectionAuthorizeLoading$);

                zenDeskConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    zenDeskAppSetupDialogErrors$.next(e.message);
                } else {
                    zenDeskAppSetupDialogErrors$.next(
                        'Failed to save ZenDesk application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving ZenDesk application info', e);
                }
            }

            zenDeskConnectionSaving$.next(false);
            zenDeskManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

zenDeskSaveConnectionAction$
    .pipe(
        map(async (name) => {
            zenDeskConnectionSaving$.next(true);
            zenDeskManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = zenDeskManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('ZenDesk Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveZenDeskConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            proceedButton: {
                                onClick: async () => {
                                    await saveZenDeskConnection(uid, name, connectionTypeUid);
                                },
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        proceedButton: {
                            onClick: async () => {
                                try {
                                    const { uid } = await createConnection(
                                        connectionType.uid,
                                        name,
                                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                                        connectionType.eventListenerTypes[0]?.uid ?? ''
                                    );

                                    zenDeskConnectionCreatedAction$.next(uid);
                                    zenDeskConnectionSavedAction$.next({ uid, connectionTypeUid });
                                    zenDeskManageConnectionCloseDialogAction$.next();
                                    publishLocalFeedbackEventAction$.next({
                                        level: 'SUCCESS',
                                        message: 'Connector created.',
                                    });
                                    connectionCreatedFirstTimeAction$.next();
                                } catch (e) {
                                    handleManageConnectionError(
                                        e,
                                        zenDeskManageConnectionDialogErrors$,
                                        APP.ZENDESK.NAME
                                    );
                                }
                            },
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, zenDeskManageConnectionDialogErrors$, APP.ZENDESK.NAME);
            }

            zenDeskConnectionSaving$.next(false);
        })
    )
    .subscribe();

zenDeskManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            zenDeskConnectionSaving$.next(true);
            zenDeskManageConnectionAuthorizeLoading$.next(true);
            zenDeskManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = zenDeskManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('ZenDesk Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    zenDeskConnectionSavedAction$.next({ uid, connectionTypeUid });
                    zenDeskAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    zenDeskConnectionCreatedAction$.next(uid);
                    zenDeskConnectionSavedAction$.next({ uid, connectionTypeUid });
                    zenDeskManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    zenDeskConnectionSaving$.next(false);

                    zenDeskAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, zenDeskManageConnectionDialogErrors$, APP.ZENDESK.NAME);
                zenDeskManageConnectionAuthorizeLoading$.next(false);
            }

            zenDeskConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(zenDeskConnectionSavedAction$);

const saveZenDeskConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        zenDeskConnectionSavedAction$.next({ uid, connectionTypeUid });
        zenDeskManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, zenDeskManageConnectionDialogErrors$, APP.ZENDESK.NAME);
    }
};
