import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Button } from '../common/buttons/Button';
import { Indicator } from '../common/Indicator';
import { SRConnectIcon } from '../icons/SRConnectIcon';
import { StyledBorderBoxHeader } from '../layout/BorderBoxComponents';
import { StyledMainActions } from '../layout/LayoutComponents';
import { documentationUrl, supportPortalUrl } from '../../utils/documentation';
import { WorkspaceLockedAlert } from '../workspace-layouts/WorkspaceLockedAlert';

interface SetupGuideCongratulationsScreenProps {
    workspaceLocked?: boolean;
    onContinue: () => void;
    onBackToWorkspaces: () => void;
    onUnlockWorkspace: () => void;
}

interface Step {
    icon: JSX.Element;
    primary: string;
    secondary: string;
}

const StyledPageContent = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
}));

const StyledHeader = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2, 0),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '1px solid ' + theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    gap: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        height: 28,
        width: 28,
    },
    '& .MuiTypography-root': {
        fontSize: theme.typography.h6.fontSize,
    },
}));

export const StyledCongratulationsContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
}));

const StyledSuccessIconContainer = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: theme.constants.radiusCircle,
    display: 'flex',
    justifyContent: 'center',
    height: 56,
    width: 56,
    padding: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
        color: theme.palette.getContrastText(theme.palette.success.main),
        height: 32,
        width: 32,
    },
}));

const StyledCongratulationsTextContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    flexDirection: 'column',
}));

const StyledNextStepsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexDirection: 'column',
    maxWidth: 720,
}));

const StyledLinksContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    flexDirection: 'row',
}));

const StyledBottomContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(3, 0),
    gap: theme.spacing(1),
    flexDirection: 'column',
}));

const StyledContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}));

const StyledItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    padding: theme.spacing(1.5),
    flexDirection: 'column',
    flex: '1 0 0',
    alignSelf: 'stretch',
    gap: theme.spacing(1.5),
}));

const StyledItemText = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
}));

const StyledNumberCircle = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: 24,
    width: 24,
    justifyContent: 'center',
    borderRadius: theme.constants.radiusCircle,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
}));

export const SetupGuideCongratulationsScreen: React.FC<SetupGuideCongratulationsScreenProps> = ({
    workspaceLocked = false,
    onContinue,
    onBackToWorkspaces,
    onUnlockWorkspace,
}) => {
    const steps: Step[] = [
        {
            icon: <Inventory2OutlinedIcon />,
            primary: 'Access your workspace',
            secondary: 'Monitor your integrations easily with a single view.',
        },
        {
            icon: <ChecklistOutlinedIcon />,
            primary: 'Check your workspace',
            secondary: 'Get access to all the resources that make up your integration.',
        },
        {
            icon: <AutoAwesomeOutlinedIcon />,
            primary: 'Explore new features',
            secondary: 'Maximise your integrations with a range of features, from the essentials to the extras.',
        },
    ];

    return (
        <StyledPageContent>
            <StyledHeader>
                <SRConnectIcon />
                <Typography>ScriptRunner Connect</Typography>
            </StyledHeader>
            {workspaceLocked && <WorkspaceLockedAlert onUnlockWorkspace={onUnlockWorkspace} />}
            <StyledCongratulationsContainer>
                <StyledSuccessIconContainer>
                    <CheckIcon color="success" />
                </StyledSuccessIconContainer>
                <StyledCongratulationsTextContainer>
                    <Typography variant="h5">Congratulations!</Typography>
                    <Typography color="text.secondary">Your integration is now functional</Typography>
                </StyledCongratulationsTextContainer>
            </StyledCongratulationsContainer>
            <StyledNextStepsContainer>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        What will happen next?
                    </Typography>
                </StyledBorderBoxHeader>
                <Divider />
                <StyledContent>
                    {steps.map((card, index) => (
                        <StyledItem key={index}>
                            <Indicator
                                left={{ icon: <StyledNumberCircle>{index + 1}</StyledNumberCircle> }}
                                right={{ icon: card.icon }}
                            />
                            <StyledItemText>
                                <Typography variant="subtitle1">{card.primary}</Typography>
                                <Typography color="text.secondary">{card.secondary}</Typography>
                            </StyledItemText>
                        </StyledItem>
                    ))}
                </StyledContent>
                <Divider />
                <StyledMainActions>
                    <Button data-pendo={'backToWorkpaceListing'} variant="outlined" onClick={onBackToWorkspaces}>
                        Back to workspaces listing
                    </Button>
                    <Button data-pendo={'continueToAdvancedView'} disabled={workspaceLocked} onClick={onContinue}>
                        Continue to advanced view
                    </Button>
                </StyledMainActions>
            </StyledNextStepsContainer>
            <StyledBottomContainer>
                <Typography variant="h6">Have questions?</Typography>
                <StyledLinksContainer>
                    <Typography>Visit our</Typography>
                    <Link underline="always" target="_blank" href={documentationUrl}>
                        Documentation
                    </Link>
                    <Typography>or</Typography>
                    <Link underline="always" target="_blank" href={supportPortalUrl}>
                        Contact Support
                    </Link>
                </StyledLinksContainer>
            </StyledBottomContainer>
        </StyledPageContent>
    );
};
