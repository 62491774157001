import { useObservableState } from 'observable-hooks';
import { OrganizationPlanSuccessStateDialog } from '../../../components/organizations/plan/OrganizationPlanSuccessStateDialog';
import {
    organizationPlanSuccessStateDialogOpen$,
    organizationPlanSuccessStateContent$,
    closeOrganizationPlanSuccessStateDialogAction$,
} from '../../../store/organization/changeOrganizationPlan';
import { teamPlans$ } from '../../../store/organizations';

export const OrganizationPlanSuccessStateContainer: React.FC = () => {
    const organizationPlanSuccessStateDialogOpen = useObservableState(organizationPlanSuccessStateDialogOpen$);
    const organizationPlanSuccessStateContent = useObservableState(organizationPlanSuccessStateContent$);
    const teamPlans = useObservableState(teamPlans$);
    const nextTeamPlan = teamPlans.find((plan) => plan.tier === organizationPlanSuccessStateContent?.tier);

    return (
        <OrganizationPlanSuccessStateDialog
            open={organizationPlanSuccessStateDialogOpen}
            successState={organizationPlanSuccessStateContent?.state ?? 'create'}
            onClose={() => closeOrganizationPlanSuccessStateDialogAction$.next()}
            teamPlan={{
                tier: nextTeamPlan?.tier ?? 'basic',
                connectorsLimit: nextTeamPlan?.connectorsLimit ?? 4,
                invocationsLimit: nextTeamPlan?.invocationsLimit ?? 100,
            }}
            lastTier={organizationPlanSuccessStateContent?.lastTier}
        />
    );
};
