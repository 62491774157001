import { LoaderFn } from 'react-location';
import { getUsers, UsersReportRequest } from '../../data/report/users';
import { LocationGenerics } from '../../router';
import { users$ } from '../../store/report/users';

export const usersReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const users = await getUsers({
        email: routeMatch.search.email as string,
        emailComparator: routeMatch.search.emailComparator as UsersReportRequest['emailComparator'],
        workspaces: routeMatch.search.workspaces as number,
        workspacesComparator: routeMatch.search.workspacesComparator as UsersReportRequest['workspacesComparator'],
        connections: routeMatch.search.connections as number,
        connectionsComparator: routeMatch.search.connectionsComparator as UsersReportRequest['connectionsComparator'],
        orderByField: routeMatch.search.orderByField as UsersReportRequest['orderByField'],
        orderByDirection: routeMatch.search.orderByDirection as UsersReportRequest['orderByDirection'],
        onboardedUsersOnly: routeMatch.search.onboardedUsersOnly as boolean,
    });
    users$.next(users);

    return {};
};
