import { styled } from '@mui/material';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { Radio } from './Radio';

interface RadioButton {
    'data-testid'?: string;
    label: string;
    value: string;
}

interface CustomRadioGroupProps extends Omit<RadioGroupProps, 'onChange'> {
    border?: boolean;
    buttons: RadioButton[];
    onChange: (value: string) => void;
}

const StyledRadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
    gap: theme.spacing(2),
}));

export const RadioGroup: React.FC<CustomRadioGroupProps> = ({ border = false, buttons, value, onChange, ...props }) => {
    const displayedButtons = buttons.map((b) => (
        <Radio
            border={border}
            data-testid={b['data-testid']}
            label={b.label}
            checked={b.value === value}
            onCheck={() => onChange(b.value)}
        />
    ));

    return (
        <StyledRadioGroup value={value} {...props}>
            {displayedButtons}
        </StyledRadioGroup>
    );
};
