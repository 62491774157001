import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { Button } from '../../common/buttons/Button';
import { OrganizationPlan } from '../../../data/organization';

interface BillingPlanCardProps {
    active?: boolean;
    disabled?: boolean;
    price: number | string;
    priceBy?: 'month' | 'year';
    planName: string;
    planDescription: string;
    priceDescription: string;
    buttonText?: string;
    planPosition?: 'left' | 'right' | 'middle';
    activePlanInPeriod?: boolean;
    connectors: number | string;
    functionInvocations: number | string;
    loading?: boolean;
    subscriptionStatus?: OrganizationPlan['subscriptionStatus'];
    pendoAttribute?: string;
    onUpgrade(): void;
}

const StyledCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'planPosition' && prop !== 'activePlan',
})<{ planPosition: 'left' | 'right' | 'middle'; activePlan?: boolean }>(({ theme, planPosition, activePlan }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    borderRadius: 0,
    borderBottomLeftRadius: planPosition === 'left' ? theme.spacing(1) : 0,
    borderTopLeftRadius: planPosition === 'left' && !activePlan ? theme.spacing(1) : 0,
    borderBottomRightRadius: planPosition === 'right' ? theme.spacing(1) : 0,
    borderTopRightRadius: planPosition === 'right' && !activePlan ? theme.spacing(1) : 0,
    backgroundColor: theme.palette.background.paper,
    cursor: 'default',
    padding: theme.spacing(2),
    position: 'relative',
    boxShadow: 'none',
    outline: `1px solid ${theme.palette.divider}`,
    width: 234,
    '& .MuiCardContent-root': {
        paddingBottom: '0px !important',
    },
    '&.disabled': {
        pointerEvents: 'none',
        '& .MuiTypography-root, .MuiCheckbox-root': {
            color: theme.palette.text.disabled,
        },
    },
}));

const StyledPlanHeaderContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'planStatus',
})<{ planStatus?: string }>(({ theme, planStatus }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    visibility:
        planStatus === 'active' || planStatus === 'cancelled' || planStatus === 'suspended' ? undefined : 'hidden',
    background:
        planStatus === 'active'
            ? theme.palette.success.main
            : planStatus === 'suspended'
            ? theme.palette.warning.main
            : planStatus === 'cancelled'
            ? theme.palette.error.main
            : undefined,
    color:
        planStatus === 'active' || planStatus === 'cancelled'
            ? theme.palette.primary.contrastText
            : planStatus === 'suspended'
            ? theme.palette.text.primary
            : undefined,
}));

export const BillingPlanCardV2: React.FC<BillingPlanCardProps> = ({
    active,
    disabled = false,
    loading = false,
    price,
    priceBy = 'month',
    planName,
    planDescription,
    priceDescription,
    connectors,
    functionInvocations,
    planPosition = 'middle',
    activePlanInPeriod = false,
    buttonText = 'Select plan',
    subscriptionStatus,
    pendoAttribute,
    onUpgrade,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const planHeaderText = !active
        ? 'Other'
        : subscriptionStatus === 'CANCELLED'
        ? 'Plan suspended'
        : subscriptionStatus === 'SUSPENDED'
        ? 'Plan cancelled'
        : 'Current plan';

    return (
        <Box>
            {activePlanInPeriod && (
                <StyledPlanHeaderContainer
                    planStatus={
                        !active
                            ? undefined
                            : subscriptionStatus === 'CANCELLED'
                            ? 'suspended'
                            : subscriptionStatus === 'SUSPENDED'
                            ? 'cancelled'
                            : 'active'
                    }
                >
                    <Typography variant="subtitle2">{planHeaderText}</Typography>
                </StyledPlanHeaderContainer>
            )}
            <StyledCard planPosition={planPosition} activePlan={active}>
                <Box>
                    <Typography variant="h6">{planName}</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {planDescription}
                    </Typography>
                </Box>
                <Box>
                    {typeof price === 'string' ? (
                        <Typography variant="h4">{price}</Typography>
                    ) : (
                        <Box display="flex" gap={0.5}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle1">$</Typography>
                            </Box>
                            <Typography variant="h4">{Math.round(price).toLocaleString('en-US')}</Typography>
                            <Box display="flex" flexDirection="column" justifyContent="flex-end">
                                <Typography variant="body1">/{priceBy}</Typography>
                            </Box>
                        </Box>
                    )}
                    <Typography variant="body1" color="text.secondary">
                        {priceDescription}
                    </Typography>
                </Box>
                <Button
                    disabled={disabled}
                    busy={loading}
                    fullWidth
                    variant={active ? 'outlined' : 'contained'}
                    data-pendo={pendoAttribute}
                    onClick={onUpgrade}
                >
                    {buttonText}
                </Button>
                <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="subtitle2">Key features</Typography>
                    <Divider />
                    <Box display="flex" alignItems="center" gap={1}>
                        <PowerOutlinedIcon />
                        <Typography variant="body1">
                            <strong>{connectors === -1 ? 'Unlimited' : connectors}</strong> connectors
                        </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" alignItems="center" gap={1}>
                        <CodeOutlinedIcon />
                        <Typography variant="body1">
                            <strong>
                                {typeof functionInvocations === 'number' && functionInvocations >= 500000
                                    ? 'Unlimited'
                                    : functionInvocations}
                            </strong>{' '}
                            executions /mo
                        </Typography>
                    </Box>
                </Box>
            </StyledCard>
        </Box>
    );
};
