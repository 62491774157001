import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
    StyledMenuItemText,
} from '../side-navigation/SidenavComponents';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface ChatHistoryItemContextMenuProps {
    anchor: HTMLButtonElement | null;
    setAnchor(anchor: null): void;
    onRenameDialogOpen(): void;
    onDeleteDialogOpen(): void;
}

export const ChatHistoryItemContextMenu: React.FC<ChatHistoryItemContextMenuProps> = ({
    anchor,
    setAnchor,
    onRenameDialogOpen,
    onDeleteDialogOpen,
}) => {
    return (
        <ClickAwayListener onClickAway={() => setAnchor(null)}>
            <StyledMenu
                hideBackdrop
                sx={{
                    width: 160,
                }}
                open={!!anchor}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -100,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setAnchor(null)}
            >
                <StyledMenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setAnchor(null);
                        onRenameDialogOpen();
                    }}
                >
                    <StyledMenuItemContent>
                        <EditOutlinedIcon />
                        <StyledMenuItemText>Rename</StyledMenuItemText>
                    </StyledMenuItemContent>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setAnchor(null);
                        onDeleteDialogOpen();
                    }}
                >
                    <StyledMenuItemContent>
                        <DeleteOutlinedIcon />
                        <StyledMenuItemText>Delete</StyledMenuItemText>
                    </StyledMenuItemContent>
                </StyledMenuItem>
            </StyledMenu>
        </ClickAwayListener>
    );
};
