import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/error';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface MicrosoftConnectionDetails extends ConnectionDetails {
    tenantId?: string;
    clientId?: string;
    clientSecret?: string;
}

interface MicrosoftAppSetupSaveProps {
    clientId: string;
    clientSecret?: string;
    tenantId: string;
}

interface MicrosoftConnectionSavedDetails {
    uid: string;
    connectionTypeUid: string;
    tenantId: string;
}

export const microsoftManageConnectionOpenDialogAction$ = monitor(
    'microsoftManageConnectionOpenDialogAction$',
    new Subject<MicrosoftConnectionDetails>()
);
export const microsoftManageConnectionCloseDialogAction$ = monitor(
    'microsoftManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const microsoftAppSetupOpenDialogAction$ = monitor(
    'microsoftAppSetupOpenDialogAction$',
    new Subject<MicrosoftConnectionDetails>()
);
export const microsoftAppSetupCloseDialogAction$ = monitor('microsoftAppSetupCloseDialogAction$', new Subject<void>());
export const microsoftAppSetupDialogOpen$ = monitor('microsoftAppSetupDialogOpen$', new BehaviorSubject(false));
export const microsoftAppSetupAuthorizeAction$ = monitor(
    'microsoftAppSetupAuthorizeAction$',
    new Subject<MicrosoftAppSetupSaveProps>()
);
export const microsoftAppSetupDialogErrors$ = monitor(
    'microsoftAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const microsoftSaveConnectionAction$ = monitor('microsoftSaveConnectionAction$', new Subject<string>());
export const microsoftConnectionCreatedAction$ = monitor('microsoftConnectionCreatedAction$', new Subject<string>());
export const microsoftConnectionSavedAction$ = monitor(
    'microsoftConnectionSavedAction$',
    new Subject<MicrosoftConnectionSavedDetails>()
);
export const microsoftManageConnectionInitiateSetupAction$ = monitor(
    'microsoftManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const microsoftManageConnectionDialogOpen$ = monitor(
    'microsoftManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const microsoftConnectionSaving$ = monitor('microsoftConnectionSaving$', new BehaviorSubject(false));
export const microsoftManageConnectionAuthorizeLoading$ = monitor(
    'microsoftManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const microsoftManageConnectionDetails$ = monitor(
    'microsoftManageConnectionDetails$',
    new BehaviorSubject<MicrosoftConnectionDetails | undefined>(undefined)
);
export const microsoftManageConnectionDialogErrors$ = monitor(
    'microsoftManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const microsoftAppSetupDialogStage$ = monitor('microsoftAppSetupDialogStage$', new BehaviorSubject(0));

microsoftAppSetupOpenDialogAction$.subscribe((details) => {
    microsoftAppSetupDialogErrors$.next(undefined);
    microsoftManageConnectionDialogErrors$.next(undefined);
    microsoftAppSetupDialogOpen$.next(true);
    microsoftAppSetupDialogStage$.next(0);
    microsoftManageConnectionDetails$.next(details);
});

microsoftAppSetupCloseDialogAction$.subscribe(() => {
    microsoftAppSetupDialogOpen$.next(false);
    microsoftManageConnectionAuthorizeLoading$.next(false);
});

microsoftManageConnectionOpenDialogAction$.subscribe((details) => {
    microsoftAppSetupDialogErrors$.next(undefined);
    microsoftManageConnectionDialogErrors$.next(undefined);
    microsoftManageConnectionDialogOpen$.next(true);
    microsoftManageConnectionDetails$.next(details);
});

microsoftManageConnectionCloseDialogAction$.subscribe(() => {
    microsoftManageConnectionDialogOpen$.next(false);
    microsoftAppSetupDialogOpen$.next(false);
    microsoftManageConnectionDetails$.next(undefined);
});

microsoftConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

microsoftAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            microsoftConnectionSaving$.next(true);
            microsoftAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = microsoftManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Microsoft Connector type not defined');
                }

                if (!uid) {
                    throw Error('Microsoft Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.microsoft?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Microsoft Connector URL configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        connectionId: uid,
                        connectionType: 'microsoft',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, microsoftManageConnectionAuthorizeLoading$);

                microsoftConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    microsoftAppSetupDialogErrors$.next(e.message);
                } else {
                    microsoftAppSetupDialogErrors$.next(
                        'Failed to save Microsoft application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Microsoft application info', e);
                }
            }

            microsoftConnectionSaving$.next(false);
            microsoftManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

microsoftSaveConnectionAction$
    .pipe(
        map(async (name) => {
            microsoftConnectionSaving$.next(true);
            microsoftManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType, tenantId = '' } = microsoftManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Microsoft Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveConnection(uid, name);

                        microsoftConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                        microsoftManageConnectionCloseDialogAction$.next();
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Connector saved.',
                        });
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            proceedButton: {
                                onClick: async () => {
                                    await saveConnection(uid, name);

                                    microsoftConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                                    microsoftManageConnectionCloseDialogAction$.next();
                                    publishLocalFeedbackEventAction$.next({
                                        level: 'SUCCESS',
                                        message: 'Connector saved.',
                                    });
                                },
                            },
                        });
                    }
                    if (connectionCreatedFirstTime$.value) {
                        connectionCreatedFirstTimeAction$.next();
                        connectionCreatedFirstTime$.next(false);
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        proceedButton: {
                            onClick: async () => {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                microsoftConnectionCreatedAction$.next(uid);
                                microsoftConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                                microsoftManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            },
                        },
                    });
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    microsoftManageConnectionDialogErrors$.next(e.message);
                } else {
                    microsoftManageConnectionDialogErrors$.next(
                        'Failed to save Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Microsoft Connector', e);
                }
            }

            microsoftConnectionSaving$.next(false);
        })
    )
    .subscribe();

microsoftManageConnectionInitiateSetupAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (name) => {
            microsoftConnectionSaving$.next(true);
            microsoftManageConnectionAuthorizeLoading$.next(true);
            microsoftManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                tenantId = '',
                name: currentName,
                clientId,
                clientSecret,
            } = microsoftManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Microsoft Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    microsoftConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                    if (tenantId && clientId) {
                        microsoftAppSetupAuthorizeAction$.next({
                            tenantId,
                            clientId,
                        });
                    } else {
                        microsoftAppSetupOpenDialogAction$.next({
                            uid,
                            connectionType,
                            name,
                            clientId,
                            clientSecret,
                            tenantId,
                        });
                    }
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    microsoftConnectionCreatedAction$.next(uid);
                    microsoftConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                    microsoftManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    microsoftConnectionSaving$.next(false);

                    microsoftAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    microsoftManageConnectionDialogErrors$.next(e.message);
                } else {
                    microsoftManageConnectionDialogErrors$.next(
                        'Failed to save Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Microsoft Connector', e);
                }
                microsoftManageConnectionAuthorizeLoading$.next(false);
            }

            microsoftConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(microsoftConnectionSavedAction$);
