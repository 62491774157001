import { Button } from '../../common/buttons/Button';
import { SetupGuideEventListenerScreen } from './event-listeners/SetupGuideEventListenerScreen';
import { SetupGuideScheduledTriggerScreen } from './scheduled-triggers/SetupGuideScheduledTriggerScreen';
import { StyledStepContainer } from '../SetupGuideComponents';
import { ToggleButtonGroup } from '../../common/buttons/button-groups/ToggleButtonGroup';
import { SetupGuideEventListener, SetupGuideScheduledTrigger } from '../types';
import { StyledBorderBox, StyledBorderBoxContent } from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';

export type TriggerScreenType = 'event' | 'schedule';

interface SetupGuideTriggerScreenProps {
    errors?: string;
    eventListeners?: SetupGuideEventListener[];
    nextStepName?: string;
    saving?: boolean;
    scheduledTriggers?: SetupGuideScheduledTrigger[];
    selectedScreen: TriggerScreenType;
    stepRequired?: boolean;
    templateMode?: boolean;
    workspaceLocked?: boolean;
    onChangeScreen: (screenType: TriggerScreenType) => void;
    onContinue: () => void;
    onCreateNewEventListener: () => void;
    onCreateNewScheduledTrigger: () => void;
    onDeleteEventListener: (uid: string) => void;
    onDeleteScheduledTrigger: (uid: string) => void;
    onEditEventListener: (uid: string) => void;
    onEditScheduledTrigger: (uid: string) => void;
    onGoBack: () => void;
}

export const SetupGuideTriggerScreen: React.FC<SetupGuideTriggerScreenProps> = ({
    errors,
    eventListeners = [],
    nextStepName,
    saving = false,
    scheduledTriggers = [],
    selectedScreen,
    stepRequired = false,
    templateMode = false,
    workspaceLocked = false,
    onChangeScreen,
    onContinue,
    onCreateNewEventListener,
    onCreateNewScheduledTrigger,
    onDeleteEventListener,
    onDeleteScheduledTrigger,
    onEditEventListener,
    onEditScheduledTrigger,
    onGoBack,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const hasBothResources = eventListeners.length > 0 && scheduledTriggers.length > 0;
    const resourcesNeedSetup = [...eventListeners, ...scheduledTriggers].some((r) => r.needsSetup);
    const resourcesDeleting = [...eventListeners, ...scheduledTriggers].some((r) => r.deleting);
    const setupComplete = !resourcesNeedSetup && (!templateMode ? hasBothResources : true); // TODO: Validate requirements for blank workspace flow. Currently necessary to create 1 scheduled trigger and 1 event listener.
    const canSave = setupComplete && !resourcesDeleting && !workspaceLocked;

    const toggleButtons = [
        {
            label: 'Event listeners',
            selected: selectedScreen === 'event',
            value: 'event',
        },
        {
            label: 'Scheduled triggers',
            selected: selectedScreen === 'schedule',
            value: 'schedule',
        },
    ];

    const nextStepPendoAttribute = nextStepName === 'Parameters' ? 'progressToParameters' : undefined;

    const actions = (
        <>
            <Button disabled={resourcesDeleting} variant="outlined" onClick={onGoBack}>
                Back
            </Button>
            {stepRequired ? (
                <Button busy={saving} data-pendo={nextStepPendoAttribute} disabled={!canSave} onClick={onContinue}>
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : canSave ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={resourcesDeleting || workspaceLocked}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : (
                <Button
                    data-pendo={nextStepPendoAttribute}
                    disabled={resourcesDeleting || workspaceLocked}
                    variant="outlined"
                    onClick={onContinue}
                >
                    {nextStepName ? `Skip to ${nextStepName}` : 'Skip'}
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <StyledBorderBox>
                <StyledBorderBoxContent>
                    <ToggleButtonGroup
                        buttons={toggleButtons}
                        exclusive
                        fullWidth
                        onChange={(_, value) => onChangeScreen(value)}
                    />
                </StyledBorderBoxContent>
            </StyledBorderBox>
            {selectedScreen === 'event' ? (
                <SetupGuideEventListenerScreen
                    errors={errors}
                    eventListeners={eventListeners}
                    templateMode={templateMode}
                    workspaceLocked={workspaceLocked}
                    onCreateNewEventListener={onCreateNewEventListener}
                    onDeleteEventListener={onDeleteEventListener}
                    onEditEventListener={onEditEventListener}
                />
            ) : (
                <SetupGuideScheduledTriggerScreen
                    errors={errors}
                    scheduledTriggers={scheduledTriggers}
                    templateMode={templateMode}
                    workspaceLocked={workspaceLocked}
                    onCreateNewScheduledTrigger={onCreateNewScheduledTrigger}
                    onDeleteScheduledTrigger={onDeleteScheduledTrigger}
                    onEditScheduledTrigger={onEditScheduledTrigger}
                />
            )}
            <StyledMainActions>{actions}</StyledMainActions>
        </StyledStepContainer>
    );
};
