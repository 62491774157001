import MuiLink from '@mui/material/Link';
import { RouterLink } from '../common/RouterLink';

export const Reports: React.FC = () => {
    return (
        <ul>
            <li>
                <MuiLink component={RouterLink} to="./users">
                    Users
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./teams">
                    Teams
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./invocations">
                    Invocations
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./anonymous">
                    Anonymous Users
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./connectionTypes">
                    Connection Types
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./usersWithDeployedWorkspaces">
                    Users With Deployed Workspaces
                </MuiLink>
            </li>
            {/* <li>
                <Link to="./userFeedback">User Feedback</Link>
            </li> */}
            <li>
                <MuiLink component={RouterLink} to="./registeredVsActiveUsers">
                    Registered vs Active Users
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./teamsUtilization">
                    Teams Utilization
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./templatesUsed">
                    Templates Used
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./userActivation">
                    User Activation
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./userOnboardingData">
                    User Onboarding Data
                </MuiLink>
            </li>
            <li>
                <MuiLink component={RouterLink} to="./discountCodes">
                    Discount Codes
                </MuiLink>
            </li>
        </ul>
    );
};
