import { useObservableState } from 'observable-hooks';
import { OrganisationDetailsReportPage } from '../../components/report/OrganisationDetailsReportPage';
import {
    selectedOrganisationDetails$,
    organisationPlanLimitsLoading$,
    organisationPlanLimitsError$,
    selectedOrganisationPlanLimits$,
    sendOrganisationToHubspotAction$,
} from '../../store/report/organizations';

export const OrganisationDetailsReportPageContainer: React.FC = () => {
    const details = useObservableState(selectedOrganisationDetails$);
    const limits = useObservableState(selectedOrganisationPlanLimits$);
    const loading = useObservableState(organisationPlanLimitsLoading$);
    const error = useObservableState(organisationPlanLimitsError$);

    const handleUpdateHubSpot = (): void => {
        if (details?.uid) {
            sendOrganisationToHubspotAction$.next({ organizationUid: details.uid });
        }
    };

    return (
        <OrganisationDetailsReportPage
            organisation={details}
            limits={limits}
            limitsLoading={loading}
            limitsError={error}
            onUpdateHubSpot={handleUpdateHubSpot}
        />
    );
};
