import { BehaviorSubject, map, Subject } from 'rxjs';
import { deleteConnection, UserConnections } from '../data/connection';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { DeletionRequest } from './workspace/types';
import { promptQuestion } from './confirm';

export const loggedInUserConnections$ = monitor('loggedInUserConnections$', new BehaviorSubject<UserConnections>([]));
export const deleteConnectionAction$ = monitor('deleteConnectionAction$', new Subject<DeletionRequest>());
export const connectionDeletedAction$ = monitor('connectionDeletedAction$', new Subject<string>());

deleteConnectionAction$
    .pipe(
        map(async (event) => {
            const initiateConnectionDelete = async (): Promise<void> => {
                try {
                    const response = await deleteConnection(event.uid, event.ignoreWarnings);

                    if (!event.ignoreWarnings && response.warning) {
                        promptQuestion({
                            title: response.warning,
                            proceedButton: {
                                label: 'Delete connector',
                                severity: 'error',
                                onClick: () =>
                                    deleteConnectionAction$.next({
                                        ...event,
                                        ignoreWarnings: true,
                                    }),
                            },
                        });
                    } else if (!response.warning) {
                        connectionDeletedAction$.next(event.uid);
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Connector deleted.',
                        });
                    }
                } catch (e) {
                    console.error('Error while deleting Connector', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to delete connector, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            };

            if (!event.ignoreWarnings) {
                promptQuestion({
                    title: 'Are you sure you want to delete the connector?',
                    proceedButton: {
                        label: 'Delete connector',
                        severity: 'error',
                        onClick: async () => {
                            await initiateConnectionDelete();
                        },
                    },
                });
            } else {
                await initiateConnectionDelete();
            }
        })
    )
    .subscribe();
