import { MouseEvent, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/Add';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import GridViewOutlined from '@mui/icons-material/GridViewOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ListAltOutlined from '@mui/icons-material/ListAltOutlined';
import { Avatar } from '../../common/avatars/Avatar';
import { Button } from '../../common/buttons/Button';
import { Checkbox } from '../../common/Checkbox';
import { EmptyState } from '../../common/EmptyState';
import { IconButton } from '../../common/buttons/IconButton';
import { IconToggleButtonGroup } from '../../common/buttons/button-groups/IconToggleButtonGroup';
import { Indicator } from '../../common/Indicator';
import { InformationChip } from '../../common/chips/InformationChip';
import { ProductIcon } from '../../icons/ProductIcon';
import { SearchField } from '../../common/inputs/custom/SearchField';
import { SortOrder, Table } from '../../common/Table';
import { StyledAvatarContainer, WorkspaceSelectorCard } from './WorkspaceSelectorCard';
import {
    StyledPageGridContainer,
    StyledPageHeader,
    StyledPageHeaderRow,
    StyledPageInnerContainer,
    StyledPageOuterContainer,
    StyledPageTitleContainer,
} from '../../layout/PageComponents';
import { SetupGuideType } from '@avst-stitch/repository-lib/lib/models';
import { Workspace } from './types';
import { sortAlphabetically } from '../../../utils/miscellaneous';
import { workspacesDocumentationUrl } from '../../../utils/documentation';

export type WorkspaceSelectorView = 'list' | 'grid';

export interface NewCopiedWorkspaceDetails {
    description?: string;
    name: string;
    setupGuide?: SetupGuideType;
    sourceUid?: string;
}

interface WorkspaceSelectorProps {
    basePath?: string;
    userCanWorkWithTemplates?: boolean;
    useRouter?: boolean;
    view: WorkspaceSelectorView;
    workspaces?: Workspace[];
    onChangeView: (view: WorkspaceSelectorView) => void;
    onCreateBlank(): void;
    onDelete(workspaceUid: string): void;
    onDuplicate(workspaceDetails: NewCopiedWorkspaceDetails): void;
}

export const StyledTextBlock = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
    WebkitLineClamp: 2,
}));

export const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({
    basePath,
    userCanWorkWithTemplates,
    useRouter = true,
    view,
    workspaces = [],
    onChangeView,
    onCreateBlank,
    onDelete,
    onDuplicate,
}) => {
    const [displayTemplates, setDisplayTemplates] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

    const handleDuplicate = (event: MouseEvent<HTMLButtonElement>, workspace: Workspace): void => {
        event.preventDefault();
        onDuplicate({
            setupGuide: workspace.setupGuide,
            sourceUid: workspace.uid,
            name: workspace.title,
            description: workspace.description,
        });
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>, uid: string): void => {
        event.preventDefault();
        onDelete(uid);
    };

    const filteredWorkspaces = workspaces
        .filter((ws) => (userCanWorkWithTemplates && displayTemplates ? !!ws.template : !ws.template))
        .filter((ws) => (searchTerm ? ws.title.toLowerCase().includes(searchTerm.toLowerCase()) : true));

    const getDisplayedWorkspaces = (): JSX.Element => {
        if (filteredWorkspaces.length === 0) {
            return (
                <EmptyState
                    borderBox
                    buttons={
                        searchTerm
                            ? undefined
                            : [
                                  <Button
                                      key="learn-more"
                                      date-test-id="workspaces-page-empty-state-documentation-button"
                                      link={{ href: workspacesDocumentationUrl, target: '_blank', type: 'external' }}
                                      variant="outlined"
                                  >
                                      Learn more
                                  </Button>,
                                  <Button
                                      key="create-workspace"
                                      data-testid="workspaces-page-empty-state-create-new-button"
                                      onClick={onCreateBlank}
                                  >
                                      Create workspace
                                  </Button>,
                              ]
                    }
                    data-testid="workspaces-page-empty-state"
                    iconState="incomplete"
                    icon={<Inventory2OutlinedIcon />}
                    subtitle={
                        searchTerm
                            ? 'Try a different search term'
                            : 'Start with a template or create a custom integration to fit your exact needs.'
                    }
                    title={searchTerm ? 'No workspaces found' : 'No workspaces created'}
                />
            );
        }
        if (view === 'list') {
            const sortedWorkspaces = sortAlphabetically(filteredWorkspaces, 'title', sortOrder);
            const columns = [
                {
                    id: 'badge',
                },
                { id: 'name', sortable: true, title: 'Name' },
                { id: 'description', title: 'Description' },
                { id: 'incoming', title: 'Incoming' },
                { id: 'outgoing', title: 'Outgoing' },
                { id: 'members', title: 'Members' },
                { id: 'environments', title: 'Environments' },
                { id: 'buttons' },
            ];

            const rows = sortedWorkspaces.map((fws) => {
                const maxAvatars = 4;
                const userAvatars = fws.users
                    .slice(0, maxAvatars)
                    .map((u) => (
                        <Avatar
                            key={u.email}
                            credentials={{ email: u.email, firstName: u.firstName, lastName: u.lastName }}
                        />
                    ));

                const undisplayedUsers = fws.users.length > maxAvatars ? fws.users.length - maxAvatars : undefined;
                const selectedEnvironmentUid =
                    fws.environments.find((e) => e.selected)?.uid ?? fws.environments[0]?.uid ?? '';

                return {
                    columns: {
                        badge: <Indicator left={{ icon: <Inventory2OutlinedIcon /> }} />,
                        name: (
                            <Tooltip title={fws.title}>
                                <StyledTextBlock variant="subtitle2">{fws.title}</StyledTextBlock>
                            </Tooltip>
                        ),
                        description: (
                            <Tooltip title={fws.description}>
                                <StyledTextBlock color="text.secondary">{fws.title}</StyledTextBlock>
                            </Tooltip>
                        ),
                        incoming: (
                            <StyledIconContainer>
                                {fws.incoming?.slice(0, 4).map((i) => (
                                    <ProductIcon key={i} name={i} tooltip />
                                ))}
                            </StyledIconContainer>
                        ),
                        outgoing: (
                            <StyledIconContainer>
                                {fws.outgoing?.slice(0, 4).map((o) => (
                                    <ProductIcon key={o} name={o} tooltip />
                                ))}
                            </StyledIconContainer>
                        ),
                        members: (
                            <StyledAvatarContainer>
                                {userAvatars}
                                {undisplayedUsers && (
                                    <Typography ml={0.5} color="text.secondary">{`+${undisplayedUsers}`}</Typography>
                                )}
                            </StyledAvatarContainer>
                        ),
                        environments: (
                            <StyledIconContainer>
                                <DisplaySettingsOutlinedIcon />
                                {fws.environments.length}
                            </StyledIconContainer>
                        ),
                        buttons: (
                            <StyledIconContainer>
                                <IconButton
                                    aria-label="Duplicate workspace"
                                    border
                                    data-testid={`workspaces-page-workspace-table-item-${fws.uid}-duplicate-button`}
                                    icon={<CopyAllOutlinedIcon />}
                                    tooltip="Duplicate workspace"
                                    onClick={(event) => handleDuplicate(event, fws)}
                                />
                                <IconButton
                                    aria-label="Delete workspace"
                                    border
                                    data-testid={`workspaces-page-workspace-table-item-${fws.uid}-delete-button`}
                                    icon={<DeleteOutlineIcon />}
                                    tooltip="Delete workspace"
                                    onClick={(event) => handleDelete(event, fws.uid)}
                                />
                            </StyledIconContainer>
                        ),
                    },
                    'data-testid': `workspaces-page-workspace-table-item-${fws.uid}`,
                    id: fws.uid,
                    link: {
                        href: `${basePath}workspace/${fws.uid}/environment/${selectedEnvironmentUid}`,
                        type: 'router' as const,
                    },
                };
            });

            return (
                <Table
                    columns={columns}
                    data-testid="workspaces-page-list-view-container"
                    rows={rows}
                    selectable="single"
                    sortBy="name"
                    sortOrder={{ name: sortOrder }}
                    useRouter={useRouter}
                    onSort={({ order }) => setSortOrder(order)}
                />
            );
        }
        return (
            <StyledPageGridContainer data-testid="workspaces-page-grid-view-container">
                {filteredWorkspaces.map((fws) => (
                    <WorkspaceSelectorCard
                        key={fws.uid}
                        basePath={basePath}
                        description={fws.description}
                        environments={fws.environments}
                        incoming={fws.incoming}
                        outgoing={fws.outgoing}
                        template={fws.template}
                        title={fws.title}
                        uid={fws.uid}
                        users={fws.users}
                        onDelete={onDelete}
                        onDuplicate={(uid) =>
                            onDuplicate({
                                setupGuide: fws.setupGuide,
                                sourceUid: uid,
                                name: fws.title,
                                description: fws.description,
                            })
                        }
                    />
                ))}
            </StyledPageGridContainer>
        );
    };

    return (
        <StyledPageOuterContainer data-testid="workspaces-page">
            <StyledPageInnerContainer>
                <StyledPageHeader data-testid="workspaces-page-header">
                    <StyledPageHeaderRow>
                        <StyledPageTitleContainer>
                            <Typography data-testid="workspaces-page-title" variant="h5">
                                Workspaces
                            </Typography>
                            <InformationChip
                                label={`${filteredWorkspaces.length} Result${
                                    filteredWorkspaces.length === 1 ? '' : 's'
                                }`}
                                severity="grey"
                            />
                        </StyledPageTitleContainer>
                        <Button
                            data-testid="workspaces-page-create-new-button"
                            startIcon={<AddOutlinedIcon />}
                            onClick={onCreateBlank}
                        >
                            Create workspace
                        </Button>
                    </StyledPageHeaderRow>
                    <StyledPageHeaderRow>
                        <SearchField
                            sx={{ flexGrow: 1 }}
                            data-testid="workspaces-page-search-field"
                            searchTerm={searchTerm}
                            size="small"
                            onChangeSearchTerm={(e) => setSearchTerm(e.target.value)}
                        />
                        {userCanWorkWithTemplates && (
                            <Checkbox
                                border
                                checked={displayTemplates}
                                data-testid="workspaces-page-display-templates-button"
                                label="Display templates"
                                onCheck={() => setDisplayTemplates(!displayTemplates)}
                            />
                        )}
                        <IconToggleButtonGroup
                            buttons={[
                                {
                                    'aria-label': 'List view',
                                    'data-testid': 'workspaces-page-list-view-button',
                                    icon: <ListAltOutlined />,
                                    selected: view === 'list',
                                    tooltip: 'List view',
                                    value: 'list',
                                },
                                {
                                    'aria-label': 'Grid view',
                                    'data-testid': 'workspaces-page-list-view-button',
                                    icon: <GridViewOutlined />,
                                    selected: view === 'grid',
                                    tooltip: 'Grid view',
                                    value: 'grid',
                                },
                            ]}
                            exclusive
                            value={view}
                            onChange={(_, value) => onChangeView(value)}
                        />
                    </StyledPageHeaderRow>
                </StyledPageHeader>
                {getDisplayedWorkspaces()}
            </StyledPageInnerContainer>
        </StyledPageOuterContainer>
    );
};
