import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material';
import { getAvatarColor } from '../../../utils/account';

interface OrganizationAvatarProps {
    name: string;
    style?: 'round' | 'square';
}

const StyledOrgAvatar = styled(Avatar)<{ name: string }>(({ theme, name }) => {
    const color = getAvatarColor(theme, name);
    return {
        backgroundClip: 'padding-box',
        backgroundColor: color.backgroundColor,
        border: `1px solid ${theme.palette.background.default}`,
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        color: color.fontColor,
        height: 52,
        marginRight: theme.spacing(1),
        width: 52,
    };
});

export const OrganizationAvatar: React.FC<OrganizationAvatarProps> = ({ name, style = 'round' }) => {
    return (
        <StyledOrgAvatar sx={style === 'square' ? { borderRadius: '12px' } : {}} name={name}>
            {name[0]?.toUpperCase() ?? ''}
        </StyledOrgAvatar>
    );
};
