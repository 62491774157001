import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import { InfoIcon } from '../../../icons/InfoIcon';
import { LoadingSpinner } from '../../../common/LoadingSpinner';
import { StyledBorderBoxTitleContainer } from '../../../layout/BorderBoxComponents';

export interface EventListenerPayload {
    uid: string;
    name: string;
}

export interface EventListenerPayloadWithContent {
    uid: string;
    content: string;
}

export interface SaveEventListenerPayloadEvent extends EventListenerPayloadWithContent {
    feedbackMessage: string;
}

interface EventListenerPayloadsProps {
    activePayloadUid?: string;
    content: string;
    errors?: string;
    eventListenerPayloads?: EventListenerPayload[];
    hasUnsavedChanges?: boolean;
    loadingContent?: boolean;
    requiresSetup?: boolean;
    saving?: boolean;
    selectedPayloadUid: string;
    workspaceLocked?: boolean;
    onCancel(): void;
    onContentChange?(payload: EventListenerPayloadWithContent): void;
    onCreateNewPayload(): void;
    onSave(payload: SaveEventListenerPayloadEvent): void;
    onSelectTestPayload?(uid: string): void;
}

const StyledEditorLoaderBox = styled(Box)({
    height: '100%',
    width: '100%',
});

const StyledEventListenerPayloadsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 72px)',
}));

// TODO: replace import for ReplayInvocationDialog before hooking up
const StyledWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
    overflow: 'hidden',
    '& section': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.constants.borderRadius,
        overflow: 'hidden',
        padding: theme.spacing(0),
    },
    '& .margin': {
        borderRight: `1px solid ${theme.palette.divider} !important`,
        backgroundColor:
            (theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900]) + '!important',
    },
}));

const StyledFlexButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}));

const StyledDropdownBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0, 1, 0),
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

/* eslint-disable sonarjs/cognitive-complexity */
export const EventListenerPayloads: React.FC<EventListenerPayloadsProps> = ({
    content,
    activePayloadUid,
    errors,
    eventListenerPayloads = [],
    hasUnsavedChanges,
    loadingContent = false,
    requiresSetup = false,
    saving = false,
    selectedPayloadUid,
    workspaceLocked = false,
    onCancel,
    onContentChange,
    onCreateNewPayload,
    onSave,
    onSelectTestPayload,
}) => {
    const [currentContent, setCurrentContent] = useState(content);

    const darkTheme = useTheme().palette.mode === 'dark';

    const canSelect = selectedPayloadUid !== activePayloadUid;
    const canSave = !!currentContent && !saving && !workspaceLocked && (hasUnsavedChanges || canSelect);

    const feedbackMessage =
        canSelect && hasUnsavedChanges
            ? 'saved and selected.'
            : canSelect && !hasUnsavedChanges
            ? 'selected.'
            : 'saved.';

    const selectMessage =
        'Select this test event payload to use it when emulating the event in the current environment.';
    const saveMessage = 'Store these details to simulate events later in this environment.';
    const saveAndSelectMessage =
        'Save and select this updated test event payload to use it when emulating the event in the current environment.';
    const saveButtonText = canSelect && hasUnsavedChanges ? 'Select and Save' : canSelect ? 'Select' : 'Save changes';

    const handleSelect = (uid: string): void => {
        onSelectTestPayload?.(uid);
    };

    //Going through local state here to prevent monaco from emitting content-related events before the whole component is rendered
    useEffect(() => {
        onContentChange?.({ uid: selectedPayloadUid, content: currentContent ?? '' });
    }, [currentContent]);

    return (
        <StyledEventListenerPayloadsContainer>
            <StyledWrapper>
                <>
                    {requiresSetup ? (
                        <Alert
                            severity="info"
                            title="You must save the Event Listener first before you can start configuring test event payloads."
                        />
                    ) : (
                        <>
                            {errors && <Alert severity="error" title={errors} />}
                            {canSelect && hasUnsavedChanges && <Alert severity="info" title={saveAndSelectMessage} />}
                            {canSelect && !hasUnsavedChanges && <Alert severity="info" title={selectMessage} />}
                            {!canSelect && hasUnsavedChanges && <Alert severity="info" title={saveMessage} />}
                            <StyledDropdownBox>
                                <StyledBorderBoxTitleContainer>
                                    <Typography variant="subtitle1">Test event payload</Typography>
                                    <InfoIcon tooltip="Use the ready-made template, modify it as required, and click the play button in the event listener node to trigger your event with the dummy event." />
                                </StyledBorderBoxTitleContainer>
                                <Dropdown
                                    fullWidth
                                    disabled={workspaceLocked}
                                    items={eventListenerPayloads.map((p) => ({
                                        value: p.uid,
                                        name: p.uid === activePayloadUid ? `${p.name} (default)` : p.name,
                                    }))}
                                    label="Use test event payload"
                                    selectedItem={selectedPayloadUid}
                                    onCreateNew={onCreateNewPayload}
                                    onSelectItem={handleSelect}
                                />
                            </StyledDropdownBox>
                            {loadingContent ? (
                                <StyledEditorLoaderBox>
                                    <LoadingSpinner />
                                </StyledEditorLoaderBox>
                            ) : (
                                <Editor
                                    theme={darkTheme ? 'vs-dark' : 'vs'}
                                    options={{
                                        minimap: { enabled: false },
                                        readOnly: workspaceLocked,
                                    }}
                                    path={selectedPayloadUid}
                                    language="json"
                                    defaultValue={content}
                                    onChange={(editorContent) => setCurrentContent(editorContent ?? '')}
                                />
                            )}
                        </>
                    )}
                </>
            </StyledWrapper>
            <Divider />
            <StyledFlexButtonBox>
                <Button onClick={onCancel} variant="outlined" disabled={workspaceLocked}>
                    Back
                </Button>
                <Button
                    busy={saving}
                    disabled={!canSave}
                    onClick={() => onSave({ uid: selectedPayloadUid, content, feedbackMessage })}
                >
                    {saveButtonText}
                </Button>
            </StyledFlexButtonBox>
        </StyledEventListenerPayloadsContainer>
    );
};
