import { Outlet } from 'react-location';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ReadmeFileDetails } from '../workspace-resources/readme/ReadmeFileDetails';
import { SetupGuideStepper } from './SetupGuideStepper';
import { SetupGuideHeader } from './SetupGuideHeader';
import { SetupGuideStep, SetupGuideStepName } from './types';
import { WorkspaceLockedAlert } from '../workspace-layouts/WorkspaceLockedAlert';

export interface SetupGuidePageProps {
    fullScreenReadmeLink: string;
    overview?: boolean;
    readmeContent: string;
    readmeHasUnsavedChanges?: boolean;
    readmeOpen?: boolean;
    readmeSaving?: boolean;
    selectedStepUid?: string;
    steps: SetupGuideStep[];
    subTitle: string;
    templateMode?: boolean;
    title: string;
    useRouter?: boolean;
    workspaceLocked?: boolean;
    workspaceName: string;
    workspaceNameSaving?: boolean;
    onChangeReadmeContent: (content: string) => void;
    onChangeWorkspaceName: (name: string) => void;
    onNavigateToAdvancedView: () => void;
    onNavigateToOverview: () => void;
    onRevertReadmeChanges: () => void;
    onExitSetupGuide: () => void;
    onSaveReadme: () => void;
    onSelectStep: (stepName: SetupGuideStepName) => void;
    onToggleReadme: () => void;
    onUnlockWorkspace: () => void;
}

const StyledPageContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
}));

const StyledPageInnerContainer = styled(Box)(() => ({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: '100%',
}));

const StyledSetupGuideContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2),
    width: '100%',
}));

export const SetupGuidePage: React.FC<SetupGuidePageProps> = ({
    fullScreenReadmeLink,
    overview,
    readmeContent,
    readmeHasUnsavedChanges = false,
    readmeOpen = false,
    readmeSaving = false,
    selectedStepUid,
    steps,
    subTitle,
    templateMode = false,
    title,
    useRouter = true,
    workspaceLocked = false,
    workspaceName,
    workspaceNameSaving = false,
    onChangeReadmeContent,
    onChangeWorkspaceName,
    onNavigateToAdvancedView,
    onNavigateToOverview,
    onRevertReadmeChanges,
    onExitSetupGuide,
    onSaveReadme,
    onSelectStep,
    onToggleReadme,
    onUnlockWorkspace,
}) => {
    return (
        <StyledPageContainer>
            {workspaceLocked && <WorkspaceLockedAlert onUnlockWorkspace={onUnlockWorkspace} />}
            <SetupGuideHeader
                readmeOpen={readmeOpen}
                workspaceLocked={workspaceLocked}
                workspaceName={workspaceName}
                workspaceNameSaving={workspaceNameSaving}
                onChangeWorkspaceName={onChangeWorkspaceName}
                onNavigateToAdvancedView={onNavigateToAdvancedView}
                onExitSetupGuide={onExitSetupGuide}
                onToggleReadme={onToggleReadme}
            />
            {!overview && (
                <SetupGuideStepper
                    selectedStepUid={selectedStepUid}
                    steps={steps}
                    onNavigateToOverview={onNavigateToOverview}
                    onSelectStep={onSelectStep}
                />
            )}
            <StyledPageInnerContainer>
                <StyledSetupGuideContainer>
                    <Typography mb={1} variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography color="text.secondary" mb={3}>
                        {subTitle}
                    </Typography>
                    {useRouter && <Outlet />}
                </StyledSetupGuideContainer>
                {readmeOpen && (
                    <>
                        <Divider orientation="vertical" />
                        <ReadmeFileDetails
                            content={readmeContent}
                            fullScreenReadmeLink={fullScreenReadmeLink}
                            hasUnsavedChanges={readmeHasUnsavedChanges}
                            saving={readmeSaving}
                            templateMode={templateMode}
                            workspaceLocked={workspaceLocked}
                            onChangeContent={onChangeReadmeContent}
                            onClose={onToggleReadme}
                            onDiscard={onRevertReadmeChanges}
                            onSave={onSaveReadme}
                        />
                    </>
                )}
            </StyledPageInnerContainer>
        </StyledPageContainer>
    );
};
