import { useObservableState } from 'observable-hooks';

import { selectedReadmeFileUid$, unsavedReadmeFileDetails$ } from '../../../store/workspace/readme';
import { selectedEnvironmentUid$ } from '../../../store/workspace';
import { FullScreenReadme } from '../../../components/workspace-resources/readme/FullScreenReadme';
import { getReadmeKey } from '../../../utils/readme';

export const FullScreenReadmeContainer: React.FC = () => {
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const unsavedReadmeDetails = useObservableState(unsavedReadmeFileDetails$);

    const readmeKey = getReadmeKey(selectedReadmeFileUid ?? '', selectedEnvironmentUid ?? '');

    return <FullScreenReadme content={unsavedReadmeDetails[readmeKey]?.content ?? ''} />;
};
