import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import {
    organizationBillingDetails$,
    selectedOrganizationUid$,
    selectedOrganizationPlan$,
    organizationBillingDetailsCustomerPortal$,
    organizationSwitchedAction$,
    selectedOrganizationViewDetails$,
    organizationMembers$,
    organizationInvoicePlanInformationDialogOpen$,
    organizationPlanChangedAction$,
} from '../../../store/organization';
import { getBasePath } from '../../../utils/path';
import { supportPortalUrl } from '../../../utils/documentation';
import { BillingDetailsOverviewV2 } from '../../../components/organizations/billing/BillingDetailsOverviewV2';
import { openCancelOrganizationPlanFlowDialogAction$ } from '../../../store/organization/cancelOrganizationPlan';
import {
    openChangeOrganizationPlanConfirmationDialogAction$,
    organizationBillingError$,
    organizationBillingUpdating$,
    resumeOrganizationPlanActionV2$,
} from '../../../store/organization/changeOrganizationPlan';
import { Plan } from '../../../components/organizations/create-organization-wizard/CreateOrganizationWizardDialogV2';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const OrganizationBillingDetailsContainerV2: React.FC = () => {
    const plan = useObservableState(selectedOrganizationPlan$);
    const selectedOrgUid = useObservableState(selectedOrganizationUid$);
    const details = useObservableState(selectedOrganizationViewDetails$);
    const members = useObservableState(organizationMembers$);
    const customerPortalUrl = useObservableState(organizationBillingDetailsCustomerPortal$);
    const updating = useObservableState(organizationBillingUpdating$);
    const errors = useObservableState(organizationBillingError$);
    const { billingEntities, selectedBillingEntityUid } = useObservableState(organizationBillingDetails$) || {};

    const planBillingEntity = billingEntities?.find((be) => be.uid === selectedBillingEntityUid);

    if (!plan) {
        return null;
    }

    const navigate = useNavigate();

    useSubscription(organizationSwitchedAction$, (uid) => {
        navigate({ to: `${getBasePath()}team/${uid}/billing` });
    });

    useSubscription(organizationPlanChangedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/billing` }), 100);
    });

    const handleReactivate = (): void => {
        if (plan.subscriptionStatus === 'CANCELLED' && selectedOrgUid) {
            resumeOrganizationPlanActionV2$.next({ organizationUid: selectedOrgUid });
        } else if (plan.subscriptionStatus === 'SUSPENDED') {
            openChangeOrganizationPlanConfirmationDialogAction$.next({
                tier: plan.tier as Plan['tier'],
                period: plan.period === 'ANNUALLY' ? 'annually' : 'monthly',
            });
        }
    };

    return (
        <BillingDetailsOverviewV2
            name={details?.name ?? ''}
            plan={plan}
            membersCount={members?.users.length ?? 1}
            billingEntity={planBillingEntity}
            errors={errors}
            loading={updating}
            onChangePlan={
                selectedOrgUid ? () => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/plan` }) : undefined
            }
            onReactivate={handleReactivate}
            onUpdateBillingContact={
                planBillingEntity?.uid
                    ? () => navigate({ to: `${getBasePath()}billing/${planBillingEntity?.uid}` })
                    : undefined
            }
            onManageBilling={customerPortalUrl ? () => window.open(customerPortalUrl, '_blank') : undefined}
            onOpenSupport={() => window.open(supportPortalUrl, '_blank')}
            onLearnAboutInvoice={() => organizationInvoicePlanInformationDialogOpen$.next(true)}
            onCancelPlan={() => openCancelOrganizationPlanFlowDialogAction$.next()}
        />
    );
};
