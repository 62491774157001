import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { APP } from '@avst-stitch/repository-lib/constants';

interface AzureDevopsEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const AzureDevopsEventListenerSetupDialog: React.FC<AzureDevopsEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.AZURE_DEVOPS.NAME;

    const listContent = [
        <Typography>
            Open <strong>Azure DevOps</strong> and navigate to the organisation you want to setup the event listener
            for.
        </Typography>,
        <Typography>
            On the bottom left, click on <strong>Organisation Settings.</strong>
        </Typography>,
        <Typography>
            Click on <strong>projects</strong> and click on the project you wish to setup an event listener for.
        </Typography>,
        <Typography>
            Under General, click on the <strong>service hooks</strong> menu item.
        </Typography>,
        <Typography>
            Click the <strong>+</strong> icon in the top bar.
        </Typography>,
        <Typography>
            From the services menu, select <strong>Web Hooks</strong> and then next.
        </Typography>,
        'Select the conditions on which the webhook will trigger, and then next.',
        <Typography>
            Paste the URL from this page into the <strong>URL</strong> field, and then finish.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'azure-devops-webhook-url'
        ),
    ];

    return (
        <Dialog
            fullWidth
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title="Event listener setup"
            subtitle={appName}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
            loading={loading}
        >
            <>
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} project to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="azure-devops" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </>
        </Dialog>
    );
};
