import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ProductIcon } from '../../../icons/ProductIcon';

interface OnboardingAppItemProps {
    name: string;
    selected?: boolean;
    uid: string;
    onSelect(uid: string): void;
}

const StyledAppItem = styled(Box)(() => ({
    textAlign: 'center',
    width: 126,
}));

const StyledAppCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
}));

const StyledAppCard = styled(ButtonBase)<{ selected: boolean }>(({ selected, theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    justifyContent: 'center',
    padding: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 48,
        width: 48,
    },
    '&:hover': {
        backgroundColor: selected ? theme.palette.action.focus : theme.palette.action.hover,
    },
}));

export const OnboardingAppItem: React.FC<OnboardingAppItemProps> = ({ selected = false, name, uid, onSelect }) => {
    return (
        <StyledAppItem>
            <StyledAppCardContainer>
                <StyledAppCard selected={selected} onClick={() => onSelect(uid)}>
                    <Tooltip title={name} describeChild>
                        <ProductIcon name={name} />
                    </Tooltip>
                </StyledAppCard>
            </StyledAppCardContainer>
            <Typography color="text.secondary">{name}</Typography>
        </StyledAppItem>
    );
};
