import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { EmptyState } from '../../common/EmptyState';
import { IconButton } from '../../common/buttons/IconButton';
import { SetupGuideApiHandlerListItem } from './SetupGuideApiHandlerListItem';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { StyledStepContainer } from '../SetupGuideComponents';
import { SetupGuideApiHandler } from '../types';

interface SetupGuideApiHandlerScreenProps {
    apiHandlers?: SetupGuideApiHandler[];
    errors?: string;
    nextStepName?: string;
    saving?: boolean;
    stepRequired?: boolean;
    templateMode?: boolean;
    workspaceLocked?: boolean;
    onContinue: () => void;
    onCreateNewApiHandler: () => void;
    onDeleteApiHandler: (uid: string) => void;
    onEditApiHandler: (uid: string) => void;
    onGoBack: () => void;
}

export const SetupGuideApiHandlerScreen: React.FC<SetupGuideApiHandlerScreenProps> = ({
    apiHandlers = [],
    errors,
    nextStepName,
    saving = false,
    stepRequired = false,
    templateMode = false,
    workspaceLocked = false,
    onContinue,
    onCreateNewApiHandler,
    onDeleteApiHandler,
    onEditApiHandler,
    onGoBack,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const resourcesDeleting = apiHandlers.some((ah) => ah.deleting);
    const resourcesNeedSetup = apiHandlers?.some((ah) => ah.needsSetup);
    const setupComplete = !resourcesNeedSetup && !templateMode ? apiHandlers.length > 0 : true;

    const canSave = setupComplete && !resourcesDeleting && !workspaceLocked;

    const paragraphs = [
        'API connections allow you to make outgoing (egress) external API calls to services you need to talk to. API connections act as a proxy and as an API client. These have been set up for you with the information you provided in the previous step.',
        `Your requests are internally proxied, where we'll be substituting the base URL and authentication headers, so you don't have to.`,
    ];

    const nextStepPendoAttribute = nextStepName === 'Triggers' ? 'progressToTriggers' : undefined;

    const actions = (
        <>
            <Button
                data-pendo={nextStepPendoAttribute}
                disabled={resourcesDeleting || workspaceLocked}
                variant="outlined"
                onClick={onGoBack}
            >
                Back
            </Button>
            {stepRequired ? (
                <Button data-pendo={nextStepPendoAttribute} busy={saving} disabled={!canSave} onClick={onContinue}>
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : canSave ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={resourcesDeleting || workspaceLocked}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : (
                <Button
                    data-pendo={nextStepPendoAttribute}
                    disabled={resourcesDeleting || workspaceLocked}
                    variant="outlined"
                    onClick={onContinue}
                >
                    {nextStepName ? `Skip to ${nextStepName}` : 'Skip'}
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox paragraphs={paragraphs} title="What is an API connection?" />
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        API connections
                    </Typography>
                    {!templateMode && (
                        <IconButton
                            aria-label="Create a new API connection"
                            icon={<AddOutlinedIcon />}
                            tooltip="Creata a new API connection"
                            onClick={() => onCreateNewApiHandler()}
                        />
                    )}
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {apiHandlers.length ? (
                        <>
                            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
                            {resourcesNeedSetup && (
                                <Alert
                                    severity="warning"
                                    text={
                                        <strong>
                                            One or more elements are incomplete. Please review the following:
                                        </strong>
                                    }
                                />
                            )}
                            {apiHandlers.map((ah) => {
                                return (
                                    <SetupGuideApiHandlerListItem
                                        key={ah.uid}
                                        uid={ah.uid}
                                        connectionName={ah.connectionName}
                                        deleting={ah.deleting}
                                        appName={ah.appName}
                                        path={ah.path}
                                        needsSetup={ah.needsSetup}
                                        templateMode={templateMode}
                                        workspaceLocked={workspaceLocked}
                                        onDelete={onDeleteApiHandler}
                                        onEdit={onEditApiHandler}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <EmptyState
                            buttons={
                                !templateMode
                                    ? [
                                          <Button
                                              data-pendo={'APIConnectionCreationInitiated'}
                                              disabled={workspaceLocked}
                                              key="create-api-connection"
                                              onClick={onCreateNewApiHandler}
                                          >
                                              Create API connection
                                          </Button>,
                                      ]
                                    : undefined
                            }
                            icon={<ApiOutlinedIcon />}
                            iconState={templateMode ? 'complete' : 'incomplete'}
                            subtitle={
                                !templateMode
                                    ? 'Create an API connection to start building your integration.'
                                    : 'Click Continue to proceed to the next step.'
                            }
                            title={templateMode ? 'No API connections in this template' : 'No API connections found'}
                        />
                    )}
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
