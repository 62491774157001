import React from 'react';
import { ConnectionDialog } from '../../ConnectionDialog';
import { ProductIcon } from '../../../icons/ProductIcon';
import { ConfirmReAuthDialog } from '../../ConfirmReAuthDialog';

export interface ManageConnectionDialog {
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly authorizeLoading: boolean;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
    readonly isConfirmModalOpen: boolean;
    readonly onConfirmCancelHandler: () => void;
    readonly onModalConfirmHandler: () => void;
    readonly onAuthorizeHandler: (newConnectionName: string) => void;
}

export const AWSManageConnectionDialog: React.FC<ManageConnectionDialog> = ({
    name,
    saving,
    onSave,
    onAuthorize,
    authorizeLoading,
    open,
    onCancel,
    authorized,
    errors,
    connectionType,
    isConfirmModalOpen,
    onConfirmCancelHandler,
    onModalConfirmHandler,
    onAuthorizeHandler,
}) => {
    const reauthorizationConnectionTitle = `You are about to re-authorize your AWS connector`;
    const reauthorizationConnectionMessage = `For security reasons, a new IAM role policy will be generated if you choose to use temporary credentials.
    Please ensure you update the IAM role with the new policy.`;

    return (
        <>
            <ConnectionDialog
                authorized={authorized}
                name={name}
                onSave={onSave}
                onAuthorize={isConfirmModalOpen || authorized ? onAuthorizeHandler : onAuthorize}
                authorizeLoading={!!authorizeLoading}
                saving={saving}
                icon={<ProductIcon name={connectionType ?? ''} />}
                open={open}
                onCancel={onCancel}
                errors={errors}
                customAuthorizeButtonLabel={'Configure'}
                connectionType={connectionType}
            />
            {isConfirmModalOpen && (
                <ConfirmReAuthDialog
                    isReauthorization={!!name}
                    link=""
                    open={isConfirmModalOpen}
                    title={authorized ? reauthorizationConnectionTitle : ''}
                    message={authorized ? reauthorizationConnectionMessage : ''}
                    onCancel={onConfirmCancelHandler}
                    onConfirm={onModalConfirmHandler}
                />
            )}
        </>
    );
};
