import { BehaviorSubject, Subject, map, filter } from 'rxjs';
import { getInvocations, InvocationsRequest, InvocationsResponse } from '../data/reporting';
import { InformativeError } from '../utils/error';
import { monitor } from './monitor';
import { abortInvocation } from '../utils/trigger';
import { publishLocalFeedbackEventAction$ } from './feedback';

export const invocationsData$ = monitor(
    'invocations$',
    new BehaviorSubject<InvocationsResponse>({ invocations: [], workspaces: {} })
);

export const reportingLoading$ = monitor('reportingLoading$', new BehaviorSubject(false));

export const reportingError$ = monitor('reportingError$', new BehaviorSubject<string | undefined>(undefined));

export const queryInvocationsAction$ = monitor('queryInvocationsAction$', new Subject<InvocationsRequest>());

export const reloadInvocationsOnOrganizationSwitchAction$ = monitor(
    'reloadInvocationsOnOrganizationSwitchAction$',
    new Subject<{ organizationUid: string }>()
);

export const searchInvocationsAction$ = monitor('searchInvocationsAction$', new Subject<InvocationsRequest>());

export const addInvocationsAction$ = monitor('addInvocationsAction$', new Subject<InvocationsResponse>());

export const abortInvocationAction$ = monitor('abortInvocationAction$', new Subject<string>());

export const abortInvocationLoading$ = monitor('abortInvocationLoading$', new BehaviorSubject(false));
export const reloadInvocationsOnOrganizationSwitchLoading$ = monitor(
    'reloadInvocationsOnOrganizationSwitchLoading$',
    new BehaviorSubject(false)
);

queryInvocationsAction$
    .pipe(
        filter(({ nextToken }) => !!nextToken),
        map(async (request) => {
            reportingError$.next(undefined);
            try {
                const invocationsResponse = await getInvocations(request);
                addInvocationsAction$.next(invocationsResponse);
            } catch (e) {
                if (e instanceof InformativeError) {
                    reportingError$.next(e.message);
                } else {
                    reportingError$.next(
                        'Failed to query for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            reportingLoading$.next(false);
        })
    )
    .subscribe();

reloadInvocationsOnOrganizationSwitchAction$
    .pipe(
        map(async (request) => {
            reloadInvocationsOnOrganizationSwitchLoading$.next(true);
            reportingError$.next(undefined);
            invocationsData$.next({ invocations: [], workspaces: {} });
            try {
                const invocationsResponse = await getInvocations(request);
                addInvocationsAction$.next(invocationsResponse);
            } catch (e) {
                if (e instanceof InformativeError) {
                    reportingError$.next(e.message);
                } else {
                    reportingError$.next(
                        'Failed to load for Invocations after switching Teams, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while loading invocations', e);
                }
            }
            reloadInvocationsOnOrganizationSwitchLoading$.next(false);
        })
    )
    .subscribe();

searchInvocationsAction$
    .pipe(
        filter(({ nextToken }) => !nextToken),
        map(async (request) => {
            reportingLoading$.next(true);
            reportingError$.next(undefined);

            try {
                const invocationsResponse = await getInvocations(request);
                invocationsData$.next(invocationsResponse);
            } catch (e) {
                if (e instanceof InformativeError) {
                    reportingError$.next(e.message);
                } else {
                    reportingError$.next(
                        'Failed to search for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            reportingLoading$.next(false);
        })
    )
    .subscribe();

addInvocationsAction$
    .pipe(
        filter(({ invocations }) => invocations.length > 0),
        map(({ invocations, nextToken, workspaces }) => {
            const currentInvocationsData = invocationsData$.value;
            return {
                invocations: [...currentInvocationsData.invocations, ...invocations],
                nextToken,
                workspaces,
            };
        })
    )
    .subscribe((invocationsData) => invocationsData$.next(invocationsData));

abortInvocationAction$
    .pipe(
        map(async (abortPath) => {
            abortInvocationLoading$.next(true);
            try {
                const invocationId = abortPath.split('/')[2];
                await abortInvocation(abortPath);

                const currentInvocationsData = invocationsData$.value;
                invocationsData$.next({
                    ...currentInvocationsData,
                    invocations: currentInvocationsData.invocations.map((invocation) => {
                        if (invocation.invocationId === invocationId) {
                            return {
                                ...invocation,
                                executionStatus: 'ABORTED',
                            };
                        }
                        return invocation;
                    }),
                });
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Invocation aborted.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to abort invocation, try again, if the issue persists please contact support.`,
                    });
                }
            }
            abortInvocationLoading$.next(false);
        })
    )
    .subscribe();
