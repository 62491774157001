/* eslint-disable sonarjs/no-duplicate-string */
import { useState } from 'react';
import { styled } from '@mui/material';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BillingPlanCardV2 } from '../create-organization-wizard/BillingPlanCardV2';
import { Plan } from '../create-organization-wizard/CreateOrganizationWizardDialogV2';
import { OrganizationPlan, TeamPlans } from '../../../data/organization';
import {
    apiConnectionDocumentationUrl,
    awsComplianceUrl,
    connectorDocumentationUrl,
    dpaUrl,
    genericConnectorDocumentationUrl,
    genericEventDocumentationUrl,
    limitsAndQuotasDocumentationUrl,
    marketplaceListingUrl,
    privacyPolicyUrl,
    scriptRunnerHQFormUrl,
    supportPortalUrl,
    termsAndConditionsUrl,
} from '../../../utils/documentation';
import { Alert } from '../../common/alerts/Alert';

interface OrganizationPlanDetailsProps {
    errors?: string;
    plan?: Omit<OrganizationPlan, 'period'> & { period: Plan['period'] };
    teamPlans: TeamPlans;
    updating: boolean;
    onUpgrade: (event?: OrganizationPlanUpdateEvent) => void;
    onWorkspaces: () => void;
}

export interface OrganizationPlanUpdateEvent {
    tier: Plan['tier'];
    period: Plan['period'];
}

const StyledPlansContainer = styled(Box)(() => ({
    display: 'flex',
}));

const StyledFullTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
}));

const StyledLastTableCell = styled(TableCell)(() => ({
    borderBottom: 'none',
}));

const StyledLink = styled(Link)(() => ({
    cursor: 'default',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    textTransform: 'none',
    width: 180,
    height: 40,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.spacing(0.5),
    boxShadow: 'none',
    outline: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    },
    '& .MuiCollapse-root': {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiAccordionDetails-root': {
        padding: theme.spacing(2),
    },
}));

export const OrganizationPlanDetailsV2: React.FC<OrganizationPlanDetailsProps> = ({
    errors,
    plan,
    updating,
    teamPlans,
    onUpgrade,
    onWorkspaces,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const isCustomPlan = !['free', 'basic', 'advanced', 'pro'].includes(plan?.tier || '');
    const [currentPeriod, setCurrentPeriod] = useState<Plan['period']>(plan?.period || 'annually');
    const activePlanInPeriod = plan?.period === currentPeriod;
    const filteredTeamPlans = teamPlans.filter((teamPlan) => teamPlan.period === currentPeriod);

    const freeTeamPlan = filteredTeamPlans.find((teamPlan) => teamPlan.tier === 'free');
    const basicTeamPlan = filteredTeamPlans.find((teamPlan) => teamPlan.tier === 'basic');
    const advancedTeamPlan = filteredTeamPlans.find((teamPlan) => teamPlan.tier === 'advanced');
    const proTeamPlan = filteredTeamPlans.find((teamPlan) => teamPlan.tier === 'pro');

    const isActivePlan = (tier: string, checkStatus = true): boolean => {
        if (checkStatus) {
            return plan?.tier === tier && plan.period === currentPeriod && plan.subscriptionStatus !== 'SUSPENDED';
        }
        return plan?.tier === tier && plan.period === currentPeriod;
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
            <Box width="100%">
                <ToggleButtonGroup
                    value={currentPeriod}
                    exclusive
                    onChange={(_, change) => setCurrentPeriod(change ?? currentPeriod)}
                    aria-label="Platform"
                >
                    <StyledToggleButton disabled={currentPeriod === 'monthly'} value="monthly">
                        Pay monthly
                    </StyledToggleButton>
                    <StyledToggleButton disabled={currentPeriod === 'annually'} value="annually">
                        Pay yearly (save 16%)
                    </StyledToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box display="flex" flexDirection="column" gap={4} width={1170}>
                {errors && <Alert severity="error" title={errors} />}
                <StyledPlansContainer>
                    <BillingPlanCardV2
                        active={isActivePlan('free', false)}
                        disabled={!(isActivePlan('free') || plan?.subscriptionStatus === 'SUSPENDED')}
                        subscriptionStatus={plan?.subscriptionStatus}
                        planName="Free"
                        planDescription="For anyone getting started"
                        planPosition="left"
                        activePlanInPeriod={activePlanInPeriod}
                        price={0}
                        priceDescription="Free for everyone"
                        connectors={freeTeamPlan?.connectorsLimit ?? 4}
                        functionInvocations={freeTeamPlan?.invocationsLimit ?? 100}
                        buttonText={isActivePlan('free') ? 'Go to workspaces' : 'Select plan'}
                        loading={updating}
                        onUpgrade={
                            isActivePlan('free')
                                ? onWorkspaces
                                : () =>
                                      onUpgrade({
                                          tier: 'free',
                                          period: currentPeriod,
                                      })
                        }
                    />
                    <BillingPlanCardV2
                        active={isActivePlan('basic', false)}
                        subscriptionStatus={plan?.subscriptionStatus}
                        disabled={
                            (plan?.paymentType === 'INVOICE' &&
                                plan.subscriptionStatus !== 'SUSPENDED' &&
                                currentPeriod === 'monthly') ||
                            plan?.subscriptionStatus === 'CANCELLED'
                        }
                        planName="Basic"
                        planDescription="For a single integration"
                        priceDescription={
                            currentPeriod === 'monthly'
                                ? 'billed monthly'
                                : `billed at $${
                                      filteredTeamPlans
                                          .find((teamPlan) => teamPlan.tier === 'basic')
                                          ?.yearlyCost?.toLocaleString('en-us') ?? '4,495'
                                  } yearly`
                        }
                        activePlanInPeriod={activePlanInPeriod}
                        price={basicTeamPlan?.monthlyCost ?? 449}
                        connectors={basicTeamPlan?.connectorsLimit ?? 4}
                        functionInvocations={basicTeamPlan?.invocationsLimit ?? -1}
                        buttonText={isActivePlan('basic') ? 'Go to workspaces' : 'Select plan'}
                        loading={updating}
                        pendoAttribute={isActivePlan('basic') ? undefined : 'selectBasicPlan'}
                        onUpgrade={
                            isActivePlan('basic')
                                ? onWorkspaces
                                : () =>
                                      onUpgrade({
                                          tier: 'basic',
                                          period: currentPeriod,
                                      })
                        }
                    />
                    <BillingPlanCardV2
                        active={isActivePlan('advanced', false)}
                        subscriptionStatus={plan?.subscriptionStatus}
                        disabled={
                            (plan?.paymentType === 'INVOICE' &&
                                plan.subscriptionStatus !== 'SUSPENDED' &&
                                currentPeriod === 'monthly') ||
                            plan?.subscriptionStatus === 'CANCELLED'
                        }
                        planName="Advanced"
                        planDescription="For multiple integrations"
                        priceDescription={
                            currentPeriod === 'monthly'
                                ? 'billed monthly'
                                : `billed at $${
                                      filteredTeamPlans
                                          .find((teamPlan) => teamPlan.tier === 'advanced')
                                          ?.yearlyCost?.toLocaleString('en-us') ?? '8,995'
                                  } yearly`
                        }
                        activePlanInPeriod={activePlanInPeriod}
                        price={advancedTeamPlan?.monthlyCost ?? 899}
                        connectors={advancedTeamPlan?.connectorsLimit ?? 8}
                        functionInvocations={advancedTeamPlan?.invocationsLimit ?? -1}
                        buttonText={isActivePlan('advanced') ? 'Go to workspaces' : 'Select plan'}
                        loading={updating}
                        pendoAttribute={isActivePlan('advanced') ? undefined : 'selectAdvancedPlan'}
                        onUpgrade={
                            isActivePlan('advanced')
                                ? onWorkspaces
                                : () =>
                                      onUpgrade({
                                          tier: 'advanced',
                                          period: currentPeriod,
                                      })
                        }
                    />
                    <BillingPlanCardV2
                        active={isActivePlan('pro', false)}
                        subscriptionStatus={plan?.subscriptionStatus}
                        disabled={
                            (plan?.paymentType === 'INVOICE' &&
                                plan.subscriptionStatus !== 'SUSPENDED' &&
                                currentPeriod === 'monthly') ||
                            plan?.subscriptionStatus === 'CANCELLED'
                        }
                        planName="Pro"
                        planDescription="For integrating a business"
                        priceDescription={
                            currentPeriod === 'monthly'
                                ? 'billed monthly'
                                : `billed at $${
                                      filteredTeamPlans
                                          .find((teamPlan) => teamPlan.tier === 'pro')
                                          ?.yearlyCost?.toLocaleString('en-us') ?? '14,995'
                                  } yearly`
                        }
                        activePlanInPeriod={activePlanInPeriod}
                        price={proTeamPlan?.monthlyCost ?? 1499}
                        connectors={proTeamPlan?.connectorsLimit ?? -1}
                        functionInvocations={proTeamPlan?.invocationsLimit ?? -1}
                        buttonText={isActivePlan('pro') ? 'Go to workspaces' : 'Select plan'}
                        loading={updating}
                        pendoAttribute={isActivePlan('pro') ? undefined : 'selectProPlan'}
                        onUpgrade={
                            isActivePlan('pro')
                                ? onWorkspaces
                                : () =>
                                      onUpgrade({
                                          tier: 'pro',
                                          period: currentPeriod,
                                      })
                        }
                    />
                    <BillingPlanCardV2
                        active={isCustomPlan && plan?.period === currentPeriod}
                        subscriptionStatus={plan?.subscriptionStatus}
                        planName="Custom"
                        planDescription="For bespoke integrations"
                        priceDescription="Custom pricing"
                        planPosition="right"
                        activePlanInPeriod={activePlanInPeriod}
                        price={isCustomPlan && plan?.period === currentPeriod ? plan.cost : 'Let’s talk'}
                        priceBy={plan?.period === 'annually' ? 'year' : 'month'}
                        connectors={
                            isCustomPlan && plan?.period === currentPeriod && plan.connectorsLimit
                                ? plan.connectorsLimit
                                : 'Custom'
                        }
                        functionInvocations={
                            isCustomPlan && plan?.period === currentPeriod && plan.invocationsLimit
                                ? plan.invocationsLimit
                                : 'Custom'
                        }
                        buttonText={
                            isCustomPlan && plan?.period === currentPeriod && plan.subscriptionStatus !== 'SUSPENDED'
                                ? 'Go to workspaces'
                                : 'Contact us'
                        }
                        loading={updating}
                        pendoAttribute={
                            isCustomPlan && plan?.period === currentPeriod && plan.subscriptionStatus !== 'SUSPENDED'
                                ? undefined
                                : 'customPlanRequested'
                        }
                        onUpgrade={
                            isCustomPlan && plan?.period === currentPeriod && plan.subscriptionStatus !== 'SUSPENDED'
                                ? onWorkspaces
                                : () => window.open(scriptRunnerHQFormUrl, '_blank')
                        }
                    />
                </StyledPlansContainer>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Typography variant="h6">All features</Typography>
                    <StyledTableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>
                                        <Typography variant="subtitle1">Free</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">Basic</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">Advanced</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">Pro</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">Custom</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledFullTableCell align="left" colSpan={6}>
                                        <Typography variant="subtitle2">Pricing</Typography>
                                    </StyledFullTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body1">Pay monthly</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">$0 USD/mo</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            $
                                            {(
                                                teamPlans.find(
                                                    (teamPlan) =>
                                                        teamPlan.period === 'monthly' && teamPlan.tier === 'basic'
                                                )?.monthlyCost ?? 449
                                            ).toLocaleString('en-US')}{' '}
                                            USD/mo
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            $
                                            {(
                                                teamPlans.find(
                                                    (teamPlan) =>
                                                        teamPlan.period === 'monthly' && teamPlan.tier === 'advanced'
                                                )?.monthlyCost ?? 899
                                            ).toLocaleString('en-US')}{' '}
                                            USD/mo
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            $
                                            {(
                                                teamPlans.find(
                                                    (teamPlan) =>
                                                        teamPlan.period === 'monthly' && teamPlan.tier === 'pro'
                                                )?.monthlyCost ?? 1499
                                            ).toLocaleString('en-US')}{' '}
                                            USD/mo
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">Contact us</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1">Pay yearly&nbsp;</Typography>
                                            <Typography variant="subtitle2" color="primary.main">
                                                (save 16%)
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">$0 USD/mo</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            $
                                            {Math.round(
                                                teamPlans.find(
                                                    (teamPlan) =>
                                                        teamPlan.period === 'annually' && teamPlan.tier === 'basic'
                                                )?.monthlyCost ?? 374.58
                                            ).toLocaleString('en-US')}{' '}
                                            USD/mo
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            $
                                            {Math.round(
                                                teamPlans.find(
                                                    (teamPlan) =>
                                                        teamPlan.period === 'annually' && teamPlan.tier === 'advanced'
                                                )?.monthlyCost ?? 749.58
                                            ).toLocaleString('en-US')}{' '}
                                            USD/mo
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            $
                                            {Math.round(
                                                teamPlans.find(
                                                    (teamPlan) =>
                                                        teamPlan.period === 'annually' && teamPlan.tier === 'pro'
                                                )?.monthlyCost ?? 1249.58
                                            ).toLocaleString('en-US')}{' '}
                                            USD/mo
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">Contact us</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledFullTableCell align="left" colSpan={6}>
                                        <Typography variant="subtitle2">Features</Typography>
                                    </StyledFullTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Add connectors to link apps. Costs depend on how many connectors you use, not the number of users."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                Connectors*
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{freeTeamPlan?.connectorsLimit ?? 4}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{basicTeamPlan?.connectorsLimit ?? 4}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {advancedTeamPlan?.connectorsLimit ?? 8}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {!proTeamPlan || proTeamPlan.connectorsLimit === -1
                                                ? 'Unlimited'
                                                : proTeamPlan.connectorsLimit}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">Contact us</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Run scripts up to the monthly limit in your plan."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                Script executions monthly**
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{freeTeamPlan?.invocationsLimit ?? 100}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {!basicTeamPlan || basicTeamPlan.invocationsLimit >= 500000
                                                ? 'Unlimited'
                                                : basicTeamPlan.invocationsLimit}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {!advancedTeamPlan || advancedTeamPlan.invocationsLimit >= 500000
                                                ? 'Unlimited'
                                                : advancedTeamPlan.invocationsLimit}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {!proTeamPlan || proTeamPlan.invocationsLimit >= 500000
                                                ? 'Unlimited'
                                                : proTeamPlan.invocationsLimit}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">Contact us</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Use credits to ask questions using the AI assistant."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                AI credits
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{freeTeamPlan?.aiCredits ?? 100}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{basicTeamPlan?.aiCredits ?? 1000}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{advancedTeamPlan?.aiCredits ?? 1000}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{proTeamPlan?.aiCredits ?? 1000}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">Contact us</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Get help and share ideas with other users in the community."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                Community support
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Access support services 24 hours a day, Monday to Friday."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                Mon-Fri 24hr support
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <CloseOutlinedIcon color="disabled" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Create integrations quickly using ready-made templates."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                Templates
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledFullTableCell align="left" colSpan={6}>
                                        <Typography variant="subtitle2">Account and security</Typography>
                                    </StyledFullTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Assign roles and set permissions to manage team access."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                User and team management (RBAC)
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Tooltip
                                            title="Add extra security by requiring a second verification step."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                2-Factor Authentication
                                            </StyledLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledLastTableCell align="left">
                                        <Tooltip
                                            title="Log in easily on different platforms with Single Sign-On."
                                            placement="right"
                                        >
                                            <StyledLink underline="always" variant="body1">
                                                SSO (SAML/0Auth/LDAP)
                                            </StyledLink>
                                        </Tooltip>
                                    </StyledLastTableCell>
                                    <StyledLastTableCell>
                                        <CloseOutlinedIcon color="disabled" />
                                    </StyledLastTableCell>
                                    <StyledLastTableCell>
                                        <CloseOutlinedIcon color="disabled" />
                                    </StyledLastTableCell>
                                    <StyledLastTableCell>
                                        <CloseOutlinedIcon color="disabled" />
                                    </StyledLastTableCell>
                                    <StyledLastTableCell>
                                        <CloseOutlinedIcon color="disabled" />
                                    </StyledLastTableCell>
                                    <StyledLastTableCell>
                                        <CheckCircleIcon color="primary" />
                                    </StyledLastTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            *A connector is a single instance of the application you want to integrate.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            **Monthly script executions are capped at 500,000 to safeguard against misconfigurations
                            that can lead to runaway processes. You can request an increase at any time free of charge.
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Typography variant="h6">Frequently asked questions</Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                        {frequentlyAskedQuestions.map((faq, i) => (
                            <StyledAccordion disableGutters key={i}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle2">{faq.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>{faq.body}</AccordionDetails>
                            </StyledAccordion>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const frequentlyAskedQuestions = [
    {
        title: 'What are connectors?',
        body: (
            <Typography variant="body1">
                A connector is a link between ScriptRunner Connect and a third-party service that ScriptRunner Connect
                manages on your behalf. We are constantly working to increase this list and you can find{' '}
                <Link underline="always" target="_blank" href={connectorDocumentationUrl}>
                    all current available connectors here.
                </Link>
            </Typography>
        ),
    },
    {
        title: <>How can I connect to X if you don&#8217;t have a connector for it?</>,
        body: (
            <Typography variant="body1">
                We have a{' '}
                <Link underline="always" target="_blank" href={genericConnectorDocumentationUrl}>
                    Generic API Connection
                </Link>{' '}
                and a{' '}
                <Link underline="always" target="_blank" href={genericEventDocumentationUrl}>
                    Generic Event Listener
                </Link>{' '}
                that you can use to connect and listen to events from unlisted apps and services.{' '}
                <Link underline="always" target="_blank" href={apiConnectionDocumentationUrl}>
                    Find out more about our API connections.
                </Link>
            </Typography>
        ),
    },
    {
        title: 'What are script executions?',
        body: (
            <Typography variant="body1">
                A script execution refers to a script being run, either by being triggered by an event, manually or
                programmatically from another script. A script execution is sometimes also referred to as a script
                invocation.
            </Typography>
        ),
    },
    {
        title: 'What are AI credits?',
        body: (
            <Typography variant="body1">
                The AI assistant's use is available according to monthly credit allotments. Each question you ask the
                assistant consumes a single credit, and your credits fully renew at the beginning of each monthly
                payment cycle.
            </Typography>
        ),
    },
    {
        title: 'Are there any additional quotas or limits?',
        body: (
            <Typography variant="body1">
                To ensure ScriptRunner Connect's service reliability, the app applies various limits and quotas to guard
                against accidents or malicious behaviour.{' '}
                <Link underline="always" target="_blank" href={limitsAndQuotasDocumentationUrl}>
                    Find out more about limits and quotas.
                </Link>
            </Typography>
        ),
    },
    {
        title: 'Do you have a free trial or plan?',
        body: (
            <Typography variant="body1">
                We have a free forever plan so you can test all your integrations before committing to a paid plan. You
                will only be limited by the number of scripts that can be run each month and some stricter rate limits
                than on paid plans.
            </Typography>
        ),
    },
    {
        title: 'How can I upgrade or downgrade a plan?',
        body: (
            <Typography variant="body1">
                You can manage your plan directly in ScriptRunner Connect in the Teams module under Plans. Our
                subscriptions are very flexible and you can change your subscription plan and duration whenever you need
                to. Your new plan limits will take effect immediately, and pricing changes will be reflected in your
                next invoice.
            </Typography>
        ),
    },
    {
        title: 'How can I cancel my plan?',
        body: (
            <Typography variant="body1">
                You can cancel your subscription at any time from within the app. Once cancelled, your subscription will
                not renew at the end of the period you&#8217;ve paid for (at the end of the month or year depending on
                your subscription).
            </Typography>
        ),
    },
    {
        title: 'Do you offer a custom plan?',
        body: (
            <Typography variant="body1">
                Yes! We can work with you on adapting our plans to your specific requirements and build a custom plan
                accordingly.{' '}
                <Link underline="always" target="_blank" href={supportPortalUrl}>
                    Get in touch via our form.
                </Link>
            </Typography>
        ),
    },
    {
        title: 'Is there a discount for paying yearly?',
        body: (
            <Typography variant="body1">
                Yes! When you take out an yearly subscription, you get 12 months for the price of 10 months.
            </Typography>
        ),
    },
    {
        title: 'Can I have a discount if I already use a ScriptRunner app?',
        body: (
            <Typography variant="body1">
                Yes! Existing ScriptRunner customers with an active licence can claim a 10% loyalty discount by filling
                in the form on our website. Please note, the discount is valid for a year and is redeemable on an annual
                basis.
            </Typography>
        ),
    },
    {
        title: 'What payment methods can I use to complete my purchase?',
        body: (
            <Typography component="ol" paddingLeft={2}>
                <Typography variant="body1" component="li" marginBottom={2}>
                    Credit/Debit Card: You can pay with any major credit or debit card. Simply enter your card details
                    at checkout for a fast and secure transaction.
                </Typography>
                <Typography variant="body1" component="li">
                    Invoice: If you prefer to pay by invoice, select this option at checkout. An invoice will be emailed
                    to you with payment instructions, allowing you to complete payment within the specified timeframe.
                </Typography>
            </Typography>
        ),
    },
    {
        title: 'Can I change my payment method?',
        body: (
            <Typography variant="body1">
                To switch between invoice and credit card payment methods, please create a new team and select your
                preferred payment method during the setup process.
            </Typography>
        ),
    },
    {
        title: 'Are you ISO 27001 certified and SOC 2 Type 2 audited?',
        body: (
            <Typography variant="body1">
                ScriptRunner Connect is GDPR compliant and ISO 27001 and SOC Type 2 certified. For further information,
                you may refer to the following related compliance documents:{' '}
                <Link underline="always" target="_blank" href={awsComplianceUrl}>
                    AWS
                </Link>
                ,{' '}
                <Link underline="always" target="_blank" href={termsAndConditionsUrl}>
                    Adaptavist Terms and Conditions
                </Link>
                ,{' '}
                <Link underline="always" target="_blank" href={privacyPolicyUrl}>
                    Privacy Policy
                </Link>
                , and the{' '}
                <Link underline="always" target="_blank" href={dpaUrl}>
                    Data Processing Addendum.
                </Link>
            </Typography>
        ),
    },
    {
        title: 'Will ScriptRunner Connect be available on the Atlassian marketplace?',
        body: (
            <Typography variant="body1">
                We have a{' '}
                <Link underline="always" target="_blank" href={marketplaceListingUrl}>
                    listing on the Atlassian Marketplace
                </Link>{' '}
                but ScriptRunner Connect is purchased directly from within the app. ScriptRunner Connect is a standalone
                tool rather than a Connect Plugin or Forge App.
            </Typography>
        ),
    },
];
