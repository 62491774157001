import { styled } from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import { ReflexContainer, ReflexElement } from 'react-reflex';

interface Dimensions {
    height: number;
    width: number;
}

export const SizeAwareContainer: React.FC<
    PropsWithChildren<{ dimensions?: Dimensions; onDimensionsChange: (width: number) => void }>
> = ({ children, dimensions, onDimensionsChange }) => {
    useEffect(() => {
        onDimensionsChange(dimensions?.width || 1000);
    }, [dimensions]);
    return <StyledSizeAwareContainer>{children}</StyledSizeAwareContainer>;
};

export const StyledReflexContainer = styled(ReflexContainer, { shouldForwardProp: (prop) => prop !== 'minSize' })(
    ({ theme }) => ({
        '&.vertical': {
            '&>.reflex-splitter ': {
                BorderBottom: 'none',
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderTop: 'none',
                width: theme.spacing(1.5) + ' !important',
                '& .MuiSvgIcon-root': {
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                },
            },
        },
        '&.horizontal': {
            '&>.reflex-splitter ': {
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: `1px solid ${theme.palette.divider}`,
                height: theme.spacing(1.5) + ' !important',
                '& .MuiSvgIcon-root': {
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%) rotate(90deg)',
                },
            },
        },
        // Keep the reflex-splitter styling here, otherwise the functionality breaks
        '& .reflex-splitter': {
            backgroundColor: theme.palette.background.default,
            borderLeft: `1px solid ${theme.palette.divider} !important`,
            borderRight: `1px solid ${theme.palette.divider} !important`,
            position: 'relative',
            zIndex: 0,
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
            },
            '&.active': {
                backgroundColor: theme.palette.action.selected,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.text.secondary,
                height: 20,
                position: 'absolute',
                width: 20,
            },
        },
    })
);

//Keeping the empty styled element in order to customise it with the sx prop
export const StyledReflexElement = styled(ReflexElement, { shouldForwardProp: (prop) => prop !== 'minSize' })(
    () => ({})
);

export const StyledReflexFooter = styled(ReflexElement, { shouldForwardProp: (prop) => prop !== 'minSize' })(() => ({
    minHeight: 60,
}));

const StyledSizeAwareContainer = styled('div')(({ theme }) => ({
    height: '100%',
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.default,
}));
