import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { RouterLink } from '../RouterLink';
import { ButtonLinkProps, ForwardedLinkProps } from './Button';
import { cloneElement, forwardRef, PropsWithChildren } from 'react';

export interface CustomListItemButtonProps extends ButtonBaseProps {
    collapsed?: boolean;
    endIcons?: JSX.Element[];
    label: string | JSX.Element;
    link?: ButtonLinkProps;
    selected?: boolean;
    startIcon?: JSX.Element;
    useRouter?: boolean;
    dataPendo?: string;
}

const StyledListItemButton = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'collapsed' && prop !== 'fixedHeight',
})<
    {
        collapsed: boolean;
        fixedHeight: boolean;
        selected: boolean;
    } & ForwardedLinkProps
>(({ collapsed, fixedHeight, selected, theme }) => {
    return {
        backgroundColor: selected ? theme.palette.action.selected : 'transparent',
        border: selected ? theme.constants.borderSelected : '1px solid transparent',
        borderRadius: theme.constants.borderRadius,
        color: selected ? theme.palette.primary.main : undefined,
        gap: theme.spacing(1),
        height: fixedHeight ? 40 : 'unset',
        justifyContent: collapsed ? 'center' : 'space-between',
        padding: theme.spacing(1),
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            textDecoration: 'none',
        },
    };
});

const StyledListItemButtonLeft = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    minWidth: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& .MuiSvgIcon-root, img': {
        alignSelf: 'flex-start',
        height: 24,
        width: 24,
    },
}));

const StyledButtonText = styled(Box)(({ theme }) => ({
    minWidth: 0,
    overflow: 'hidden',
    textAlign: 'start',
    '& .MuiTypography-root': {
        ...theme.typography.overflowLine,
    },
}));

const StyledLabel = styled(Typography)<{ selected: boolean }>(({ selected, theme }) => ({
    color: selected ? theme.palette.primary.main : undefined,
    fontWeight: selected ? theme.typography.fontWeightBold : undefined,
}));

const StyledEndIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

export const ListItemButton = forwardRef<HTMLButtonElement, PropsWithChildren<CustomListItemButtonProps>>(
    (
        {
            collapsed = false,
            endIcons = [],
            label,
            link,
            selected = false,
            startIcon,
            useRouter = true,
            children,
            dataPendo,
            ...props
        },
        ref
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
        const endIconsWithKey = endIcons.map((ei, i) => cloneElement(ei, { key: `end-icon-${i}` }));

        const regularButton = (
            <StyledListItemButton
                ref={ref}
                collapsed={collapsed}
                fixedHeight={!children}
                selected={selected}
                data-pendo={dataPendo}
                {...props}
            >
                <StyledListItemButtonLeft>
                    {startIcon}
                    <StyledButtonText>
                        {!collapsed &&
                            (typeof label === 'string' ? (
                                <StyledLabel selected={selected}>{label}</StyledLabel>
                            ) : (
                                label
                            ))}
                        {!collapsed && children}
                    </StyledButtonText>
                </StyledListItemButtonLeft>
                {!collapsed && <StyledEndIconContainer>{endIconsWithKey}</StyledEndIconContainer>}
            </StyledListItemButton>
        );

        const externalLinkButton = (
            <StyledListItemButton
                ref={ref}
                collapsed={collapsed}
                component={Link}
                fixedHeight={!children}
                href={link?.href}
                selected={selected}
                target={link?.target}
                data-pendo={dataPendo}
                {...props}
            >
                <StyledListItemButtonLeft>
                    {startIcon}
                    <StyledButtonText>
                        {!collapsed &&
                            (typeof label === 'string' ? (
                                <StyledLabel selected={selected}>{label}</StyledLabel>
                            ) : (
                                label
                            ))}
                        {!collapsed && children}
                    </StyledButtonText>
                </StyledListItemButtonLeft>
                {!collapsed && <StyledEndIconContainer>{endIconsWithKey}</StyledEndIconContainer>}
            </StyledListItemButton>
        );

        const routerLinkButton = (
            <StyledListItemButton
                ref={ref}
                collapsed={collapsed}
                component={RouterLink}
                fixedHeight={!children}
                selected={selected}
                target={link?.target}
                to={link?.href}
                data-pendo={dataPendo}
                {...props}
            >
                <StyledListItemButtonLeft>
                    {startIcon}
                    <StyledButtonText textAlign="start">
                        {!collapsed &&
                            (typeof label === 'string' ? (
                                <StyledLabel selected={selected}>{label}</StyledLabel>
                            ) : (
                                label
                            ))}
                        {!collapsed && children}
                    </StyledButtonText>
                </StyledListItemButtonLeft>
                {!collapsed && <StyledEndIconContainer>{endIconsWithKey}</StyledEndIconContainer>}
            </StyledListItemButton>
        );

        return !link || !useRouter ? regularButton : link.type === 'external' ? externalLinkButton : routerLinkButton;
    }
);
