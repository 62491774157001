import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { ListItemButton } from '../../common/buttons/ListItemButton';
import { RouterLink } from '../../common/RouterLink';
import { StyledMenu, StyledMenuItem, StyledMenuItemContent } from './SidenavComponents';

interface SidenavReportingNavigatorProps {
    collapsed: boolean;
    isSelected: boolean;
    useRouter?: boolean;
    basePath: string;
}

export const SidenavReportingNavigator: React.FC<SidenavReportingNavigatorProps> = ({
    collapsed,
    isSelected,
    useRouter = false,
    basePath,
}) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const menuItems = [
        <StyledMenuItem
            key="invocationLogs"
            component={RouterLink}
            to={useRouter ? basePath + '/reporting' : null}
            onClick={() => setAnchor(null)}
        >
            <StyledMenuItemContent>
                <QueryStatsOutlinedIcon />
                Script Invocation Logs
            </StyledMenuItemContent>
        </StyledMenuItem>,
        <StyledMenuItem
            key="auditLogs"
            component={RouterLink}
            to={useRouter ? basePath + '/auditLogs' : null}
            onClick={() => setAnchor(null)}
        >
            <StyledMenuItemContent>
                <AssessmentOutlinedIcon />
                Audit Logs
            </StyledMenuItemContent>
        </StyledMenuItem>,
    ];

    return (
        <>
            <ListItemButton
                collapsed={collapsed}
                label="Reporting"
                selected={!!anchor || isSelected}
                startIcon={
                    <Tooltip title="Reporting">
                        <TroubleshootOutlinedIcon />
                    </Tooltip>
                }
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
            />
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
