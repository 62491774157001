import {
    getTemplates as getTemplatesRcp,
    getTemplateCategories as getTemplateCategoriesRcp,
} from '@avst-stitch/repository-lib/lib';
import { Response as Templates } from '@avst-stitch/repository-lib/lib/rpcs/getTemplates';
import { Response as TemplateCategories } from '@avst-stitch/repository-lib/lib/rpcs/getTemplateCategories';
import { repositoryInvoker } from '../utils/repository';
export type { Templates, TemplateCategories };

export const getTemplates = async (): Promise<Templates> => {
    return await getTemplatesRcp(repositoryInvoker);
};

export const getTemplateCategories = async (): Promise<TemplateCategories> => {
    return await getTemplateCategoriesRcp(repositoryInvoker);
};
