import { useObservableState } from 'observable-hooks';
import { selectedReadOnlyTemplate$ } from '../../../store/templates';
import { navigateToOnboardingStepAction$ } from '../../../store/onboarding';
import { selectedReadmeFileUid$, unsavedReadmeFileDetails$ } from '../../../store/workspace/readme';
import { getBasePath } from '../../../utils/path';
import { themeMode$ } from '../../../store/theme';
import { getIframeThemeMode } from '../../../utils/miscellaneous';
import { TemplateBox } from '../../../components/templates/TemplateBox';
import { getReadmeKey } from '../../../utils/readme';

export const OnboardingTemplateViewContainer: React.FC = () => {
    const template = useObservableState(selectedReadOnlyTemplate$);
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const unsavedReadmeFileDetails = useObservableState(unsavedReadmeFileDetails$);
    const themeMode = useObservableState(themeMode$);

    const readmeKey = getReadmeKey(selectedReadmeFileUid ?? '', template?.environmentUid ?? '');

    return (
        <TemplateBox
            apps={Array.from(new Set([...(template?.incomingApps ?? []), ...(template?.outgoingApps ?? [])]))}
            basePath={getBasePath()}
            customId={template?.templateCustomId}
            description={template?.description}
            iframeThemeMode={getIframeThemeMode(themeMode)}
            name={template?.name ?? ''}
            onboardingMode
            readmeContent={unsavedReadmeFileDetails[readmeKey]?.content}
            uid={template?.templateUid ?? ''}
            onGoBack={() => navigateToOnboardingStepAction$.next({ route: 'templates' })}
            onUseTemplate={() =>
                navigateToOnboardingStepAction$.next({ route: 'naming', templateUid: template?.templateUid })
            }
        />
    );
};
