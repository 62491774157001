import { useEffect, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import { IconCircle } from '../common/IconCircle';
import { TextField } from '../common/inputs/TextField';

interface ImpersonationPasswordDialogProps {
    errors?: string;
    open: boolean;
    saving?: boolean;
    onCancel(): void;
    onProceed(password: string): void;
}

export const ImpersonationPasswordDialog: React.FC<ImpersonationPasswordDialogProps> = ({
    errors,
    open,
    saving,
    onProceed,
    onCancel,
}) => {
    const [password, setPassword] = useState('');

    useEffect(() => {
        setPassword('');
    }, [open]);

    const buttons = [
        <Button onClick={() => onCancel()} variant="outlined">
            Cancel
        </Button>,
        <Button busy={saving} disabled={!password || saving} variant="contained" onClick={() => onProceed(password)}>
            OK
        </Button>,
    ];

    return (
        <Dialog
            buttons={buttons}
            icon={<IconCircle icon={<LockOutlinedIcon />} />}
            open={open}
            title="What's the magic word?"
            onKeyDown={(event) => {
                if (event.key === 'Enter' && password && !saving) {
                    onProceed(password);
                }
            }}
        >
            {errors && <Alert severity="error" title={errors} />}
            <TextField
                label="Password"
                placeholder="Enter password"
                required
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
        </Dialog>
    );
};
