import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Button } from '../../../common/buttons/Button';
import {
    StyledListItemActions,
    StyledListItemRightContainer,
    StyledRunScreenListItem,
    StyledListItemLeftContainer,
} from '../../SetupGuideComponents';
import { Language } from '../../../workspace-dialogs/language-selector';
import { SetupGuideRunScreenScript } from '../../types';
import { Indicator } from '../../../common/Indicator';

export interface SetupGuideRunScreenScriptListItemProps extends SetupGuideRunScreenScript {
    language: Language;
    onRun: (uid: string) => void;
}

export const SetupGuideRunScreenScriptListItem: React.FC<SetupGuideRunScreenScriptListItemProps> = ({
    disabled,
    path,
    uid,
    running,
    language,
    onRun,
}) => {
    return (
        <StyledRunScreenListItem>
            <StyledListItemLeftContainer>
                <Indicator
                    left={{ icon: <CodeOutlinedIcon /> }}
                    right={{
                        icon: (
                            <Box
                                alt={(language ? language : 'Typescript') + ' icon'}
                                component="img"
                                src={process.env.PUBLIC_URL + (language === 'js' ? '/js.png' : '/ts.png')}
                            />
                        ),
                    }}
                />
                <Typography>{path}</Typography>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    <Button
                        busy={running}
                        disabled={disabled}
                        startIcon={<PlayCircleOutlineIcon />}
                        variant="text"
                        onClick={() => onRun(uid)}
                    >
                        Run Script
                    </Button>
                </StyledListItemActions>
            </StyledListItemRightContainer>
        </StyledRunScreenListItem>
    );
};
