import { Theme } from '@mui/material';
import { UserNameDetails } from '../components/app-main/AppMain';
import { configTopic$, stitchSession$ } from '../store/config';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { getFetchOptions } from './fetch';

interface IsAccountVerifiedResponse {
    accountVerified: boolean;
    acceptedOrganizationInvites?: string[];
    failedOrganizationInvites?: string[];
}

interface AvatarColor {
    backgroundColor?: string;
    fontColor?: string;
}

export const isAccountVerified = async (): Promise<IsAccountVerifiedResponse> => {
    const verifyAccountUrl = configTopic$.value.trigger?.verifyAccount;
    if (!verifyAccountUrl) {
        throw new Error('No verify account url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' });
    const response = await fetch(verifyAccountUrl, fetchOptions);
    if (!response.ok) {
        console.error('Error while checking user verification status');

        const message = await response.text();
        if (message) {
            console.error('Error encountered while checking if user account is verified: ' + message);
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while checking if user account is verified: ${response.status}`);
        }
    }

    const respBody = await response.text();
    if (respBody) {
        return JSON.parse(respBody);
    } else {
        return { accountVerified: false };
    }
};

export const resetAccountPassword = async (userEmail: string): Promise<void> => {
    const auth0BaseUrl = `https://${configTopic$.value.auth0?.domain}`;
    try {
        const fetchOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                client_id: configTopic$.value.auth0.clientId,
                email: userEmail,
                connection: 'Username-Password-Authentication',
            }),
        };
        const response = await fetch(`${auth0BaseUrl}/dbconnections/change_password/`, fetchOptions);
        const respBody = await response.text();
        if (response.ok && respBody) {
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: respBody,
            });
        } else {
            throw new Error(`Error ocurred while password reset. Invalid status code: ${response.status}.`);
        }
    } catch (err) {
        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message: 'Something went wrong with password reset.',
        });
        throw new Error((err as Error).message);
    }
};

export const getUserDisplayName = (user: UserNameDetails): string => {
    let userDisplayName = '';

    if (user.firstName) {
        userDisplayName += user.firstName;
    }

    if (user.lastName) {
        userDisplayName += ` ${user.lastName}`;
    }

    userDisplayName = userDisplayName.trim();

    if (!userDisplayName) {
        userDisplayName = user.email;
    }

    return userDisplayName;
};

export type ResendStatus = 'completed' | 'pending' | 'failed' | 'verified';
interface ResendVerificationLinkResponse {
    status: ResendStatus;
    id?: string;
}
export const resendVerificationLink = async (jobId?: string): Promise<ResendVerificationLinkResponse> => {
    const resendVerificationUrl = configTopic$.value.trigger?.resendVerificationUrl;
    if (!resendVerificationUrl) {
        throw new Error('No resend verify account url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, { jobId });
    const response = await fetch(resendVerificationUrl, fetchOptions);
    if (!response.ok) {
        console.error('Error while sending verification url');

        const message = await response.text();
        if (message) {
            console.error('Error encountered while sending verification url to user ' + message);
            return {
                status: 'failed',
            };
        } else {
            console.error(`Unexpected error while sending verification url to user: ${response.status}`);
            return {
                status: 'failed',
            };
        }
    }

    const respBody = await response.text();
    if (respBody) {
        return JSON.parse(respBody);
    } else {
        return {
            status: 'completed',
        };
    }
};

export const getAvatarColor = (theme: Theme, name: string): AvatarColor => {
    const backgroundColors = [
        theme.palette.primary.main,
        theme.palette.primary.light,
        theme.palette.primary.dark,
        theme.palette.secondary.main,
        theme.palette.secondary.light,
        theme.palette.secondary.dark,
        theme.palette.setup.dark,
        theme.palette.setup.light,
        theme.palette.setup.main,
        theme.palette.info.main,
        theme.palette.info.light,
        theme.palette.info.dark,
    ];

    const fontColors = [
        theme.palette.primary.contrastText,
        theme.palette.primary.contrastText,
        theme.palette.primary.contrastText,
        theme.palette.secondary.contrastText,
        theme.palette.secondary.contrastText,
        theme.palette.secondary.contrastText,
        theme.palette.setup.contrastText,
        theme.palette.setup.contrastText,
        theme.palette.setup.contrastText,
        theme.palette.info.contrastText,
        theme.palette.info.contrastText,
        theme.palette.info.contrastText,
    ];

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return {
        backgroundColor: backgroundColors[Math.abs(hash) % backgroundColors.length],
        fontColor: fontColors[Math.abs(hash) % backgroundColors.length],
    };
};
