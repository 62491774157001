import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../common/buttons/Button';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import { IconButton } from '../../../common/buttons/IconButton';
import { InformationChip } from '../../../common/chips/InformationChip';
import { TextField } from '../../../common/inputs/TextField';
import { ToggleButtonGroup } from '../../../common/buttons/button-groups/ToggleButtonGroup';
import {
    DragButton,
    StyledDropArea,
    StyledReadOnlyExpandButton,
    StyledVariable,
    StyledVariableWrapper,
    emptyWarning,
    StyledReadOnlyValueField,
    StyledReadOnlyRow,
    StyledReadOnlyKeyField,
    StyledReadOnlyVariableHeader,
    StyledEditModeHeaderActions,
} from '../EnvironmentVariableComponents';
import {
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxHeaderActions,
} from '../../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../../layout/LayoutComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, handleKeyDown, isFocused } from '../../../../utils/input';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    INVALID_KEY_NAME_HELPER_TEXT,
    isDescriptionTooLong,
    isEnvVariableNameValid,
} from '../utils';

interface UpdateTextVariableEvent {
    defaultValue?: string;
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: string;
}

interface EnvironmentVariableTextVariantProps {
    defaultValue?: string;
    description?: string;
    disabled?: boolean;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    readOnlyTemplateMode?: boolean;
    required?: boolean;
    sameLevelKeyNames?: string[];
    setupGuideTemplateMode?: boolean;
    value?: string;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateTextVariableEvent): void;
}

export const EnvironmentVariableTextVariant: React.FC<EnvironmentVariableTextVariantProps> = ({
    defaultValue = '',
    description = '',
    disabled = false,
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    readOnlyTemplateMode = false,
    required = false,
    sameLevelKeyNames = [],
    setupGuideTemplateMode = false,
    value = '',
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentValue, setCurrentValue] = useState(value);
    const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentValue(value);
        setCurrentDefaultValue(defaultValue);
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleUpdate = (): void => {
        onUpdate?.({
            defaultValue: currentDefaultValue,
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'TEXT',
            value: currentValue,
        });
    };

    const hasChanged =
        currentValue !== value ||
        currentDefaultValue !== defaultValue ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const isCurrentRequiredValueMissing = currentRequired && !currentValue;
    const canUpdate =
        !isKeyNameDuplicate &&
        isEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const isSavedRequiredValueMissing = required && !value;
    const missingInformation = !keyName || isSavedRequiredValueMissing;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const displayedValue = readOnlyTemplateMode ? defaultValue : value;

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdate,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledBorderBoxHeader>
                            <Typography variant="subtitle2">
                                {hasBeenEdited ? 'Edit parameter' : 'New parameter'}
                            </Typography>
                            <StyledEditModeHeaderActions>
                                <ToggleButtonGroup
                                    buttons={[
                                        { label: 'Optional', selected: !currentRequired, value: !currentRequired },
                                        { label: 'Required', selected: currentRequired, value: currentRequired },
                                    ]}
                                    disabled={setupGuideTemplateMode}
                                    exclusive
                                    size="small"
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                />
                                <IconButton
                                    aria-label="Cancel editing"
                                    icon={<CloseIcon />}
                                    size="small"
                                    tooltip="Cancel editing"
                                    onClick={handleCancel}
                                />
                            </StyledEditModeHeaderActions>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <Dropdown
                                disabled={hasBeenEdited || setupGuideTemplateMode}
                                fullWidth
                                inputRef={typeInputRef}
                                items={environmentVariableTypes}
                                label="Type"
                                required
                                selectedItem={'TEXT'}
                                size="small"
                                onSelectItem={(v) =>
                                    onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })
                                }
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                fullWidth
                                label="Key"
                                required
                                size="small"
                                error={!isEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isEnvVariableNameValid(currentKeyName)
                                        ? INVALID_KEY_NAME_HELPER_TEXT
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Value"
                                required={currentRequired}
                                size="small"
                                helperText={isCurrentRequiredValueMissing && 'Please enter key value'}
                                placeholder="Enter value"
                                value={currentValue}
                                onChange={(e) => setCurrentValue(e.target.value)}
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                fullWidth
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            <Divider>
                                <Typography color="text.secondary" textTransform="uppercase" variant="body2">
                                    Developer Options
                                </Typography>
                            </Divider>
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Default value"
                                size="small"
                                placeholder="Enter default value"
                                value={currentDefaultValue}
                                onChange={(e) => setCurrentDefaultValue(e.target.value)}
                            />
                        </StyledBorderBoxContent>
                        <Divider />
                        <StyledMainActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdate}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledMainActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledBorderBoxHeaderActions>
                                <DragButton
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    aria-label="Edit parameter"
                                    data-pendo={'editParameter'}
                                    disabled={disabled || readOnlyTemplateMode}
                                    icon={<EditOutlinedIcon />}
                                    size="small"
                                    tooltip="Edit parameter"
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                />
                                <IconButton
                                    aria-label="Delete parameter"
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    icon={<DeleteOutlineIcon />}
                                    size="small"
                                    tooltip="Delete parameter"
                                    onClick={() => onDelete?.({ id, parentId })}
                                />
                            </StyledBorderBoxHeaderActions>
                            <StyledBorderBoxHeaderActions>
                                {missingInformation && (
                                    <InformationChip label="Missing information" severity="warning" />
                                )}
                                {!readOnlyTemplateMode && (
                                    <InformationChip
                                        label={required ? 'Required' : 'Optional'}
                                        severity={required ? 'primary' : 'grey'}
                                    />
                                )}
                                <StyledReadOnlyExpandButton
                                    aria-label={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    icon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    tooltip={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                >
                                    <Typography>Show all</Typography>
                                </StyledReadOnlyExpandButton>
                            </StyledBorderBoxHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {displayedValue ? (
                                    <Typography>{displayedValue}</Typography>
                                ) : (
                                    emptyWarning(!readOnlyTemplateMode && required)
                                )}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Text</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                {!readOnlyTemplateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyValueField>
                                            {defaultValue ? <Typography>{defaultValue}</Typography> : emptyWarning()}
                                        </StyledReadOnlyValueField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
