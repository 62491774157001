import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, CustomAlertProps } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';

export interface WorkspaceLockedAlertProps extends CustomAlertProps {
    onUnlockWorkspace: () => void;
}

export const WorkspaceLockedAlert: React.FC<WorkspaceLockedAlertProps> = ({ onUnlockWorkspace, ...props }) => {
    return (
        <Alert
            action={
                <Button color="warning" variant="outlined" onClick={onUnlockWorkspace}>
                    Unlock workspace
                </Button>
            }
            icon={<LockOutlinedIcon />}
            severity="warning"
            text="You've opened the same workspace from a different tab or someone else is working on it. Click 
        'Unlock workspace' to edit the workspace."
            title="Workspace is locked"
            {...props}
        />
    );
};
