import Tooltip from '@mui/material/Tooltip';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { BitbucketIcon } from './product-icons/BitbucketIcon';
import { JiraCloudIcon } from './product-icons/JiraCloudIcon';
import { SlackIcon } from './product-icons/SlackIcon';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ServiceNowIcon } from './product-icons/ServiceNowIcon';
import { JiraOnPremiseIcon } from './product-icons/JiraOnPremiseIcon';
import { ConfluenceIcon } from './product-icons/ConfluenceIcon';
import { JiraServiceManagementIcon } from './product-icons/JiraServiceManagementIcon';
import { GoogleSheetsIcon } from './product-icons/GoogleSheetsIcon';
import { GenericIcon } from './product-icons/GenericIcon';
import { GitlabIcon } from './product-icons/GitlabIcon';
import { GithubIcon } from './product-icons/GithubIcon';
import { MondayIcon } from './product-icons/MondayIcon';
import { TempoIcon } from './product-icons/TempoIcon';
import { GoogleCalendarIcon } from './product-icons/GoogleCalendarIcon';
import { MicrosoftIcon } from './product-icons/MicrosoftIcon';
import { ZoomIcon } from './product-icons/ZoomIcon';
import { SalesforceIcon } from './product-icons/SalesforceIcon';
import { StatuspageIcon } from './product-icons/StatuspageIcon';
import { TrelloIcon } from './product-icons/TrelloIcon';
import { OpsgenieIcon } from './product-icons/OpsgenieIcon';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { NetSuiteIcon } from './product-icons/NetSuiteIcon';
import { AzureDevopsIcon } from './product-icons/AzureDevopsIcon';
import { ZenDeskIcon } from './product-icons/ZenDeskIcon';
import { AWSIcon } from './product-icons/AWSIcon';
import { forwardRef } from 'react';
import Box from '@mui/material/Box';

interface ProductIconProps extends SvgIconProps {
    name?: string;
    tooltip?: boolean;
}

export const ProductIcon = forwardRef<SVGSVGElement, ProductIconProps>(({ name, tooltip = false, ...props }, ref) => {
    const iconMap = {
        [APP.GENERIC.NAME]: GenericIcon,
        [APP.JIRA_CLOUD.NAME]: JiraCloudIcon,
        [APP.BITBUCKET_CLOUD.NAME]: BitbucketIcon,
        [APP.BITBUCKET_ON_PREMISE.NAME]: BitbucketIcon,
        [APP.JIRA_ON_PREMISE.NAME]: JiraOnPremiseIcon,
        [APP.CONFLUENCE_CLOUD.NAME]: ConfluenceIcon,
        [APP.CONFLUENCE_ON_PREMISE.NAME]: ConfluenceIcon,
        [APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME]: JiraServiceManagementIcon,
        [APP.JIRA_SERVICE_MANAGEMENT_CLOUD_ASSETS.NAME]: JiraServiceManagementIcon,
        [APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE.NAME]: JiraServiceManagementIcon,
        [APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE_ASSETS.NAME]: JiraServiceManagementIcon,
        [APP.TRELLO.NAME]: TrelloIcon,
        [APP.SERVICENOW.NAME]: ServiceNowIcon,
        [APP.SLACK.NAME]: SlackIcon,
        [APP.GOOGLE_SHEETS.NAME]: GoogleSheetsIcon,
        [APP.GITHUB.NAME]: GithubIcon,
        [APP.MONDAY.NAME]: MondayIcon,
        [APP.GITLAB.NAME]: GitlabIcon,
        [APP.TEMPO_CLOUD.NAME]: TempoIcon,
        [APP.TEMPO_TIMESHEETS_ON_PREMISE.NAME]: TempoIcon,
        [APP.TEMPO_PLANNER_ON_PREMISE.NAME]: TempoIcon,
        [APP.GOOGLE_CALENDAR.NAME]: GoogleCalendarIcon,
        [APP.MICROSOFT.NAME]: MicrosoftIcon,
        [APP.AZURE_DEVOPS.NAME]: AzureDevopsIcon,
        [APP.ZOOM.NAME]: ZoomIcon,
        [APP.SALESFORCE.NAME]: SalesforceIcon,
        [APP.STATUSPAGE.NAME]: StatuspageIcon,
        [APP.OPSGENIE.NAME]: OpsgenieIcon,
        [APP.NETSUITE.NAME]: NetSuiteIcon,
        [APP.ZENDESK.NAME]: ZenDeskIcon,
        [APP.AWS.NAME]: AWSIcon,
    };

    const IconComponent = iconMap[name as keyof typeof iconMap] || HelpOutlinedIcon;

    return tooltip ? (
        <Tooltip title={name}>
            <Box display="inline-flex">
                <IconComponent ref={ref} {...props} />
            </Box>
        </Tooltip>
    ) : (
        <IconComponent ref={ref} {...props} />
    );
});
