import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';
import { Alert } from '../common/alerts/Alert';
import { OrganizationsReportResponse, OrganizationsReportRequest } from '../../data/report/organizations';
import {
    OrganizationsReportRequestPeriod,
    OrganizationsReportRequestPlan,
    OrganizationsReportRequestStatus,
    OrganizationsReportRequestUserType,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getOrganisations';
import { getBasePath } from '../../utils/path';
import { Button } from '../common/buttons/Button';

export interface OrganizationsReportPageProps extends OrganizationsReportRequest {
    errors?: string;
    loading?: boolean;
    organizations?: OrganizationsReportResponse['organizations'];
    onSearch(request: OrganizationsReportRequest): void;
}

export const OrganizationsReportPage: React.FC<OrganizationsReportPageProps> = ({
    cursor,
    errors,
    loading = false,
    organizations = [],
    plan = 'all',
    planPeriod = 'all',
    status = 'all',
    userType = 'all',
    onSearch,
}) => {
    const [userTypeFilterValue, setUserTypeFilterValue] = useState<OrganizationsReportRequestUserType>('all');
    const [statusFilterValue, setStatusFilterValue] = useState<OrganizationsReportRequestStatus>('all');
    const [planFilterValue, setPlanFilterValue] = useState<OrganizationsReportRequestPlan>('all');
    const [planPeriodFilterValue, setPlanPeriodFilterValue] = useState<OrganizationsReportRequestPeriod>('all');

    const teamPlans = ['Free', 'Silver', 'Gold', 'Platinum', 'Basic', 'Advanced', 'Pro', 'Custom'];

    useEffect(() => {
        setUserTypeFilterValue(userType);
        setStatusFilterValue(status);
        setPlanFilterValue(plan);
        setPlanPeriodFilterValue(planPeriod);
    }, []);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Teams</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <Box>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by User Type"
                            value={userTypeFilterValue}
                            onChange={(event) =>
                                setUserTypeFilterValue(event.target.value as OrganizationsReportRequestUserType)
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                            <MenuItem value="adaptavist">Adaptavist Only</MenuItem>
                            <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                            <MenuItem value="monday">monday.com</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Status"
                            value={statusFilterValue}
                            onChange={(event) =>
                                setStatusFilterValue(event.target.value as OrganizationsReportRequestStatus)
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                        </TextField>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Plan"
                            value={planFilterValue}
                            onChange={(event) =>
                                setPlanFilterValue(event.target.value as OrganizationsReportRequestPlan)
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="silver">Silver</MenuItem>
                            <MenuItem value="gold">Gold</MenuItem>
                            <MenuItem value="platinum">Platinum</MenuItem>
                            <MenuItem value="free">Free</MenuItem>
                            <MenuItem value="basic">Basic</MenuItem>
                            <MenuItem value="advanced">Advanced</MenuItem>
                            <MenuItem value="pro">Pro</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Plan Period"
                            value={planPeriodFilterValue}
                            onChange={(event) =>
                                setPlanPeriodFilterValue(event.target.value as OrganizationsReportRequestPeriod)
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="annually">Annually</MenuItem>
                        </TextField>
                    </FormControl>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={() =>
                            onSearch({
                                userType: userTypeFilterValue,
                                status: statusFilterValue,
                                plan: planFilterValue,
                                planPeriod: planPeriodFilterValue,
                            })
                        }
                        sx={{ m: 2 }}
                    >
                        Search
                    </Button>
                </Box>
            </Box>
            {errors && <Alert severity="error" title={errors} />}
            <Box sx={{ width: '30%', paddingBottom: 2 }}>
                {organizations.length > 0 && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Plan Type</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Total Active</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamPlans.map((teamPlan, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{teamPlan}</TableCell>
                                    <TableCell>
                                        {organizations.filter((org) => org.plan === teamPlan.toLowerCase()).length}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            organizations.filter(
                                                (org) => org.plan === teamPlan.toLowerCase() && org.status === 'Active'
                                            ).length
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
            <Box>
                <Typography>Total Teams: {organizations.length}</Typography>
                {cursor && (
                    <Button
                        busy={loading}
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                            onSearch({
                                cursor,
                                userType: userTypeFilterValue,
                                status: statusFilterValue,
                                plan: planFilterValue,
                                planPeriod: planPeriodFilterValue,
                            })
                        }
                    >
                        Load more teams
                    </Button>
                )}
            </Box>

            {organizations.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Team ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Billing Contact</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Plan Period</TableCell>
                            <TableCell>Subscription Status</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizations.map((org) => (
                            <TableRow id={org.uid} key={org.uid}>
                                <TableCell>
                                    <Link href={`${getBasePath()}reports/team/${org.uid}`} target="_blank">
                                        {org.uid}
                                    </Link>
                                </TableCell>
                                <TableCell>{org.name}</TableCell>
                                <TableCell>{org.createdBy}</TableCell>
                                <TableCell>{org.createdDate}</TableCell>
                                <TableCell>{org.billingContact ?? ''}</TableCell>
                                <TableCell>{org.plan ?? ''}</TableCell>
                                <TableCell>{org.planPeriod ?? ''}</TableCell>
                                <TableCell>{org.subscriptionStatus ?? ''}</TableCell>
                                <TableCell>{org.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Box>No Teams found</Box>
            )}
        </PageContainer>
    );
};
