import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { ButtonCard } from '../common/buttons/ButtonCard';
import { SRConnectIcon } from '../icons/SRConnectIcon';
import { StyledPageOuterContainer } from '../layout/PageComponents';
import { videoTutorialsUrl } from '../../utils/documentation';

export interface EmptyWorkspaceViewProps {
    onOpenHelpAndSupportDialog?: () => void;
}

const StyledContent = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 600,
    padding: theme.spacing(2),
    '&>.MuiSvgIcon-root': {
        height: 42,
        marginBottom: theme.spacing(2),
        width: 42,
    },
}));

const StyledHelpItems = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    justifyContent: 'center',
}));

const StyledLabel = styled(Box)(() => ({
    textAlign: 'start',
}));

export const EmptyWorkspaceView: React.FC<EmptyWorkspaceViewProps> = ({ onOpenHelpAndSupportDialog }) => {
    return (
        <StyledPageOuterContainer>
            <StyledContent>
                <SRConnectIcon />
                <Typography mb={3} variant="h5" component="h2">
                    How can I help you?
                </Typography>
                <StyledHelpItems>
                    <ButtonCard
                        endIcon={<KeyboardArrowRightIcon />}
                        iconSize="medium"
                        label={
                            <StyledLabel>
                                <Typography variant="subtitle2">Help & support</Typography>
                                <Typography>Learn the best practices of ScriptRunner Connect</Typography>
                            </StyledLabel>
                        }
                        startIcon={<HelpOutlineOutlinedIcon />}
                        onClick={onOpenHelpAndSupportDialog}
                    />
                    <ButtonCard
                        endIcon={<KeyboardArrowRightIcon />}
                        iconSize="medium"
                        label={
                            <StyledLabel>
                                <Typography variant="subtitle2">Watch video</Typography>
                                <Typography>Understand how to build your integration</Typography>
                            </StyledLabel>
                        }
                        link={{ href: videoTutorialsUrl, target: '_blank', type: 'external' }}
                        startIcon={<PlayCircleOutlinedIcon />}
                    />
                </StyledHelpItems>
            </StyledContent>
        </StyledPageOuterContainer>
    );
};
