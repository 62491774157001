import { useObservableState } from 'observable-hooks';
import { AWSConnectionAppConfigureDialog } from '../../../../components/connections/apps/aws/AWSAppConfigureDialog';
import {
    awsConnectionSaving$,
    awsManageConnectionDetails$,
    awsSetupDialogErrors$,
    awsSetupDialogOpen$,
    awsSetupCloseDialogAction$,
    awsSetupAuthorizeAction$,
    awsSetupDialogStage$,
    awsSetupDialogAuthMode$,
    awsSetupDialogRolePolicy$,
    awsGenerateRolePolicyAction$,
    awsSetupDialogRolePolicyExternalId$,
} from '../../../../store/connection/aws/setup-connection';

export const AWSConfigureDialog: React.FC = () => {
    const open = useObservableState(awsSetupDialogOpen$);
    const saving = useObservableState(awsConnectionSaving$);
    const details = useObservableState(awsManageConnectionDetails$);
    const errors = useObservableState(awsSetupDialogErrors$);
    const currentStage = useObservableState(awsSetupDialogStage$);
    const currentAuthMode = useObservableState(awsSetupDialogAuthMode$);
    const rolePolicy = useObservableState(awsSetupDialogRolePolicy$);
    const rolePolicyExternalId = useObservableState(awsSetupDialogRolePolicyExternalId$);

    return (
        <AWSConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                awsSetupCloseDialogAction$.next();
                awsSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => {
                if (errors) {
                    awsSetupDialogErrors$.next(undefined);
                }
                awsSetupDialogStage$.next(stage);
            }}
            clientInfo={{
                accessKey: details?.accessKey ?? '',
                secretKey: details?.secretKey ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => awsSetupDialogErrors$.next(error)}
            clearErrors={() => awsSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) =>
                awsSetupAuthorizeAction$.next({
                    ...appInfoProps,
                    externalId: rolePolicyExternalId,
                })
            }
            currentAuthMode={currentAuthMode}
            setAuthMode={(authMode) => awsSetupDialogAuthMode$.next(authMode)}
            rolePolicy={rolePolicy}
            generatePolicy={() => {
                if (!rolePolicy) {
                    awsGenerateRolePolicyAction$.next();
                }
            }}
        />
    );
};
