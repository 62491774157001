import { BehaviorSubject, map, Subject } from 'rxjs';
import { monitor } from './monitor';
import { SetupGuideAppInstance, SetupGuideStep, SetupGuideStepName } from '../components/setup-guide/types';
import { selectedEnvironmentUid$, selectedWorkspaceResources$ } from './workspace';
import { saveApiHandlerV2 } from '../data/api-handler';
import { saveEventListener } from '../data/event-listener';
import {
    getCompletedWorkspaceSetupGuideSteps,
    getWorkspaceSetupGuideSteps,
    saveWorkspaceSetupGuideProgress,
    toggleWorkspaceSetupGuide,
} from '../data/setup-guide';
import { getAppInstanceSelectedConnections, getRemappedSetupGuideSteps } from '../utils/setupGuide';
import { ConnectionDetails, openConnectionDetailsDialogAction$ } from './connection';
import { apps$ } from './apps';
import { ConnectionType } from '../data/apps';
import { InformativeError, PermissionError } from '../utils/error';
import { AppInstanceDetails } from '../components/setup-guide/connections/SetupGuideConnectionScreen';
import { loadWorkspaceResources } from './workspace/utils';
import { promptQuestion } from './confirm';
import { publishLocalFeedbackEventAction$ } from './feedback';
import {
    environmentVariablesHaveUnsavedChanges$,
    saveEnvironmentVariablesAction$,
} from './workspace/environment-variable';
import { TriggerScreenType } from '../components/setup-guide/triggers/SetupGuideTriggerScreen';
import { readLocalStorage, saveLocalStorage } from '../utils/localStorage';
import { WEBHOOK_SETUP_COMPLETE_KEY } from '../containers/setup-guide/triggers/SetupGuideTriggerScreenContainer';
import { generate } from 'short-uuid';
import { APP } from '@avst-stitch/repository-lib/constants';
import { cronHasUnsavedChanges$ } from './workspace/scheduled-trigger';
import { saveScheduledTrigger } from '../data/scheduled-trigger';

export const workspaceSetupGuideSteps$ = monitor(
    'workspaceSetupGuideSteps$',
    new BehaviorSubject<SetupGuideStep[]>([])
);

export const selectedWorkspaceSetupGuideStep$ = monitor(
    'selectedWorkspaceSetupGuideStep$',
    new BehaviorSubject<SetupGuideStep | undefined>(undefined)
);

export const workspaceSetupGuideReadmeFileOpen$ = monitor(
    'workspaceSetupGuideReadmeFileOpen$',
    new BehaviorSubject(true)
);

export const workspaceSetupGuideStepError$ = monitor(
    'workspaceSetupGuideStepError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const workspaceSetupGuideStepSaving$ = monitor('workspaceSetupGuideStepSaving$', new BehaviorSubject(false));

export const modifiedWorkspaceSetupGuideAppInstanceDetails$ = monitor(
    'modifiedWorkspaceSetupGuideAppInstanceDetails$',
    new BehaviorSubject<AppInstanceDetails | undefined>(undefined)
);

export const selectedWorkspaceSetupGuideConnections$ = monitor(
    'selectedWorkspaceSetupGuideConnections$',
    new BehaviorSubject<Record<string, string | undefined>>({})
);

export const workspaceSetupGuideConnectionSelectorOpen$ = monitor(
    'workspaceSetupGuideConnectionSelectorOpen$',
    new BehaviorSubject(false)
);

export const workspaceSetupGuideConnectionsHaveUnsavedChanges$ = monitor(
    'workspaceSetupGuideConnectionsHaveUnsavedChanges$',
    new BehaviorSubject(false)
);

export const selectedWorkspaceSetupGuideTriggerScreenType$ = monitor(
    'selectedWorkspaceSetupGuideTriggerScreenType$',
    new BehaviorSubject<TriggerScreenType | undefined>(undefined)
);

export const navigateToAdvancedViewAction$ = monitor(
    'navigateToAdvancedViewAction$',
    new Subject<{ workspaceUid: string; ignoreWarnings?: boolean | undefined }>()
);

export const workspaceSetupGuideModeUpdatedAction$ = monitor(
    'workspaceSetupGuideModeUpdatedAction$',
    new Subject<'TEMPLATE' | 'BLANK_WORKSPACE' | undefined>()
);

export const exitWorkspaceSetupGuideAction$ = monitor('exitWorkspaceSetupGuideAction$', new Subject<void>());

export const workspaceSetupGuideExitedAction$ = monitor('workspaceSetupGuideExitedAction$', new Subject<void>());

export const navigateToWorkspaceSetupGuideStepAction$ = monitor(
    'navigateToWorkspaceSetupguideStepAction$',
    new Subject<SetupGuideStepName | undefined>()
);

export const navigateToWorkspaceSetupGuideCongratulationsScreenAction$ = monitor(
    'navigateToWorkspaceSetupGuideCongratulationsScreenAction$',
    new Subject<void>()
);

export const openWorkspaceSetupGuideConnectionSelectorAction$ = monitor(
    'openWorkspaceSetupGuideConnectionSelectorAction$',
    new Subject<AppInstanceDetails>()
);

export const closeWorkspaceSetupGuideConnectionSelectorAction$ = monitor(
    'closeWorkspaceSetupGuideConnectionSelectorAction$',
    new Subject<void>()
);

export const selectWorkspaceSetupGuideConnectionAction$ = monitor(
    'selectWorkspaceSetupGuideConnectionAction$',
    new Subject<string>()
);

export const createWorkspaceSetupGuideConnectionAction$ = monitor(
    'createWorkspaceSetupGuideConnectionAction$',
    new Subject<AppInstanceDetails>()
);

export const saveWorkspaceSetupGuideProgressAction$ = monitor(
    'saveWorkspaceSetupGuideProgressAction$',
    new Subject<{ stepUid: string; workspaceUid: string }>()
);

export const editWorkspaceSetupGuideConnectionAction$ = monitor(
    'editWorkspaceSetupGuideConnectionAction$',
    new Subject<AppInstanceDetails & { connectionDetails: ConnectionDetails; workspaceUid: string }>()
);

export const changeWorkspaceSetupGuideConnectionAction$ = monitor(
    'changeWorkspaceSetupGuideConnectionAction$',
    new Subject<AppInstanceDetails & { workspaceUid: string }>()
);

export const saveWorkspaceSetupGuideConnectionsAction$ = monitor(
    'saveWorkspaceSetupGuideConnectionsAction$',
    new Subject<{
        appInstances: SetupGuideAppInstance[];
        stepUid: string;
        templateMode: boolean;
        workspaceUid: string;
    }>()
);

export const saveWorkspaceSetupGuideReviewStepAction$ = monitor(
    'saveWorkspaceSetupGuideReviewStepAction$',
    new Subject<{ stepUid: string; workspaceUid: string }>()
);

export const navigateToWorkspaceSetupGuideTriggerScreenAction$ = monitor(
    'navigateToWorkspaceSetupGuideTriggerScreenAction$',
    new Subject<TriggerScreenType>()
);

export const saveWorkspaceSetupGuideEnvironmentVariablesAction$ = monitor(
    'saveWorkspaceSetupGuideEnvironmentVariablesAction$',
    new Subject<{ stepUid: string; workspaceUid: string }>()
);

openWorkspaceSetupGuideConnectionSelectorAction$.subscribe(({ appName, group }) => {
    modifiedWorkspaceSetupGuideAppInstanceDetails$.next({ appName, group });
    workspaceSetupGuideConnectionSelectorOpen$.next(true);
});

closeWorkspaceSetupGuideConnectionSelectorAction$.subscribe(() => {
    workspaceSetupGuideConnectionSelectorOpen$.next(false);
    modifiedWorkspaceSetupGuideAppInstanceDetails$.next(undefined);
});

selectWorkspaceSetupGuideConnectionAction$.subscribe((uid) => {
    const existingConnections = selectedWorkspaceSetupGuideConnections$.value;
    const { appName, group } = modifiedWorkspaceSetupGuideAppInstanceDetails$.value ?? {};

    const key = appName + (group ? ` ${group}` : '');

    selectedWorkspaceSetupGuideConnections$.next({ ...existingConnections, [key]: uid });

    closeWorkspaceSetupGuideConnectionSelectorAction$.next();
});

createWorkspaceSetupGuideConnectionAction$.subscribe(({ appName, group }) => {
    const connectionType = apps$.value.find((a) => a.name === appName)?.connectionType;

    modifiedWorkspaceSetupGuideAppInstanceDetails$.next({ appName, group });
    openConnectionDetailsDialogAction$.next({
        connectionType: connectionType as ConnectionType,
    });
});

navigateToWorkspaceSetupGuideTriggerScreenAction$.subscribe((screenType) => {
    selectedWorkspaceSetupGuideTriggerScreenType$.next(screenType);
});

navigateToAdvancedViewAction$.subscribe(() => {
    removeCompleteEventListenersFromLocalStorage();
});

exitWorkspaceSetupGuideAction$.subscribe(() => {
    if (workspaceSetupGuideHasUnsavedChanges()) {
        promptQuestion({
            cancelButton: {
                label: 'Go back',
            },
            icon: 'warning',
            messages: ['Progress will be lost if you continue without saving.'],
            proceedButton: {
                severity: 'error',
                label: 'Discard & continue',
                onClick: () => workspaceSetupGuideExitedAction$.next(),
            },
            title: 'Your workspace has unsaved changes',
        });
    } else {
        workspaceSetupGuideExitedAction$.next();
    }
});

const warningMessages = [
    'Your work will be saved, but your progress will be erased.',
    'You will need to review all the steps again.',
    'You cannot undo this action',
];

changeWorkspaceSetupGuideConnectionAction$
    .pipe(
        map(async ({ appName, group, workspaceUid }) => {
            const selectedStep = workspaceSetupGuideSteps$.value.find((s) => s.name === 'CONNECTIONS');

            if (selectedStep?.completed) {
                promptQuestion({
                    icon: 'warning',
                    messages: warningMessages,
                    proceedButton: {
                        onClick: async () => {
                            await resetSetupGuideProgress({
                                appName,
                                group,
                                selectedStep,
                                workspaceUid,
                                onSucceed: () =>
                                    openWorkspaceSetupGuideConnectionSelectorAction$.next({ appName, group }),
                            });
                        },
                    },
                    title: 'Are you sure you want to change connector?',
                });
            } else {
                openWorkspaceSetupGuideConnectionSelectorAction$.next({ appName, group });
            }
        })
    )
    .subscribe();

editWorkspaceSetupGuideConnectionAction$
    .pipe(
        map(async ({ appName, group, connectionDetails, workspaceUid }) => {
            const selectedStep = workspaceSetupGuideSteps$.value.find((s) => s.name === 'CONNECTIONS');

            if (selectedStep?.completed) {
                promptQuestion({
                    icon: 'warning',
                    messages: warningMessages,
                    proceedButton: {
                        label: 'Edit connector',
                        onClick: async () => {
                            await resetSetupGuideProgress({
                                appName,
                                group,
                                selectedStep,
                                workspaceUid,
                                onSucceed: () => openConnectionDetailsDialogAction$.next(connectionDetails),
                            });
                        },
                    },
                    title: 'Are you sure you want to edit connector?',
                });
            } else {
                openConnectionDetailsDialogAction$.next(connectionDetails);
            }
        })
    )
    .subscribe();

saveWorkspaceSetupGuideConnectionsAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async ({ appInstances, stepUid, templateMode, workspaceUid }) => {
            const apiHandlers = selectedWorkspaceResources$.value?.apiHandlers ?? [];
            const eventListeners = selectedWorkspaceResources$.value?.eventListeners ?? [];

            try {
                if (templateMode) {
                    workspaceSetupGuideStepError$.next(undefined);
                    workspaceSetupGuideStepSaving$.next(true);

                    const genericEventListeners = eventListeners.filter((el) => el.appName === APP.GENERIC.NAME);

                    await Promise.all(
                        genericEventListeners.map((el) =>
                            saveEventListener({
                                disabled: true,
                                environmentUid: selectedEnvironmentUid$.value ?? '',
                                eventTypeUid: el.eventType?.uid,
                                scriptName: el.script?.name,
                                scriptUid: el.script?.uid,
                                uid: el.uid,
                                urlId: el.urlId || generate(),
                            })
                        )
                    );

                    for (const appInstance of appInstances) {
                        const relatedApiHandlers = apiHandlers.filter(
                            (ah) => ah.appName === appInstance.appName && ah.group === appInstance.group
                        );
                        const relatedEventListeners = eventListeners.filter(
                            (el) =>
                                el.appName === appInstance.appName &&
                                el.group === appInstance.group &&
                                appInstance.appName !== APP.GENERIC.NAME
                        );

                        await Promise.all(
                            relatedApiHandlers.map((ah) =>
                                saveApiHandlerV2({
                                    apiHandlerLibraryUid: ah.selectedApiHandlerLibrary?.uid,
                                    connectionUid: appInstance.selectedConnectionUid,
                                    environmentUid: selectedEnvironmentUid$.value ?? '',
                                    path: ah.path,
                                    uid: ah.uid,
                                })
                            )
                        );

                        await Promise.all(
                            relatedEventListeners.map((el) =>
                                saveEventListener({
                                    connectionUid: appInstance.selectedConnectionUid,
                                    disabled: true,
                                    environmentUid: selectedEnvironmentUid$.value ?? '',
                                    eventTypeUid: el.eventType?.uid,
                                    scriptName: el.script?.name,
                                    scriptUid: el.script?.uid,
                                    uid: el.uid,
                                    urlId: el.urlId,
                                })
                            )
                        );
                    }

                    await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');

                    const resources = selectedWorkspaceResources$.value;

                    const selectedConnections = getAppInstanceSelectedConnections(
                        resources.apiHandlers,
                        resources.eventListeners
                    );
                    selectedWorkspaceSetupGuideConnections$.next(selectedConnections);
                } else {
                    // TODO: Figure something out for the blank workspace flow
                }

                saveWorkspaceSetupGuideProgressAction$.next({ stepUid, workspaceUid });
            } catch (e) {
                const message =
                    e instanceof InformativeError
                        ? e.message
                        : 'Error saving connectors. Please try again, if the issue persists, please contact support.';
                workspaceSetupGuideStepError$.next(message);
                console.error('Error saving connectors', e);
            }
            workspaceSetupGuideStepSaving$.next(false);
        })
    )
    .subscribe();

saveWorkspaceSetupGuideEnvironmentVariablesAction$
    .pipe(
        map(async ({ stepUid, workspaceUid }) => {
            workspaceSetupGuideStepError$.next(undefined);
            try {
                workspaceSetupGuideStepSaving$.next(true);
                saveEnvironmentVariablesAction$.next();
                saveWorkspaceSetupGuideProgressAction$.next({ stepUid, workspaceUid });
            } catch (e) {
                workspaceSetupGuideStepSaving$.next(false);
                const message =
                    e instanceof InformativeError
                        ? e.message
                        : 'Error saving parameters. Please try again, if the issue persists, please contact support.';
                workspaceSetupGuideStepError$.next(message);
                console.error('Error saving parameters', e);
            }
        })
    )
    .subscribe();

saveWorkspaceSetupGuideReviewStepAction$
    .pipe(
        map(async ({ stepUid, workspaceUid }) => {
            const runStep = workspaceSetupGuideSteps$.value?.find((s) => s.name === 'RUN');

            if (runStep?.disabled) {
                promptQuestion({
                    icon: 'info',
                    messages: ['This will activate your integration.'],
                    title: 'Proceed to the next step?',
                    proceedButton: {
                        onClick: async () => {
                            const eventListeners = selectedWorkspaceResources$.value?.eventListeners ?? [];
                            const scheduledTriggers = selectedWorkspaceResources$.value?.scheduledTriggers ?? [];

                            workspaceSetupGuideStepError$.next(undefined);

                            try {
                                workspaceSetupGuideStepSaving$.next(true);

                                await Promise.all([
                                    [
                                        ...eventListeners.map((el) =>
                                            saveEventListener({
                                                connectionUid: el.connectionUid,
                                                disabled: false,
                                                environmentUid: selectedEnvironmentUid$.value ?? '',
                                                eventTypeUid: el.eventType?.uid,
                                                group: el.group,
                                                scriptName: el.script?.name,
                                                scriptUid: el.script?.uid,
                                                uid: el.uid,
                                                urlId: el.urlId,
                                            })
                                        ),
                                    ],
                                    [
                                        ...scheduledTriggers.map((st) =>
                                            saveScheduledTrigger({
                                                cronExpression: st.cronExpression,
                                                disabled: false,
                                                environmentUid: selectedEnvironmentUid$.value ?? '',
                                                scriptUid: st.script?.uid,
                                                uid: st.uid,
                                            })
                                        ),
                                    ],
                                ]);

                                saveWorkspaceSetupGuideProgressAction$.next({ stepUid, workspaceUid });
                            } catch (e) {
                                workspaceSetupGuideStepSaving$.next(false);
                                const message =
                                    e instanceof InformativeError
                                        ? e.message
                                        : 'Error activating your integration. Please try again, if the issue persists, please contact support.';
                                workspaceSetupGuideStepError$.next(message);
                                console.error('Error activating workspace', e);
                            }
                        },
                    },
                });
            } else {
                saveWorkspaceSetupGuideProgressAction$.next({ stepUid, workspaceUid });
            }
        })
    )
    .subscribe();

saveWorkspaceSetupGuideProgressAction$
    .pipe(
        map(async ({ workspaceUid, stepUid }) => {
            workspaceSetupGuideStepError$.next(undefined);
            try {
                workspaceSetupGuideStepSaving$.next(true);
                const completedSteps = workspaceSetupGuideSteps$.value.filter((cs) => cs.completed).map((s) => s.uid);

                await saveWorkspaceSetupGuideProgress([...completedSteps, stepUid], workspaceUid);
                await loadWorkspaceSetupGuideSteps(workspaceUid);

                const currentStep = workspaceSetupGuideSteps$.value?.find((s) => s.uid === stepUid);

                const nextStep = workspaceSetupGuideSteps$.value?.find(
                    (s) => currentStep && s.number === currentStep.number + 1
                );

                if (nextStep) {
                    navigateToWorkspaceSetupGuideStepAction$.next(nextStep.name);
                } else {
                    navigateToWorkspaceSetupGuideCongratulationsScreenAction$.next();
                }
            } catch (e) {
                const message =
                    e instanceof InformativeError
                        ? e.message
                        : 'Error saving setup guide progress. Please try again, if the issue persists, please contact support.';
                workspaceSetupGuideStepError$.next(message);
                console.error('Error saving setup guide progress', e);
            }
            workspaceSetupGuideStepSaving$.next(false);
        })
    )
    .subscribe();

navigateToAdvancedViewAction$
    .pipe(
        map(async ({ workspaceUid, ignoreWarnings }) => {
            const navigateToAdvancedView = async (): Promise<void> => {
                try {
                    await toggleWorkspaceSetupGuide(workspaceUid);
                    workspaceSetupGuideModeUpdatedAction$.next(undefined);
                } catch (e) {
                    const message =
                        e instanceof InformativeError
                            ? e.message
                            : 'Error navigating to advanced view. If the issue persists, please contact support.';

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message,
                    });
                    console.error('Error navigating to advanced view', e);
                }
            };

            if (!ignoreWarnings) {
                promptQuestion({
                    icon: 'warning',
                    messages: [
                        'If you transfer your work to the advanced view, the setup guide will close.',
                        'You will not be able to return to the current setup guide.',
                    ],
                    proceedButton: {
                        label: 'Exit setup guide',
                        onClick: async () => {
                            await navigateToAdvancedView();
                        },
                    },
                    title: 'Proceed to advanced view?',
                });
            } else {
                await navigateToAdvancedView();
            }
        })
    )
    .subscribe();

export const loadWorkspaceSetupGuideSteps = async (workspaceUid: string): Promise<void> => {
    // Make this dynamic if needed in the future
    const OPTIONAL_STEPS = ['SCRIPTS', 'RUN'] as SetupGuideStepName[];

    const [steps, completedSteps] = await Promise.all([
        getWorkspaceSetupGuideSteps(),
        getCompletedWorkspaceSetupGuideSteps(workspaceUid),
    ]);

    const displayedSteps = getRemappedSetupGuideSteps(steps, completedSteps, OPTIONAL_STEPS);
    workspaceSetupGuideSteps$.next(displayedSteps);
};

export const loadWorkspaceSetupGuideStep = async (
    workspaceUid: string,
    stepName: SetupGuideStepName
): Promise<void> => {
    if (!workspaceSetupGuideSteps$.value.length) {
        await loadWorkspaceSetupGuideSteps(workspaceUid);
    }
    const steps = workspaceSetupGuideSteps$.value;
    const currentStep = steps.find((s) => s.name === stepName);

    if (currentStep?.disabled) {
        throw new PermissionError('Please complete all preceding required steps.');
    }

    selectedWorkspaceSetupGuideStep$.next(currentStep);
};

const resetSetupGuideProgress = async (options: {
    appName?: string;
    group?: string;
    selectedStep: SetupGuideStep;
    workspaceUid: string;
    onSucceed: () => void;
}): Promise<void> => {
    try {
        const precedingSteps = workspaceSetupGuideSteps$.value // In case the step gets moved
            .filter((s) => s.number < options.selectedStep.number)
            .map((s) => s.uid);
        await saveWorkspaceSetupGuideProgress(precedingSteps, options.workspaceUid);
        await loadWorkspaceSetupGuideSteps(options.workspaceUid);

        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Setup guide progress has been reset.',
        });

        removeCompleteEventListenersFromLocalStorage(options.appName, options.group);

        options.onSucceed();
    } catch (e) {
        if (e instanceof InformativeError) {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: e.message,
            });
        } else {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    'Error resetting setup guide progress. Please try again, if the issue persists, please contact support.',
            });
            console.error('Error resetting setup guide progress', e);
        }
    }
};

const removeCompleteEventListenersFromLocalStorage = (appName?: string, group?: string): void => {
    const completeEventListeners = readLocalStorage<string[]>(WEBHOOK_SETUP_COMPLETE_KEY, []);

    const eventListenerUids = selectedWorkspaceResources$.value.eventListeners
        .filter((el) =>
            appName ? el.appName !== APP.GENERIC.NAME && el.appName === appName && el.group === group : true
        )
        .map((fel) => fel.uid);

    const updatedEventListenerList = completeEventListeners.filter((el) => !eventListenerUids.includes(el));

    saveLocalStorage(WEBHOOK_SETUP_COMPLETE_KEY, updatedEventListenerList);
};

const workspaceSetupGuideHasUnsavedChanges = (): boolean => {
    const hasUnsavedEnvironmentVariables = environmentVariablesHaveUnsavedChanges$.value;
    const hasUnsavedSelectedConnections = workspaceSetupGuideConnectionsHaveUnsavedChanges$.value;
    const cronHasUnsavedChanges = cronHasUnsavedChanges$.value;

    return hasUnsavedEnvironmentVariables || hasUnsavedSelectedConnections || cronHasUnsavedChanges;
};
