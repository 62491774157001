import { styled } from '@mui/material';
import MuiToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';

interface CustomToggleButtonProps extends ToggleButtonProps {
    icon?: JSX.Element;
    label: string | JSX.Element;
}

export interface CustomToggleButtonGroupProps extends ToggleButtonGroupProps {
    buttons: CustomToggleButtonProps[];
    size?: 'small' | 'medium';
}

const calculateGridTemplateColumns = (length: number): string => {
    return new Array(length).fill('1fr').join(' ');
};

export const StyledToggleButtonGroup = styled(MuiToggleButtonGroup, {
    shouldForwardProp: (prop) => prop !== 'buttonLength',
})<{ buttonLength: number; fullWidth: boolean }>(({ buttonLength, fullWidth }) => ({
    alignItems: 'center',
    width: fullWidth ? '100%' : 'auto',
    display: 'inline-grid',
    gridTemplateColumns: calculateGridTemplateColumns(buttonLength),
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        marginLeft: 0, // Overwrite native styles to preserve border
    },
}));

export const StyledToggleButton = styled(ToggleButton, {
    shouldForwardProp: (prop) => prop !== 'buttonSize',
})<{
    buttonSize: 'small' | 'medium';
}>(({ buttonSize, theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    gap: theme.spacing(0.5),
    height: buttonSize === 'small' ? 36 : undefined,
    padding: buttonSize === 'small' ? theme.spacing(1, 1.5) : undefined,
    textTransform: 'none',
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
    '&.Mui-disabled.Mui-selected': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled,
    },
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

export const ToggleButtonGroup: React.FC<CustomToggleButtonGroupProps> = ({
    buttons,
    fullWidth = false,
    size = 'medium',
    ...props
}) => {
    const toggleButtons = buttons.map((b, i) => (
        <StyledToggleButton buttonSize={size} key={`toggle-button-${i}`} {...b}>
            {b.icon}
            {b.label}
        </StyledToggleButton>
    ));

    return (
        <StyledToggleButtonGroup buttonLength={buttons.length} fullWidth={fullWidth} size={size} {...props}>
            {toggleButtons}
        </StyledToggleButtonGroup>
    );
};
