import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';

export interface OnboardingTemplateCardProps {
    apps: string[];
    name: string;
    uid: string;
    onSelect: (uid: string) => void;
}

const StyledCard = styled(ButtonBase)(({ theme }) => ({
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexDirection: 'column',
    gap: theme.spacing(1),
    height: 170,
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    position: 'relative',
    textAlign: 'start',
    width: 231,
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
}));

const StyledAppNames = styled(Typography)(() => ({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    wordBreak: 'break-word',
}));

const StyledName = styled(Typography)(() => ({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    wordBreak: 'break-word',
}));

export const OnboardingTemplateCard: React.FC<OnboardingTemplateCardProps> = ({ apps, name, uid, onSelect }) => {
    const icons = apps.slice(0, 3).map((app) => <IconCircle icon={<ProductIcon name={app} />} />);

    return (
        <StyledCard data-pendo={'templateViewed'} onClick={() => onSelect(uid)}>
            {icons.length > 0 && <StyledIconContainer>{icons}</StyledIconContainer>}
            <Tooltip title={apps.join(' · ')}>
                <StyledAppNames variant="body2" color="text.secondary">
                    {apps.join(' · ')}
                </StyledAppNames>
            </Tooltip>
            <Tooltip title={name}>
                <StyledName variant="subtitle2">{name}</StyledName>
            </Tooltip>
        </StyledCard>
    );
};
