import { useObservableState } from 'observable-hooks';
import { apps$ } from '../../../store/apps';
import { selectedEventListenerDetails$ } from '../../../store/workspace/event-listener';
import { selectedWorkspaceResources$ } from '../../../store/workspace';
import { EventListenerTabbedDetails } from '../../../components/workspace-resources/event-listeners/EventListenerTabbedDetails';

export const ReadOnlyEventListenerDetailsContainer: React.FC = () => {
    const details = useObservableState(selectedEventListenerDetails$);
    const apps = useObservableState(apps$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    const selectedAppName = apps.find((a) => a.uid === details?.appUid)?.name ?? '';
    const filteredEventTypes =
        apps
            .find((a) => a.uid === details?.appUid)
            ?.connectionType.eventListenerTypes.find((elt) => elt.uid === details?.eventListenerTypeUid)?.eventTypes ??
        [];

    return (
        <EventListenerTabbedDetails
            appName={selectedAppName}
            details={{
                scripts: workspaceResources.scripts,
                selectedEventTypeUid: details?.eventTypeUid,
                selectedScriptUid: details?.scriptUid,
                eventTypes: filteredEventTypes,
                uid: details?.uid ?? '',
            }}
            templatePreviewMode={true}
        />
    );
};
