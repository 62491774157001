import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Button } from '../../../common/buttons/Button';
import { ProductIcon } from '../../../icons/ProductIcon';
import { Indicator } from '../../../common/Indicator';
import {
    StyledListItemActions,
    StyledListItemBottomTextContainer,
    StyledListItemRightContainer,
    StyledRunScreenListItem,
    StyledListItemLeftContainer,
} from '../../SetupGuideComponents';
import { SetupGuideRunScreenEventListener } from '../../types';

export interface SetupGuideRunScreenEventListenerListItemProps extends SetupGuideRunScreenEventListener {
    onRun: (uid: string) => void;
}

export const SetupGuideRunScreenEventListenerListItem: React.FC<SetupGuideRunScreenEventListenerListItemProps> = ({
    appName,
    disabled,
    running,
    scriptName,
    uid,
    onRun,
}) => {
    return (
        <StyledRunScreenListItem>
            <StyledListItemLeftContainer>
                <Indicator left={{ icon: <HearingOutlinedIcon /> }} right={{ icon: <ProductIcon name={appName} /> }} />
                <Box>
                    <Typography>{appName}</Typography>
                    <StyledListItemBottomTextContainer>
                        <CodeOutlinedIcon />
                        <Typography>Script:</Typography>
                        <Typography color="primary.main">{scriptName}</Typography>
                    </StyledListItemBottomTextContainer>
                </Box>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    <Button
                        busy={running}
                        disabled={disabled}
                        startIcon={<PlayCircleOutlineIcon />}
                        variant="text"
                        onClick={() => onRun(uid)}
                    >
                        Run Test Event
                    </Button>
                </StyledListItemActions>
            </StyledListItemRightContainer>
        </StyledRunScreenListItem>
    );
};
