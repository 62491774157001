import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    OrganizationDetails,
    OrganizationPlan,
    OrganizationUsage,
    OrganizationViewDetails,
    saveOrganizationDetails,
    SaveOrganizationDetailsRequest,
    changeOrganizationBillingEntity,
    OrganizationBillingDetails,
    UpdateOrganizationBillingDetails,
    OrganizationMembers,
    updateOrganizationMemberPermissions,
    OrganizationMemberPermissionsUpdateRequest,
    updateOrganizationInvitedUserPermissions,
    OrganizationInvitedUserPermissionsUpdateRequest,
    removeOrganizationMember,
    OrganizationMemberRemovalRequest,
    removeOrganizationInvite,
    OrganizationInviteRemovalRequest,
    MemberInvitationRequest,
    sendOrganizationMemberInvite,
    resendOrganizationMemberInvite,
    getOrganizationWorkspacesWithUserConnections,
    getOrganizationUsage,
    getOrganizationPlan,
} from '../data/organization';
import { InformativeError, PermissionError } from '../utils/error';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { promptQuestion } from './confirm';
import { AYSYWTRTU } from '../i18n';
import { saveLocalStorage } from '../utils/localStorage';
import { loadLoggedInUserDetails, selectedOrganizationUidKey } from '../data/user';
import { initializePendoForUser } from '../data/pendo-analytics';
import { loggedInUserDetails$ } from './user';
import { loadErrorPage } from './error';
import { getLoggedInUserWorkspaces } from '../data/workspaces';
import { loggedInUserWorkspaces$ } from './workspaces';
import { aiAssistanceAvailableCredits$ } from './ai-assistance';
import {
    changeOrganizationPlanValidationError$,
    organizationPlanUpdating$,
} from './organization/changeOrganizationPlan';

export const selectOrganizationAndInitPendoAction$ = monitor(
    'selectOrganizationAndInitPendoAction$',
    new Subject<{
        userUid?: string;
        userOrigin?: string;
        orgUid?: string;
        orgDetails?: Record<string, unknown>;
    }>()
);

export const saveOrganizationDetailsAction$ = monitor(
    'saveOrganizationDetailsAction$',
    new Subject<SaveOrganizationDetailsRequest>()
);

export const selectedOrganizationDashboardUsageAction$ = monitor(
    'selectedOrganizationDashboardUsageAction$',
    new Subject<string>()
);

export const organizationDetailsUpdatedAction$ = monitor('organizationDetailsUpdatedAction$', new Subject<string>());

export const organizationPlanChangedAction$ = monitor('organizationPlanChangedAction$', new Subject<void>());

export const addNewOrganizationMemberAction$ = monitor(
    'addNewOrganizationMemberAction$',
    new Subject<MemberInvitationRequest>()
);

export const organizationMemberInviteSentAction$ = monitor('organizationMemberInviteSentAction$', new Subject<void>());

export const updateOrganizationMemberPermissionsAction$ = monitor(
    'updateOrganizatioMemberPermissionsAction$',
    new Subject<OrganizationMemberPermissionsUpdateRequest>()
);

export const organizationMemberPermissionsUpdatedAction$ = monitor(
    'organizationMemberPermissionsUpdated$Action',
    new Subject<string>()
);

export const updateOrganizationInvitedUserPermissionsAction$ = monitor(
    'updateOrganizatioInvitedUserPermissionsAction$',
    new Subject<OrganizationInvitedUserPermissionsUpdateRequest>()
);
export const organizationInvitedUserPermissionsUpdatedAction$ = monitor(
    'organizationInvitedUserPermissionsUpdatedAction$',
    new Subject<string>()
);

export const removeOrganizationMemberAction$ = monitor(
    'removeOrganizatioMemberAction$',
    new Subject<OrganizationMemberRemovalRequest>()
);

export const organizationMemberRemovedAction$ = monitor('organizationMemberRemovedAction$', new Subject<string>());

export const removeOrganizationInviteAction$ = monitor(
    'removeOrganizatioInviteAction$',
    new Subject<OrganizationInviteRemovalRequest>()
);

export const switchOrganizationAction$ = monitor('switchOrganizationAction$', new Subject<string>());

export const organizationSwitchedAction$ = monitor('organizationSwitchedAction$', new Subject<string>());

export const selectedOrganizationUid$ = monitor(
    'selectedOrganizationUid$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedOrganizationViewDetails$ = monitor(
    'selectedOrganizationViewDetails$',
    new BehaviorSubject<OrganizationViewDetails | undefined>(undefined)
);

export const organizationMembers$ = monitor(
    'organizationMembers$',
    new BehaviorSubject<OrganizationMembers | undefined>(undefined)
);

export const organizationBillingEntityPermissionError$ = monitor(
    'organizationBillingEntityPermissionError$',
    new BehaviorSubject<string>('')
);

export const organizationBillingDetails$ = monitor(
    'organizationBillingDetails$',
    new BehaviorSubject<OrganizationBillingDetails | undefined>(undefined)
);

export const organizationBillingDetailsCustomerPortal$ = monitor(
    'organizationBillingDetailsCustomerPortal$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const changeOrganizationBillingDetailsAction$ = monitor(
    'changeOrganizationBillingDetailsAction$',
    new Subject<UpdateOrganizationBillingDetails>()
);

export const organizationBillingDetailsUpdatedAction$ = monitor(
    'organizationBillingDetailsUpdatedAction$',
    new Subject<void>()
);

export const organizationBillingDetailsUpdateCancelledAction$ = monitor(
    'organizationBillingDetailsCancelledAction$',
    new Subject<void>()
);

export const resendOrganizationMemberInviteAction$ = monitor(
    'resendOrganizationMemberInviteAction$',
    new Subject<string>()
);

export const organizationInviteRemovedAction$ = monitor('organizationInviteRemovedAction$', new Subject<string>());

export const savingOrganizationBillingDetails$ = monitor(
    'savingOrganizationBillingDetails$',
    new BehaviorSubject(false)
);

export const selectedOrganizationUsage$ = monitor(
    'selectedOrganizationUsage$',
    new BehaviorSubject<OrganizationUsage | undefined>(undefined)
);

export const addNewOrganizationMemberDialogOpen$ = monitor(
    'addNewOrganizationMemberDialogOpen$',
    new BehaviorSubject(false)
);

export const sendingOrganizationMemberInvite$ = monitor('sendingOrganizationMemberInvite$', new BehaviorSubject(false));

export const sendOrganizationMemberInviteError$ = monitor(
    'sendOrganizationMemberInviteError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const memberManagementError$ = monitor(
    'memberManagementError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const memberManagementSaving$ = monitor('memberManagementSaving$', new BehaviorSubject(false));

export const selectedOrganizationDetails$ = monitor(
    'selectedOrganizationDetails$',
    new BehaviorSubject<OrganizationDetails | undefined>(undefined)
);

export const saveOrganizationDetailsValidationError$ = monitor(
    'saveOrganizationDetailsValidationError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const organizationDetailsUpdating$ = monitor(
    'organizationDetailsUpdating$',
    new BehaviorSubject<boolean>(false)
);

export const selectedOrganizationPlan$ = monitor(
    'selectedOrganizationPlan$',
    new BehaviorSubject<OrganizationPlan | undefined>(undefined)
);

export const selectedOrganizationPlanLastUpdatedDate$ = monitor(
    'selectedOrganizationPlanLastUpdatedDate$',
    new BehaviorSubject<Date>(new Date())
);

export const organizationInvoicePlanInformationDialogOpen$ = monitor(
    'organizationInvoicePlanInformationDialogOpen$',
    new BehaviorSubject<boolean>(false)
);

selectOrganizationAndInitPendoAction$.subscribe(({ userUid, userOrigin, orgUid, orgDetails }) => {
    const loggedInUserDetails = loggedInUserDetails$.value;
    selectedOrganizationUid$.next(orgUid);

    const user = userUid ?? loggedInUserDetails?.uid;
    const userOriginToPendo = userOrigin ?? loggedInUserDetails?.userOrigin;

    if (user) {
        if (orgUid) {
            if (orgDetails && Object.keys(orgDetails).length > 0) {
                initializePendoForUser({
                    userUid: user,
                    userOrigin: userOriginToPendo,
                    organizationUid: orgUid,
                    orgDetails,
                });
            } else {
                const org = loggedInUserDetails?.organizations?.find((el) => el.uid === orgUid);
                if (org) {
                    initializePendoForUser({
                        userUid: user,
                        userOrigin: userOriginToPendo,
                        organizationUid: orgUid,
                        orgDetails: {
                            teamName: org.name,
                            planUid: org.planUid,
                            planName: org.planName,
                            planPeriod: org.planPeriod,
                            planStatus: org.planStatus,
                        },
                    });
                }
            }
        } else {
            initializePendoForUser({
                userUid: user,
                userOrigin: userOriginToPendo,
            });
        }
    }
});

switchOrganizationAction$.subscribe((organizationUid) => {
    selectOrganizationAndInitPendoAction$.next({ orgUid: organizationUid });
    saveLocalStorage(selectedOrganizationUidKey, organizationUid);
    organizationSwitchedAction$.next(organizationUid);
});

organizationPlanChangedAction$
    .pipe(
        map(async () => {
            organizationPlanUpdating$.next(true);
            changeOrganizationPlanValidationError$.next(undefined);
            try {
                await loadLoggedInUserDetails();
            } catch (e) {
                const message = e instanceof InformativeError ? e.message : 'Error loading user details.';
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message,
                });

                console.error('Error loading user details', e);
            }

            try {
                const selectedOrganizationUid = selectedOrganizationUid$.value;
                if (selectedOrganizationUid) {
                    const plan = await getOrganizationPlan({ organizationUid: selectedOrganizationUid });
                    selectedOrganizationPlan$.next(plan);
                }
                organizationPlanUpdating$.next(false);
            } catch (e) {
                organizationPlanUpdating$.next(false);
                if (e instanceof InformativeError) {
                    changeOrganizationPlanValidationError$.next(e.message);
                } else {
                    console.error('Failed to change plan', e);
                    changeOrganizationPlanValidationError$.next(
                        'Error occurred while changing plan. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

changeOrganizationBillingDetailsAction$
    .pipe(
        map(async (updateData) => {
            savingOrganizationBillingDetails$.next(true);
            try {
                await changeOrganizationBillingEntity(updateData);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Billing details updated.',
                });
                organizationBillingEntityPermissionError$.next('');
                organizationBillingDetailsUpdatedAction$.next();
            } catch (e) {
                if (e instanceof PermissionError) {
                    organizationBillingEntityPermissionError$.next(e.message);
                } else {
                    organizationBillingEntityPermissionError$.next(
                        'Error occurred while updating team billing details.'
                    );
                    throw new Error((e as Error).message);
                }
            }
            savingOrganizationBillingDetails$.next(false);
        })
    )
    .subscribe();

updateOrganizationMemberPermissionsAction$
    .pipe(
        map(async (update) => {
            memberManagementError$.next(undefined);
            memberManagementSaving$.next(true);
            try {
                await updateOrganizationMemberPermissions(update);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Member updated.',
                });
                organizationInvitedUserPermissionsUpdatedAction$.next(update.memberUid);
            } catch (e) {
                if (e instanceof PermissionError || e instanceof InformativeError) {
                    memberManagementError$.next(e.message);
                } else {
                    memberManagementError$.next(
                        'Error occurred while updating team member. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            memberManagementSaving$.next(false);
        })
    )
    .subscribe();

updateOrganizationInvitedUserPermissionsAction$
    .pipe(
        map(async (update) => {
            {
                memberManagementError$.next(undefined);
                memberManagementSaving$.next(true);
                try {
                    await updateOrganizationInvitedUserPermissions(update);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Invited user updated.',
                    });
                    organizationInvitedUserPermissionsUpdatedAction$.next(update.inviteUid);
                } catch (e) {
                    if (e instanceof PermissionError || e instanceof InformativeError) {
                        memberManagementError$.next(e.message);
                    } else {
                        memberManagementError$.next(
                            'Error occurred while updating invited user. Please try again, if the issue persists please contact support.'
                        );
                    }
                }
                memberManagementSaving$.next(false);
            }
        })
    )
    .subscribe();

removeOrganizationMemberAction$
    .pipe(
        map(async (event) => {
            {
                const callRemoveMember = async (): Promise<void> => {
                    await removeOrganizationMember(event);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Member removed.',
                    });
                    organizationMemberRemovedAction$.next(event.removedUser);
                };

                memberManagementError$.next(undefined);
                memberManagementSaving$.next(true);

                try {
                    const workspacesWithUserConnections = await getOrganizationWorkspacesWithUserConnections({
                        memberUid: event.removedUser,
                        organizationUid: event.organizationUid,
                    });

                    const workspaceNames = workspacesWithUserConnections.map((w) => w.name);
                    const message = workspacesWithUserConnections.length
                        ? `This will also remove all connectors in the team owned by this user, affecting the following workspaces: ${workspaceNames.join(
                              ', '
                          )}.`
                        : '';
                    promptQuestion({
                        title: AYSYWTRTU,
                        messages: [message],
                        proceedButton: {
                            label: 'Remove member',
                            severity: 'error',
                            onClick: async () => await callRemoveMember(),
                        },
                    });
                } catch (e) {
                    if (e instanceof PermissionError || e instanceof InformativeError) {
                        memberManagementError$.next(e.message);
                    } else {
                        memberManagementError$.next(
                            'Error occurred while removing team member. Please try again, if the issue persists please contact support.'
                        );
                    }
                }

                memberManagementSaving$.next(false);
            }
        })
    )
    .subscribe();

removeOrganizationInviteAction$
    .pipe(
        map(async (event) => {
            {
                memberManagementError$.next(undefined);
                memberManagementSaving$.next(true);
                try {
                    await removeOrganizationInvite(event);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Invite removed.',
                    });
                    organizationInviteRemovedAction$.next(event.removedInvite);
                } catch (e) {
                    if (e instanceof PermissionError || e instanceof InformativeError) {
                        memberManagementError$.next(e.message);
                    } else {
                        memberManagementError$.next(
                            'Error occurred while removing team invite. Please try again, if the issue persists please contact support.'
                        );
                    }
                }
                memberManagementSaving$.next(false);
            }
        })
    )
    .subscribe();

addNewOrganizationMemberAction$
    .pipe(
        map(async (invite) => {
            sendOrganizationMemberInviteError$.next(undefined);
            sendingOrganizationMemberInvite$.next(true);

            try {
                await sendOrganizationMemberInvite(invite);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Invite sent.',
                });
                addNewOrganizationMemberDialogOpen$.next(false);
                organizationMemberInviteSentAction$.next();
            } catch (e) {
                sendingOrganizationMemberInvite$.next(false);
                if (e instanceof PermissionError || e instanceof InformativeError) {
                    sendOrganizationMemberInviteError$.next(e.message);
                } else {
                    console.error('Failed to send invitation', e);
                    sendOrganizationMemberInviteError$.next(
                        'Error occurred while sending invitation. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            sendingOrganizationMemberInvite$.next(false);
        })
    )
    .subscribe();

resendOrganizationMemberInviteAction$
    .pipe(
        map(async (uid) => {
            try {
                await resendOrganizationMemberInvite(uid);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Invite resent.',
                });
            } catch (e) {
                if (e instanceof PermissionError || e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message:
                            'Error occurred while resending invite. Please try again, if the issue persists please contact support.',
                    });
                }
            }
        })
    )
    .subscribe();

saveOrganizationDetailsAction$
    .pipe(
        map(async ({ name, organizationUid, description }) => {
            try {
                organizationDetailsUpdating$.next(true);
                saveOrganizationDetailsValidationError$.next(undefined);
                await saveOrganizationDetails(name, organizationUid, description);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Team details updated.',
                });
                organizationDetailsUpdating$.next(false);
                organizationDetailsUpdatedAction$.next(name);
                await loadLoggedInUserDetails();
            } catch (e) {
                organizationDetailsUpdating$.next(false);
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    saveOrganizationDetailsValidationError$.next(e.message);
                } else {
                    saveOrganizationDetailsValidationError$.next(
                        'Error occurred while updating team details. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

selectedOrganizationDashboardUsageAction$.pipe(map(async (uid) => await loadDashboardUsage(uid))).subscribe();

export const loadDashboardUsage = async (uid: string): Promise<void> => {
    try {
        const usage = await getOrganizationUsage(uid);
        selectedOrganizationUsage$.next(usage);
        aiAssistanceAvailableCredits$.next(usage.aiAssistanceAvailableCredits);
        const workspaces = await getLoggedInUserWorkspaces(uid);
        loggedInUserWorkspaces$.next(workspaces);
        selectedOrganizationPlanLastUpdatedDate$.next(new Date());
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load team usage details for Dashboard.',
        });

        throw e;
    }
};
