import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { StyledResourceListItemButton } from './ResourceTreeComponents';
import { WorkspaceLanguage } from './types';
import { MouseEvent } from 'react';

interface ResourceTreeScriptProps {
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    executing?: boolean;
    language?: WorkspaceLanguage;
    name: string;
    readOnlyMode?: boolean;
    saving?: boolean;
    selectedNode?: string;
    uid: string;
    unsaved?: boolean;
    onDelete(uid: string): void;
    onSave?(uid: string): void;
    onTrigger?(uid: string): void;
}

export const ResourceTreeScript: React.FC<ResourceTreeScriptProps> = ({
    deleting,
    deployedEnvironmentMode = false,
    executing,
    language = 'ts',
    name,
    readOnlyMode = false,
    saving = false,
    selectedNode,
    uid,
    unsaved,
    onDelete,
    onSave,
    onTrigger,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selected = selectedNode === uid;
    const showSaveIcon = !deployedEnvironmentMode && !readOnlyMode && unsaved;
    const canTrigger = !deleting && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    const handleSave = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onSave?.(uid);
    };

    const handleTrigger = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onTrigger?.(uid);
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onDelete(uid);
    };

    return (
        <StyledResourceListItemButton
            key={uid}
            endIcons={[
                ...(showSaveIcon
                    ? [
                          <IconButton
                              aria-label="Save script"
                              busy={saving}
                              icon={<SaveOutlinedIcon />}
                              size="small"
                              tooltip="Save script"
                              onClick={handleSave}
                          />,
                      ]
                    : []),
                ...(canTrigger
                    ? [
                          <IconButton
                              aria-label="Trigger script manually"
                              busy={executing}
                              icon={<PlayCircleOutlineRoundedIcon />}
                              size="small"
                              tooltip="Trigger script manually"
                              onClick={handleTrigger}
                          />,
                      ]
                    : []),
                ...(canDelete
                    ? [
                          <IconButton
                              aria-label="Delete script"
                              busy={deleting}
                              icon={<DeleteOutlineIcon />}
                              size="small"
                              tooltip="Delete script"
                              onClick={handleDelete}
                          />,
                      ]
                    : []),
            ]}
            label={name}
            link={{ href: `./script/${uid}`, type: 'router' }}
            selected={selected}
            startIcon={
                <img
                    src={process.env.PUBLIC_URL + `/${language !== 'js' ? 'ts' : 'js'}.png`}
                    alt={(language ? language : 'Typescript') + ' icon'}
                />
            }
        />
    );
};
