import { useObservableState } from 'observable-hooks';
import {
    cancelDeleteAccountAction$,
    customRoleValidationError$,
    deleteAccountAction$,
    loggedInUserDetails$,
    resetUserPasswordAction$,
    updateUserDetailsAction$,
    userDetailsUpdating$,
} from '../../../store/user';
import { ProfileSettings } from '../../../components/profile/profile-settings';
import { useNavigate } from 'react-location';
import { useAuth0 } from '@auth0/auth0-react';
import { industryRoles$ } from '../../../store/roles';
import { promptQuestion } from '../../../store/confirm';
import { supportPortalUrl } from '../../../utils/documentation';
import { scriptingFamiliarityOptions$ } from '../../../store/onboarding';
import { apps$ } from '../../../store/apps';
import { getBasePath } from '../../../utils/path';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProfileSettingsContainer: React.FC = () => {
    const isUpdating = useObservableState(userDetailsUpdating$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const industryRoles = useObservableState(industryRoles$);
    const navigate = useNavigate();
    const validationError = useObservableState(customRoleValidationError$);
    const scriptingFamiliarities = useObservableState(scriptingFamiliarityOptions$);
    const apps = useObservableState(apps$);

    const handleReset = (): void => {
        if (loggedInUserDetails?.email) {
            resetUserPasswordAction$.next(loggedInUserDetails.email);
        }
    };

    const isManualReg = useAuth0().user?.sub?.startsWith('auth0') ?? false;

    const remappedIndustryRoles = industryRoles?.map((r) => {
        return {
            value: r.uid,
            name: r.label,
        };
    });
    const remappedScriptingFamiliarity = scriptingFamiliarities?.map((sf) => {
        return {
            value: sf.uid,
            name: sf.label,
        };
    });

    const handleDeleteAccount = (): void => {
        promptQuestion({
            title: 'Are you sure you want to delete the account?',
            proceedButton: {
                label: 'Delete account',
                severity: 'error',
                onClick: () => deleteAccountAction$.next(),
            },
        });
    };

    const handleCancelAccountDeletion = (): void => {
        promptQuestion({
            title: 'Are you sure you want to cancel the account deletion?',
            cancelButton: {
                label: 'No',
            },
            proceedButton: {
                label: 'Yes',
                onClick: () => cancelDeleteAccountAction$.next(),
            },
        });
    };

    const handleDiscard = (): void => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/profile` }), 100);
    };

    return (
        <ProfileSettings
            apps={apps.map(({ uid, name }) => ({
                uid,
                name,
            }))}
            company={loggedInUserDetails?.company ?? ''}
            customRole={loggedInUserDetails?.customRole ?? ''}
            deleteDate={loggedInUserDetails?.deleteDate}
            email={loggedInUserDetails?.email ?? ''}
            emailNotificationsEnabled={!!loggedInUserDetails?.emailNotificationsEnabled}
            errors={validationError}
            firstName={loggedInUserDetails?.firstName ?? ''}
            lastName={loggedInUserDetails?.lastName ?? ''}
            mfaEnabled={!!loggedInUserDetails?.mfaEnabled}
            roles={remappedIndustryRoles}
            roleUid={loggedInUserDetails?.roleUid ?? 'Other'}
            saving={isUpdating}
            scriptingFamiliarities={remappedScriptingFamiliarity ?? []}
            scriptingFamiliarityUid={loggedInUserDetails?.scriptingFamiliarityUid ?? ''}
            selectedApps={loggedInUserDetails?.userAppPreference ?? []}
            showPasswordReset={isManualReg}
            supportPortalUrl={supportPortalUrl}
            onCancelDelete={handleCancelAccountDeletion}
            onDelete={handleDeleteAccount}
            onDiscard={handleDiscard}
            onPasswordReset={handleReset}
            onUpdate={({
                firstName,
                lastName,
                company,
                roleUid,
                customRole,
                enableEmailNotifications,
                enableMfa,
                scriptingFamiliarityUid,
                userAppPreference,
            }) =>
                updateUserDetailsAction$.next({
                    company,
                    customIndustryRole: customRole,
                    enableEmailNotifications,
                    enableMfa,
                    firstName,
                    lastName,
                    roleUid,
                    scriptingFamiliarityUid,
                    userAppPreference,
                })
            }
        />
    );
};
