import { styled } from '@mui/material';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';

export interface CustomRadioProps extends ButtonBaseProps {
    border?: boolean;
    checked: boolean;
    label: string;
    onCheck: (checked: boolean) => void;
}

const StyledButton = styled(ButtonBase)<{ border: boolean }>(({ border, theme }) => ({
    backgroundColor: border ? theme.palette.background.paper : undefined,
    border: border ? `1px solid ${theme.palette.divider}` : 'none',
    borderRadius: theme.constants.borderRadius,
    padding: theme.spacing(1, 1.5, 1, 1),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    margin: 0,
    gap: theme.spacing(1),
}));

const StyledRadio = styled(MuiRadio)(() => ({
    padding: 0,
    margin: 0,
    '& input:hover': {
        backgroundColor: 'none',
    },
}));

export const Radio: React.FC<CustomRadioProps> = ({ border = false, checked, label, onCheck, ...props }) => {
    return (
        <StyledButton border={border} onClick={() => onCheck(!checked)} {...props}>
            <StyledFormControlLabel
                control={<StyledRadio checked={checked} onChange={(e) => e.preventDefault()} tabIndex={-1} />}
                label={label}
                onClick={(e) => e.preventDefault()}
            />
        </StyledButton>
    );
};
