import { styled, Theme, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

type BadgeSeverity = 'grey' | 'paper';

interface BadgeProps extends BoxProps {
    icon?: JSX.Element;
    label?: string | JSX.Element;
    severity?: BadgeSeverity;
    size?: 'small' | 'medium';
}

const getBadgeColor = (theme: Theme, color: BadgeSeverity): { backgroundColor: string; color: string } => {
    switch (color) {
        case 'paper':
            return {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            };
        default:
        case 'grey':
            return {
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], // Change this
                color: theme.palette.text.primary, // Change this
            };
    }
};

const StyledBadge = styled(Box, { shouldForwardProp: (prop) => prop !== 'severity' })<{
    severity: BadgeSeverity;
    size: 'small' | 'medium';
}>(({ severity, size, theme }) => {
    const isSmall = size === 'small';
    return {
        ...getBadgeColor(theme, severity),
        ...theme.typography.flexAlignCenter,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.constants.borderRadius,
        height: isSmall ? 32 : 40,
        justifyContent: 'center',
        gap: theme.spacing(1),
        padding: isSmall ? theme.spacing(0.5, 1) : theme.spacing(1),
        '& .MuiSvgIcon-root': {
            height: 24,
            width: 24,
        },
        flexShrink: 0,
    };
});

export const Badge: React.FC<BadgeProps> = ({ icon, label, severity = 'grey', size = 'medium', ...props }) => {
    return (
        <StyledBadge severity={severity} size={size} {...props}>
            {icon}
            {typeof label === 'string' ? <Typography>{label}</Typography> : label}
        </StyledBadge>
    );
};
