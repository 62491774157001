import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';

interface OpsgenieEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const OpsgenieEventListenerSetupDialog: React.FC<OpsgenieEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Opsgenie';
    const normalizedUrl = setupBaseUrl ? (setupBaseUrl.endsWith('/') ? setupBaseUrl.slice(0, -1) : setupBaseUrl) : '';

    const listContent = [
        setupBaseUrl ? (
            <Typography>
                Visit your instance's{' '}
                <Link target="_blank" href={normalizedUrl + '/settings/integration/add/Webhook/'}>
                    Create a Webhook
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_INSTANCE]/settings/integration/add/Webhook/ URL in your browser.</Typography>
        ),
        'Give your webhook a meaningful name.',
        <Typography>
            Make sure that <strong>Enabled</strong> setting is checked.
        </Typography>,
        <Typography>
            In <strong>For Opsgenie alerts</strong> section select ONLY the following event to listen:{' '}
            <strong>{eventTypeName}</strong>.
        </Typography>,
        <Typography>
            Delete the rest predefined events using <strong>X</strong> icon on the right.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong> Webhook URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'opsgenie-webhook-url'
        ),
        <Typography>
            Then click <strong>Save Integration</strong>.
        </Typography>,
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={connectionTypeName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={connectionTypeName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="opsgenie" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
