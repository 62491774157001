import { useObservableState } from 'observable-hooks';
import {
    organizationBillingDetails$,
    selectedOrganizationPlan$,
    selectedOrganizationUid$,
} from '../../../store/organization';
import {
    changeOrganizationPlanActionV2$,
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$,
    closeChangeOrganizationPlanConfirmationDialogAction$,
    openChangeOrganizationPlanConfirmPurchaseDialogAction$,
} from '../../../store/organization/changeOrganizationPlan';
import { changeOrganizationPlanConfirmationDialogOpen$ } from '../../../store/organization/changeOrganizationPlan';
import { ChangeOrganizationPlanConfirmationDialog } from '../../../components/organizations/plan/change-organization-plan-wizard/ChangeOrganizationPlanConfirmationDialog';
import { teamPlans$ } from '../../../store/organizations';

export const ChangeOrganizationPlanConfirmationContainer: React.FC = () => {
    const open = useObservableState(changeOrganizationPlanConfirmationDialogOpen$);
    const nextPlan = useObservableState(changeOrganizationPlanConfirmPurchaseDialogNextPlan$);
    const currentPlan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const billingDetails = useObservableState(organizationBillingDetails$);
    const teamPlans = useObservableState(teamPlans$);

    const nextTeamPlan = teamPlans.find((plan) => plan.period === nextPlan?.period && plan.tier === nextPlan.tier);
    if (!currentPlan || !nextTeamPlan || !nextPlan) {
        return null;
    }

    const handleProceed = (): void => {
        closeChangeOrganizationPlanConfirmationDialogAction$.next();
        if (currentPlan.tier === 'free' || nextPlan.tier === 'free' || currentPlan.subscriptionStatus === 'SUSPENDED') {
            changeOrganizationPlanActionV2$.next({
                plan: nextPlan,
                paymentMethod: nextPlan.paymentMethod,
                organizationUid: orgUid || '',
                existingBillingEntityUid: billingDetails?.selectedBillingEntityUid,
                originalReferrer: window.location.origin,
            });
        } else {
            openChangeOrganizationPlanConfirmPurchaseDialogAction$.next(nextPlan);
        }
    };

    return (
        <ChangeOrganizationPlanConfirmationDialog
            open={open}
            currentPlan={currentPlan}
            nextPlan={{
                tier: nextTeamPlan.tier,
                period: nextTeamPlan.period === 'monthly' ? 'MONTHLY' : 'ANNUALLY',
                connectorsLimit: nextTeamPlan.connectorsLimit,
                invocationsLimit: nextTeamPlan.invocationsLimit,
                cost: nextTeamPlan.yearlyCost ?? nextTeamPlan.monthlyCost,
            }}
            onCancel={() => closeChangeOrganizationPlanConfirmationDialogAction$.next()}
            onProceed={handleProceed}
        />
    );
};
