/* eslint-disable sonarjs/no-identical-functions */
import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import { ButtonCard } from '../../common/buttons/ButtonCard';
import { EmptyState } from '../../common/EmptyState';
import { InformationChip } from '../../common/chips/InformationChip';
import { ProductIcon } from '../../icons/ProductIcon';
import { SearchField } from '../../common/inputs/custom/SearchField';
import { Template, TemplateSelectorCard } from './TemplateSelectorCard';
import {
    StyledPageGridContainer,
    StyledPageHeader,
    StyledPageHeaderRow,
    StyledPageInnerContainer,
    StyledPageOuterContainer,
    StyledPageTitleContainer,
} from '../../layout/PageComponents';

export interface TemplateWithCategories extends Template {
    complexity?: string;
    useCases?: string[];
}

interface TemplateSelectorProps {
    apps?: string[];
    basePath?: string;
    complexities?: string[];
    templates?: TemplateWithCategories[];
    useCases?: string[];
    userCanWorkWithTemplates?: boolean;
    onSelect(templateUid: string): void;
}

const StyledPageContainer = styled(Box)(() => ({
    display: 'flex',
    height: '100vh',
    overflowY: 'hidden',
    width: '100%',
}));

const StyledFilterPanel = styled(Box)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flex: '0 1 280px',
    flexDirection: 'column',
    maxWidth: 280,
    minWidth: 180,
    overflowY: 'hidden',
    width: '100%',
}));

const StyledFilterList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
    apps = [],
    basePath,
    complexities = [],
    templates = [],
    useCases = [],
    userCanWorkWithTemplates = false,
    onSelect,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedComplexities, setSelectedComplexities] = useState<string[]>([]);
    const [selectedUseCases, setSelectedUseCases] = useState<string[]>([]);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const [filteredTemplates, setFilteredTemplates] = useState<TemplateWithCategories[]>([]);

    useEffect(() => {
        setFilteredTemplates(getFilteredTemplates());
    }, [searchTerm, selectedComplexities, selectedUseCases, selectedApps]);

    const getCategoryIcon = (categoryName: string): JSX.Element => {
        switch (categoryName) {
            case 'Automation':
                return <RepeatOutlinedIcon />;
            case 'Migration':
                return <InputOutlinedIcon />;
            case 'Sync':
                return <SyncAltOutlinedIcon />;
            case 'Example':
                return <SchoolOutlinedIcon />;
            case 'Basic':
                return <StarBorderOutlinedIcon />;
            case 'Intermediate':
                return <StarHalfOutlinedIcon />;
            case 'Advanced':
                return <StarOutlinedIcon />;
            default:
                return <HelpOutlinedIcon />;
        }
    };

    const handleSelectAllTemplates = (): void => {
        setSelectedApps([]);
        setSelectedComplexities([]);
        setSelectedUseCases([]);
        setSearchTerm('');
    };

    const handleSetSelectedUseCases = (useCase: string): void => {
        if (selectedUseCases.includes(useCase)) {
            setSelectedUseCases(selectedUseCases.filter((suc) => suc !== useCase));
        } else {
            setSelectedUseCases([...selectedUseCases, useCase]);
        }
    };

    const handleSetSelectedComplexities = (complexity: string): void => {
        if (selectedComplexities.includes(complexity)) {
            setSelectedComplexities(selectedComplexities.filter((sc) => sc !== complexity));
        } else {
            setSelectedComplexities([...selectedComplexities, complexity]);
        }
    };

    type TemplateFilter = 'app' | 'complexity' | 'useCase';

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getFilteredTemplates = (exclude?: TemplateFilter): TemplateWithCategories[] => {
        return templates
            .filter((t) =>
                exclude === 'app'
                    ? true
                    : selectedApps.length > 0
                    ? t.apps?.some((app) => selectedApps.includes(app))
                    : true
            )
            .filter((t) =>
                exclude === 'complexity'
                    ? true
                    : selectedComplexities.length > 0
                    ? selectedComplexities.includes(t.complexity ?? '')
                    : true
            )
            .filter((t) =>
                exclude === 'useCase'
                    ? true
                    : selectedUseCases.length > 0
                    ? t.useCases?.some((uc) => selectedUseCases.includes(uc))
                    : true
            )
            .filter((t) => (searchTerm ? t.name.toLowerCase().includes(searchTerm.toLowerCase()) : true));
    };

    const handleSetSelectedApps = (app: string): void => {
        if (selectedApps.includes(app)) {
            setSelectedApps(selectedApps.filter((a) => a !== app));
        } else {
            setSelectedApps([...selectedApps, app]);
        }
    };

    const getTemplateCountByUseCase = (useCase: string): number => {
        return getFilteredTemplates('useCase').filter((t) => t.useCases?.some((uc) => useCase === uc)).length;
    };

    const getTemplateCountByComplexity = (complexity: string): number => {
        return getFilteredTemplates('complexity').filter((t) => t.complexity === complexity).length;
    };

    const getTemplateCountByApp = (app: string): number => {
        return getFilteredTemplates('app').filter((t) => t.apps?.some((a) => app === a)).length;
    };

    const allTemplatesSelected =
        selectedUseCases.length === 0 &&
        selectedComplexities.length === 0 &&
        selectedApps.length === 0 &&
        filteredTemplates.length === templates.length;

    const UseCaseOptions = useCases.map((useCase) => (
        <ButtonCard
            key={useCase}
            endIcon={<Typography>{getTemplateCountByUseCase(useCase)}</Typography>}
            label={useCase}
            selected={selectedUseCases.includes(useCase)}
            startIcon={getCategoryIcon(useCase)}
            onClick={() => handleSetSelectedUseCases(useCase)}
        />
    ));

    const complexityOptions = complexities.map((c) => (
        <ButtonCard
            key={c}
            endIcon={<Typography>{getTemplateCountByComplexity(c)}</Typography>}
            label={c}
            selected={selectedComplexities.includes(c)}
            startIcon={getCategoryIcon(c)}
            onClick={() => handleSetSelectedComplexities(c)}
        />
    ));

    const appFilters = apps.map((app) => (
        <ButtonCard
            key={app}
            endIcon={<Typography>{getTemplateCountByApp(app)}</Typography>}
            label={app}
            selected={selectedApps.includes(app)}
            startIcon={<ProductIcon name={app} />}
            onClick={() => handleSetSelectedApps(app)}
        />
    ));

    const getDisplayedTemplates = (): JSX.Element => {
        if (filteredTemplates.length === 0) {
            return (
                <EmptyState
                    borderBox
                    icon={<LocalLibraryOutlinedIcon />}
                    iconState="incomplete"
                    subtitle={searchTerm ? 'Try a different search term' : 'Try different filters'}
                    title="No templates found"
                />
            );
        }
        return (
            <StyledPageGridContainer>
                {filteredTemplates.map((t) => (
                    <TemplateSelectorCard
                        key={t.uid}
                        apps={t.apps}
                        basePath={basePath}
                        devMode={userCanWorkWithTemplates}
                        draft={t.draft}
                        isNew={t.isNew}
                        name={t.name}
                        uid={t.uid}
                        onSelect={onSelect}
                    />
                ))}
                ;
            </StyledPageGridContainer>
        );
    };

    return (
        <StyledPageContainer>
            <StyledFilterPanel>
                <StyledFilterList>
                    <ButtonCard
                        endIcon={<Typography>{templates.length}</Typography>}
                        label="All templates"
                        selected={allTemplatesSelected}
                        onClick={handleSelectAllTemplates}
                    />
                    <Typography variant="subtitle2">Use cases</Typography>
                    {UseCaseOptions}
                    <Typography variant="subtitle2">Complexity</Typography>
                    {complexityOptions}
                    <Typography variant="subtitle2">App</Typography>
                    {appFilters}
                </StyledFilterList>
            </StyledFilterPanel>
            <StyledPageOuterContainer flex={1}>
                <StyledPageInnerContainer>
                    <StyledPageHeader>
                        <StyledPageHeaderRow>
                            <StyledPageTitleContainer>
                                <Typography variant="h5">Templates</Typography>
                                <InformationChip
                                    label={`${filteredTemplates.length} Result${
                                        filteredTemplates.length === 1 ? '' : 's'
                                    }`}
                                    severity="grey"
                                />
                            </StyledPageTitleContainer>
                        </StyledPageHeaderRow>
                        <StyledPageHeaderRow>
                            <SearchField
                                fullWidth
                                searchTerm={searchTerm}
                                size="small"
                                onChangeSearchTerm={(e) => setSearchTerm(e.target.value)}
                            />
                        </StyledPageHeaderRow>
                    </StyledPageHeader>
                    {getDisplayedTemplates()}
                </StyledPageInnerContainer>
            </StyledPageOuterContainer>
        </StyledPageContainer>
    );
};
