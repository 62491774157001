import { ChangeEvent } from 'react';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { CustomTextFieldProps, TextField } from '../TextField';

interface SearchProps {
    placeholder?: string;
    searchTerm: string;
    onChangeSearchTerm: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

type SearchFieldProps = Omit<CustomTextFieldProps, 'label' | 'placeholder' | 'startIcon' | 'value' | 'onChange'> &
    SearchProps;

export const SearchField: React.FC<SearchFieldProps> = ({
    placeholder = 'Search',
    searchTerm,
    onChangeSearchTerm,
    ...props
}) => {
    return (
        <TextField
            label={null}
            placeholder={placeholder}
            startIcon={<SearchOutlined />}
            value={searchTerm}
            onChange={onChangeSearchTerm}
            {...props}
        />
    );
};
