import { styled, useTheme } from '@mui/material/styles';
import ReactHtmlParser from 'react-html-parser';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button } from '../../common/buttons/Button';
import { IconButton } from '../../common/buttons/IconButton';
import {
    StyledEditorMainContainer,
    StyledEditorToolbar,
    StyledEditorToolbarActions,
    StyledEditorViewContainer,
    StyledMainActions,
} from '../../layout/LayoutComponents';
import { ToggleButtonGroup } from '../../common/buttons/button-groups/ToggleButtonGroup';
import { Editor } from '@monaco-editor/react';
import { getSanitizedParsedMarkdown } from '../../../utils/readme';
import { useState } from 'react';

export interface ReadmeFileDetailsProps {
    content: string;
    environmentVariablesMode?: boolean; // Think of a better way to display different versions when building the blank workspace setup flow
    fullScreenReadmeLink: string;
    hasUnsavedChanges?: boolean;
    templateMode?: boolean;
    useRouter?: boolean;
    templatePreviewMode?: boolean;
    saving?: boolean;
    workspaceLocked?: boolean;
    onChangeContent?: (content: string) => void;
    onClose?: () => void;
    onDiscard?: () => void;
    onSave?: () => void;
}

const StyledReadmeContent = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2),
    overflow: 'auto',
}));

const StyledReadmePreviewContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledReadmeEditModeContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(2, 2, 2, 0),
}));

export const ReadmeFileDetails: React.FC<ReadmeFileDetailsProps> = ({
    content,
    environmentVariablesMode = false,
    fullScreenReadmeLink,
    hasUnsavedChanges = false,
    templateMode = false,
    useRouter = true,
    templatePreviewMode = false,
    saving = false,
    workspaceLocked = false,
    onChangeContent,
    onClose,
    onDiscard,
    onSave,
}) => {
    const theme = useTheme();
    const editorTheme = theme.palette.mode === 'dark' ? 'vs-dark' : 'vs';

    const [readmeMode, setReadmeMode] = useState<'preview' | 'edit'>('preview');

    const toggleButtons = [
        { icon: <PreviewOutlinedIcon />, label: 'Preview', value: 'preview' },
        { icon: <EditOutlinedIcon />, label: 'Edit', value: 'edit' },
    ];

    const canSave = hasUnsavedChanges && !!content && !workspaceLocked;

    const handleChangeContent = (value?: string): void => {
        onChangeContent?.(value ?? '');
    };

    return (
        <StyledEditorViewContainer sx={templateMode ? { maxWidth: 600 } : null}>
            <StyledEditorToolbar>
                <Typography variant="h5">README</Typography>
                {!templatePreviewMode && (
                    <StyledEditorToolbarActions>
                        {!templateMode && !environmentVariablesMode && (
                            <>
                                <ToggleButtonGroup
                                    buttons={toggleButtons}
                                    disabled={workspaceLocked}
                                    exclusive
                                    size="small"
                                    value={readmeMode}
                                    onChange={(_, value) => {
                                        if (value) {
                                            setReadmeMode(value);
                                        }
                                    }}
                                />
                                <IconButton
                                    aria-label="Save changes"
                                    border
                                    busy={saving}
                                    disabled={!canSave}
                                    icon={<SaveOutlinedIcon />}
                                    tooltip="Save changes"
                                    onClick={onSave}
                                />
                            </>
                        )}
                        <IconButton
                            aria-label="Open in new tab"
                            border
                            icon={<OpenInNewOutlinedIcon />}
                            link={{ href: fullScreenReadmeLink, target: '_blank', type: 'router' }}
                            tooltip="Open in new tab"
                            useRouter={useRouter}
                        />
                        {!!onClose && (
                            <IconButton
                                aria-label="Close README"
                                border
                                icon={<CloseOutlinedIcon />}
                                tooltip="Close README"
                                onClick={onClose}
                            />
                        )}
                    </StyledEditorToolbarActions>
                )}
            </StyledEditorToolbar>
            <Divider />
            <StyledEditorMainContainer>
                {readmeMode === 'edit' ? (
                    <StyledReadmeEditModeContent>
                        <Editor
                            value={content}
                            language="markdown"
                            options={{
                                minimap: { enabled: false },
                                readOnly: templateMode || workspaceLocked || templatePreviewMode,
                            }}
                            theme={editorTheme}
                            onChange={handleChangeContent}
                        />
                    </StyledReadmeEditModeContent>
                ) : (
                    <StyledReadmePreviewContent>
                        {ReactHtmlParser(getSanitizedParsedMarkdown(content))}
                    </StyledReadmePreviewContent>
                )}
            </StyledEditorMainContainer>
            {readmeMode === 'edit' && (
                <>
                    <Divider />
                    <StyledMainActions>
                        {hasUnsavedChanges && (
                            <Typography color="text.secondary" variant="subtitle1">
                                Unsaved changes
                            </Typography>
                        )}
                        <Button disabled={!hasUnsavedChanges} onClick={onDiscard} variant="outlined">
                            Discard
                        </Button>
                        <Button busy={saving} disabled={!canSave} onClick={onSave}>
                            Save changes
                        </Button>
                    </StyledMainActions>
                </>
            )}
        </StyledEditorViewContainer>
    );
};
