import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { ProductIcon } from '../../icons/ProductIcon';
import { StyledResourceListItemButton, StyledResourceInfo } from './ResourceTreeComponents';
import { APP } from '@avst-stitch/repository-lib/constants';
import { MouseEvent } from 'react';

interface ResourceTreeEventListenerProps {
    appName?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    disabled?: boolean;
    eventType?: string;
    executing?: boolean;
    externalTriggerBaseUrl?: string;
    inactive?: boolean;
    needsSetup?: boolean;
    readOnlyMode?: boolean;
    scriptName?: string;
    selectedNode?: string;
    uid: string;
    urlId?: string;
    onDelete(uid: string): void;
    onTrigger?(uid: string): void;
    onUrlCopy?(uid: string): void;
}

export const ResourceTreeEventListener: React.FC<ResourceTreeEventListenerProps> = ({
    appName,
    deleting = false,
    deployedEnvironmentMode = false,
    eventType,
    executing = false,
    externalTriggerBaseUrl,
    inactive = false,
    needsSetup = false,
    readOnlyMode = false,
    scriptName,
    selectedNode,
    uid,
    urlId,
    disabled = false,
    onDelete,
    onTrigger,
    onUrlCopy,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selected = selectedNode === uid;
    const isGeneric = appName === APP.GENERIC.NAME;
    const showUrlId = !needsSetup && !readOnlyMode && !!urlId && isGeneric;
    const showScriptName = !needsSetup && !!scriptName;
    const showSetupWarning = !readOnlyMode && needsSetup;
    const showInactiveWarning = inactive && !needsSetup && !readOnlyMode;
    const showDisabled = disabled && !inactive && !needsSetup && !readOnlyMode;
    const canCopyPath = isGeneric && !readOnlyMode && !!urlId;
    const canTrigger = !deleting && !needsSetup && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    const handleUrlCopy = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onUrlCopy?.(uid);
    };

    const handleTrigger = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onTrigger?.(uid);
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onDelete(uid);
    };

    return (
        <StyledResourceListItemButton
            key={uid}
            endIcons={[
                ...(canCopyPath
                    ? [
                          <IconButton
                              aria-label="Copy URL"
                              icon={<ContentCopyIcon />}
                              size="small"
                              tooltip="Copy URL"
                              onClick={handleUrlCopy}
                          />,
                      ]
                    : []),
                ...(canTrigger
                    ? [
                          <IconButton
                              aria-label="Emulate event with test payload"
                              busy={executing}
                              icon={<PlayCircleOutlineRoundedIcon />}
                              size="small"
                              tooltip="Emulate event with test payload"
                              onClick={handleTrigger}
                          />,
                      ]
                    : []),
                ...(canDelete
                    ? [
                          <IconButton
                              aria-label="Delete event listener"
                              busy={deleting}
                              icon={<DeleteOutlineIcon />}
                              size="small"
                              tooltip="Delete event listener"
                              onClick={handleDelete}
                          />,
                      ]
                    : []),
            ]}
            label={eventType ?? 'New event listener'}
            link={{ href: `./event/${uid}`, type: 'router' }}
            selected={selected}
            startIcon={
                <Tooltip title={appName}>
                    <ProductIcon name={appName} />
                </Tooltip>
            }
        >
            {showUrlId && (
                <Tooltip title={`${externalTriggerBaseUrl}/${urlId}`} placement="top-start">
                    <StyledResourceInfo>
                        <LinkIcon />
                        <Typography>
                            URL ID:{' '}
                            <Typography color="primary.main" display="inline">
                                {urlId}
                            </Typography>
                        </Typography>
                    </StyledResourceInfo>
                </Tooltip>
            )}
            {showScriptName && (
                <StyledResourceInfo>
                    <CodeIcon />
                    <Typography>
                        Script:{' '}
                        <Typography color="primary.main" display="inline">
                            {scriptName}
                        </Typography>
                    </Typography>
                </StyledResourceInfo>
            )}
            {showSetupWarning && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Complete event listener setup</em>
                    </Typography>
                </StyledResourceInfo>
            )}
            {showInactiveWarning && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Inactive</em>
                    </Typography>
                </StyledResourceInfo>
            )}
            {showDisabled && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Disabled</em>
                    </Typography>
                </StyledResourceInfo>
            )}
        </StyledResourceListItemButton>
    );
};
