import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import { RouterLink } from '../../common/RouterLink';
import {
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
    StyledMenuItemText,
    StyledOrganizationAvatar,
} from './SidenavComponents';
import { ListItemButton } from '../../common/buttons/ListItemButton';

export type SidenavOrganizationSettingsSubRoute = 'details' | 'usage' | 'members' | 'plan' | 'billing';

interface SidenavOrganizationSettingsNavigatorProps {
    collapsed: boolean;
    isSelected: boolean;
    organizationName: string;
    organizationUid: string;
    ownedDefaultOrganization?: boolean;
    useRouter?: boolean;
    basePath: string;
}

export const SidenavOrganizationSettingsNavigator: React.FC<SidenavOrganizationSettingsNavigatorProps> = ({
    basePath,
    collapsed,
    isSelected,
    organizationName,
    organizationUid,
    ownedDefaultOrganization = false,
    useRouter = false,
}) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const menuItems = [
        {
            icon: (
                <StyledOrganizationAvatar ownedDefaultOrganization={ownedDefaultOrganization}>
                    {organizationName.slice(0, 2).toLocaleUpperCase()}
                </StyledOrganizationAvatar>
            ),
            label: 'General',
            value: 'details' as const,
            dataPendo: 'generalSettingsSelected',
        },
        { icon: <DataUsageOutlinedIcon />, label: 'Usage', value: 'usage' as const, dataPendo: 'usageSelected' },
        {
            icon: <PeopleOutlineOutlinedIcon />,
            label: 'Members',
            value: 'members' as const,
            dataPendo: 'membersSelected',
        },
        { icon: <StyleOutlinedIcon />, label: 'Plans', value: 'plan' as const, dataPendo: 'plansSelected' },
        { icon: <CreditCardOutlinedIcon />, label: 'Billing', value: 'billing' as const, dataPendo: 'billingSelected' },
    ].map((m) => (
        <StyledMenuItem
            key={m.value}
            component={RouterLink}
            data-pendo={m.dataPendo}
            to={useRouter ? basePath + `/team/${organizationUid}/${m.value}` : null}
            onClick={() => setAnchor(null)}
        >
            <StyledMenuItemContent>
                {m.icon}
                <StyledMenuItemText>{m.label}</StyledMenuItemText>
            </StyledMenuItemContent>
        </StyledMenuItem>
    ));

    return (
        <>
            <ListItemButton
                collapsed={collapsed}
                data-pendo="teamSettings"
                label="Team settings"
                selected={!!anchor || isSelected}
                dataPendo={'teamSettingsSelected'}
                startIcon={
                    <Tooltip title="Team settings">
                        <SettingsOutlinedIcon />
                    </Tooltip>
                }
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
            />
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
