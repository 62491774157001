import { useObservableState, useSubscription } from 'observable-hooks';
import { AuditLogsPage } from '../../components/reporting/audit-logs-page';
import {
    auditLogs$,
    auditLogsPageErrors$,
    auditLogsPageLoading$,
    getMyAuditLogsAction$,
    getMyAuditLogsUrlAction$,
    auditLogsPageGeneratingCsv$,
    reloadMyAuditLogsOnOrganizationSwitchAction$,
    auditLogsPageOnOrganizationSwitchLoading$,
} from '../../store/auditLogs';
import { getBasePath } from '../../utils/path';
import { organizationSwitchedAction$, selectedOrganizationUid$ } from '../../store/organization';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';

export const AuditLogsPageContainer: React.FC = () => {
    const auditLogs = useObservableState(auditLogs$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const auditLogsPageError = useObservableState(auditLogsPageErrors$);
    const loading = useObservableState(auditLogsPageLoading$);
    const generating = useObservableState(auditLogsPageGeneratingCsv$);
    const auditLogsPageOnOrganizationSwitchLoading = useObservableState(auditLogsPageOnOrganizationSwitchLoading$);
    const basePath = getBasePath();

    const handleExportCsv = (): void => {
        if (selectedOrganizationUid) {
            getMyAuditLogsUrlAction$.next({
                organizationUid: selectedOrganizationUid,
            });
        }
    };

    useSubscription(organizationSwitchedAction$, (uid) =>
        reloadMyAuditLogsOnOrganizationSwitchAction$.next({ organizationUid: uid })
    );

    return (
        <>
            {auditLogsPageOnOrganizationSwitchLoading ? (
                <LoadingSpinner />
            ) : (
                <AuditLogsPage
                    errors={auditLogsPageError}
                    isLoading={loading}
                    auditLogs={auditLogs.auditLogs}
                    cursor={auditLogs.cursor}
                    selectedTeamUid={selectedOrganizationUid}
                    basePath={basePath}
                    isGenerating={generating}
                    onQueryAuditLogs={(request) => getMyAuditLogsAction$.next(request)}
                    onExportCsv={handleExportCsv}
                />
            )}
        </>
    );
};
