import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { ProductIcon } from '../../icons/ProductIcon';
import { Indicator } from '../../common/Indicator';
import { StatusChip } from '../../common/chips/StatusChip';
import {
    StyledIndicatorContainer,
    StyledListItem,
    StyledListItemActions,
    StyledListItemLeftContainer,
    StyledListItemRightContainer,
    StyledListItemWarningContainer,
} from '../SetupGuideComponents';
import { SetupGuideConnection } from '../types';

interface SetupGuideConnectionListItemProps extends SetupGuideConnection {
    appName: string;
    deleting?: boolean;
    workspaceLocked: boolean;
    onEdit: (uid: string) => void;
    onSwap: () => void;
}

export const SetupGuideConnectionListItem: React.FC<SetupGuideConnectionListItemProps> = ({
    appName,
    authorized = false,
    deleting = false,
    name,
    uid,
    workspaceLocked,
    onEdit,
    onSwap,
}) => {
    return (
        <StyledListItem needsSetup={!authorized}>
            <StyledListItemLeftContainer>
                <StyledIndicatorContainer>
                    <Indicator
                        left={{ icon: <PowerOutlinedIcon /> }}
                        right={{ icon: <ProductIcon name={appName} />, state: authorized ? 'authorized' : 'active' }}
                    />
                </StyledIndicatorContainer>
                <Box>
                    {appName && <Typography>{appName}</Typography>}
                    <Typography variant="subtitle1">{name}</Typography>
                    {!authorized && (
                        <StyledListItemWarningContainer>
                            <WarningAmberOutlinedIcon />
                            <Typography>Selected Connector is not authorized</Typography>
                        </StyledListItemWarningContainer>
                    )}
                </Box>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    <IconButton
                        aria-label={'Swap connector'}
                        disabled={deleting || workspaceLocked}
                        icon={<SwapHorizOutlinedIcon />}
                        size="small"
                        tooltip={'Swap connector'}
                        onClick={() => onSwap()}
                    />
                    <IconButton
                        aria-label={'Edit connector'}
                        disabled={deleting || workspaceLocked}
                        icon={<EditOutlinedIcon />}
                        size="small"
                        tooltip={'Edit connector'}
                        onClick={() => onEdit(uid)}
                    />
                </StyledListItemActions>
                {authorized ? (
                    <StatusChip icon={<CheckCircleOutlineOutlinedIcon />} label="Authorized" severity="success" />
                ) : (
                    <StatusChip icon={<UnpublishedOutlinedIcon />} label="Incomplete" />
                )}
            </StyledListItemRightContainer>
        </StyledListItem>
    );
};
