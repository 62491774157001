import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeaderSmall,
} from '../../../layout/BorderBoxComponents';
import { handleKeyDown } from '../../../../utils/input';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { APP } from '@avst-stitch/repository-lib/constants';

interface ZenDeskEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    zendeskUrl?: string;
    webhookUrlId: string;
    onClose: () => void;
}

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '100%',
    '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export const ZenDeskEventListenerSetupDialog: React.FC<ZenDeskEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    webhookBaseUrl,
    zendeskUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.ZENDESK.NAME;

    const [webhookType, setWebhookType] = useState<'events' | 'automation'>('events');

    const examplePayload = `{"ticketId": "{{ticket.id}}", "ticketTitle": "{{ticket.title}}"}`;

    const webhookListItems = [
        zendeskUrl ? (
            <Typography>
                Visit the{' '}
                <Link target="_blank" href={`${zendeskUrl}/admin/home`}>
                    Admin Center
                </Link>{' '}
                of your {appName} account.
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_ZENDESK.COM_ACCOUNT]/admin/home URL in your browser.</Typography>
        ),
        <Typography>
            Click <strong>Apps and integrations</strong> in the sidebar, then scroll down to select{' '}
            <strong>Webhooks</strong>.
        </Typography>,
        <Typography>
            Click the <strong>Create webhook</strong> button.
        </Typography>,
        <Typography>
            Select the <strong>Zendesk events</strong> option.
        </Typography>,
        <Typography>
            <strong>Subscribe to</strong> one or more <strong>Zendesk webhook events</strong> from the dropdown options.
        </Typography>,
        <Typography>
            Click the <strong>Next</strong> button on the <strong>bottom right</strong> of the page.
        </Typography>,
        <Typography>
            Enter a meaningful <strong>Name</strong> for the webhook.
        </Typography>,
        <Typography>
            Copy the <strong>Webhook URL</strong> below and in Zendesk, paste it into the <strong>Endpoint URL</strong>{' '}
            field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'zendesk-events-webhook-url'
        ),

        <Typography>
            Click on <strong>Create webhook</strong>.
        </Typography>,
    ];

    const automationListItems = [
        zendeskUrl ? (
            <Typography>
                Visit the{' '}
                <Link target="_blank" href={`${zendeskUrl}/admin/home`}>
                    Admin Center
                </Link>{' '}
                of your {appName} account.
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_ZENDESK.COM_ACCOUNT]/admin/home URL in your browser.</Typography>
        ),

        <Typography>
            Click <strong>Apps and integrations</strong> in the sidebar, then scroll down to select{' '}
            <strong>Webhooks</strong>.
        </Typography>,
        <Typography>
            Click the <strong>Create webhook</strong> button.
        </Typography>,
        <Typography>
            Select the <strong>Trigger or automation</strong> option.
        </Typography>,
        <Typography>
            Click the <strong>Next</strong> button on the <strong>bottom right</strong> of the page.
        </Typography>,
        <Typography>
            Enter a meaningful <strong>Name</strong> for the webhook.
        </Typography>,
        <Typography>
            Copy the <strong>Webhook URL</strong> below and in Zendesk, paste it into the <strong>Endpoint URL</strong>{' '}
            field.{' '}
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'zendesk-trigger-webhook-url'
        ),

        <Typography>
            Click on <strong>Create webhook</strong>.{' '}
        </Typography>,
        zendeskUrl ? (
            <Typography>
                Navigate to the{' '}
                <Link target="_blank" href={`${zendeskUrl}/admin/objects-rules/rules/triggers`}>
                    Triggers
                </Link>
                page.
            </Typography>
        ) : (
            <Typography>
                Navigate to{' '}
                <strong>
                    Objects and Rules {`>`} Business Rules {`>`} Triggers
                </strong>
            </Typography>
        ),
        <Typography>
            Click the <strong>Create Trigger</strong> button on the top right of the page or select an existing trigger.
        </Typography>,
        <Typography>
            Enter a meaningful <strong>Name</strong> and choose an appropriate <strong>Category</strong> for the
            trigger.
        </Typography>,
        <Typography>
            Under <strong>Conditions</strong> add at least one condition and complete it by selecting first a{' '}
            <strong>Category</strong>, then an <strong>Operator</strong> and finally a <strong>Value</strong> for your
            trigger. This will determine when the trigger will be run.
        </Typography>,
        <Typography>
            Under <strong>Actions</strong> click the <strong>Add action</strong> button.
        </Typography>,
        <Typography>
            From the dropdown select the <strong>Notify by</strong> option, then select <strong>Active webhook</strong>.
        </Typography>,
        <Typography>
            From the new dropdown that appears, select the webhook you created in the previous steps.
        </Typography>,
        <Typography>
            Construct your event payload by editing the <strong>JSON body</strong>. In order to reference data from the
            event, click on <strong>View available placeholders</strong> and copy relevant properties over to your{' '}
            <strong>JSON body</strong>. Please review the example payload below to see how it needs to be structured.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Example payload" value={examplePayload} />,
            'zendesk-example-payload'
        ),
        <Typography>
            Click on <strong>Create</strong>.
        </Typography>,
    ];

    return (
        <Dialog
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            icon={<IconCircle icon={<ProductIcon name="Zendesk" />} size="medium" />}
            open={open}
            title="Event listener setup"
            loading={loading}
            subtitle={appName}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" mb={1}>
                        How to set up an event listener
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Create a webhook in Zendesk project to listen to events. Select from the following options:
                    </Typography>
                </Box>
                <StyledBorderBox>
                    <StyledBorderBoxHeaderSmall>
                        <StyledLabel
                            control={
                                <Radio checked={webhookType === 'events'} onClick={() => setWebhookType('events')} />
                            }
                            label="Method 1: Zendesk events"
                        />
                    </StyledBorderBoxHeaderSmall>
                    {webhookType === 'events' && (
                        <>
                            <Divider />
                            <StyledBorderBoxContent gap={1.5}>
                                <OrderedList content={webhookListItems} id="zendesk-webhook" />
                            </StyledBorderBoxContent>
                        </>
                    )}
                </StyledBorderBox>
                <StyledBorderBox>
                    <StyledBorderBoxHeaderSmall>
                        <StyledLabel
                            control={
                                <Radio
                                    checked={webhookType === 'automation'}
                                    onClick={() => setWebhookType('automation')}
                                />
                            }
                            label="Method 2: Trigger or automation"
                        />
                    </StyledBorderBoxHeaderSmall>
                    {webhookType === 'automation' && (
                        <>
                            <Divider />
                            <StyledBorderBoxContent gap={1.5}>
                                <OrderedList content={automationListItems} id="zendesk-automation" />
                            </StyledBorderBoxContent>
                        </>
                    )}
                </StyledBorderBox>
            </>
        </Dialog>
    );
};
