import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Button } from '../../common/buttons/Button';
import { handleKeyDown } from '../../../utils/input';
import { Dialog } from '../../common/dialogs/Dialog';
import { IconCircle } from '../../common/IconCircle';
import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { OrderedList } from '../../common/lists/OrderedList';
import { Alert } from '../../common/alerts/Alert';

interface OrganizationInvoicePlanInformationDialogProps {
    open?: boolean;
    onCancel(): void;
}

const StyledInformationContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

export const OrganizationInvoicePlanInformationDialog: React.FC<OrganizationInvoicePlanInformationDialogProps> = ({
    open = false,
    onCancel,
}) => {
    return (
        <Dialog
            buttons={[
                <Button variant="contained" onClick={onCancel}>
                    Close
                </Button>,
            ]}
            icon={<IconCircle icon={<ReceiptLongOutlinedIcon />} size="medium" />}
            open={open}
            title="Invoice payment"
            subtitle="Conveniently pay by invoice"
            onClose={onCancel}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterFn: onCancel,
                    escFn: onCancel,
                })
            }
        >
            <StyledInformationContainer>
                <Box display="flex" padding={2}>
                    <Typography variant="subtitle1">How do invoice payments work?</Typography>
                </Box>
                <Divider />
                <OrderedList
                    id="invoice-payment-information"
                    sx={{
                        padding: 2,
                    }}
                    content={[
                        <Box>
                            <Typography variant="subtitle2">
                                Invoice request sent to the ScriptRunner Connect Team
                            </Typography>
                            <Typography variant="body1">
                                Submitting this form requests an invoice; our team will contact you if more information
                                is needed.
                            </Typography>
                        </Box>,
                        <Box>
                            <Typography variant="subtitle2">Invoice will be sent by email</Typography>
                            <Typography variant="body1">
                                An invoice detailing charges will be emailed to you post-purchase.
                            </Typography>
                        </Box>,
                        <Box>
                            <Typography variant="subtitle2">Review and verify</Typography>
                            <Typography variant="body1">
                                Review your invoice to ensure all details are accurate and match your purchase.
                            </Typography>
                        </Box>,
                        <Box>
                            <Typography variant="subtitle2">Submit payment</Typography>
                            <Typography variant="body1">
                                You will receive an email with an invoice detailing the charges after your purchase.
                            </Typography>
                        </Box>,
                    ]}
                />
                <Divider />
                <Alert
                    severity="warning"
                    title="Disclaimer: Payment by due date"
                    text="Please complete your payment on time to avoid any interruptions to your service."
                    icon={false}
                />
            </StyledInformationContainer>
        </Dialog>
    );
};
