import { useEffect, useRef, useState } from 'react';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { Dialog } from '../../../common/dialogs/Dialog';
import { TextField } from '../../../common/inputs/TextField';
import { autoFocus, handleKeyDown } from '../../../../utils/input';

interface NewEventListenerPayloadDialogProps {
    errors?: string;
    name?: string;
    open?: boolean;
    saving?: boolean; // Show spinner for save button and disable it
    onCancel(): void;
    onSave(name: string): void;
}

export const NewEventListenerPayloadDialog: React.FC<NewEventListenerPayloadDialogProps> = ({
    errors,
    name = '',
    open = false,
    saving = false,
    onCancel,
    onSave,
}) => {
    const [currentName, setCurrentName] = useState(name);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentName(name);
        autoFocus(inputRef);
    }, [open]);

    const canCreate = !saving && !!currentName;

    const buttons = [
        <Button key="cancel-button" onClick={() => onCancel()} variant="outlined">
            Cancel
        </Button>,
        <Button key="create-button" busy={saving} disabled={!canCreate} onClick={() => onSave(currentName)}>
            Create
        </Button>,
    ];

    return (
        <Dialog
            buttons={buttons}
            open={open}
            title="New event listener payload"
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: canCreate, enterFn: () => onSave(currentName), escFn: onCancel })
            }
        >
            <>
                {errors && <Alert severity="error" title={errors} />}
                <TextField
                    label="Event listener payload name"
                    placeholder="Enter a name"
                    required
                    value={currentName}
                    onChange={(e) => setCurrentName(e.target.value)}
                />
            </>
        </Dialog>
    );
};
