import { styled } from '@mui/material/styles';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { ButtonLinkProps, ForwardedLinkProps } from './Button';
import { LoadingSpinner } from '../LoadingSpinner';
import { RouterLink } from '../RouterLink';

export interface CustomIconButtonProps extends IconButtonProps {
    'aria-label': string;
    border?: boolean;
    busy?: boolean;
    icon: JSX.Element;
    link?: ButtonLinkProps;
    selected?: boolean;
    size?: 'small' | 'medium';
    tooltip: string;
    tooltipPlacement?: TooltipProps['placement'];
    useRouter?: boolean;
}

const StyledIconButton = styled(MuiIconButton, { shouldForwardProp: (prop) => prop !== 'border' })<
    {
        border: boolean;
        selected?: boolean;
        size: 'small' | 'medium';
    } & ForwardedLinkProps
>(({ border, selected = false, size, theme }) => ({
    backgroundColor: selected ? theme.palette.action.selected : 'transparent',
    border: border ? `1px solid ${theme.palette.divider}` : undefined,
    height: size === 'small' ? 30 : 36,
    position: 'relative',
    width: size === 'small' ? 30 : 36,
    '& .MuiSvgIcon-root': {
        height: size === 'small' ? 20 : 24,
        width: size === 'small' ? 20 : 24,
    },
}));

export const IconButton: React.FC<CustomIconButtonProps> = ({
    'aria-label': ariaLabel,
    border = false,
    busy = false,
    icon,
    link,
    selected = false,
    size = 'medium',
    tooltip,
    tooltipPlacement = 'top',
    useRouter = true,
    ...props
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const disabledButton = (
        // No tooltip for disabled or busy state to prevent react from screaming in console
        <StyledIconButton aria-label={ariaLabel} border={border} disabled selected={selected} size={size} {...props}>
            {busy && <LoadingSpinner size="small" />}
            {icon}
        </StyledIconButton>
    );

    const regularButton = (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            <StyledIconButton aria-label={ariaLabel} border={border} selected={selected} size={size} {...props}>
                {icon}
            </StyledIconButton>
        </Tooltip>
    );

    const externalLinkButton = (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            <StyledIconButton
                aria-label={ariaLabel}
                border={border}
                component={Link}
                href={link?.href}
                selected={selected}
                size={size}
                target={link?.target}
                {...props}
            >
                {icon}
            </StyledIconButton>
        </Tooltip>
    );

    const routerLinkButton = (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            <StyledIconButton
                aria-label={ariaLabel}
                border={border}
                component={RouterLink}
                selected={selected}
                size={size}
                target={link?.target}
                to={link?.href}
                {...props}
            >
                {icon}
            </StyledIconButton>
        </Tooltip>
    );
    return busy || props.disabled
        ? disabledButton
        : !link || !useRouter
        ? regularButton
        : link.type === 'external'
        ? externalLinkButton
        : routerLinkButton;
};
