import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from './monitor';
import { readLocalStorage, saveLocalStorage } from '../utils/localStorage';

type ConfirmDialogButtonSeverity = 'primary' | 'info' | 'success' | 'warning' | 'error';
type ConfirmDialogIcon = 'info' | 'success' | 'warning' | 'error' | JSX.Element;

interface ConfirmDialogButton {
    label?: string;
    severity?: ConfirmDialogButtonSeverity;
    onClick?: () => void | Promise<void>;
}

interface PromptMessageOptions {
    button?: ConfirmDialogButton;
    icon?: ConfirmDialogIcon;
    messages?: (JSX.Element | string)[];
    subtitle?: string;
    title: string;
}

interface PromptQuestionOptions {
    cancelButton?: ConfirmDialogButton;
    icon?: ConfirmDialogIcon;
    messages?: (string | JSX.Element)[];
    proceedButton?: ConfirmDialogButton;
    subtitle?: string;
    title: string;
}

interface TutorialDialogOptions {
    id: string;
    size?: 'small' | 'medium';
    title?: string;
    content: JSX.Element;
}

export const CLOSED_DISMISSIBLE_DIALOGS_KEY = 'CLOSED_DISMISSIBLE_DIALOGS';

export const confirmDialogDetails$ = monitor(
    'confirmDialogDetails$',
    new BehaviorSubject<PromptQuestionOptions | undefined>(undefined)
);

export const tutorialDialogDetails$ = monitor(
    'tutorialDialogDetails$',
    new BehaviorSubject<TutorialDialogOptions | undefined>(undefined)
);

export const confirmDialogOpen$ = monitor('confirmDialogOpen$', new BehaviorSubject(false));
export const tutorialDialogOpen$ = monitor('tutorialDialogOpen$', new BehaviorSubject(false));

export const openConfirmDialogAction$ = monitor('openConfirmDialogAction$', new Subject<PromptQuestionOptions>());
export const closeConfirmDialogAction$ = monitor('closeConfirmDialogAction$', new Subject<void>());

export const openTutorialDialogAction$ = monitor('openTutorialDialogAction$', new Subject<TutorialDialogOptions>());
export const closeTutorialDialogAction$ = monitor('closeTutorialDialogAction$', new Subject<string | undefined>());

openTutorialDialogAction$.subscribe((details) => {
    tutorialDialogDetails$.next(details);
    tutorialDialogOpen$.next(true);
});

closeTutorialDialogAction$.subscribe((id) => {
    tutorialDialogOpen$.next(false);
    tutorialDialogDetails$.next(undefined);

    const allDialogIds = readLocalStorage<string[]>(CLOSED_DISMISSIBLE_DIALOGS_KEY, []);

    if (id) {
        saveLocalStorage(CLOSED_DISMISSIBLE_DIALOGS_KEY, [...allDialogIds, id]);
    }
});

openConfirmDialogAction$.subscribe((details) => {
    confirmDialogDetails$.next(details);
    confirmDialogOpen$.next(true);
});

closeConfirmDialogAction$.subscribe(() => {
    confirmDialogOpen$.next(false);
    confirmDialogDetails$.next(undefined);
});

export const promptMessage = (options: PromptMessageOptions): void => {
    const button = {
        label: options.button?.label ?? 'Proceed',
        severity: options.button?.severity ?? 'primary',
        onClick: async () => {
            closeConfirmDialogAction$.next();
            await options.button?.onClick?.();
        },
    };
    openConfirmDialogAction$.next({
        icon: options.icon,
        messages: options.messages,
        proceedButton: button,
        subtitle: options.subtitle,
        title: options.title,
    });
};

export const promptQuestion = (options: PromptQuestionOptions): void => {
    const cancelButton = {
        label: options.cancelButton?.label ?? 'Cancel',
        severity: options.cancelButton?.severity ?? 'primary',
        onClick: async () => {
            closeConfirmDialogAction$.next();
            await options.cancelButton?.onClick?.();
        },
    };
    const proceedButton = {
        label: options.proceedButton?.label ?? 'Proceed',
        severity: options.proceedButton?.severity ?? 'primary',
        onClick: async () => {
            closeConfirmDialogAction$.next();
            await options.proceedButton?.onClick?.();
        },
    };

    openConfirmDialogAction$.next({
        cancelButton,
        icon: options.icon,
        messages: options.messages,
        proceedButton,
        subtitle: options.subtitle,
        title: options.title,
    });
};
