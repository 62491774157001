import copy from 'copy-to-clipboard';
import { RefObject, useState } from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { IconButton } from './buttons/IconButton';

export interface IconCopyButtonProps {
    copiedTooltip?: string;
    label?: string | JSX.Element;
    tooltip?: string;
    value?: string | RefObject<Element>;
    size?: 'small' | 'medium';
}

/**
 * An icon button that copies a given value to the clipboard and provides visual feedback.
 *
 * @component
 * @param {string | React.RefObject<HTMLElement>} value - The text or a ref to an element whose text content will be copied.
 * @param {string} [tooltip='Copy to clipboard'] - The tooltip text shown before copying.
 * @param {string} [copiedTooltip='Copied to clipboard'] - The tooltip text shown after copying.
 * @param {('small' | 'medium' | 'large')} [size='medium'] - The size of the icon button.
 * @returns {JSX.Element | null} The rendered IconCopyButton component or `null` if `value` is undefined.
 */
export const IconCopyButton: React.FC<IconCopyButtonProps> = ({
    copiedTooltip = 'Copied to clipboard',
    tooltip = 'Copy to clipboard',
    value,
    size = 'medium',
}) => {
    const [copied, setCopied] = useState<boolean>(false);

    const doCopy = (): void => {
        const content = typeof value === 'string' ? value : value?.current?.textContent ?? undefined;

        if (content !== undefined) {
            copy(content);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    };

    return value !== undefined ? (
        <IconButton
            size={size}
            aria-label="Copy"
            icon={copied ? <CheckOutlinedIcon color="success" /> : <ContentCopyOutlinedIcon />}
            tooltip={copied ? copiedTooltip : tooltip}
            onClick={doCopy}
        />
    ) : null;
};
