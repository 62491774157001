import React from 'react';
import { Dialog } from '../../../common/dialogs/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { Button } from '../../../common/buttons/Button';
import { OrganizationPlan } from '../../../../data/organization';
import { IconCircle } from '../../../common/IconCircle';
import { handleKeyDown } from '../../../../utils/input';
import { Alert } from '../../../common/alerts/Alert';

export interface CancelOrganizationPlanConfirmationDialogProps {
    open?: boolean;
    saving?: boolean;
    currentPlan: OrganizationPlan;
    errors?: string;
    onCancel?: () => void;
    onProceed?: () => void;
}

const StyledInformationContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledBillingInfoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(() => ({
    padding: 0,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

export const CancelOrganizationPlanConfirmationDialog: React.FC<CancelOrganizationPlanConfirmationDialogProps> = ({
    open = false,
    saving = false,
    currentPlan,
    errors,
    onCancel,
    onProceed,
}) => {
    const isCustomPlan = !['free', 'basic', 'advanced', 'pro'].includes(currentPlan.tier);
    const endDateString = new Date(currentPlan.endDate ?? '').toLocaleDateString(undefined, {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    });

    return (
        <Dialog
            buttons={[
                <Button busy={saving} variant="outlined" onClick={onCancel}>
                    Keep my plan
                </Button>,
                <Button color="error" busy={saving} onClick={onProceed}>
                    Confirm cancellation
                </Button>,
            ]}
            icon={<IconCircle icon={<WarningAmberOutlinedIcon color="warning" />} size="medium" />}
            open={open}
            title="Cancel plan"
            subtitle="Are you sure you want to cancel this team plan?"
            onClose={onCancel}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterFn: onCancel,
                    escFn: onCancel,
                })
            }
        >
            {errors && <DialogAlert severity="error" title={errors} />}
            <StyledInformationContainer>
                <StyledBillingInfoContainer>
                    <Typography variant="subtitle1">Plan details</Typography>
                </StyledBillingInfoContainer>
                <Divider />
                <StyledBillingInfoContainer>
                    <Typography flexBasis="33%" variant="subtitle2">
                        Your plan
                    </Typography>
                    <Typography variant="body1" textTransform="capitalize">
                        {isCustomPlan ? 'Custom' : currentPlan.tier}
                    </Typography>
                </StyledBillingInfoContainer>
                <Divider />
                <StyledBillingInfoContainer>
                    <Typography flexBasis="33%" variant="subtitle2">
                        Billing period
                    </Typography>
                    <Typography variant="body1">{currentPlan.period === 'ANNUALLY' ? 'Yearly' : 'Monthly'}</Typography>
                </StyledBillingInfoContainer>
                <Divider />
                <StyledBillingInfoContainer>
                    <Typography flexBasis="33%" variant="subtitle2">
                        Upcoming amount
                    </Typography>
                    <Typography variant="body1">
                        ${currentPlan.cost.toLocaleString('en-us')}/
                        {currentPlan.period === 'ANNUALLY' ? 'year' : 'month'}
                    </Typography>
                </StyledBillingInfoContainer>
            </StyledInformationContainer>
            <StyledInformationContainer>
                <StyledBillingInfoContainer>
                    <Typography variant="subtitle1">What will happen?</Typography>
                </StyledBillingInfoContainer>
                <Divider />
                <Box display="flex" flexDirection="column" padding={2} gap={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1">
                            You will stay on the <strong>{currentPlan.tier} plan</strong> until the end of the billing
                            cycle.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1">
                            Here&#8217;s what you lose after <strong>{endDateString}:</strong>
                        </Typography>
                    </Box>
                    <StyledBox>
                        <PowerOutlinedIcon />
                        <Typography variant="body1">
                            <strong>
                                {currentPlan.connectorsLimit === -1 ? 'Unlimited' : currentPlan.connectorsLimit}
                            </strong>{' '}
                            connectors
                        </Typography>
                    </StyledBox>
                    <StyledBox>
                        <CodeOutlinedIcon />
                        <Typography variant="body1">
                            <strong>
                                {currentPlan.invocationsLimit >= 500000 ? 'Unlimited' : currentPlan.invocationsLimit}
                            </strong>{' '}
                            executions /mo
                        </Typography>
                    </StyledBox>
                    <StyledBox>
                        <AssistantOutlinedIcon fontSize="small" />
                        <Typography variant="body1">
                            <strong>{currentPlan.aiCredits}</strong> AI credits
                        </Typography>
                    </StyledBox>
                    <StyledBox>
                        <CheckOutlinedIcon />
                        <Typography variant="body1">Community support</Typography>
                    </StyledBox>
                    {currentPlan.tier.toLowerCase() !== 'basic' && (
                        <StyledBox>
                            <CheckOutlinedIcon />
                            <Typography variant="body1">Mon-Fri 24hr support</Typography>
                        </StyledBox>
                    )}
                </Box>
                <Divider />
                <Alert
                    severity="warning"
                    title="Cancellation disables workspace functionality"
                    text="Workspaces stop running at the end of the plan period rather than immediately."
                    icon={false}
                />
            </StyledInformationContainer>
            <StyledDialogContent>
                <Typography variant="body2" color="text.secondary">
                    Disclaimer: Refunds are carried out on a case by case basis. You can change your plan at any time.
                </Typography>
            </StyledDialogContent>
        </Dialog>
    );
};
