import Typography from '@mui/material/Typography';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { AppBanners } from './AppBanners';
import { Button } from '../common/buttons/Button';
import { EmptyState } from '../common/EmptyState';
import {
    StyledPageHeader,
    StyledPageHeaderRow,
    StyledPageInnerContainer,
    StyledPageOuterContainer,
    StyledPageTitleContainer,
} from '../layout/PageComponents';

interface ErrorPageProps {
    title?: string;
    background?: 'paper' | 'default';
    error?: string;
    skipRefreshMessage?: boolean;
    skipRefreshMessageOverride?: string;
    showDashboardLink?: boolean;
    showRefreshSessionWarning?: boolean;
    supportPortalUrl: string;
    onLogoutFromApp(): void;
    onSetLoadTokens(loadTokens: boolean): void;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
    background = 'default',
    error,
    title,
    skipRefreshMessage = false,
    skipRefreshMessageOverride,
    showDashboardLink = true,
    showRefreshSessionWarning = false,
    supportPortalUrl,
    onLogoutFromApp,
    onSetLoadTokens,
}) => {
    const message = !skipRefreshMessage ? (
        <>
            {skipRefreshMessageOverride ?? 'Try refreshing your browser to resolve the error.'}
            <br />
            {showDashboardLink ? 'Go to the homepage or contact support.' : 'Contact support if needed.'}
        </>
    ) : undefined;

    // TODO: Deprecate the background property
    return (
        <StyledPageOuterContainer sx={{ backgroundColor: background === 'paper' ? 'background.paper' : undefined }}>
            <StyledPageInnerContainer>
                {showRefreshSessionWarning && (
                    <AppBanners
                        showRefreshSessionWarning={showRefreshSessionWarning}
                        onSetLoadTokens={onSetLoadTokens}
                    />
                )}
                <StyledPageHeader>
                    <StyledPageHeaderRow>
                        <StyledPageTitleContainer>
                            <Typography variant="h5">{title}</Typography>
                        </StyledPageTitleContainer>
                    </StyledPageHeaderRow>
                </StyledPageHeader>
                <EmptyState
                    borderBox
                    title={error ?? 'Page not found'}
                    subtitle={message}
                    icon={<ReportOutlinedIcon />}
                    buttons={[
                        <Button
                            key="log-out"
                            variant="text"
                            onClick={onLogoutFromApp}
                            startIcon={<LogoutOutlinedIcon />}
                        >
                            Log out
                        </Button>,
                        <Button
                            variant="outlined"
                            key="contact-support"
                            onClick={() => window.open(supportPortalUrl, '_blank')}
                            startIcon={<QuestionAnswerOutlinedIcon />}
                        >
                            Contact support
                        </Button>,
                        ...(showDashboardLink
                            ? [
                                  <Button
                                      key="dashboard"
                                      onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/dashboard`)}
                                      startIcon={<CottageOutlinedIcon />}
                                  >
                                      Go to home
                                  </Button>,
                              ]
                            : []),
                    ]}
                />
            </StyledPageInnerContainer>
        </StyledPageOuterContainer>
    );
};
