import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import {
    OrganizationPlanDetailsV2,
    OrganizationPlanUpdateEvent,
} from '../../../components/organizations/plan/OrganizationPlanDetailsV2';
import { selectedOrganizationPlan$, organizationSwitchedAction$ } from '../../../store/organization';
import {
    changeOrganizationPlanValidationError$,
    organizationPlanUpdating$,
    openChangeOrganizationPlanConfirmationDialogAction$,
} from '../../../store/organization/changeOrganizationPlan';
import { getBasePath } from '../../../utils/path';
import { teamPlans$ } from '../../../store/organizations';

export const OrganizationPlanContainerV2: React.FC = () => {
    const navigate = useNavigate();

    const plan = useObservableState(selectedOrganizationPlan$);
    const updating = useObservableState(organizationPlanUpdating$);
    const errors = useObservableState(changeOrganizationPlanValidationError$);
    const plans = useObservableState(teamPlans$);

    useSubscription(organizationSwitchedAction$, (uid) => navigate({ to: `${getBasePath()}team/${uid}/plan` }));

    const handleUpdate = (selectedPlan?: OrganizationPlanUpdateEvent): void => {
        if (selectedPlan) {
            openChangeOrganizationPlanConfirmationDialogAction$.next(selectedPlan);
        }
    };

    return (
        <OrganizationPlanDetailsV2
            plan={
                plan && {
                    ...plan,
                    period: plan.period.toLocaleLowerCase() as 'monthly' | 'annually',
                }
            }
            teamPlans={plans}
            errors={errors}
            updating={updating}
            onUpgrade={handleUpdate}
            onWorkspaces={() => navigate({ to: `${getBasePath()}workspaces` })}
        />
    );
};
