import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StatusChip } from '../../../common/chips/StatusChip';
import { Indicator } from '../../../common/Indicator';
import { SetupGuideReviewScreenItem } from '../SetupGuideReviewScreenItem';
import {
    StyledListItemLeftContainer,
    StyledListItemRightContainer,
    StyledSetupGuideReviewScreenListContainer,
} from '../../SetupGuideComponents';
import { SetupGuideAppInstance } from '../../types';

export interface SetupGuideReviewScreenConnectionsProps {
    appInstances: SetupGuideAppInstance[];
    onEdit: () => void;
}

export const SetupGuideReviewScreenConnections: React.FC<SetupGuideReviewScreenConnectionsProps> = ({
    appInstances,
    onEdit,
}) => {
    return (
        <SetupGuideReviewScreenItem itemCount={appInstances.length} itemName="Connectors" onEdit={onEdit}>
            {appInstances.map((ai) => {
                const foundConnection = ai.connections?.find((con) => con.uid === ai.selectedConnectionUid);
                return (
                    <StyledSetupGuideReviewScreenListContainer key={`${ai.appName}_${ai.group}`}>
                        <StyledListItemLeftContainer>
                            <Indicator
                                left={{ icon: <PowerOutlinedIcon /> }}
                                right={{ icon: <ProductIcon name={ai.appName} /> }}
                            />
                            <Box>
                                <Typography>{ai.appName}</Typography>
                                <Typography variant="subtitle1">{foundConnection?.name}</Typography>
                            </Box>
                        </StyledListItemLeftContainer>
                        <StyledListItemRightContainer>
                            <StatusChip
                                icon={<CheckCircleOutlineOutlinedIcon />}
                                label="Authorized"
                                severity="success"
                            />
                        </StyledListItemRightContainer>
                    </StyledSetupGuideReviewScreenListContainer>
                );
            })}
        </SetupGuideReviewScreenItem>
    );
};
