import { useObservableState, useSubscription } from 'observable-hooks';
import { selectedWorkspace$, selectedWorkspaceResources$ } from '../../../store/workspace';
import { loggedInUserConnections$ } from '../../../store/connections';
import { getUserDisplayName } from '../../../utils/account';
import { useNavigate } from 'react-location';
import { OpenSetupInstructionsEvent } from '../../../components/setup-guide/triggers/event-listeners/SetupGuideEditEventListenerScreen';
import {
    eventListenerSavedAction$,
    openEventListenerSetupDialog,
    saveEventListenerAction$,
    savingSelectedEventListener$,
    selectedEventListenerDetails$,
    selectedEventListenerErrors$,
} from '../../../store/workspace/event-listener';
import { apps$ } from '../../../store/apps';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { WEBHOOK_SETUP_COMPLETE_KEY } from './SetupGuideTriggerScreenContainer';
import { navigateToWorkspaceSetupGuideTriggerScreenAction$ } from '../../../store/setup-guide';
import { EventListenerTabbedDetails } from '../../../components/workspace-resources/event-listeners/EventListenerTabbedDetails';

export const SetupGuideEditEventListenerScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const { scripts } = useObservableState(selectedWorkspaceResources$);
    const connections = useObservableState(loggedInUserConnections$);
    const details = useObservableState(selectedEventListenerDetails$);
    const errors = useObservableState(selectedEventListenerErrors$);
    const saving = useObservableState(savingSelectedEventListener$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const apps = useObservableState(apps$);

    const appName = connections.find((c) => c.connectionType.uid === details?.connectionTypeUid)?.connectionType.name;

    const currentApp = apps.find((a) => a.uid === details?.appUid);
    const filteredEventTypes =
        currentApp?.connectionType.eventListenerTypes.find((elt) => elt.uid === details?.eventListenerTypeUid)
            ?.eventTypes ?? [];

    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            authorized: c.authorized,
            name: c.name,
            uid: c.uid,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            authorized: details.selectedSharedConnection.authorized,
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            uid: details.selectedSharedConnection.uid,
        });
    }

    useSubscription(eventListenerSavedAction$, ({ eventListenerUid }) => {
        if (eventListenerUid === details?.uid) {
            navigateToWorkspaceSetupGuideTriggerScreenAction$.next('event');
        }
    });

    useSubscription(navigateToWorkspaceSetupGuideTriggerScreenAction$, (screen) => {
        if (screen === 'event') {
            navigate({ to: '../../' });
        }
    });

    const completedEventListeners = readLocalStorage<string[]>(WEBHOOK_SETUP_COMPLETE_KEY, []);

    const handleOpenSetupInstructionsForTemplateMode = (event: OpenSetupInstructionsEvent): void => {
        navigateToWorkspaceSetupGuideTriggerScreenAction$.next('event');
        openEventListenerSetupDialog(
            {
                connectionUid: event.connectionUid,
                eventListenerTypeUid: details?.eventListenerTypeUid,
                eventTypeCategory: details?.eventTypeCategory,
                eventTypeUid: event.eventTypeUid,
                uid: event.uid,
                urlId: event.urlId,
            },
            currentApp?.uid
        );
        if (!selectedWorkspace?.locked) {
            saveLocalStorage(WEBHOOK_SETUP_COMPLETE_KEY, [...completedEventListeners, event.uid]);
        }
    };

    return (
        <EventListenerTabbedDetails
            appName={appName ?? ''}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            details={{
                connections: filteredConnections,
                disabled: details?.disabled,
                errors: errors,
                eventTypes: filteredEventTypes,
                saving: saving,
                scripts: scripts,
                selectedConnectionUid: details?.connectionUid,
                selectedEventTypeUid: details?.eventTypeUid,
                selectedScriptUid: details?.scriptUid,
                workspaceLocked: !!selectedWorkspace?.locked,
                uid: details?.uid ?? '',
                urlId: details?.urlId ?? '',
                webhookSetup: !!details?.uid && completedEventListeners.includes(details?.uid),
                onCancel: () => navigateToWorkspaceSetupGuideTriggerScreenAction$.next('event'),
                onOpenSetupInstructions: handleOpenSetupInstructionsForTemplateMode,
                onSave: (event) => saveEventListenerAction$.next(event),
            }}
        />
    );
};
