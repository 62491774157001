import { LoaderFn } from 'react-location';
import { getLoggedInUserAuditLogs } from '../data/reporting';
import { LocationGenerics } from '../router';
import { auditLogs$, auditLogsPageErrors$ } from '../store/auditLogs';
import { selectedOrganizationUid$ } from '../store/organization';
import { loadErrorPage } from '../store/error';

export const auditLogsLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        auditLogsPageErrors$.next(undefined);
        const selectedOrganizationUid = selectedOrganizationUid$.value;

        const auditLogs = await getLoggedInUserAuditLogs({
            organizationUid: selectedOrganizationUid ?? '',
        });

        auditLogs$.next(auditLogs);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Audit Logs',
            genericMessage: 'Failed to load audit logs.',
        });

        throw e;
    }

    return {};
};
