import { useObservableState } from 'observable-hooks';
import { EditWorkspaceDialog } from '../../components/workspace-dialogs/EditWorkspaceDialog';
import { loggedInUserDetails$ } from '../../store/user';
import {
    cancelEditWorkspaceAction$,
    workspaceBeingEditted$,
    editWorkspaceDialogOpen$,
    openWorkspacePreviewDialogErrors$,
    savingExistingWorkspace$,
    updateWorkspaceDetailsAction$,
    loadingWorkspaceFormDetails$,
    workspaceFormDetails$,
} from '../../store/workspaces';

export const EditWorkspaceDialogContainer: React.FC = () => {
    const editWorkspaceDialogOpen = useObservableState(editWorkspaceDialogOpen$);
    const workspace = useObservableState(workspaceBeingEditted$);
    const savingExistingWorkspace = useObservableState(savingExistingWorkspace$);
    const openWorkspacePreviewDialogErrors = useObservableState(openWorkspacePreviewDialogErrors$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const loadingWorkspaceFormDetails = useObservableState(loadingWorkspaceFormDetails$);
    const workspaceFormDetails = useObservableState(workspaceFormDetails$);

    const templateOption =
        !!loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates && !workspace?.createdFromTemplate;

    return (
        <EditWorkspaceDialog
            name={workspace?.name}
            description={workspace?.description}
            open={editWorkspaceDialogOpen}
            saving={savingExistingWorkspace}
            errors={openWorkspacePreviewDialogErrors}
            onCancel={() => cancelEditWorkspaceAction$.next()}
            templateSelectorDisabled={!templateOption}
            isTemplate={workspace?.template}
            organizations={workspaceFormDetails?.organizations}
            selectedOrganizationUid={workspaceFormDetails?.selectedOrganizationUid}
            organizationSelectorDisabled={workspaceFormDetails?.organizationDropdownDisabled}
            defaultOrganizationUid={workspaceFormDetails?.defaultOrganizationUid ?? ''}
            isWorkspaceOwner={workspaceFormDetails?.isWorkspaceOwner}
            useCases={workspaceFormDetails?.useCases}
            complexities={workspaceFormDetails?.complexities}
            selectedUseCaseUids={(workspaceFormDetails?.useCases ?? []).filter((uc) => uc.selected).map((uc) => uc.uid)}
            selectedComplexityUid={(workspaceFormDetails?.complexities ?? []).find((c) => c.selected)?.uid}
            templateCustomId={workspaceFormDetails?.templateCustomId}
            onSave={(details) => {
                const wasTemplate = workspace?.template;
                const isTemplate = !!details.template || false;
                updateWorkspaceDetailsAction$.next({
                    uid: workspace?.uid ?? '',
                    name: details.name,
                    description: details.description,
                    template: details.template ?? false,
                    newTemplate: !wasTemplate && isTemplate,
                    organizationUid: details.selectedOrganizationUid ?? '',
                    useCaseUids: details.useCases,
                    complexityUid: details.complexity,
                    templateCustomId: details.templateCustomId,
                });
            }}
            loading={loadingWorkspaceFormDetails}
        />
    );
};
