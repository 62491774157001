import { useObservableState } from 'observable-hooks';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import { TemplatePreviewScreen } from '../../components/templates/TemplatePreviewScreen';
import { getBasePath } from '../../utils/path';
import { createWorkspaceFromTemplateAction$ } from '../../store/workspaces';
import { themeMode$ } from '../../store/theme';
import { selectedReadmeFileUid$, unsavedReadmeFileDetails$ } from '../../store/workspace/readme';
import { getIframeThemeMode } from '../../utils/miscellaneous';

export const TemplatePreviewContainer: React.FC = () => {
    const template = useObservableState(selectedReadOnlyTemplate$);
    const themeMode = useObservableState(themeMode$);
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const unsavedReadmeFileDetails = useObservableState(unsavedReadmeFileDetails$);

    const readmeKey = `${selectedReadmeFileUid ?? ''}_${template?.environmentUid ?? ''}`;
    const readmeContent = unsavedReadmeFileDetails[readmeKey]?.content;

    return (
        <TemplatePreviewScreen
            apps={Array.from(new Set([...(template?.incomingApps ?? []), ...(template?.outgoingApps ?? [])]))}
            basePath={getBasePath()}
            customId={template?.templateCustomId}
            description={template?.description}
            iframeThemeMode={getIframeThemeMode(themeMode)}
            name={template?.name ?? ''}
            readmeContent={readmeContent}
            uid={template?.templateUid ?? ''}
            onUseTemplate={() => createWorkspaceFromTemplateAction$.next()}
        />
    );
};
