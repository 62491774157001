import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { sessionLockAcquired } from '../..';
import { UsersReportPage } from '../../components/report/UsersReportPage';
import { UsersReportRequest } from '../../data/report/users';
import { users$ } from '../../store/report/users';
import { askImpersonationPasswordAction$ } from '../../store/user';

export const UsersReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const users = useObservableState(users$);

    return (
        <UsersReportPage
            users={users}
            email={match.search.email as string}
            emailComparator={match.search.emailComparator as UsersReportRequest['emailComparator']}
            workspaces={match.search.workspaces as number}
            workspacesComparator={match.search.workspacesComparator as UsersReportRequest['workspacesComparator']}
            connections={match.search.connections as number}
            connectionsComparator={match.search.connectionsComparator as UsersReportRequest['connectionsComparator']}
            orderByField={match.search.orderByField as UsersReportRequest['orderByField']}
            orderByDirection={match.search.orderByDirection as UsersReportRequest['orderByDirection']}
            onboardedUsersOnly={match.search.onboardedUsersOnly as boolean}
            onSearch={(
                email,
                emailComparator,
                workspaces,
                workspacesComparator,
                connections,
                connectionsComparator,
                orderByField,
                orderByDirection,
                onboardedUsersOnly
            ) => {
                // Ugly hack to force loader to get called
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './users',
                            search: {
                                search: true,
                                email,
                                emailComparator,
                                workspaces,
                                workspacesComparator,
                                connections,
                                connectionsComparator,
                                orderByField,
                                orderByDirection,
                                onboardedUsersOnly,
                            },
                        }),
                    100
                );
            }}
            onImpersonate={(impersonateeUserUid) => {
                if (!sessionLockAcquired) {
                    alert("You can't start impersonation session on this tab, please open a new tab and try again.");
                    return;
                }

                askImpersonationPasswordAction$.next(impersonateeUserUid);
            }}
        />
    );
};
