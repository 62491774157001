import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { getBasePath } from '../../../utils/path';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { LoadingSpinner } from '../../common/LoadingSpinner';
import { InfoIcon } from '../../icons/InfoIcon';
import { InvocationRecord } from '../../../data/reporting';
import { ReplayInvocationDetails } from '../../../store/workspace/replay-invocation';
import { InvocationsReportFilters } from './InvocationsReportPage';
import { InvocationsReportRequest } from '../../../data/report/invocations';
import { useInfiniteScroll } from '../../../utils/miscellaneous';

interface WithWorkspaceDeleted {
    workspaceDeleted?: boolean;
}

interface InvocationsReportPageTableProps {
    errors?: string;
    filters: InvocationsReportFilters;
    impersonating?: boolean;
    invocationPayloadIsLoading?: boolean;
    invocations: (InvocationRecord & WithWorkspaceDeleted)[];
    isLoading: boolean;
    nextToken?: string;
    showLogsLinks?: boolean;
    onQueryInvocations(request: InvocationsReportRequest): void;
    onReplayInvocation?(details: ReplayInvocationDetails): void;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: 4,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
}));

const StyledTableCell = styled(TableCell)(() => ({
    width: 150,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

export const InvocationsReportPageTable: React.FC<InvocationsReportPageTableProps> = ({
    errors,
    filters,
    impersonating,
    invocationPayloadIsLoading,
    invocations,
    isLoading,
    nextToken,
    showLogsLinks = true,
    onQueryInvocations,
    onReplayInvocation,
}) => {
    const lastPostRef = useInfiniteScroll({
        cursor: nextToken,
        loading: isLoading,
        callback: () =>
            onQueryInvocations({
                nextToken,
                ...filters,
            }),
    });

    // TODO: find the actual type of the Chip colors and swap with any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExecutionStatusColor = (status: string): string | any => {
        switch (status) {
            case 'ABORTED':
            case 'TIMED_OUT':
            case 'DENIED':
                return 'warning';
            case 'RUNNING':
                return 'info';
            case 'FUNCTION_ERROR':
            case 'RUNTIME_ERROR':
            case 'MALFORMED_PAYLOAD_ERROR':
                return 'error';
            case 'FINISHED':
                return 'success';
            default:
                return 'default';
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const tableRows = invocations.map((invocation, i) => {
        const chainedTrigger = invocation.triggerType === 'CHAINED' ? ` (${invocation.rootTriggerType})` : '';
        const workspaceUrl = `${getBasePath()}workspace/${invocation.workspaceUid}/environment/${
            invocation.environmentUid
        }`;
        const replayableInvocation =
            invocation.triggerType !== 'MANUAL' &&
            invocation.triggerType !== 'SCHEDULED' &&
            invocation.invocationType !== 'MALFORMED_PAYLOAD_EVENT';

        return (
            <TableRow key={i} ref={invocations.length === i + 1 ? lastPostRef : undefined}>
                <TableCell sx={{ minWidth: 150 }}>
                    {invocation.workspaceDeleted ? (
                        `${invocation.workspaceName} (deleted)`
                    ) : (
                        <Link href={workspaceUrl} target="_blank">
                            {invocation.workspaceName}
                        </Link>
                    )}
                </TableCell>
                <StyledTableCell>{invocation.invocationId}</StyledTableCell>
                <StyledTableCell>{invocation.workspaceOwnerName}</StyledTableCell>
                <StyledTableCell>{invocation.environmentName}</StyledTableCell>
                <StyledTableCell
                    sx={{
                        maxWidth: 150,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {invocation.workspaceDeleted ? (
                        invocation.scriptName
                    ) : (
                        <Link href={`${workspaceUrl}/script/${invocation.scriptUid}`} target="_blank">
                            {invocation.scriptName}
                        </Link>
                    )}
                </StyledTableCell>
                <StyledTableCell>{`${invocation.triggerType.replaceAll('_', ' ')}${chainedTrigger}`}</StyledTableCell>
                <StyledTableCell>{invocation.duration}ms</StyledTableCell>
                <StyledTableCell>
                    {invocation.nrOfLogs > 0 && showLogsLinks ? (
                        <Link
                            href={`${getBasePath()}invocationlogs/workspace/${invocation.workspaceUid}/invocation/${
                                invocation.invocationId
                            }`}
                            target={impersonating ? '_self' : '_blank'}
                        >
                            {invocation.nrOfLogs}
                        </Link>
                    ) : (
                        invocation.nrOfLogs
                    )}
                </StyledTableCell>
                <StyledTableCell>
                    {invocation.nrOfHttpLogs > 0 && showLogsLinks ? (
                        <Link
                            href={`${getBasePath()}httplogs/workspace/${invocation.workspaceUid}/invocation/${
                                invocation.invocationId
                            }`}
                            target={impersonating ? '_self' : '_blank'}
                        >
                            {invocation.nrOfHttpLogs}
                        </Link>
                    ) : (
                        invocation.nrOfHttpLogs
                    )}
                </StyledTableCell>
                <StyledTableCell>{new Date(`${invocation.starttime}Z`).toLocaleString()}</StyledTableCell>
                <StyledTableCell>
                    <StyledBox>
                        <StyledChip
                            label={invocation.executionStatus
                                .replaceAll('_', ' ')
                                .toLowerCase()
                                .replace(/\b\w/g, (letter) => letter.toUpperCase())}
                            size="small"
                            color={getExecutionStatusColor(invocation.executionStatus)}
                        />
                        {invocation.deniedReason && <InfoIcon tooltip={invocation.deniedReason} />}
                    </StyledBox>
                </StyledTableCell>
                <TableCell>
                    {replayableInvocation && !invocation.workspaceDeleted && (
                        <Button
                            size="small"
                            busy={invocationPayloadIsLoading}
                            onClick={() =>
                                onReplayInvocation?.({
                                    invocationUid: invocation.invocationId,
                                    workspaceUid: invocation.workspaceUid,
                                    environmentUid: invocation.environmentUid,
                                    scriptUid: invocation.scriptUid,
                                })
                            }
                        >
                            Replay
                        </Button>
                    )}
                </TableCell>
            </TableRow>
        );
    });

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {errors && <Alert severity="error" title={errors} />}
                    {invocations.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Invocation ID</TableCell>
                                    <TableCell>Workspace</TableCell>
                                    <TableCell>Workspace Owner</TableCell>
                                    <TableCell>Environment</TableCell>
                                    <TableCell>Script</TableCell>
                                    <TableCell>Trigger Type</TableCell>
                                    <TableCell>Invocation duration (ms)</TableCell>
                                    <TableCell>Logs</TableCell>
                                    <TableCell>HTTP Logs</TableCell>
                                    <TableCell>Invocation started</TableCell>
                                    <TableCell>Execution Status</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>{tableRows}</TableBody>
                        </Table>
                    ) : (
                        <div>No Invocations found</div>
                    )}
                </>
            )}
        </>
    );
};
