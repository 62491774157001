import { styled } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { AppMainProps } from '../../app-main/AppMain';
import { getAvatarColor } from '../../../utils/account';

type AvatarSize = 'small' | 'medium' | 'large';

interface CustomAvatarProps {
    credentials: AppMainProps['userCredentials'];
    size?: AvatarSize;
}

const StyledAvatar = styled(MuiAvatar)<{ name: string; size: AvatarSize }>(({ name, size, theme }) => {
    const color = getAvatarColor(theme, name);

    return {
        backgroundClip: 'padding-box',
        backgroundColor: color.backgroundColor,
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        height: size === 'large' ? 80 : size === 'small' ? 30 : 40,
        width: size === 'large' ? 80 : size === 'small' ? 30 : 40,
    };
});

export const Avatar: React.FC<CustomAvatarProps> = ({ credentials, size = 'medium' }) => {
    const { firstName, lastName, email } = credentials;

    const handleInitials = (): string => {
        if (firstName && lastName) {
            return `${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`;
        } else {
            return email?.substring(0, 1).toUpperCase();
        }
    };
    const initials = handleInitials();

    return (
        <StyledAvatar name={initials} size={size}>
            <Typography variant={size === 'large' ? 'h4' : size === 'medium' ? 'subtitle1' : undefined}>
                {initials}
            </Typography>
        </StyledAvatar>
    );
};
