import { styled } from '@mui/material';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';

interface CustomCheckboxProps extends ButtonBaseProps {
    border?: boolean;
    checked: boolean;
    label: string;
    onCheck: (checked: boolean) => void;
}

const StyledButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'border' })<{ border: boolean }>(
    ({ border, theme }) => ({
        backgroundColor: border ? theme.palette.background.paper : undefined,
        border: border ? `1px solid ${theme.palette.divider}` : 'none',
        borderRadius: theme.constants.borderRadius,
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    })
);

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    margin: 0,
    gap: theme.spacing(1),
}));

const StyledCheckbox = styled(MuiCheckbox)(() => ({
    padding: 0,
    margin: 0,
    '& input:hover': {
        backgroundColor: 'none',
    },
}));

export const Checkbox: React.FC<CustomCheckboxProps> = ({ border = false, checked, label, onCheck, ...props }) => {
    return (
        <StyledButton border={border} onClick={() => onCheck(!checked)} {...props}>
            <StyledFormControlLabel
                control={<StyledCheckbox checked={checked} onChange={(e) => e.preventDefault()} tabIndex={-1} />}
                label={label}
                onClick={(e) => e.preventDefault()}
            />
        </StyledButton>
    );
};
