import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { EmptyState } from '../../common/EmptyState';
import { IconButton } from '../../common/buttons/IconButton';
import { SetupGuideScriptListItem } from './SetupGuideScriptListItem';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { StyledStepContainer } from '../SetupGuideComponents';
import { Language } from '../../workspace-dialogs/language-selector';
import { SetupGuideScript } from '../types';

interface SetupGuideScriptScreenProps {
    errors?: string;
    language: Language;
    nextStepName?: string;
    saving?: boolean;
    scripts?: SetupGuideScript[];
    stepRequired?: boolean;
    templateMode?: boolean;
    workspaceLocked?: boolean;
    onContinue: () => void;
    onCreateNew: () => void;
    onDeleteScript: (uid: string) => void;
    onEditScript: (uid: string) => void;
    onGoBack: () => void;
}

export const SetupGuideScriptScreen: React.FC<SetupGuideScriptScreenProps> = ({
    errors,
    language,
    nextStepName,
    saving = false,
    scripts = [],
    stepRequired = false,
    templateMode = false,
    workspaceLocked = false,
    onContinue,
    onCreateNew,
    onDeleteScript,
    onEditScript,
    onGoBack,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const paragraphs = [
        'Use JavaScript or TypeScript scripting languages to build out your integration logic.',
        'Currently you can only review the scripts that come with the template, once you complete the setup you can modify these scripts.',
    ];

    const tasksComplete = true; // Putting it here for scaling to blank workspace flow

    const nextStepPendoAttribute = nextStepName === 'Review' ? 'progressToReview' : undefined;

    const actions = (
        <>
            <Button variant="outlined" onClick={onGoBack}>
                Back
            </Button>
            {stepRequired ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : tasksComplete ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : (
                <Button
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    variant="outlined"
                    onClick={onContinue}
                >
                    {nextStepName ? `Skip to ${nextStepName}` : 'Skip'}
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox paragraphs={paragraphs} title="What is a script?" />
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        Scripts
                    </Typography>
                    {!templateMode && (
                        <IconButton
                            aria-label="Create a new script"
                            icon={<AddOutlinedIcon />}
                            tooltip="Create a new script"
                            onClick={() => onCreateNew()}
                        />
                    )}
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {scripts.length ? (
                        <>
                            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
                            {scripts.map((s) => {
                                return (
                                    <SetupGuideScriptListItem
                                        key={s.uid}
                                        uid={s.uid}
                                        deleting={s.deleting}
                                        language={language}
                                        path={s.path}
                                        templateMode={templateMode}
                                        workspaceLocked={workspaceLocked}
                                        onDelete={onDeleteScript}
                                        onEdit={onEditScript}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <EmptyState
                            buttons={
                                !templateMode
                                    ? [
                                          <Button disabled={workspaceLocked} key="create-script" onClick={onCreateNew}>
                                              Create script
                                          </Button>,
                                      ]
                                    : undefined
                            }
                            icon={<CodeOutlinedIcon />}
                            iconState={templateMode ? 'complete' : 'incomplete'}
                            subtitle={
                                !templateMode
                                    ? 'Create a script to start building your integration.'
                                    : 'Click on Continue to proceed to the next step.'
                            }
                            title={templateMode ? 'No scripts in this template' : 'No scripts found'}
                        />
                    )}
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
