import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../common/buttons/Button';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import LoopIcon from '@mui/icons-material/Loop';
import Draggable from 'react-draggable';
import MuiDialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { WelcomeScreen } from './WelcomeScreen';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import NorthWestOutlinedIcon from '@mui/icons-material/NorthWestOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { LearnAboutCreditsScreen } from './LearnAboutCreditsScreen';
import { MoreActionsContextMenu } from './MoreActionsContextMenu';
import { BestPracticesScreen } from './BestPracticesScreen';
import { PromptLibraryScreen } from './PromptLibraryScreen';
import {
    AiAssistanceResponseMessage,
    CodeBlock,
    CreditsChip,
    NewTabLink,
    PromptInput,
    StyledBotMessage,
    StyledBoxCopyCodeIconButton,
    StyledBoxExamplePromptIcon,
    StyledExample,
    StyledExamplePromptLinkIconBox,
    StyledFooter,
} from './AiAssistanceComponents';
import { handleKeyDown } from '../../../utils/input';
import { Avatar } from '../../common/avatars/Avatar';
import { UserNameDetails } from '../AppMain';
import { IconCopyButton } from '../../common/IconCopyButton';
import { AiChatResponseRating } from '@avst-stitch/repository-lib/lib/models';
import { EmptyState } from '../../common/EmptyState';
import { limitsAndQuotasDocumentationUrl, supportPortalUrl } from '../../../utils/documentation';
import { IconButton } from '../../common/buttons/IconButton';
import { ChatHistoryScreen } from './ChatHistoryScreen';
import {
    ChatContentRequest,
    ChatHistory,
    DeleteConversationRequest,
    RenameTitleRequest,
} from '../../../data/ai-assistance';
import { Role } from '@avst-stitch/repository-lib/src/utils/chatGPT';
import { LoadingSpinner } from '../../common/LoadingSpinner';

interface AiAssistanceDialogProps {
    open?: boolean;
    asking: boolean;
    answering: boolean;
    aiGeneratedAnswer?: string;
    chat: {
        role: Role;
        message: string;
        uid?: string;
        rating?: AiChatResponseRating;
    }[];
    examples?: {
        prompt: string;
        promptType: string;
        icon: JSX.Element;
    }[];
    errors?: string;
    aiAssistanceIsLongResponse: boolean;
    availableCredits: number;
    userCredentials: UserNameDetails;
    aiAssistanceOnboardingCompleted: boolean;
    chatHistory: ChatHistory;
    chatHistoryLoading: boolean;
    renameOrDeleteLoading: boolean;
    onSend(message: string): void;
    onClose(): void;
    onReset(): void;
    onCopy?(): void;
    onCompleteOnboarding(): void;
    onStopAiAssistanceResponse(): void;
    onRateAiAssistanceMessage(request: { uid: string; rating: AiChatResponseRating }): void;
    onChatHistoryOpen(): void;
    onChatHistoryConversationTitleRename(update: RenameTitleRequest): void;
    onChatHistoryConversationDelete(update: DeleteConversationRequest): void;
    onGetChatHistoryConversationContent(data: ChatContentRequest): void;
}

const dialogWidth = 620;
const dialogHeight = 640;
const dialogOuterSpacing = 24;
const defaultX = window.innerWidth - dialogWidth - dialogOuterSpacing;
const defaultY = window.innerHeight - dialogHeight - dialogOuterSpacing;
const keyX = 'aiAssistanceDialogX';
const keyY = 'aiAssistanceDialogY';

const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    '& .MuiInputBase-root': {
        marginBottom: 0,
    },
}));

const StyledConversationContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(2),
}));

const StyledConversation = styled(Box)(() => ({
    minWidth: '100%',
    '& strong': {
        fontWeight: 700,
    },
}));

const StyledUserMessage = styled(Box)(({ theme }) => ({
    width: '100%',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    p: {
        margin: 0,
    },
}));

const StyledBoxLongResponse = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.warning.dark}`,
    borderBottomLeftRadius: theme.constants.borderRadius,
    borderBottomRightRadius: theme.constants.borderRadius,
}));

const StyledBoxAssistantRedIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,
    borderRadius: theme.constants.borderRadius,
    '& .MuiSvgIcon-root': {
        height: 32,
        width: 32,
        color: theme.palette.primary.contrastText,
    },
    backgroundColor: theme.palette.brand.main,
}));

const StyledBoxCircleAssistantRedIcon = styled(StyledBoxAssistantRedIcon)(({ theme }) => ({
    height: 30,
    width: 30,
    flexShrink: 0,
    borderRadius: theme.constants.radiusCircle,
    '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
        color: theme.palette.primary.contrastText,
    },
    backgroundColor: theme.palette.brand.main,
}));

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        height: dialogHeight,
        width: dialogWidth,
    },
    '& .MuiPaper-root': {
        margin: 0,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
    },
    '& .MuiDialog-paper': {
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        padding: theme.spacing(0),
    },
}));

export const AiAssistanceDialog: React.FC<AiAssistanceDialogProps> = ({
    open = false,
    asking,
    answering,
    examples = [],
    chat = [],
    aiGeneratedAnswer,
    aiAssistanceIsLongResponse,
    availableCredits,
    userCredentials,
    aiAssistanceOnboardingCompleted,
    chatHistory,
    chatHistoryLoading,
    renameOrDeleteLoading,
    onSend,
    onClose,
    onReset,
    onCopy,
    onCompleteOnboarding,
    onStopAiAssistanceResponse,
    onRateAiAssistanceMessage,
    onChatHistoryOpen,
    onChatHistoryConversationTitleRename,
    onChatHistoryConversationDelete,
    onGetChatHistoryConversationContent,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [showWelcomeScreenState, setShowWelcomeScreenState] = useState(!aiAssistanceOnboardingCompleted);
    const [showLearnMoreAboutCreditsScreenState, setShowLearnMoreAboutCreditsScreenState] = useState(false);
    const [showBestPracticesScreenState, setShowBestPracticesScreenState] = useState(false);
    const [showPromptLibraryScreenState, setShowPromptLibraryScreenState] = useState(false);
    const [showChatHistoryScreenState, setShowChatHistoryScreenState] = useState(false);
    const [currentXPos, setXpos] = useState(readLocalStorage(keyX, defaultX));
    const [currentYPos, setYpos] = useState(readLocalStorage(keyY, defaultY));
    const conversationRef = useRef<HTMLDivElement>(null);
    const prevScrollHeightRef = useRef(0);
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const [prompt, setPrompt] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    /* If running in React Strict mode, ReactDOM.findDOMNode() is deprecated.
     * Unfortunately, in order for <Draggable> to work properly, we need raw access
     * to the underlying DOM node.
     * Passing `nodeRef` fixes the warning.
     * https://www.npmjs.com/package/react-draggable#draggable
     */
    const nodeRef = React.useRef(null);
    const isAtBottom = (container: HTMLDivElement, previousScrollHeight: number): boolean => {
        return container.scrollTop + container.clientHeight >= previousScrollHeight;
    };

    const handleResize = (): void => {
        setXpos(window.innerWidth - dialogWidth - dialogOuterSpacing);
        setYpos(window.innerHeight - dialogHeight - dialogOuterSpacing);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useLayoutEffect(() => {
        if (conversationRef.current) {
            prevScrollHeightRef.current = conversationRef.current.scrollHeight;
        }
        if (
            conversationRef.current?.lastElementChild &&
            isAtBottom(conversationRef.current, prevScrollHeightRef.current)
        ) {
            conversationRef.current.lastElementChild.scrollIntoView({
                block: 'start',
                inline: 'nearest',
            });
            prevScrollHeightRef.current = conversationRef.current.scrollHeight;
        }
    }, [chat, aiGeneratedAnswer]);

    useEffect(() => {
        if (open === false) {
            setShowBestPracticesScreenState(false);
            setShowChatHistoryScreenState(false);
            setShowLearnMoreAboutCreditsScreenState(false);
            setShowPromptLibraryScreenState(false);
        }
    }, [open]);

    const handleShowPromptLibraryScreenState = (isOpen: boolean): void => {
        setShowPromptLibraryScreenState(isOpen);
    };

    const conversation = useMemo(() => {
        return chat.map((c, i) => {
            return c.role === 'user' ? (
                <Box display="flex" width="100%" gap={1.5} mb={2} key={'message' + i}>
                    <Avatar credentials={userCredentials} size="small" />
                    <StyledUserMessage>
                        <ReactMarkdown
                            components={{
                                code: (props) => <CodeBlock {...props} onCopy={onCopy} isUserMessage />,
                            }}
                        >
                            {c.message}
                        </ReactMarkdown>
                        <StyledBoxCopyCodeIconButton mt={2}>
                            <IconCopyButton
                                size="small"
                                tooltip="Copy message"
                                value={c.message}
                                data-pendo={'messageCopied'}
                            />
                        </StyledBoxCopyCodeIconButton>
                    </StyledUserMessage>
                </Box>
            ) : c.role === 'assistant' ? (
                <Box display="flex" width="100%" gap={1.5} mb={2} key={'message' + i}>
                    <StyledBoxCircleAssistantRedIcon>
                        <AssistantOutlinedIcon />
                    </StyledBoxCircleAssistantRedIcon>
                    <Box width="100%">
                        <AiAssistanceResponseMessage
                            uid={c.uid ?? ''}
                            message={c.message}
                            onCopy={onCopy}
                            rating={c.rating}
                            onRateAiAssistanceMessage={onRateAiAssistanceMessage}
                            aiAssistanceIsLongResponse={aiAssistanceIsLongResponse}
                        />
                        {aiAssistanceIsLongResponse && (
                            <StyledBoxLongResponse>
                                <Box>
                                    <Typography variant="subtitle2" color="warning.dark">
                                        Partial response created
                                    </Typography>
                                    <Typography variant="body1" color="warning.dark">
                                        Select button to view remaining information.
                                    </Typography>
                                </Box>
                                <Button
                                    tooltip="Continue with the next part of the response"
                                    startIcon={<LoopIcon />}
                                    variant="outlined"
                                    onClick={() => handleSend('Continue')}
                                >
                                    Generate more
                                </Button>
                            </StyledBoxLongResponse>
                        )}
                    </Box>
                </Box>
            ) : null;
        });
    }, [chat]);

    const handleSend = (prompt: string): void => {
        if (prompt) {
            onSend(prompt);
        }
    };

    const answeringAnimation = Array.from({ length: 3 }).map((_, i) => (
        <Typography
            key={i}
            component="span"
            sx={{
                animation: `fade-in 1.5s infinite`,
                animationDelay: `${i * 0.5}s`,
                opacity: 0,
                display: 'inline-block',
                '@keyframes fade-in': {
                    '0%': { opacity: 0 },
                    '30%': { opacity: 1 },
                    '100%': { opacity: 0 },
                },
            }}
        >
            .
        </Typography>
    ));

    const handlePrompt = (prompt: string): void => {
        if (!busy) {
            setPrompt(prompt);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const examplePrompts = examples.map((e, i) => {
        return (
            <StyledExample
                data-prompt={e.prompt}
                key={`${e.promptType}-${i}`}
                onClick={() => handlePrompt(e.prompt)}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: (): void => handlePrompt(e.prompt) })}
            >
                <Box display="flex">
                    <StyledBoxExamplePromptIcon>{e.icon}</StyledBoxExamplePromptIcon>
                    <Box>
                        <Typography variant="body1" color="text.secondary">
                            {e.promptType}
                        </Typography>
                        <Typography variant="subtitle2">{e.prompt}</Typography>
                    </Box>
                </Box>
                <StyledExamplePromptLinkIconBox>
                    <NorthWestOutlinedIcon />
                </StyledExamplePromptLinkIconBox>
            </StyledExample>
        );
    });

    const startingScreen = (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1.5} boxSizing="border-box">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={0.5}>
                <StyledBoxAssistantRedIcon>
                    <AssistantOutlinedIcon />
                </StyledBoxAssistantRedIcon>
                <Typography variant="h6">How can I help you?</Typography>
            </Box>
            {examplePrompts}
            <Box>
                <Button onClick={() => handleShowPromptLibraryScreenState(true)} variant="text">
                    EXPLORE PROMPT LIBRARY
                </Button>
            </Box>
        </Box>
    );

    const currentDate = new Date();
    const renewalDate = new Date(currentDate.setMonth(currentDate.getMonth() + 2));
    const emptyState = (
        <EmptyState
            borderBox
            icon={<ReportOutlinedIcon />}
            title="You've reached your credit limit for this month"
            subtitle={`Your credit limit resets on 1/${renewalDate.getMonth()}/${renewalDate.getFullYear()}`}
            buttons={[
                <Button
                    LinkComponent={NewTabLink}
                    href={limitsAndQuotasDocumentationUrl}
                    startIcon={<OpenInNewOutlinedIcon />}
                    variant="outlined"
                >
                    View AI credit limits
                </Button>,
                <Button LinkComponent={NewTabLink} href={supportPortalUrl} startIcon={<QuestionAnswerOutlinedIcon />}>
                    Contact support
                </Button>,
            ]}
        />
    );

    const busy = asking || answering || availableCredits === 0;

    const defineChatContent = (): JSX.Element => {
        if (chat.length) {
            return <>{conversation}</>;
        } else if (availableCredits === 0) {
            return emptyState;
        } else if (chatHistoryLoading) {
            return <LoadingSpinner />;
        }
        return startingScreen;
    };

    const chatContent = defineChatContent();

    return (
        <Draggable
            nodeRef={nodeRef}
            cancel=".no-drag"
            bounds={{
                left: -dialogWidth + 40,
                right: window.innerWidth - 40,
                top: -dialogHeight + 40,
                bottom: window.innerHeight - 40,
            }}
            position={{ x: currentXPos, y: currentYPos }}
            defaultPosition={{ x: currentXPos, y: currentYPos }}
            onStop={(_, value) => {
                setXpos(value.x);
                setYpos(value.y);
                saveLocalStorage('aiAssistanceDialogX', value.x);
                saveLocalStorage('aiAssistanceDialogY', value.y);
            }}
        >
            <StyledDialog ref={nodeRef} disableEnforceFocus hideBackdrop open={open}>
                {showWelcomeScreenState && (
                    <WelcomeScreen
                        onCompleteOnboarding={() => {
                            onCompleteOnboarding();
                            setShowWelcomeScreenState(false);
                        }}
                        availableCredits={availableCredits}
                        onClose={onClose}
                    />
                )}
                {showLearnMoreAboutCreditsScreenState && (
                    <LearnAboutCreditsScreen
                        onBack={() => {
                            setShowLearnMoreAboutCreditsScreenState(false);
                        }}
                        onClose={onClose}
                        availableCredits={availableCredits}
                    />
                )}
                {showBestPracticesScreenState && (
                    <BestPracticesScreen
                        onBack={() => {
                            setShowBestPracticesScreenState(false);
                        }}
                        onClose={onClose}
                        availableCredits={availableCredits}
                        onCredits={() => {
                            setShowBestPracticesScreenState(false);
                            setShowLearnMoreAboutCreditsScreenState(true);
                        }}
                    />
                )}
                {showPromptLibraryScreenState && (
                    <PromptLibraryScreen
                        onBack={() => {
                            handleShowPromptLibraryScreenState(false);
                        }}
                        onClose={onClose}
                        availableCredits={availableCredits}
                        onSend={onSend}
                        busy={busy}
                        prompt={prompt}
                        setPrompt={setPrompt}
                        onCredits={() => {
                            setShowPromptLibraryScreenState(false);
                            setShowLearnMoreAboutCreditsScreenState(true);
                        }}
                    />
                )}
                {showChatHistoryScreenState && (
                    <ChatHistoryScreen
                        chats={chatHistory}
                        onBack={() => {
                            setShowChatHistoryScreenState(false);
                        }}
                        onClose={onClose}
                        availableCredits={availableCredits}
                        onSend={onSend}
                        onNewChat={() => {
                            setShowChatHistoryScreenState(false);
                            onReset();
                        }}
                        onCredits={() => {
                            setShowChatHistoryScreenState(false);
                            setShowLearnMoreAboutCreditsScreenState(true);
                        }}
                        onChatSelected={(uid) => {
                            onGetChatHistoryConversationContent({ uid });
                            setShowChatHistoryScreenState(false);
                        }}
                        chatHistoryLoading={chatHistoryLoading}
                        renameOrDeleteLoading={renameOrDeleteLoading}
                        onRename={onChatHistoryConversationTitleRename}
                        onDelete={onChatHistoryConversationDelete}
                    />
                )}
                {!showWelcomeScreenState &&
                    !showLearnMoreAboutCreditsScreenState &&
                    !showBestPracticesScreenState &&
                    !showPromptLibraryScreenState &&
                    !showChatHistoryScreenState && (
                        <>
                            <StyledHeader>
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" gap={1} mr={2}>
                                        <IconButton
                                            size="small"
                                            icon={<AddOutlinedIcon />}
                                            tooltip="Start new chat"
                                            disabled={!chat.length || asking || answering}
                                            onClick={onReset}
                                            data-pendo={'newChatStarted'}
                                            aria-label="Start new chat button"
                                        />
                                        <IconButton
                                            size="small"
                                            icon={<HistoryOutlinedIcon />}
                                            tooltip="Chat history"
                                            onClick={() => {
                                                onChatHistoryOpen();
                                                setShowChatHistoryScreenState(true);
                                            }}
                                            data-pendo={'chatHistoryAccessed'}
                                            aria-label="Chat history button"
                                            tooltipPlacement="bottom"
                                            disabled={asking || answering}
                                        />
                                    </Box>
                                    <Typography variant="h6">AI assistant</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CreditsChip
                                        availableCredits={availableCredits}
                                        onClick={() => setShowLearnMoreAboutCreditsScreenState(true)}
                                    />
                                    <IconButton
                                        size="small"
                                        icon={<TipsAndUpdatesOutlinedIcon />}
                                        tooltip="Prompt library"
                                        onClick={() => handleShowPromptLibraryScreenState(true)}
                                        aria-label="Prompt library button"
                                    />
                                    <IconButton
                                        size="small"
                                        icon={<MoreVertOutlinedIcon />}
                                        tooltip="More actions"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setAnchor(anchor ? null : event.currentTarget);
                                        }}
                                        aria-label="More actions button"
                                        tooltipPlacement="bottom"
                                    />
                                    <MoreActionsContextMenu
                                        anchor={anchor}
                                        setAnchor={setAnchor}
                                        setShowLearnMoreAboutCreditsScreenState={
                                            setShowLearnMoreAboutCreditsScreenState
                                        }
                                        setShowBestPracticesScreenState={setShowBestPracticesScreenState}
                                    />
                                    <IconButton
                                        size="small"
                                        icon={<CloseIcon />}
                                        tooltip="Close"
                                        onClick={onClose}
                                        aria-label="Close button"
                                        tooltipPlacement="top"
                                    />
                                </Box>
                            </StyledHeader>
                            <Divider />
                            <StyledConversationContainer className="no-drag">
                                <StyledConversation>
                                    {chatContent}
                                    {aiGeneratedAnswer && (
                                        <Box display="flex" width="100%" gap={1.5} mb={2} key={'message' + chat.length}>
                                            <StyledBoxCircleAssistantRedIcon>
                                                <AssistantOutlinedIcon />
                                            </StyledBoxCircleAssistantRedIcon>
                                            <StyledBotMessage ref={conversationRef}>
                                                <ReactMarkdown
                                                    components={{
                                                        code: (props) => <CodeBlock {...props} onCopy={onCopy} />,
                                                        a: (props) => <Link {...props} target="_blank" />,
                                                    }}
                                                >
                                                    {aiGeneratedAnswer}
                                                </ReactMarkdown>
                                            </StyledBotMessage>
                                        </Box>
                                    )}
                                    {!aiGeneratedAnswer && !asking && answering && (
                                        <Box display="flex" width="100%" gap={1.5} mb={2}>
                                            <StyledBoxCircleAssistantRedIcon>
                                                <AssistantOutlinedIcon />
                                            </StyledBoxCircleAssistantRedIcon>
                                            <StyledBotMessage>{answeringAnimation}</StyledBotMessage>
                                        </Box>
                                    )}
                                </StyledConversation>
                            </StyledConversationContainer>
                            <Divider />
                            <StyledFooter>
                                <PromptInput
                                    ref={inputRef}
                                    prompt={prompt}
                                    onSend={handleSend}
                                    busy={busy}
                                    setPrompt={setPrompt}
                                    onStopAiAssistanceResponse={onStopAiAssistanceResponse}
                                />
                                <Typography variant="body2" color="text.secondary" mt={1}>
                                    AI messages might be wrong. Check important facts yourself.
                                </Typography>
                            </StyledFooter>
                        </>
                    )}
            </StyledDialog>
        </Draggable>
    );
};
