import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { APP } from '@avst-stitch/repository-lib/constants';
import { pascalCase } from 'pascal-case';

interface MondayEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    mondayUrl?: string;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const MondayEventListenerSetupDialog: React.FC<MondayEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    mondayUrl,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.MONDAY.NAME;

    const whenAColumnChanges = 'When a column changes';
    const WHEN_A_COLUMN_CHANGES_EVENTS = [
        'When a person changes',
        'When a date changes',
        'When a status changes',
        whenAColumnChanges,
    ];
    const isWhenAColumnChangesEvent = WHEN_A_COLUMN_CHANGES_EVENTS.includes(eventTypeName);
    const columnName = isWhenAColumnChangesEvent ? eventTypeName.split(' ')[2] : undefined;

    const listContent = [
        mondayUrl ? (
            <Typography>
                Visit your {appName}{' '}
                <Link target="_blank" href={mondayUrl}>
                    account
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_MONDAY.COM_ACCOUNT] URL in your browser.</Typography>
        ),
        <Typography>Open the board you wish to setup the event listener for.</Typography>,
        <Typography>
            On the top right corner, click on <strong>Integrate</strong>.
        </Typography>,
        <Typography>
            Type in <strong>webhooks</strong> in the search bar and select <strong>webhooks</strong>.
        </Typography>,
        <Typography>
            Select ONLY the following event to listen to:{' '}
            <strong>{isWhenAColumnChangesEvent ? whenAColumnChanges : eventTypeName}, send a webhook</strong> and click{' '}
            <strong>Add to board</strong>.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>Webhook URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'monday.com-webhook-url'
        ),
        <Typography>
            Click <strong>Connect</strong>. At this point, {appName} will test the connection to this webhook url.
        </Typography>,
        ...(isWhenAColumnChangesEvent && columnName
            ? [
                  <Typography>
                      If successful, the selected event will appear on the screen. Click on the{' '}
                      <strong>a column</strong> dropdown and select{' '}
                      <strong>
                          {eventTypeName === whenAColumnChanges ? 'a column on the board' : pascalCase(columnName)}
                      </strong>
                      .
                  </Typography>,
                  <Typography>
                      To complete the setup, click on the <strong>Add to board</strong> button on the bottom right
                      corner of the screen.
                  </Typography>,
              ]
            : [
                  <Typography>
                      If successful, an <strong>Add to board</strong> button will appear on the bottom right corner of
                      the screen. Click on this to complete the setup.
                  </Typography>,
              ]),
        <Typography>
            (<strong>Optional</strong>) After webhook creation, consider adding a description to be able to distinguish
            the webhook you just created.
        </Typography>,
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} board to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="monday.com" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
