import { useObservableState } from 'observable-hooks';
import {
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideReviewStepAction$,
    selectedWorkspaceSetupGuideConnections$,
    selectedWorkspaceSetupGuideStep$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../../store/workspace';
import { Language } from '../../../components/workspace-dialogs/language-selector';
import { SetupGuideReviewScreen } from '../../../components/setup-guide/review-screen/SetupGuideReviewScreen';
import { getAppInstances, getPreviousEnabledStep } from '../../../utils/setupGuide';
import { loggedInUserConnections$ } from '../../../store/connections';
import {
    toggleEnvironmentVariableExpandAction$,
    unsavedEnvironmentVariables$,
} from '../../../store/workspace/environment-variable';
import { apps$ } from '../../../store/apps';

export const SetupGuideReviewScreenContainer: React.FC = () => {
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const selectedConnections = useObservableState(selectedWorkspaceSetupGuideConnections$);
    const errors = useObservableState(workspaceSetupGuideStepError$);
    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const { apiHandlers, eventListeners, scheduledTriggers, scripts } = useObservableState(selectedWorkspaceResources$);
    const variables = useObservableState(unsavedEnvironmentVariables$);
    const connections = useObservableState(loggedInUserConnections$);
    const apps = useObservableState(apps$);

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    const appInstances = getAppInstances(apiHandlers, eventListeners, connections, selectedConnections, apps);

    return (
        <SetupGuideReviewScreen
            apiHandlers={apiHandlers.map((ah) => ({
                appName: ah.appName,
                group: ah.group,
                path: ah.path,
                uid: ah.path ?? '',
            }))}
            appInstances={appInstances}
            errors={errors}
            environmentVariables={variables}
            eventListeners={eventListeners.map((el) => ({
                appName: el.appName,
                group: el.group,
                scriptName: el.script?.name,
                uid: el.uid ?? '',
            }))}
            language={selectedWorkspace?.language as Language}
            nextStepName={nextStep?.title}
            saving={saving}
            scheduledTriggers={scheduledTriggers.map((st) => ({
                cron: st.cronExpression,
                scriptName: st.script?.name,
                uid: st.uid ?? '',
            }))}
            scripts={scripts.map((s) => ({ path: s.name, uid: s.uid }))}
            steps={setupGuideSteps}
            workspace={{
                apps: {
                    incoming: selectedWorkspace?.incomingApps ?? [],
                    outgoing: selectedWorkspace?.outgoingApps ?? [],
                },
                name: selectedWorkspace?.name ?? '',
            }}
            workspaceLocked={!!selectedWorkspace?.locked}
            onContinue={() =>
                saveWorkspaceSetupGuideReviewStepAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
            onNavigateToStep={(stepName) => navigateToWorkspaceSetupGuideStepAction$.next(stepName)}
            onToggleVariableExpand={(event) => toggleEnvironmentVariableExpandAction$.next(event)}
        />
    );
};
