import { styled } from '@mui/material';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface IconToggleButtonProps extends ToggleButtonProps {
    'aria-label': string;
    'data-testid'?: string;
    icon: JSX.Element;
    tooltip: string;
    tooltipPlacement?: TooltipProps['placement'];
}

export interface IconToggleButtonGroupProps extends ToggleButtonGroupProps {
    buttons: IconToggleButtonProps[];
    size?: 'small' | 'medium';
}

const calculateGridTemplateColumns = (length: number): string => {
    return new Array(length).fill('1fr').join(' ');
};

export const StyledIconToggleButtonGroup = styled(ToggleButtonGroup, {
    shouldForwardProp: (prop) => prop !== 'buttonLength',
})<{ buttonLength: number; fullWidth: boolean }>(({ buttonLength, fullWidth }) => ({
    alignItems: 'center',
    width: fullWidth ? '100%' : 'auto',
    display: 'inline-grid',
    gridTemplateColumns: calculateGridTemplateColumns(buttonLength),
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        marginLeft: 0, // Overwrite native styles to preserve border
    },
}));

export const StyledIconToggleButton = styled(ToggleButton, {
    shouldForwardProp: (prop) => prop !== 'buttonSize',
})<{
    buttonSize: 'small' | 'medium';
}>(({ buttonSize, theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    height: buttonSize === 'small' ? 36 : 40,
    padding: buttonSize === 'small' ? theme.spacing(1) : theme.spacing(1.5),
    width: buttonSize === 'small' ? 36 : 40,
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.action.focus,
        },
    },
    '&.Mui-disabled.Mui-selected': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled,
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const IconToggleButtonGroup: React.FC<IconToggleButtonGroupProps> = ({
    buttons,
    fullWidth = false,
    size = 'medium',
    ...props
}) => {
    const toggleButtons = buttons.map((b, i) =>
        b.disabled ? (
            <StyledIconToggleButton buttonSize={size} key={`toggle-button-${i}`} {...b}>
                {b.icon}
            </StyledIconToggleButton>
        ) : (
            <Tooltip key={`toggle-button-${i}`} placement={b.tooltipPlacement ?? 'top'} title={b.tooltip}>
                <StyledIconToggleButton buttonSize={size} {...b}>
                    {b.icon}
                </StyledIconToggleButton>
            </Tooltip>
        )
    );

    return (
        <StyledIconToggleButtonGroup buttonLength={buttons.length} fullWidth={fullWidth} size={size} {...props}>
            {toggleButtons}
        </StyledIconToggleButtonGroup>
    );
};
