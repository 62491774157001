import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { AuthMode, AWSConnection } from './AWSAppConfigureDialog';

export enum AWSWizardTemporaryAuthStage {
    SELECT_AUTHORIZATION_MODE,
    CONFIGURE_ROLE,
    INPUT_ROLE_DETAILS,
}

export enum AWSWizardPermanentAuthStage {
    SELECT_AUTHORIZATION_MODE,
    CREATE_USER,
    GENERATE_CREDENTIALS,
    INPUT_CREDENTIALS,
}

export interface AWSWizardStepsProps {
    readonly stage: AWSWizardTemporaryAuthStage | AWSWizardPermanentAuthStage;
    readonly authMode: 'TEMPORARY' | 'PERMANENT';
}

export const AWSWizardSteps: React.FC<AWSWizardStepsProps> = ({ stage, authMode = 'TEMPORARY' }) => {
    return authMode === 'PERMANENT' ? (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AWSWizardPermanentAuthStage.SELECT_AUTHORIZATION_MODE}>
                <StepLabel>Select Mode</StepLabel>
            </Step>
            <Step key={AWSWizardPermanentAuthStage.CREATE_USER}>
                <StepLabel>Create user</StepLabel>
            </Step>
            <Step key={AWSWizardPermanentAuthStage.GENERATE_CREDENTIALS}>
                <StepLabel>Generate access key and secret</StepLabel>
            </Step>
            <Step key={AWSWizardPermanentAuthStage.INPUT_CREDENTIALS}>
                <StepLabel>Enter access key and secret</StepLabel>
            </Step>
        </Stepper>
    ) : (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AWSWizardTemporaryAuthStage.SELECT_AUTHORIZATION_MODE}>
                <StepLabel>Select Mode</StepLabel>
            </Step>
            <Step key={AWSWizardTemporaryAuthStage.CONFIGURE_ROLE}>
                <StepLabel>Configure role</StepLabel>
            </Step>
            <Step key={AWSWizardTemporaryAuthStage.INPUT_ROLE_DETAILS}>
                <StepLabel>Input role details</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    AWSConnection & {
        stage: AWSWizardTemporaryAuthStage | AWSWizardPermanentAuthStage;
        setStage: (stage: AWSWizardTemporaryAuthStage | AWSWizardPermanentAuthStage) => void;
        authMode: AuthMode;
        setAuthMode: (newMode: AuthMode) => void;
    }
>;
