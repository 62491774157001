import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { StyledResourceInfo, StyledResourceListItemButton } from './ResourceTreeComponents';
import { MouseEvent } from 'react';

interface ResourceTreeScheduledTriggerProps {
    cronDescription?: string;
    cronExpression?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    disabled?: boolean;
    inactive?: boolean;
    needsSetup?: boolean;
    readOnlyMode?: boolean;
    scriptName?: string;
    selectedNode?: string;
    uid: string;
    onDelete(uid: string): void;
}

export const ResourceTreeScheduledTrigger: React.FC<ResourceTreeScheduledTriggerProps> = ({
    cronDescription,
    cronExpression,
    deleting = false,
    deployedEnvironmentMode = false,
    inactive = false,
    needsSetup = false,
    readOnlyMode = false,
    scriptName,
    selectedNode,
    uid,
    disabled = false,
    onDelete,
}) => {
    const selected = selectedNode === uid;
    const showSetupWarning = needsSetup && !readOnlyMode;
    const showInactiveWarning = inactive && !needsSetup && !readOnlyMode;
    const showDisabledWarning = disabled && !inactive && !needsSetup && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    const handleDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onDelete(uid);
    };

    return (
        <StyledResourceListItemButton
            key={uid}
            endIcons={
                canDelete
                    ? [
                          <IconButton
                              aria-label="Delete scheduled trigger"
                              busy={deleting}
                              icon={<DeleteOutlineIcon />}
                              size="small"
                              tooltip="Delete scheduled trigger"
                              onClick={handleDelete}
                          />,
                      ]
                    : undefined
            }
            label={
                <Tooltip title={cronDescription}>
                    <Typography>{cronExpression || 'New scheduled trigger'}</Typography>
                </Tooltip>
            }
            link={{ href: `./scheduled/${uid}`, type: 'router' }}
            selected={selected}
            startIcon={
                <Tooltip title="Scheduled trigger">
                    <TimerOutlinedIcon />
                </Tooltip>
            }
        >
            {scriptName && (
                <Tooltip title={scriptName} placement="top-start">
                    <StyledResourceInfo>
                        <CodeIcon />
                        <Typography>
                            Script:{' '}
                            <Typography color="primary.main" display="inline">
                                {scriptName}
                            </Typography>
                        </Typography>
                    </StyledResourceInfo>
                </Tooltip>
            )}
            {showSetupWarning && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Complete scheduled trigger setup</em>
                    </Typography>
                </StyledResourceInfo>
            )}
            {showInactiveWarning && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Inactive</em>
                    </Typography>
                </StyledResourceInfo>
            )}
            {showDisabledWarning && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Disabled</em>
                    </Typography>
                </StyledResourceInfo>
            )}
        </StyledResourceListItemButton>
    );
};
