import { PropsWithChildren, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../common/buttons/Button';
import { IconButton } from '../../common/buttons/IconButton';
import { StyledBorderBox, StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';

export interface SetupGuideReviewScreenItemProps {
    itemCount: number;
    itemName: string;
    onEdit: () => void;
}

const StyledItemContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
}));

export const SetupGuideReviewScreenItem: React.FC<PropsWithChildren<SetupGuideReviewScreenItemProps>> = ({
    itemCount,
    itemName,
    onEdit,
    children,
}) => {
    const [open, setOpen] = useState(true);

    return (
        <StyledBorderBox maxWidth={688} width="100%">
            <StyledBorderBoxHeader>
                <Typography variant="h6">{`${itemName} (${itemCount})`}</Typography>
                <Box display="flex" gap={1}>
                    <Button startIcon={<CreateOutlinedIcon />} size="small" variant="outlined" onClick={onEdit}>
                        Edit
                    </Button>
                    <IconButton
                        aria-label={open ? 'Collapse section' : 'Expand section'}
                        icon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        tooltip={open ? 'Collapse section' : 'Expand section'}
                        onClick={() => setOpen(!open)}
                    />
                </Box>
            </StyledBorderBoxHeader>
            {open && (
                <>
                    <Divider /> <StyledItemContent>{children}</StyledItemContent>
                </>
            )}
        </StyledBorderBox>
    );
};
