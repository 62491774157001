import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { IconCircle } from '../../common/IconCircle';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    StyledBorderBox,
    StyledBorderBoxHeader,
    StyledBorderBoxTitleContainer,
} from '../../layout/BorderBoxComponents';
import { StyledStepContainer } from '../../setup-guide/SetupGuideComponents';
import { SetupGuideConnection } from '../../setup-guide/types';
import { TabContext } from '../../common/TabContext';
import {
    EventListenerPayload,
    EventListenerPayloadWithContent,
    EventListenerPayloads,
    SaveEventListenerPayloadEvent,
} from './event-listener-payloads';
import { EventListenerDetails, OpenSetupInstructionsEvent, SaveEventListenerEvent } from './EventListenerDetails';

type EventListenerTab = 'details' | 'test-event-payload';

interface EventListenerTabbedDetailsProps {
    appName: string;
    templateMode?: boolean;
    templatePreviewMode?: boolean;
    details: {
        connectionRequired?: boolean;
        createdConnectionUid?: string;
        connections?: SetupGuideConnection[];
        disabled?: boolean;
        errors?: string;
        eventTypes?: { name: string; uid: string; category?: string | null }[];
        saving?: boolean;
        scripts?: { name: string; uid: string }[];
        selectedConnectionUid?: string;
        selectedEventTypeUid?: string;
        selectedScriptUid?: string;
        uid: string;
        urlId?: string;
        webhookSetup?: boolean;
        workspaceLocked?: boolean;
        existingEvent?: boolean;
        hasImplicitlySharedConnectionAttached?: boolean;
        environmentDeployed?: boolean;
        warnings?: string[];
        onNewConnection?: () => void;
        onCancel?: () => void;
        onOpenSetupInstructions?: (event: OpenSetupInstructionsEvent) => void;
        onSave?: (event: SaveEventListenerEvent) => void;
    };
    testPayload?: {
        activePayloadUid?: string;
        content: string;
        errors?: string;
        eventListenerPayloads?: EventListenerPayload[];
        hasUnsavedChanges?: boolean;
        loadingContent?: boolean;
        requiresSetup?: boolean;
        saving?: boolean;
        selectedPayloadUid: string;
        onCancel(): void;
        onContentChange?(payload: EventListenerPayloadWithContent): void;
        onCreateNewPayload(): void;
        onSave(payload: SaveEventListenerPayloadEvent): void;
        onSelectTestPayload?(uid: string): void;
    };
}

const StyledTabContextBox = styled(Box)(({ theme }) => ({
    height: 'calc(100% - 52px)',
    '& .MuiTabs-root': {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    '& .MuiTabPanel-root': {
        height: '100%',
    },
}));

const StyledStepEventListenerContainer = styled(StyledStepContainer, {
    shouldForwardProp: (prop) => prop !== 'isTestPayloadView',
})<{ isTestPayloadView?: boolean }>(({ theme, isTestPayloadView }) => ({
    maxWidth: isTestPayloadView ? '100%' : undefined,
    paddingRight: isTestPayloadView ? theme.spacing(3) : undefined,
    paddingLeft: isTestPayloadView ? theme.spacing(3) : undefined,
}));

export const EventListenerTabbedDetails: React.FC<EventListenerTabbedDetailsProps> = ({
    appName,
    templateMode = false,
    templatePreviewMode = false,
    details,
    testPayload,
}) => {
    const [selectedEventListenerTab, setSelectedEventListenerTab] = useState<EventListenerTab>('details');
    const isTestPayloadView = selectedEventListenerTab === 'test-event-payload';

    return (
        <StyledStepEventListenerContainer isTestPayloadView={isTestPayloadView}>
            <StyledBorderBox
                marginTop={templateMode ? undefined : 4}
                height={!templateMode && isTestPayloadView ? '100%' : undefined}
            >
                <StyledBorderBoxHeader>
                    <StyledBorderBoxTitleContainer>
                        <IconCircle icon={<ProductIcon name={appName} />} size="small" />
                        <Typography variant="h6" component="h4">
                            {`Configure ${appName} event listener`}
                        </Typography>
                    </StyledBorderBoxTitleContainer>
                </StyledBorderBoxHeader>
                <Divider />
                {!templatePreviewMode && !templateMode && testPayload ? (
                    <StyledTabContextBox>
                        <TabContext
                            tabs={[
                                {
                                    content: (
                                        <EventListenerDetails
                                            appName={appName}
                                            templateMode={templateMode}
                                            templatePreviewMode={templatePreviewMode}
                                            testPayload={{ requiresSetup: testPayload?.requiresSetup }}
                                            {...details}
                                        />
                                    ),
                                    label: 'Event listener details',
                                    value: 'details',
                                },
                                {
                                    content: (
                                        <EventListenerPayloads
                                            {...testPayload}
                                            workspaceLocked={details.workspaceLocked || details.environmentDeployed}
                                        />
                                    ),
                                    label: 'Test event payload',
                                    value: 'test-event-payload',
                                },
                            ]}
                            value={selectedEventListenerTab}
                            setTabValue={(value) => setSelectedEventListenerTab(value as EventListenerTab)}
                        />
                    </StyledTabContextBox>
                ) : (
                    <EventListenerDetails
                        appName={appName}
                        templateMode={templateMode}
                        templatePreviewMode={templatePreviewMode}
                        testPayload={{ requiresSetup: testPayload?.requiresSetup }}
                        {...details}
                    />
                )}
            </StyledBorderBox>
        </StyledStepEventListenerContainer>
    );
};
