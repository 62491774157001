import { useObservableState, useSubscription } from 'observable-hooks';
import { selectedWorkspace$, selectedWorkspaceResources$ } from '../../../store/workspace';
import { useNavigate } from 'react-location';
import {
    scheduledTriggerSavedAction$,
    saveScheduledTriggerAction$,
    savingSelectedScheduledTrigger$,
    selectedScheduledTriggerDetails$,
    selectedScheduledTriggerErrors$,
    scheduleBuilderDialogOpen$,
    unsavedCronExpression$,
    cronHasUnsavedChanges$,
} from '../../../store/workspace/scheduled-trigger';
import { navigateToWorkspaceSetupGuideTriggerScreenAction$ } from '../../../store/setup-guide';
import { ScheduledTriggerDetails } from '../../../components/workspace-resources/scheduled-triggers/ScheduledTriggerDetails';

export const SetupGuideEditScheduledTriggerScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const { scripts } = useObservableState(selectedWorkspaceResources$);
    const details = useObservableState(selectedScheduledTriggerDetails$);
    const selectedScheduleBuilderCron = useObservableState(unsavedCronExpression$);
    const cronHasUnsavedChanges = useObservableState(cronHasUnsavedChanges$);
    const errors = useObservableState(selectedScheduledTriggerErrors$);
    const saving = useObservableState(savingSelectedScheduledTrigger$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    useSubscription(scheduledTriggerSavedAction$, () => {
        navigateToWorkspaceSetupGuideTriggerScreenAction$.next('schedule');
    });

    useSubscription(navigateToWorkspaceSetupGuideTriggerScreenAction$, (screen) => {
        if (screen === 'schedule') {
            navigate({ to: '../../' });
        }
    });

    return (
        <ScheduledTriggerDetails
            cron={selectedScheduleBuilderCron}
            cronHasUnsavedChanges={cronHasUnsavedChanges}
            disabled={details?.disabled}
            errors={errors}
            nextScheduledDates={details?.nextScheduledDates.map((t) => new Date(t)) ?? []}
            saving={saving}
            scripts={scripts}
            selectedScriptUid={details?.scriptUid}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            workspaceLocked={!!selectedWorkspace?.locked}
            uid={details?.uid ?? ''}
            onCancel={() => navigateToWorkspaceSetupGuideTriggerScreenAction$.next('schedule')}
            onChangeCron={(cron) => unsavedCronExpression$.next(cron)}
            onOpenScheduleBuilder={() => scheduleBuilderDialogOpen$.next(true)}
            onSave={(event) => saveScheduledTriggerAction$.next(event)}
        />
    );
};
