import { useState } from 'react';
import { useObservableState, useSubscription } from 'observable-hooks';
import DragIndicatorIcon from '@mui/icons-material/DragIndicatorOutlined';
import { SetupGuidePage } from '../../components/setup-guide/SetupGuidePage';
import {
    navigateToAdvancedViewAction$,
    navigateToWorkspaceSetupGuideCongratulationsScreenAction$,
    navigateToWorkspaceSetupGuideStepAction$,
    exitWorkspaceSetupGuideAction$,
    selectedWorkspaceSetupGuideStep$,
    workspaceSetupGuideModeUpdatedAction$,
    workspaceSetupGuideReadmeFileOpen$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideExitedAction$,
} from '../../store/setup-guide';
import {
    readmeFileHasUnsavedChanges$,
    revertReadmeFileChangesAction$,
    saveReadmeFileAction$,
    selectedReadmeFileContentChangedAction$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../store/workspace/readme';
import { selectedEnvironmentUid$, selectedWorkspace$, selectedWorkspaceUid$ } from '../../store/workspace';
import { useNavigate } from 'react-location';
import { getBasePath } from '../../utils/path';
import { getSetupGuideStepPath } from '../../utils/setupGuide';
import {
    assumeWorkspaceEditControlAction$,
    openWorkspacePreviewDialogErrors$,
    savingExistingWorkspace$,
    updateWorkspaceDetailsAction$,
    workspaceEditControlAssumedAction$,
} from '../../store/workspaces';
import {
    StyledReflexContainer,
    StyledReflexElement,
    StyledReflexFooter,
} from '../../components/reflex/ReflexComponents';
import { ConsoleContainer } from '../workspace-layouts/ConsoleContainer';
import { HandlerProps, ReflexSplitter } from 'react-reflex';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';
import { consoleFullScreen$ } from '../../store/workspace/console';
import { getReadmeKey } from '../../utils/readme';

const consoleFlexGrowKey = 'stitchSetupGuideConsoleHeight';
const consoleDefaultFlexGrow = 0.2;

export const SetupGuideContainer: React.FC = () => {
    const navigate = useNavigate();

    const [consoleFlexGrow, setConsoleFlexGrow] = useState(
        readLocalStorage(consoleFlexGrowKey, consoleDefaultFlexGrow)
    );

    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const saving = useObservableState(savingExistingWorkspace$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const readmeOpen = useObservableState(workspaceSetupGuideReadmeFileOpen$);
    const unsavedReadmeDetails = useObservableState(unsavedReadmeFileDetails$);
    const readmeHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);
    const isConsoleFullScreen = useObservableState(consoleFullScreen$);

    const readmeKey = getReadmeKey(selectedReadmeFileUid ?? '', selectedEnvironmentUid ?? '');

    const title = selectedStep?.title || 'Build your integration.';
    const subTitle = selectedStep?.description || 'Follow these steps to set up your integration.';

    const splitPath = window.location.pathname.split('/').filter((p) => !!p);
    const overview = splitPath[splitPath.length - 1] === selectedWorkspaceUid;

    useSubscription(navigateToWorkspaceSetupGuideStepAction$, (stepName) => {
        if (stepName) {
            const stepPath = getSetupGuideStepPath(stepName);
            navigate({ to: `${getBasePath()}guide/workspace/${selectedWorkspaceUid}/step/${stepPath}` });
        } else navigate({ to: `${getBasePath()}guide/workspace/${selectedWorkspaceUid}` });
    });

    useSubscription(navigateToWorkspaceSetupGuideCongratulationsScreenAction$, () => {
        navigate({ to: `${getBasePath()}guide/complete/workspace/${selectedWorkspaceUid}` });
    });

    useSubscription(workspaceSetupGuideModeUpdatedAction$, (setupGuideType) => {
        if (!setupGuideType) {
            navigate({ to: `${getBasePath()}workspace/${selectedWorkspaceUid}/environment/${selectedEnvironmentUid}` });
        }
    });

    useSubscription(workspaceSetupGuideExitedAction$, () => {
        navigate({ to: `${getBasePath()}workspaces` });
    });

    useSubscription(openWorkspacePreviewDialogErrors$, (error) => {
        if (error) {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: error,
            });
        }
    });

    useSubscription(workspaceEditControlAssumedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: getBasePath() });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}guide/workspace/${selectedWorkspaceUid}`,
                }),
            100
        );
    });

    const handleReflexResize = (e: HandlerProps, key: string): void => {
        if (e.domElement instanceof Element) {
            const flexGrowCurrent = +window.getComputedStyle(e.domElement).flexGrow;
            saveLocalStorage(key, flexGrowCurrent);
            setConsoleFlexGrow(flexGrowCurrent);
        }
    };

    return (
        <StyledReflexContainer sx={{ overflow: 'hidden' }}>
            {!isConsoleFullScreen && (
                <StyledReflexElement>
                    <SetupGuidePage
                        fullScreenReadmeLink={`${getBasePath()}fullscreen-readme/${selectedReadmeFileUid}/workspace/${selectedWorkspaceUid}/environment/${selectedEnvironmentUid}`}
                        readmeContent={unsavedReadmeDetails[readmeKey]?.content ?? ''}
                        readmeOpen={readmeOpen}
                        readmeHasUnsavedChanges={readmeHasUnsavedChanges[selectedReadmeFileUid ?? '']}
                        overview={overview}
                        selectedStepUid={selectedStep?.uid}
                        steps={setupGuideSteps}
                        subTitle={subTitle}
                        templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
                        title={title}
                        workspaceLocked={!!selectedWorkspace?.locked}
                        workspaceName={selectedWorkspace?.name ?? ''}
                        workspaceNameSaving={saving}
                        onChangeReadmeContent={(content) => selectedReadmeFileContentChangedAction$.next(content)}
                        onChangeWorkspaceName={(name) =>
                            updateWorkspaceDetailsAction$.next({
                                description: selectedWorkspace?.description,
                                name,
                                organizationUid: selectedWorkspace?.organization?.uid ?? '',
                                template: false,
                                uid: selectedWorkspace?.uid ?? '',
                                useCaseUids: [],
                            })
                        }
                        onNavigateToAdvancedView={() =>
                            navigateToAdvancedViewAction$.next({ workspaceUid: selectedWorkspaceUid ?? '' })
                        }
                        onNavigateToOverview={() => navigateToWorkspaceSetupGuideStepAction$.next(undefined)}
                        onRevertReadmeChanges={() => revertReadmeFileChangesAction$.next()}
                        onExitSetupGuide={() => exitWorkspaceSetupGuideAction$.next()}
                        onSaveReadme={() => saveReadmeFileAction$.next()}
                        onSelectStep={(stepName) => navigateToWorkspaceSetupGuideStepAction$.next(stepName)}
                        onToggleReadme={() => workspaceSetupGuideReadmeFileOpen$.next(!readmeOpen)}
                        onUnlockWorkspace={() => assumeWorkspaceEditControlAction$.next(false)}
                    />
                </StyledReflexElement>
            )}
            {selectedStep?.name === 'RUN' && !isConsoleFullScreen && (
                <ReflexSplitter>
                    <DragIndicatorIcon />
                </ReflexSplitter>
            )}
            {selectedStep?.name === 'RUN' && ( // Need this under separate clause or reflex breaks
                <StyledReflexFooter
                    onStopResize={(e) => handleReflexResize(e, consoleFlexGrowKey)}
                    className="footer"
                    flex={consoleFlexGrow}
                >
                    <ConsoleContainer />
                </StyledReflexFooter>
            )}
        </StyledReflexContainer>
    );
};
