import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export interface CustomAlertProps extends AlertProps {
    closeable?: boolean;
    scrollIntoViewDisabled?: boolean;
    text?: string | JSX.Element;
    onClose?(): void;
}

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    width: '100%',
    '& .MuiAlert-message': {
        flexGrow: 1,
    },
    '& .MuiAlert-action': {
        marginLeft: theme.spacing(1),
        marginRight: 0,
        padding: 0,
    },
}));

const StyledAlertMessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
}));

const StyledAlertTitle = styled(AlertTitle)(() => ({
    margin: 0,
}));

export const Alert: React.FC<CustomAlertProps> = ({
    action,
    closeable,
    icon,
    scrollIntoViewDisabled = false,
    severity,
    text,
    title,
    onClose,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (ref && ref.current && !scrollIntoViewDisabled) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }
    }, []);
    return open ? (
        <StyledAlert
            ref={ref}
            {...props}
            action={action}
            icon={icon} // overwrites default icon if specified
            iconMapping={{
                success: <CheckCircleOutlineIcon />,
            }}
            severity={severity}
            onClose={
                severity === 'info' || closeable
                    ? () => {
                          setOpen(false);
                          onClose?.();
                      }
                    : undefined
            }
        >
            <StyledAlertMessageContainer>
                <StyledAlertTitle>{title}</StyledAlertTitle>
                {text}
            </StyledAlertMessageContainer>
        </StyledAlert>
    ) : null;
};
