import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const StyledSectionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    marginBottom: theme.spacing(3),
}));

export const StyledSectionHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
}));

export const StyledSectionMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
}));

export const StyledQuickActionsBlockContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    padding: theme.spacing(2),
    width: 325,
    height: 331,
}));

export const StyledQuickActionsBlockImageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 293,
    height: 162,
}));

export const StyledBadgeContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
}));

export const StyledOrganizationName = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
}));

export const StyledUsageBlockContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: 260,
    height: 135,
}));

export const StyledUsageBlockSubtitleContainer = styled(Box)(({ theme }) => ({
    alignContent: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 0, 1, 0),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    '& .MuiTypography-root': {
        lineHeight: 1.5,
    },
}));

export const StyledUsageDataText = styled(Typography)({
    fontSize: 16,
    lineHeight: 1.2,
});
