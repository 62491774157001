import {
    getMyOrganizations as getMyOrganizationsRpc,
    deleteOrganization as deleteOrganizationRpc,
} from '@avst-stitch/repository-lib/lib';
import { Response as UserOrganizations } from '@avst-stitch/repository-lib/lib/rpcs/getMyOrganizations';
import {
    Response as CreatedOrganizationV2,
    Request as CreateOrganizationRequestV2,
    createOrganizationV2 as createOrganizationV2Rpc,
} from '@avst-stitch/repository-lib/lib/rpcs/createOrganizationV2';
import {
    Response as CreatedOrganizationV3,
    Request as CreateOrganizationRequestV3,
    createOrganizationV3 as createOrganizationV3Rpc,
} from '@avst-stitch/repository-lib/lib/rpcs/createOrganizationV3';
import { repositoryInvoker } from '../utils/repository';
export type {
    UserOrganizations,
    CreatedOrganizationV2,
    CreateOrganizationRequestV2,
    CreatedOrganizationV3,
    CreateOrganizationRequestV3,
};

export const getLoggedInUserOrganizations = async (): Promise<UserOrganizations> => {
    return await getMyOrganizationsRpc(repositoryInvoker);
};

export const deleteOrganization = async (uid: string): Promise<void> => {
    return await deleteOrganizationRpc(repositoryInvoker, { uid });
};

export const createOrganizationV2 = async (request: CreateOrganizationRequestV2): Promise<CreatedOrganizationV2> => {
    return await createOrganizationV2Rpc(repositoryInvoker, request);
};

export const createOrganizationV3 = async (request: CreateOrganizationRequestV3): Promise<CreatedOrganizationV2> => {
    return await createOrganizationV3Rpc(repositoryInvoker, request);
};
