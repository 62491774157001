import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import MuiTable, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { ButtonLinkProps, ForwardedLinkProps } from './buttons/Button';
import { RouterLink } from './RouterLink';

type Selectable = 'single' | 'multiple';

export type SortOrder = 'asc' | 'desc';

export interface SortDetails {
    by: string;
    order: SortOrder;
}

interface TableColumn {
    id: string;
    sortable?: boolean;
    title?: string;
}

interface TableItem {
    columns: Record<string, string | JSX.Element>;
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'data-testid'?: string;
    id: string;
    link?: ButtonLinkProps;
}

interface CustomTableProps extends TableProps {
    columns: TableColumn[];
    rows: TableItem[];
    selectable?: Selectable;
    selectedItems?: string[];
    sortBy?: string;
    sortOrder?: Record<string, SortOrder>;
    useRouter?: boolean;
    onSelectItem?: (id: string) => void;
    onSelectAll?: () => void;
    onSort?: (details: SortDetails) => void;
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& th': { backgroundColor: theme.palette.background.paper },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginLeft: theme.spacing(1), // TODO: Find a better solution
    padding: 0,
    '&:hover': {
        backgroundColor: undefined,
    },
}));

const StyledTableRow = styled(TableRow)<{ selectable?: boolean; selected?: boolean } & ForwardedLinkProps>(
    ({ selectable = false, selected = false, theme }) => ({
        cursor: selectable ? 'pointer' : undefined,
        textDecoration: 'none',
        '&:last-of-type td': { borderBottom: 'none' },
        '&:hover': selectable
            ? selected
                ? {
                      backgroundColor: theme.palette.action.focus,
                  }
                : {
                      backgroundColor: theme.palette.action.hover,
                  }
            : undefined,
        '&:focus': selectable
            ? {
                  backgroundColor: theme.palette.action.focus,
              }
            : undefined,
    })
);

const StyledTableCell = styled(TableCell)(() => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
    '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
    },
}));

export const Table: React.FC<CustomTableProps> = ({
    columns,
    rows,
    selectable,
    selectedItems = [],
    sortBy,
    sortOrder,
    useRouter = true,
    onSelectAll,
    onSelectItem,
    onSort,
}) => {
    const allOptionsSelected = rows.length === selectedItems.length;

    const headCells = columns.map((c) => (
        <StyledTableCell
            key={c.id}
            onClick={() =>
                c.sortable ? onSort?.({ by: c.id, order: sortOrder?.[c.id] === 'desc' ? 'asc' : 'desc' }) : undefined
            }
        >
            {c.sortable ? (
                <StyledTableSortLabel active={sortBy === c.id} direction={sortOrder?.[c.id]}>
                    <Typography variant="subtitle1">{c.title}</Typography>
                </StyledTableSortLabel>
            ) : (
                <Typography variant="subtitle1">{c.title}</Typography>
            )}
        </StyledTableCell>
    ));

    const tableRows = rows.map((r) => {
        const cells = columns.map((c) => (
            <StyledTableCell key={c.id}>
                {typeof r.columns[c.id] === 'string' ? <Typography>{r.columns[c.id]}</Typography> : r.columns[c.id]}
            </StyledTableCell>
        ));

        if (!selectable) {
            return <StyledTableRow data-testid={r['data-testid']}>{cells}</StyledTableRow>;
        } else if (selectable === 'multiple') {
            const isItemSelected = selectedItems.some((si) => si === r.id);

            return (
                <StyledTableRow
                    key={r.id}
                    data-testid={r['data-testid']}
                    selectable
                    selected={isItemSelected}
                    onClick={() => onSelectItem?.(r.id)}
                >
                    <StyledTableCell>
                        <StyledCheckbox checked={isItemSelected} aria-label={isItemSelected ? 'Unselect' : 'Select'} />
                    </StyledTableCell>
                    {cells}
                </StyledTableRow>
            );
        } else if (!r.link || !useRouter) {
            return (
                <StyledTableRow
                    key={r.id}
                    data-testid={r['data-testid']}
                    selectable
                    tabIndex={0}
                    onClick={() => onSelectItem?.(r.id)}
                >
                    {cells}
                </StyledTableRow>
            );
        } else if (r.link.type === 'external') {
            return (
                <StyledTableRow
                    key={r.id}
                    component={Link}
                    data-testid={r['data-testid']}
                    href={r.link.href}
                    selectable
                    tabIndex={0}
                    target={r.link.target}
                >
                    {cells}
                </StyledTableRow>
            );
        } else {
            return (
                <StyledTableRow
                    key={r.id}
                    component={RouterLink}
                    data-testid={r['data-testid']}
                    to={r.link.href}
                    selectable
                    tabIndex={0}
                    target={r.link.target}
                >
                    {cells}
                </StyledTableRow>
            );
        }
    });

    return (
        <StyledTableContainer>
            <MuiTable stickyHeader>
                <StyledTableHead>
                    <TableRow>
                        {selectable === 'multiple' && (
                            <StyledTableCell>
                                <StyledCheckbox
                                    aria-label={allOptionsSelected ? 'Unselect all' : 'Select all'}
                                    indeterminate={selectedItems.length > 0 && !allOptionsSelected}
                                    checked={allOptionsSelected}
                                    onChange={onSelectAll}
                                />
                            </StyledTableCell>
                        )}
                        {headCells}
                    </TableRow>
                </StyledTableHead>
                <TableBody>{tableRows}</TableBody>
            </MuiTable>
        </StyledTableContainer>
    );
};
