import { useObservableState } from 'observable-hooks';
import { ServiceNowEventListenerSetupDialogV2 as ServiceNowEventListenerSetupDialog } from '../../../../components/connections/apps/servicenow/EventListenerSetupDialogV2';
import { configTopic$ } from '../../../../store/config';
import {
    serviceNowEventListenerSetupDialogCloseAction$,
    serviceNowEventListenerSetupDialogDetails$,
    serviceNowEventListenerSetupDialogLoading$,
    serviceNowEventListenerSetupDialogOpen$,
} from '../../../../store/connection/servicenow/setup-event-listener';

export const ServiceNowEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(serviceNowEventListenerSetupDialogLoading$);
    const open = useObservableState(serviceNowEventListenerSetupDialogOpen$);
    const details = useObservableState(serviceNowEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <ServiceNowEventListenerSetupDialog
            loading={loading}
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => {
                serviceNowEventListenerSetupDialogCloseAction$.next();
            }}
        />
    );
};
