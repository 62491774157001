import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { selectedWorkspaceSelectedResource$ } from '../../store/workspace';

export const defaultWorkspaceViewLoader: LoaderFn<LocationGenerics> = async () => {
    selectedWorkspaceSelectedResource$.next({
        resourceType: 'DEFAULT',
    });

    return {};
};
