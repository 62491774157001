import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../layout/BorderBoxComponents';

export const StyledOnboardingBox = styled(StyledBorderBox)(() => ({
    maxWidth: 600,
    width: '100%',
}));

export const StyledOnboardingBoxHeader = styled(StyledBorderBoxHeader)(({ theme }) => ({
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    padding: theme.spacing(3),
    textAlign: 'center',
}));

export const StyledOnboardingBoxContent = styled(StyledBorderBoxContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

export const StyledOnboardingOptionGroup = styled(RadioGroup)(({ theme }) => ({
    gap: theme.spacing(2),
}));

export const StyledOnboardingOption = styled(ButtonBase)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    height: 56,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
}));

export const StyledOnboardingActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    padding: theme.spacing(3),
}));
