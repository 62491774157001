import { styled } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import Link from '@mui/material/Link';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { LoadingSpinner } from '../LoadingSpinner';
import { RouterLink } from '../RouterLink';

export interface ButtonLinkProps {
    href: string;
    target?: string;
    type: 'router' | 'external';
}

export interface ForwardedLinkProps {
    component?: typeof Link | typeof RouterLink;
    href?: string;
    target?: string;
    to?: string;
    useRouter?: boolean;
}

export interface CustomButtonProps extends ButtonProps {
    busy?: boolean;
    link?: ButtonLinkProps;
    tooltip?: string;
    tooltipPlacement?: TooltipProps['placement'];
    useRouter?: boolean;
}

const StyledButton = styled(MuiButton)<ForwardedLinkProps>(() => ({
    whiteSpace: 'nowrap',
}));

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<CustomButtonProps>>(
    (
        {
            busy,
            disabled = false,
            link,
            tooltip,
            tooltipPlacement = 'top',
            variant = 'contained',
            useRouter = true,
            children,
            ...props
        },
        ref
    ) => {
        const disabledButton = (
            <StyledButton disabled variant={variant} {...props} ref={ref}>
                {busy && <LoadingSpinner size="small" />}
                {children}
            </StyledButton>
        );

        const regularButton = (
            <StyledButton variant={variant} {...props} ref={ref}>
                {busy && <LoadingSpinner size="small" />}
                {children}
            </StyledButton>
        );

        const externalLinkButton = (
            <StyledButton href={link?.href} target={link?.target} variant={variant} {...props} ref={ref}>
                {busy && <LoadingSpinner size="small" />}
                {children}
            </StyledButton>
        );

        const routerLinkButton = (
            <StyledButton
                component={RouterLink}
                target={link?.target}
                to={link?.href}
                variant={variant}
                {...props}
                ref={ref}
            >
                {busy && <LoadingSpinner size="small" />}
                {children}
            </StyledButton>
        );

        const button =
            !link || !useRouter ? regularButton : link.type === 'external' ? externalLinkButton : routerLinkButton;

        return busy || disabled ? (
            disabledButton
        ) : !tooltip ? (
            button
        ) : (
            <Tooltip placement={tooltipPlacement} title={tooltip}>
                {button}
            </Tooltip>
        );
    }
);
