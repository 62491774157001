import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { WorkspaceSelector } from '../../components/workspace-layouts/workspace-selector';
import {
    openNewBlankWorkspaceAction$,
    loggedInUserWorkspaces$,
    newWorkspaceFromTemplate$,
    deleteWorkspaceAction$,
    workspaceDeletedAction$,
    newDuplicatedWorkspace$,
    workspaceSelectorView$,
    changeWorkspaceSelectorViewAction$,
    loggedInUserWorkspacesLoading$,
} from '../../store/workspaces';
import { getBasePath } from '../../utils/path';
import { loggedInUserDetails$ } from '../../store/user';
import { organizationSwitchedAction$ } from '../../store/organization';
import { getLoggedInUserWorkspacesAction$ } from '../../store/workspaces';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';

export const WorkspaceSelectorContainer: React.FC = () => {
    const workspaces = useObservableState(loggedInUserWorkspaces$);
    const workspaceSelectorView = useObservableState(workspaceSelectorView$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const loggedInUserWorkspacesLoading = useObservableState(loggedInUserWorkspacesLoading$);
    const userCanWorkWithTemplates = !!(loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates);

    const navigate = useNavigate();

    useSubscription(organizationSwitchedAction$, (uid) => getLoggedInUserWorkspacesAction$.next(uid));

    useSubscription(workspaceDeletedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: `${getBasePath()}dashboard` });
        setTimeout(() => navigate({ to: `${getBasePath()}workspaces` }), 100);
    });

    return (
        <>
            {loggedInUserWorkspacesLoading ? (
                <LoadingSpinner />
            ) : (
                <WorkspaceSelector
                    basePath={getBasePath()}
                    userCanWorkWithTemplates={userCanWorkWithTemplates}
                    view={workspaceSelectorView}
                    workspaces={workspaces.map(
                        ({
                            uid,
                            name,
                            template,
                            description,
                            incomingApps,
                            outgoingApps,
                            environments,
                            members,
                            setupGuide,
                        }) => ({
                            uid,
                            title: name,
                            description,
                            template,
                            incoming: incomingApps,
                            outgoing: outgoingApps,
                            environments,
                            users: members,
                            setupGuide,
                        })
                    )}
                    onChangeView={(view) => changeWorkspaceSelectorViewAction$.next(view)}
                    onCreateBlank={() => {
                        newDuplicatedWorkspace$.next(undefined);
                        newWorkspaceFromTemplate$.next(undefined);
                        openNewBlankWorkspaceAction$.next('home');
                    }}
                    onDelete={(uid) => deleteWorkspaceAction$.next(uid)}
                    onDuplicate={(details) => {
                        newDuplicatedWorkspace$.next(details);
                        newWorkspaceFromTemplate$.next(undefined);
                        openNewBlankWorkspaceAction$.next('duplicated');
                    }}
                />
            )}
        </>
    );
};
