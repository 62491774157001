import { Editor } from '@monaco-editor/react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button } from '../../common/buttons/Button';
import { IconButton } from '../../common/buttons/IconButton';
import { StyledBorderBox, StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { Language } from '../../workspace-dialogs/language-selector';

export interface SetupGuideEditScriptScreenProps {
    hasUnsavedChanges?: boolean;
    content: string;
    language?: Language;
    path: string;
    saving?: boolean;
    templateMode?: boolean;
    workspaceLocked?: boolean;
    onCancel: () => void;
    onChangeContent: (content: string) => void;
    onOpenImportApiConnectionDialog: () => void;
    onOpenRenameScriptDialog: () => void;
    onSave: () => void;
    onToggleHelperPopup: () => void;
    onTrigger: () => void;
}

const StyledScriptBox = styled(StyledBorderBox)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    width: '100%',
}));

const StyledScriptActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    justifyContent: 'center',
}));

export const SetupGuideEditScriptScreen: React.FC<SetupGuideEditScriptScreenProps> = ({
    content,
    hasUnsavedChanges = false,
    language = 'ts',
    path,
    saving = false,
    templateMode = false,
    workspaceLocked = false,
    onCancel,
    onChangeContent,
    onOpenImportApiConnectionDialog,
    onOpenRenameScriptDialog,
    onSave,
    onToggleHelperPopup,
    onTrigger,
}) => {
    const theme = useTheme();
    const editorTheme = theme.palette.mode === 'dark' ? 'vs-dark' : 'vs';

    const actions = (
        <>
            <Button variant="outlined" onClick={onCancel}>
                Back
            </Button>
            {!templateMode && (
                <Button busy={saving} disabled={!hasUnsavedChanges || workspaceLocked} onClick={onSave}>
                    Save
                </Button>
            )}
        </>
    );

    const editorLanguage = language === 'js' ? 'javascript' : 'typescript';

    return (
        <StyledScriptBox>
            <StyledBorderBoxHeader>
                <Typography variant="h5">{path}</Typography>
                {!templateMode && (
                    <StyledScriptActions>
                        <IconButton
                            aria-label="Trigger script"
                            disabled={workspaceLocked}
                            border
                            icon={<PlayCircleOutlineOutlinedIcon />}
                            tooltip="Trigger script"
                            onClick={onTrigger}
                        />
                        <IconButton
                            aria-label="Save changes"
                            border
                            disabled={!hasUnsavedChanges || workspaceLocked}
                            icon={<SaveOutlinedIcon />}
                            tooltip="Save changes"
                            onClick={onSave}
                        />
                        <IconButton
                            aria-label="Import API connection"
                            border
                            disabled={workspaceLocked}
                            icon={<DownloadOutlinedIcon />}
                            tooltip="Import API connection"
                            onClick={onOpenImportApiConnectionDialog}
                        />
                        <IconButton
                            aria-label="Rename script"
                            border
                            disabled={workspaceLocked}
                            icon={<EditOutlinedIcon />}
                            tooltip="Rename script"
                            onClick={onOpenRenameScriptDialog}
                        />
                        <IconButton
                            aria-label="Display keyboard shortcuts"
                            border
                            icon={<KeyboardOutlinedIcon />}
                            tooltip="Display keyboard shortcuts"
                            onClick={onToggleHelperPopup}
                        />
                    </StyledScriptActions>
                )}
            </StyledBorderBoxHeader>
            <Divider />
            <Editor
                defaultValue=""
                language={editorLanguage}
                options={{
                    minimap: { enabled: false },
                    readOnly: templateMode || workspaceLocked,
                }}
                theme={editorTheme}
                value={content}
                onChange={(value) => onChangeContent(value ?? '')}
            />
            <Divider />
            <StyledMainActions>{actions}</StyledMainActions>
        </StyledScriptBox>
    );
};
