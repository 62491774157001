import { useObservableState, useSubscription } from 'observable-hooks';
import {
    closeWorkspaceSetupGuideConnectionSelectorAction$,
    createWorkspaceSetupGuideConnectionAction$,
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideConnectionsAction$,
    selectedWorkspaceSetupGuideStep$,
    modifiedWorkspaceSetupGuideAppInstanceDetails$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
    selectedWorkspaceSetupGuideConnections$,
    editWorkspaceSetupGuideConnectionAction$,
    changeWorkspaceSetupGuideConnectionAction$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../../store/workspace';

import { SetupGuideConnectionScreen } from '../../../components/setup-guide/connections/SetupGuideConnectionScreen';
import { loggedInUserConnections$ } from '../../../store/connections';
import { connectionCreatedAction$, ConnectionDetails } from '../../../store/connection';
import { getAppInstances, getPreviousEnabledStep } from '../../../utils/setupGuide';
import { apps$ } from '../../../store/apps';

export const SetupGuideConnectionScreenContainer: React.FC = () => {
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const { apiHandlers, eventListeners } = selectedWorkspaceResources$.value;
    const modifiedAppInstanceDetails = useObservableState(modifiedWorkspaceSetupGuideAppInstanceDetails$);
    const connections = useObservableState(loggedInUserConnections$);
    const selectedConnections = useObservableState(selectedWorkspaceSetupGuideConnections$);
    const apps = useObservableState(apps$);

    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const errors = useObservableState(workspaceSetupGuideStepError$);

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    const appInstances = getAppInstances(apiHandlers, eventListeners, connections, selectedConnections, apps);

    useSubscription(connectionCreatedAction$, (uid) => {
        closeWorkspaceSetupGuideConnectionSelectorAction$.next();

        if (modifiedAppInstanceDetails?.appName) {
            const key =
                modifiedAppInstanceDetails.appName +
                (modifiedAppInstanceDetails.group ? ` ${modifiedAppInstanceDetails.group}` : '');

            selectedWorkspaceSetupGuideConnections$.next({ ...selectedConnections, [key]: uid });
        }
    });

    return (
        <SetupGuideConnectionScreen
            appInstances={appInstances}
            errors={errors}
            nextStepName={nextStep?.title}
            saving={saving}
            stepRequired={selectedSetupGuideStep?.required}
            workspaceLocked={!!selectedWorkspace?.locked}
            onChangeConnection={(details) =>
                changeWorkspaceSetupGuideConnectionAction$.next({
                    appName: details.appName,
                    group: details.group,
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onContinue={(appInstances) =>
                saveWorkspaceSetupGuideConnectionsAction$.next({
                    appInstances,
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    templateMode: selectedWorkspace?.setupGuide === 'TEMPLATE',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onCreateNewConnection={(details) => createWorkspaceSetupGuideConnectionAction$.next(details)}
            onEditConnection={(details) => {
                const foundConnection = connections.find((c) => c.uid === details.uid);
                if (foundConnection) {
                    editWorkspaceSetupGuideConnectionAction$.next({
                        appName: details.appName,
                        group: details.group,
                        connectionDetails: foundConnection as unknown as ConnectionDetails, // TODO: This cast is widespread, but we could do without it
                        workspaceUid: selectedWorkspaceUid ?? '',
                    });
                }
            }}
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
        />
    );
};
