import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { styled, useTheme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { DialogAlert, DialogCloseButton } from '../../../for-deprecation/dialog/DialogComponents';
import { Button } from '../../../common/buttons/Button';
import { ChangeOrganizationPlanConfirmationContent, TeamPlan } from './ChangeOrganizationPlanConfirmationContent';

export interface ChangeOrganizationPlanConfirmationDialogProps {
    open?: boolean;
    currentPlan: TeamPlan;
    nextPlan: TeamPlan;
    onCancel?: () => void;
    onProceed?: () => void;
}

const StyledDialogContent = styled(DialogContent)(() => ({
    padding: 0,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
}));

const StyledDialogInstructions = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.text.primary}`,
}));

const StyledNextPlanCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
}));

const StyledCurrentPlanHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.palette.background.paper,
}));

const StyledNextPlanHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.palette.background.paper,
}));

const StyledDoubleArrowContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'center',
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledDialogAlert = styled(DialogAlert)(({ theme }) => ({
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: 'auto',
}));

const PLAN_TIERS = ['free', 'basic', 'advanced', 'pro'];

export const ChangeOrganizationPlanConfirmationDialog: React.FC<ChangeOrganizationPlanConfirmationDialogProps> = ({
    open = false,
    currentPlan,
    nextPlan,
    onCancel,
    onProceed,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const theme = useTheme();

    const currentPlanTierIndex = PLAN_TIERS.indexOf(currentPlan.tier);
    const nextPlanTierIndex = PLAN_TIERS.indexOf(nextPlan.tier);
    const planChangeStatus =
        nextPlanTierIndex === currentPlanTierIndex ||
        currentPlan.tier === 'custom' ||
        currentPlan.subscriptionStatus === 'SUSPENDED'
            ? 'select'
            : nextPlanTierIndex > currentPlanTierIndex
            ? 'upgrade'
            : 'downgrade';

    const nextPlanColor =
        planChangeStatus === 'downgrade'
            ? theme.palette.warning.main
            : planChangeStatus === 'select'
            ? theme.palette.info.dark
            : theme.palette.success.main;

    const subtitle =
        currentPlan.subscriptionStatus === 'SUSPENDED'
            ? 'By choosing a plan, you can unlock its benefits and thrive with enhanced features.'
            : planChangeStatus === 'downgrade'
            ? 'Are you sure you want to downgrade this team plan?'
            : planChangeStatus === 'upgrade'
            ? 'Ready to upgrade for an enhanced team plan?'
            : 'By choosing a plan, you can unlock its benefits and thrive with enhanced features.';

    return (
        <Dialog open={open}>
            <div>
                <DialogCloseButton onClick={onCancel} />
                <StyledDialogTitle variant="h4" className="extra-bold">
                    {planChangeStatus} Plan
                </StyledDialogTitle>
                <StyledDialogInstructions>{subtitle}</StyledDialogInstructions>
            </div>
            <StyledDialogContent>
                <StyledCard elevation={1}>
                    <StyledCurrentPlanHeader
                        sx={(theme) => ({
                            background:
                                currentPlan.subscriptionStatus === 'SUSPENDED'
                                    ? theme.palette.error.dark
                                    : theme.palette.text.primary,
                        })}
                    >
                        <Typography component="h4">
                            {currentPlan.subscriptionStatus === 'SUSPENDED' ? 'Suspended' : 'Current'} Plan
                        </Typography>
                    </StyledCurrentPlanHeader>
                    <Divider />
                    <ChangeOrganizationPlanConfirmationContent
                        plan={currentPlan}
                        listIcon={currentPlan.subscriptionStatus === 'SUSPENDED' ? <HighlightOffIcon /> : undefined}
                        listIconColor={
                            currentPlan.subscriptionStatus === 'SUSPENDED'
                                ? theme.palette.error.main
                                : theme.palette.text.primary
                        }
                    />
                </StyledCard>
                <StyledDoubleArrowContainer>
                    <KeyboardDoubleArrowDownIcon fontSize="large" />
                </StyledDoubleArrowContainer>
                <StyledNextPlanCard elevation={1} sx={{ border: `1px solid ${nextPlanColor}` }}>
                    <StyledNextPlanHeader sx={{ background: nextPlanColor }}>
                        <Typography component="h4">New Plan</Typography>
                    </StyledNextPlanHeader>
                    <Divider />
                    <ChangeOrganizationPlanConfirmationContent plan={nextPlan} listIconColor={nextPlanColor} />
                    {planChangeStatus === 'downgrade' && (
                        <StyledDialogAlert
                            severity="success"
                            color="info"
                            title="Downgrade: Billing Information"
                            text='By downgrading your plan, any applicable billing adjustments will be made for the remaining days of the month based on the new plan cost. The downgraded plan will take effect immediately, and you can view the billing details under the "Billing Details" tab.'
                            icon={<MonetizationOnOutlinedIcon fontSize="inherit" />}
                        />
                    )}
                    {planChangeStatus === 'upgrade' && (
                        <StyledDialogAlert
                            severity="success"
                            color="info"
                            title="Upgrade: Billing Information"
                            text='When you upgrade your plan, the billing will depend on the type of plan you have. If you upgrade a yearly plan, you will be billed immediately for the remaining days of your current plan based on the new plan cost. If you upgrade a monthly plan, you will be billed at the start of the next period for the remaining days of your current plan at the new plan cost. The upgraded plan becomes active immediately, and you can view the billing details under the "Billing Details" tab.'
                            icon={<MonetizationOnOutlinedIcon fontSize="inherit" />}
                        />
                    )}
                    {planChangeStatus === 'select' && currentPlan.subscriptionStatus === 'SUSPENDED' && (
                        <StyledDialogAlert
                            severity="success"
                            title="Activation enables workspace functionality"
                            text="Triggers immediate event processing for all affected workspaces"
                        />
                    )}
                </StyledNextPlanCard>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button variant="outlined" onClick={onCancel}>
                    Back
                </Button>
                <Button onClick={onProceed}>
                    {planChangeStatus} {planChangeStatus === 'select' ? '' : 'to '}
                    {nextPlan.tier}
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
