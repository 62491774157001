import { useState } from 'react';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { Button } from '../../buttons/Button';
import { Checkbox } from '../../Checkbox';
import { Dialog } from '../Dialog';
import { handleKeyDown } from '../../../../utils/input';
import { videoTutorialsUrl } from '../../../../utils/documentation';

interface TutorialDialogProps {
    id: string;
    content?: JSX.Element;
    open?: boolean;
    size?: 'small' | 'medium';
    title: string;
    onClose: (notShowAgainId?: string) => void;
}

export const TutorialDialog: React.FC<TutorialDialogProps> = ({
    id,
    content,
    open = false,
    size = 'medium',
    title,
    onClose,
}) => {
    const [checked, setChecked] = useState(false);

    const handleClose = (): void => {
        onClose(checked ? id : undefined);
    };

    return (
        <Dialog
            buttons={[
                <Button
                    link={{ href: videoTutorialsUrl, target: '_blank', type: 'external' }}
                    startIcon={<PlayCircleOutlinedIcon />}
                    variant="outlined"
                >
                    Video tutorials
                </Button>,
                <Button onClick={handleClose}>Close</Button>,
            ]}
            leftButton={
                <Checkbox checked={checked} label="Don't show this again" onCheck={() => setChecked(!checked)} />
            }
            open={open}
            size={size}
            title={title}
            onClose={handleClose}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: handleClose, escFn: handleClose })}
        >
            {content}
        </Dialog>
    );
};
