// Eventually move this outside this folder and use across the whole app
import { useEffect, useRef, useState } from 'react';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { Alert } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import { IconCircle } from '../common/IconCircle';
import { TextField } from '../common/inputs/TextField';
import { autoFocus, handleKeyDown } from '../../utils/input';

export interface NewScriptDialogProps {
    editMode?: boolean;
    errors?: string;
    open?: boolean;
    scriptName?: string;
    saving?: boolean;
    onCancel(): void;
    onSave(name: string): void;
}

const isScriptNameValid = (name?: string): boolean => {
    const regex = /^[a-zA-Z0-9_-]+(?:\/[a-zA-Z0-9_-]+)*\/?$/;

    return name ? regex.test(name) : true;
};

export const NewScriptDialog: React.FC<NewScriptDialogProps> = ({
    editMode = false,
    errors,
    open = false,
    scriptName = '',
    saving = false,
    onCancel,
    onSave,
}) => {
    const [currentScriptName, setCurrentScriptName] = useState(scriptName);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        autoFocus(inputRef);
        setCurrentScriptName(scriptName);
    }, [open]);

    const canCreate = !!currentScriptName && currentScriptName !== scriptName && isScriptNameValid(currentScriptName);

    const buttons = [
        <Button variant="outlined" onClick={onCancel}>
            Cancel
        </Button>,
        <Button busy={saving} disabled={!canCreate} onClick={() => onSave(currentScriptName)}>
            {editMode ? 'Save changes' : 'Create script'}
        </Button>,
    ];

    return (
        <Dialog
            buttons={buttons}
            icon={<IconCircle icon={<CodeOutlinedIcon />} />}
            open={open}
            size="small"
            title={editMode ? 'Edit script' : 'New script'}
            onClose={onCancel}
            onKeyUp={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canCreate,
                    enterFn: () => onSave(currentScriptName),
                    escFn: onCancel,
                })
            }
        >
            {errors && <Alert title={errors} severity="error" />}
            <Alert
                text="To ensure precise folder structuring, use the forward slash '/' to separate individual folders within the specified path"
                title="Separate folders using the forward slash '/'"
                severity="info"
            />
            <TextField
                error={!isScriptNameValid(currentScriptName)}
                fullWidth
                helperText={
                    !isScriptNameValid(currentScriptName)
                        ? 'Script name can only contain alphanumeric characters, forward slashes, underscores and dashes.'
                        : ''
                }
                inputRef={inputRef}
                label="Script name"
                placeholder="Enter name"
                value={currentScriptName}
                onChange={(e) => setCurrentScriptName(e.target.value)}
            />
        </Dialog>
    );
};
