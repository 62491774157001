import { getFetchOptions } from '../../../utils/fetch';
import { configTopic$, stitchSession$ } from '../../config';

export interface AWSSetupSaveProps {
    accessKey?: string;
    secretKey?: string;
    roleArn?: string;
    externalId?: string;
    duration?: number;
}

export interface SaveAwsCredentialsProps extends AWSSetupSaveProps {
    connectionId: string;
    authMode: 'PERMANENT' | 'TEMPORARY';
}

export const saveCredentials = async (request: SaveAwsCredentialsProps): Promise<void> => {
    const connectorUrl = configTopic$.value.connection?.aws?.baseUrl;
    if (!connectorUrl) {
        throw new Error('No AWS connector url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, request);

    const response = await fetch(`${connectorUrl}/save`, fetchOptions);
    if (!response.ok) {
        console.error('Error while saving token');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while saving token to AWS Connector Service: ${response.status}`);
        }
    }
};

interface GeneratePolicyResponse {
    policy: string;
    externalId: string;
}
export const generateAwsPolicy = async (): Promise<GeneratePolicyResponse> => {
    const connectorUrl = configTopic$.value.connection?.aws?.baseUrl;
    if (!connectorUrl) {
        throw new Error('No AWS connector url configured in meta');
    }

    const response = await fetch(`${connectorUrl}/generate`, {
        method: 'GET',
        headers: {
            Authorization: stitchSession$.value?.jwt ?? '',
        },
    });
    if (!response.ok) {
        console.error('Error while generating policy');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while generating policy from AWS connector Service: ${response.status}`);
        }
    } else {
        return await response.json();
    }
};
