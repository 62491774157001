import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import HearingIcon from '@mui/icons-material/Hearing';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Alert } from '../../common/alerts/Alert';
import { BulletList } from '../../common/lists/BulletList';
import { Button } from '../../common/buttons/Button';
import { IconCircle } from '../../common/IconCircle';
import { InfoIcon } from '../../icons/InfoIcon';
import { OrderedList } from '../../common/lists/OrderedList';
import { ProductIcon } from '../../icons/ProductIcon';
import { SetupGuideRunScreenEventListenerListItem } from './resources/SetupGuideRunScreenEventListenerListItem';
import { SetupGuideRunScreenScheduledTriggerListItem } from './resources/SetupGuideRunScreenScheduledTriggerListItem';
import { SetupGuideRunScreenScriptListItem } from './resources/SetupGuideRunScreenScriptListItem';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxTitleContainer,
} from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { StyledIconContainer, StyledStepContainer } from '../SetupGuideComponents';
import { TabContext } from '../../common/TabContext';
import { ToggleButtonGroup } from '../../common/buttons/button-groups/ToggleButtonGroup';
import { Language } from '../../workspace-dialogs/language-selector';
import {
    SetupGuideRunScreenEventListener,
    SetupGuideRunScreenScheduledTrigger,
    SetupGuideRunScreenScript,
    SetupGuideStepName,
    SetupGuideWorkspace,
} from '../types';

type TriggerTab = 'manual' | 'external' | 'scheduled';
type EventListenerTab = 'test-event' | 'external-trigger';

export interface SetupGuideRunScreenProps {
    errors?: string;
    eventListeners?: SetupGuideRunScreenEventListener[];
    language?: Language;
    nextStepName?: string;
    saving?: boolean;
    scheduledTriggers?: SetupGuideRunScreenScheduledTrigger[];
    scripts?: SetupGuideRunScreenScript[];
    workspace: SetupGuideWorkspace;
    workspaceLocked?: boolean;
    onContinue: () => void;
    onEdit: (stepName: SetupGuideStepName) => void;
    onGoBack: () => void;
    onRunEventListener: (uid: string) => void;
    onRunScheduledTrigger: (scriptUid?: string) => void;
    onRunScript: (uid: string) => void;
}

const StyledRunScreenList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
}));

export const SetupGuideRunScreen: React.FC<SetupGuideRunScreenProps> = ({
    errors,
    eventListeners = [],
    language = 'ts',
    nextStepName,
    saving = false,
    scheduledTriggers = [],
    scripts = [],
    workspace,
    workspaceLocked = false,
    onContinue,
    onEdit,
    onRunEventListener,
    onRunScheduledTrigger,
    onRunScript,
    onGoBack,

    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const hasScripts = scripts.length > 0;
    const hasEventListeners = eventListeners.length > 0;
    const hasScheduledTriggers = scheduledTriggers.length > 0;

    const hasMultipleResources =
        [hasScripts, hasEventListeners, hasScheduledTriggers].filter((prop) => prop).length > 1;

    const defaultTab = hasScripts
        ? 'manual'
        : hasEventListeners
        ? 'external'
        : hasScheduledTriggers
        ? 'scheduled'
        : null;

    const [currentTab, setCurrentTab] = useState<TriggerTab | null>(defaultTab);
    const [selectedEventListenerTab, setSelectedEventListenerTab] = useState<EventListenerTab>('test-event');

    const scriptSteps = [
        'Review all scripts.',
        'Find the script you want to run.',
        "Press 'Run Script' to execute it.",
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'Monitor the console log for issues and bugs.',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        "Select 'Edit' to make changes if necessary.",
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'Continue to next step.',
    ];
    const testEventSteps = [
        'Review all event listeners.',
        'Find the event listener you want to test.',
        "Press 'Run Test Event' to execute it. (Test Event Payload)",
        'Monitor the console log for activity and feedback.',
        "Select 'Edit' to make changes if necessary.",
        'Continue to next step.',
    ];

    const testEventBulletItems = [
        `A 'Test Event Payload' is sample data used to test your event listener without
        real data.`,
        `By clicking the 'Run Test Event' button, you can see if your event listener
        works correctly.`,
        'Based on the test results, you can make any needed changes.',
    ];

    const externalTriggerSteps = [
        'Leave ScriptRunner Connect and open up the application from which you want to trigger the integration.',
        'Perform the relevant action (creating an issue, adding a comment, creating a new page, etc.).',
        'Monitor the console log for activity and feedback.',
        'Open the target application to ensure the expected changes were made.',
    ];
    const scheduledTriggerSteps = [
        'Review all scheduled triggers.',
        'Find the scheduled trigger you want to test.',
        "Press 'Run Scheduled Trigger' to execute it.",
        'Monitor the console log for issues and bugs.',
        "Select 'Edit' to make changes if necessary.",
        'Continue to next step.',
    ];

    const icons = [...workspace.apps.incoming, ...workspace.apps.outgoing].map((a, i) => (
        <IconCircle key={`icon-${i}`} icon={<ProductIcon name={a} />} size="small" />
    ));
    const appNames = workspace.apps.incoming.join(', ') + ' · ' + workspace.apps.outgoing.join(', ');

    const toggleButtons = [
        ...(hasScripts ? [{ label: 'Manual Triggers · Scripts', value: 'manual' }] : []),
        ...(hasEventListeners ? [{ label: 'Event listeners', value: 'external' }] : []),
        ...(hasScheduledTriggers ? [{ label: 'Scheduled triggers', value: 'scheduled' }] : []),
    ];

    const eventListenerTabs = [
        {
            content: (
                <>
                    <OrderedList content={testEventSteps} id="test-event-steps" />
                    <Typography mt={2} fontWeight="bold">
                        Explanation: Test Event Payload
                    </Typography>
                    <BulletList content={testEventBulletItems} id="test-event-bullet-list" />
                </>
            ),
            label: 'Method 1: Test event',
            value: 'test-event',
        },
        {
            content: (
                <>
                    <StyledBorderBox mb={2}>
                        <StyledBorderBoxContent>
                            <StyledIconContainer>{icons}</StyledIconContainer>
                            <Typography color="text.secondary">{appNames}</Typography>
                            <Typography fontWeight="bold"> {workspace.name}</Typography>
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <OrderedList content={externalTriggerSteps} id="external-trigger-steps" />
                </>
            ),
            label: 'Method 2: External trigger',
            value: 'external-trigger',
        },
    ];

    return (
        <StyledStepContainer>
            {hasMultipleResources && (
                <StyledBorderBox>
                    <StyledBorderBoxContent>
                        <ToggleButtonGroup
                            buttons={toggleButtons}
                            exclusive
                            fullWidth
                            value={currentTab}
                            onChange={(_, value) => setCurrentTab((prev) => (value ? value : prev))}
                        ></ToggleButtonGroup>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            )}
            {currentTab === 'manual' && (
                <>
                    <SetupGuideSummaryBox
                        icon={<PlayCircleOutlineIcon />}
                        title="Instructions for manual triggers · scripts"
                    >
                        <OrderedList content={scriptSteps} id="script-steps" />
                    </SetupGuideSummaryBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <StyledBorderBoxTitleContainer display="flex" alignItems="center">
                                <Typography variant="h6">{`Scripts (${scripts.length})`}</Typography>
                                <InfoIcon tooltip="Scripts allow you to describe your business logic." />
                            </StyledBorderBoxTitleContainer>
                            <Button
                                startIcon={<CreateOutlinedIcon />}
                                variant="outlined"
                                size="small"
                                onClick={() => onEdit('SCRIPTS')}
                            >
                                Edit
                            </Button>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledRunScreenList>
                            {hasScripts &&
                                scripts.map(({ path, uid, running }, index) => (
                                    <Box key={`script-item-${index}`}>
                                        <SetupGuideRunScreenScriptListItem
                                            disabled={workspaceLocked}
                                            language={language}
                                            path={path}
                                            running={running}
                                            uid={uid}
                                            onRun={onRunScript}
                                        />
                                        {index < scripts.length - 1 && <Divider />}
                                    </Box>
                                ))}
                        </StyledRunScreenList>
                    </StyledBorderBox>
                </>
            )}
            {currentTab === 'external' && (
                <>
                    <SetupGuideSummaryBox icon={<HearingIcon />} title="Instructions for event listeners">
                        <TabContext
                            tabs={eventListenerTabs}
                            value={selectedEventListenerTab}
                            setTabValue={(value) => setSelectedEventListenerTab(value as EventListenerTab)}
                        />
                    </SetupGuideSummaryBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <StyledBorderBoxTitleContainer display="flex" alignItems="center">
                                <Typography variant="h6">{`Event Listeners (${eventListeners.length})`}</Typography>
                                <InfoIcon tooltip="Event Listeners allow you to listen to events triggered by external services." />
                            </StyledBorderBoxTitleContainer>
                            <Button
                                startIcon={<CreateOutlinedIcon />}
                                variant="outlined"
                                size="small"
                                onClick={() => onEdit('TRIGGERS')}
                            >
                                Edit
                            </Button>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledRunScreenList>
                            {hasEventListeners &&
                                eventListeners.map(({ appName, scriptName, uid, running }, index) => {
                                    return (
                                        <Box key={`event-item-${index}`}>
                                            <SetupGuideRunScreenEventListenerListItem
                                                appName={appName}
                                                disabled={workspaceLocked}
                                                running={running}
                                                scriptName={scriptName}
                                                uid={uid}
                                                onRun={onRunEventListener}
                                            />
                                            {index !== eventListeners.length - 1 && <Divider />}
                                        </Box>
                                    );
                                })}
                        </StyledRunScreenList>
                    </StyledBorderBox>
                </>
            )}
            {currentTab === 'scheduled' && (
                <>
                    <SetupGuideSummaryBox icon={<TimerOutlinedIcon />} title="Instructions for scheduled triggers">
                        <OrderedList content={scheduledTriggerSteps} id="scheduled-trigger-steps" />
                    </SetupGuideSummaryBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <StyledBorderBoxTitleContainer>
                                <Typography variant="h6">{`Scheduled Triggers (${scheduledTriggers.length})`}</Typography>

                                <InfoIcon tooltip="Scheduled Triggers allow you to trigger scripts based on a schedule." />
                            </StyledBorderBoxTitleContainer>
                            <Button
                                startIcon={<CreateOutlinedIcon />}
                                variant="outlined"
                                size="small"
                                onClick={() => onEdit('TRIGGERS')}
                            >
                                Edit
                            </Button>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledRunScreenList>
                            {hasScheduledTriggers &&
                                scheduledTriggers.map(({ cron, scriptName, scriptUid, running }, index) => {
                                    return (
                                        <Box key={`trigger-item-${index}`}>
                                            <SetupGuideRunScreenScheduledTriggerListItem
                                                cron={cron}
                                                disabled={workspaceLocked}
                                                running={running}
                                                scriptName={scriptName}
                                                scriptUid={scriptUid}
                                                onRun={onRunScheduledTrigger}
                                            />
                                            {index < scheduledTriggers.length - 1 && <Divider />}
                                        </Box>
                                    );
                                })}
                        </StyledRunScreenList>
                    </StyledBorderBox>
                </>
            )}
            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
            <StyledMainActions>
                <Button variant="outlined" onClick={onGoBack}>
                    Back
                </Button>
                <Button busy={saving} data-pendo="completeSetup" disabled={workspaceLocked} onClick={onContinue}>
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            </StyledMainActions>
        </StyledStepContainer>
    );
};
