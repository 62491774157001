import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { editorDocumentationUrl, youtubeEditorVideoUrl } from '../../../utils/documentation';

interface ScriptHelperPopupProps {
    open?: boolean;
    shortcuts?: Shortcut[];
    onClose(): void;
}

export interface Shortcut {
    combinations: string[];
    text?: string;
}

const StyledCombination = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
}));

const StyledMinimizeButton = styled(IconButton)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: 20,
        width: 20,
    },
}));

const StyledPopup = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    bottom: 110,
    padding: theme.spacing(1, 2),
    position: 'absolute',
    right: 44,
    minWidth: 466,
    zIndex: 1300,
}));

const StyledPopupFirstRow = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
}));

const StyledPopupRow = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
    },
}));

const StyledPopupTitleContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    },
}));

const StyledShortcutBox = styled('div')(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
}));

const StyledSubscript = styled('div')(({ theme }) => ({
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    padding: theme.spacing(2, 0, 1),
}));

const StyledYoutubeIcon = styled('img')(({ theme }) => ({
    height: 20,
    marginRight: theme.spacing(2),
}));

export const ScriptHelperPopup: React.FC<ScriptHelperPopupProps> = ({ open = true, shortcuts = [], onClose }) => {
    const popupRows = shortcuts.map((sc, index) => {
        return (
            <StyledPopupRow key={'row' + index}>
                {sc.combinations.map((c, i) => {
                    return (
                        <StyledCombination key={'combination' + i}>
                            <StyledShortcutBox>
                                <Typography variant="subtitle2" fontWeight={700}>
                                    {c}
                                </Typography>
                            </StyledShortcutBox>
                            {i < sc.combinations.length - 1 && (
                                <Typography sx={{ marginRight: 0.5 }} fontWeight={500}>
                                    or
                                </Typography>
                            )}
                        </StyledCombination>
                    );
                })}
                <Typography noWrap fontWeight={500}>
                    {sc.text}
                </Typography>
            </StyledPopupRow>
        );
    });

    return open ? (
        <StyledPopup>
            <StyledPopupFirstRow>
                <StyledPopupTitleContainer>
                    <KeyboardAltOutlinedIcon />
                    <Typography variant="subtitle1">Shortcuts</Typography>
                </StyledPopupTitleContainer>
                <StyledMinimizeButton onClick={onClose} title="Close">
                    <RemoveIcon />
                </StyledMinimizeButton>
            </StyledPopupFirstRow>
            {popupRows}
            <StyledSubscript>
                <StyledYoutubeIcon
                    src={process.env.PUBLIC_URL + '/youtube-icon.svg'}
                    alt="Youtube icon"
                ></StyledYoutubeIcon>
                <Typography>
                    Watch the{' '}
                    <Link target="_blank" href={youtubeEditorVideoUrl}>
                        video
                    </Link>
                    , or learn more about the <strong>Editor</strong>{' '}
                    <Link target="_blank" href={editorDocumentationUrl}>
                        here
                    </Link>
                </Typography>
            </StyledSubscript>
        </StyledPopup>
    ) : null;
};
