import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { ResourceTreeScript } from './ResourceTreeScript';
import {
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledResourceInfo,
} from './ResourceTreeComponents';
import { WorkspaceLanguage, TreeResource } from './types';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';

interface ResourceTreeFolderProps {
    deployedEnvironmentMode?: boolean;
    fullPath: string;
    language?: WorkspaceLanguage;
    name: string;
    readOnlyMode?: boolean;
    selectedNode?: string;
    subItems: TreeResource[];
    workspaceUid: string;
    onDelete(uid: string): void;
    onSave?(uid: string): void;
    onTrigger?(uid: string): void;
}

export const ResourceTreeFolder: React.FC<ResourceTreeFolderProps> = ({
    deployedEnvironmentMode = false,
    fullPath,
    language,
    name,
    readOnlyMode = false,
    selectedNode,
    subItems = [],
    workspaceUid,
    onDelete,
    onSave,
    onTrigger,
}) => {
    const expandedKey = `isExpanded-${workspaceUid}-${fullPath}/`;

    const [expanded, setExpanded] = useState(readLocalStorage(expandedKey, false));

    useEffect(() => {
        saveLocalStorage(expandedKey, expanded);
    }, [expanded]);

    return (
        <>
            <StyledAccordion expanded={expanded} data-testid={`resource-tree-${name}`}>
                <StyledAccordionSummary
                    className="folder"
                    onClick={() => setExpanded(!expanded)}
                    expandIcon={
                        <IconButton
                            aria-label={`${expanded ? 'Collapse' : 'Expand'} folder`}
                            icon={<ArrowDropDownOutlinedIcon />}
                            size="small"
                            tooltip={`${expanded ? 'Collapse' : 'Expand'} folder`}
                        />
                    }
                >
                    <StyledResourceInfo>
                        <FolderOutlinedIcon />
                        <Typography>{name}</Typography>
                    </StyledResourceInfo>
                </StyledAccordionSummary>
                <StyledAccordionDetails className="folder">
                    {subItems.map((si, i) => {
                        if (si.type === 'FOLDER') {
                            return (
                                <ResourceTreeFolder
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    fullPath={si.fullPath}
                                    key={'folder' + i}
                                    language={language}
                                    name={si.name}
                                    readOnlyMode={readOnlyMode}
                                    selectedNode={selectedNode}
                                    subItems={si.subItems}
                                    workspaceUid={workspaceUid}
                                    onDelete={onDelete}
                                    onTrigger={onTrigger}
                                />
                            );
                        } else {
                            return (
                                <ResourceTreeScript
                                    deleting={si.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    executing={si.executing}
                                    key={si.uid}
                                    language={language}
                                    name={si.name}
                                    readOnlyMode={readOnlyMode}
                                    saving={si.saving}
                                    selectedNode={selectedNode}
                                    uid={si.uid}
                                    unsaved={si.unsaved}
                                    onDelete={onDelete}
                                    onSave={onSave}
                                    onTrigger={onTrigger}
                                />
                            );
                        }
                    })}
                </StyledAccordionDetails>
            </StyledAccordion>
        </>
    );
};
