/* eslint-disable sonarjs/no-duplicate-string */
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CustomIconButtonProps, IconButton } from '../../common/buttons/IconButton';
import { dragOverlayBoxShadow } from './utils';
import { useDroppable } from '@dnd-kit/core';
import {
    StyledBorderBox,
    StyledBorderBoxHeaderActions,
    StyledBorderBoxHeaderSmall,
} from '../../layout/BorderBoxComponents';

type DragButtonProps = Omit<CustomIconButtonProps, 'aria-label' | 'border' | 'icon' | 'tooltip'>;

// Leave that as 'div' not Box for the time being, otherwise TS will scream when implementing multiple refs
// TODO: Find a workaround
export const StyledVariableWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'nested',
})<{ isDragging?: boolean; nested?: boolean }>(({ isDragging, nested, theme }) => ({
    height: isDragging ? 0 : 'auto',
    marginTop: isDragging ? (nested ? theme.spacing(-1) : theme.spacing(-1.5)) : 0,
    visibility: isDragging ? 'hidden' : 'visible',
    width: '100%',
    '& *': {
        visibility: isDragging ? 'hidden' : 'visible',
    },
}));

export const StyledDropArea = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'height' && prop !== 'nested' && prop !== 'visible',
})<{ height: number; nested?: boolean; visible?: boolean }>(({ height = 0, nested, visible, theme }) => ({
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.constants.borderRadius,
    height: visible ? height : 0,
    marginBottom: visible ? (nested ? theme.spacing(1) : theme.spacing(1.5)) : 0,
    width: '100%',
}));

export const StyledVariable = styled(StyledBorderBox)(() => ({
    '&.dragOverlay': {
        boxShadow: dragOverlayBoxShadow,
    },
}));

export const StyledReadOnlyVariableHeader = styled(StyledBorderBoxHeaderSmall)(({ theme }) => ({
    padding: theme.spacing(0.5),
}));

export const StyledEditModeHeaderActions = styled(StyledBorderBoxHeaderActions)(({ theme }) => ({
    gap: theme.spacing(1),
}));

export const StyledReadOnlyExpandButton = styled(IconButton)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(0.5, 1),
}));

export const StyledReadOnlyRow = styled(Box)(({ theme }) => ({
    alignItems: 'stretch',
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 30,
    width: '100%',
}));

const StyledReadOnlyField = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: theme.spacing(0.5, 1),
    wordBreak: 'break-word',
}));

export const StyledReadOnlyKeyField = styled(StyledReadOnlyField)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    flex: '100px 0 0',
}));

export const StyledReadOnlyValueField = styled(StyledReadOnlyField)(() => ({
    flexGrow: '1',
}));

export const StyledReadOnlyMultipleValuesField = styled(StyledReadOnlyField)(({ theme }) => ({
    display: 'flex',
    flexGrow: '1',
    gap: theme.spacing(1),
}));

export const StyledReadOnlyListItem = styled(Chip)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.action.selected,
    borderRadius: theme.constants.borderRadius,
    fontSize: theme.typography.body1.fontSize,
    height: 'unset',
    padding: theme.spacing(0.1, 0),
}));

export const StyledEditModeChoicesContainer = styled(Box)(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 0),
    width: '100%',
}));

export const StyledGhostVariable = styled(Box)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    height: 100,
    width: '100%',
}));

const StyledBorderedArea = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
}));

const StyledEmptyWarning = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.warning.main,
        height: 20,
        marginRight: theme.spacing(1),
        width: 20,
    },
}));

const StyledDragButton = styled(IconButton)(() => ({
    cursor: 'all-scroll',
}));

export const emptyWarning = (required = false, folder = false): JSX.Element => (
    <StyledEmptyWarning>
        {required && <WarningAmberIcon />}
        <Typography>{folder ? 'FOLDER NAME MISSING' : 'EMPTY'}</Typography>
    </StyledEmptyWarning>
);

export const GhostVariable: React.FC<{
    id: string;
    nested?: boolean;
}> = ({ id, nested }) => {
    const { active, isOver, setNodeRef } = useDroppable({ id });

    const height = (active?.data.current?.height ?? 0) as number;

    return (
        <StyledVariableWrapper ref={setNodeRef}>
            <StyledDropArea height={height} nested={nested} visible={isOver} />
            <StyledGhostVariable>
                {nested && (
                    <StyledBorderedArea>
                        <DragIndicatorIcon />
                        <Typography>Drag parameters into folder</Typography>
                    </StyledBorderedArea>
                )}
            </StyledGhostVariable>
        </StyledVariableWrapper>
    );
};

export const DragButton: React.FC<DragButtonProps> = (props) => {
    return (
        <StyledDragButton
            aria-label="Drag parameter"
            icon={<DragIndicatorIcon />}
            size="small"
            tooltip="Drag parameter"
            {...props}
        />
    );
};
