import { useObservableState } from 'observable-hooks';
import { closeTutorialDialogAction$, tutorialDialogDetails$, tutorialDialogOpen$ } from '../../store/confirm';
import { TutorialDialog } from '../../components/common/dialogs/custom/TutorialDialog';

export const TutorialDialogContainer: React.FC = () => {
    const { id, content, size, title } = useObservableState(tutorialDialogDetails$) ?? {};
    const open = useObservableState(tutorialDialogOpen$);

    return (
        <TutorialDialog
            id={id ?? ''}
            content={content}
            open={open}
            size={size}
            title={title ?? 'Demo'}
            onClose={(id) => closeTutorialDialogAction$.next(id)}
        />
    );
};
