import { styled } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { ListItemButton } from '../../common/buttons/ListItemButton';

export const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    '&:before': {
        display: 'none',
    },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'row-reverse',
    gap: theme.spacing(1),
    height: 40,
    minHeight: 0,
    padding: theme.spacing(1),
    '& .MuiAccordionSummary-content': {
        ...theme.typography.flexAlignCenter,
        justifyContent: 'space-between',
    },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: 0,
    '&.folder:after': {
        borderLeft: `2px solid ${theme.palette.action.selected}`,
        content: '""',
        height: `calc(100% - 34px)`,
        left: 22,
        position: 'absolute',
        top: 26,
        width: 1,
        zIndex: 10,
    },
    '&.folder': {
        paddingLeft: theme.spacing(4),
    },
}));

export const StyledResourceListItemButton = styled(ListItemButton)(() => ({
    borderRadius: 0,
}));

export const StyledResourceInfo = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));
