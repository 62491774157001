import {
    getOrganisationDetailsReport as getOrganisationDetailsReportRpc,
    getOrganizationPlanLimits as getOrganizationPlanLimitsRpc,
    changeOrganizationPlanAsAdmin as changeOrganizationPlanAsAdminRpc,
} from '@avst-stitch/repository-lib/lib';
import {
    Response as OrganisationDetails,
    Request as OrganisationDetailsRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getOrganisationDetails';
import {
    Response as OrganisationPlanLimits,
    Request as OrganisationPlanLimitsRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationPlanLimits';
import { Request as ChangeOrganisationPlanRequest } from '@avst-stitch/repository-lib/lib/rpcs/changeOrganizationPlanAsAdmin';
import {
    Request as ChangeOrganisationPlanRequestV2,
    changeOrganizationPlanAsAdminV2 as changeOrganizationPlanAsAdminV2Rpc,
} from '@avst-stitch/repository-lib/lib/rpcs/changeOrganizationPlanAsAdminV2';
import {
    Request as ChangeOrganizationPartnerCompanyRequest,
    changeOrganizationPartnerCompany as changeOrganizationPartnerCompanyRpc,
} from '@avst-stitch/repository-lib/lib/rpcs/changeOrganizationPartnerCompany';
import {
    Request as SendOrganizationToHubSpotRequest,
    sendOrganizationToHubSpot as sendOrganizationToHubSpotRpc,
} from '@avst-stitch/repository-lib/lib/rpcs/sendOrganizationToHubSpot';
import { repositoryInvoker } from '../../utils/repository';
export type {
    OrganisationDetails,
    OrganisationPlanLimits,
    OrganisationPlanLimitsRequest,
    ChangeOrganisationPlanRequest,
    ChangeOrganisationPlanRequestV2,
    ChangeOrganizationPartnerCompanyRequest,
    SendOrganizationToHubSpotRequest,
};

export const getOrganisationDetails = async (request: OrganisationDetailsRequest): Promise<OrganisationDetails> => {
    return await getOrganisationDetailsReportRpc(repositoryInvoker, request);
};

export const getOrganizationPlanLimits = async (
    request: OrganisationPlanLimitsRequest
): Promise<OrganisationPlanLimits> => {
    return await getOrganizationPlanLimitsRpc(repositoryInvoker, request);
};

export const changeOrganizationPlanAsAdmin = async (request: ChangeOrganisationPlanRequest): Promise<void> => {
    return await changeOrganizationPlanAsAdminRpc(repositoryInvoker, request);
};

export const changeOrganizationPlanAsAdminV2 = async (request: ChangeOrganisationPlanRequestV2): Promise<void> => {
    return await changeOrganizationPlanAsAdminV2Rpc(repositoryInvoker, request);
};

export const changeOrganizationPartnerCompany = async (
    request: ChangeOrganizationPartnerCompanyRequest
): Promise<void> => {
    return await changeOrganizationPartnerCompanyRpc(repositoryInvoker, request);
};

export const sendOrganizationToHubSpot = async (request: SendOrganizationToHubSpotRequest): Promise<void> => {
    return await sendOrganizationToHubSpotRpc(repositoryInvoker, request);
};
