import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { APP } from '@avst-stitch/repository-lib/constants';

interface GenericEventListenerSetupDialogProps {
    open?: boolean;
    url: string;
    onClose: () => void;
}

export const GenericEventListenerSetupDialog: React.FC<GenericEventListenerSetupDialogProps> = ({
    open = false,
    url,
    onClose,
}) => {
    const appName = APP.GENERIC.NAME;
    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={`${appName} App`}
                onClose={onClose}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList
                            content={[
                                'Copy and use the URL to connect to ScriptRunner Connect.',
                                makeNonListItem(
                                    <CopyTextField fullWidth label="Webhook URL" value={url} />,
                                    'generic-webhook-url'
                                ),
                            ]}
                            id="generic-instructions"
                        />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            Common use cases
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList
                            content={[
                                'Register the URL as a webhook in an external app to listen for events.',
                                'Build your own API by sending back JSON in the response.',
                                'Build your own user interface by sending back HTML in the response.',
                            ]}
                            counterType="alpha"
                            id="generic-helper-steps"
                        />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
