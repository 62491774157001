import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate, useSearch } from 'react-location';
import { InvocationLogsPage, ReportingFilters } from '../../components/reporting/InvocationLogsPage';
import { getBasePath } from '../../utils/path';
import {
    loadingReplayInvocationPayload$,
    navigateToReplayInvocationReviewAction$,
    replayInvocationReviewAction$,
} from '../../store/workspace/replay-invocation';
import { loggedInUserDetails$ } from '../../store/user';
import {
    abortInvocationAction$,
    abortInvocationLoading$,
    invocationsData$,
    queryInvocationsAction$,
    reloadInvocationsOnOrganizationSwitchAction$,
    reloadInvocationsOnOrganizationSwitchLoading$,
    reportingError$,
    reportingLoading$,
    searchInvocationsAction$,
} from '../../store/reporting';
import { organizationSwitchedAction$, selectedOrganizationUid$ } from '../../store/organization';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';

export const ReportingContainer: React.FC = () => {
    const navigate = useNavigate();
    const search = useSearch();
    const invocationsData = useObservableState(invocationsData$);
    const isLoading = useObservableState(reportingLoading$);
    const errors = useObservableState(reportingError$);
    const invocationPayloadIsLoading = useObservableState(loadingReplayInvocationPayload$);
    const abortInvocationLoading = useObservableState(abortInvocationLoading$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const reloadInvocationsOnOrganizationSwitchLoading = useObservableState(
        reloadInvocationsOnOrganizationSwitchLoading$
    );

    useSubscription(navigateToReplayInvocationReviewAction$, (details) => {
        navigate({
            to: `${getBasePath()}workspace/${details.workspaceUid}/environment/${details.environmentUid}/script/${
                details.scriptUid
            }`,
        });
    });

    useSubscription(organizationSwitchedAction$, (uid) => {
        navigate({ to: `${getBasePath()}reporting` }); // Navigate to drop any query params
        reloadInvocationsOnOrganizationSwitchAction$.next({ organizationUid: uid });
    });

    return (
        <>
            {reloadInvocationsOnOrganizationSwitchLoading ? (
                <LoadingSpinner />
            ) : (
                <InvocationLogsPage
                    invocations={invocationsData.invocations}
                    nextToken={invocationsData.nextToken}
                    workspaces={invocationsData.workspaces}
                    isLoading={isLoading}
                    errors={errors}
                    filters={
                        {
                            invocationId: search.invocationId,
                            invocationIdComparator: search.invocationIdComparator,
                            workspaces: search.workspaces,
                            environment: search.environment,
                            environmentComparator: search.environmentComparator,
                            script: search.script,
                            scriptComparator: search.scriptComparator,
                            executionStatuses: search.executionStatuses,
                            triggerTypes: search.triggerTypes,
                            duration: search.duration,
                            durationComparator: search.durationComparator,
                            logCount: search.logCount,
                            logCountComparator: search.logCountComparator,
                            httpLogCount: search.httpLogCount,
                            httpLogCountComparator: search.httpLogCountComparator,
                            from: search.from,
                            to: search.to,
                            orderByField: search.orderByField,
                            orderByDirection: search.orderByDirection,
                        } as ReportingFilters
                    }
                    onQueryInvocations={(request) =>
                        queryInvocationsAction$.next({ ...request, organizationUid: selectedOrganizationUid ?? '' })
                    }
                    onSearchInvocations={(request) =>
                        searchInvocationsAction$.next({ ...request, organizationUid: selectedOrganizationUid ?? '' })
                    }
                    onSearch={(request) => {
                        navigate({
                            search: {
                                ...request,
                            },
                        });
                    }}
                    onReplayInvocation={(details) => replayInvocationReviewAction$.next(details)}
                    invocationPayloadIsLoading={invocationPayloadIsLoading}
                    onAbortInvocation={(path) => abortInvocationAction$.next(path)}
                    abortInvocationLoading={abortInvocationLoading}
                    impersonating={!!loggedInUserDetails?.impersonating}
                />
            )}
        </>
    );
};
