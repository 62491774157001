import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { ButtonCard } from '../common/buttons/ButtonCard';
import { ProductIcon } from '../icons/ProductIcon';
import { StyledSectionContainer, StyledSectionHeader, StyledSectionMain } from './DashboardComponents';
import { slackCommunityUrl, documentationUrl, supportPortalUrl, videoTutorialsUrl } from '../../utils/documentation';

export const StyledButtonCard = styled(ButtonCard)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export const DashboardResources: React.FC = () => {
    return (
        <StyledSectionContainer>
            <StyledSectionHeader>
                <Typography variant="h6">Resources</Typography>
            </StyledSectionHeader>
            <Divider />
            <StyledSectionMain m={0}>
                <StyledButtonCard
                    data-pendo="videos"
                    endIcon={<KeyboardArrowRightIcon />}
                    label="Video tutorials"
                    link={{ href: videoTutorialsUrl, target: '_blank', type: 'external' }}
                    iconSize="medium"
                    startIcon={
                        <Tooltip title="Video tutorials">
                            <OndemandVideoOutlinedIcon />
                        </Tooltip>
                    }
                />
                <StyledButtonCard
                    data-pendo="docs"
                    endIcon={<KeyboardArrowRightIcon />}
                    label="Documentation"
                    link={{ href: documentationUrl, target: '_blank', type: 'external' }}
                    iconSize="medium"
                    startIcon={
                        <Tooltip title="Documentation">
                            <MenuBookOutlinedIcon />
                        </Tooltip>
                    }
                />
                <StyledButtonCard
                    data-pendo="support"
                    endIcon={<KeyboardArrowRightIcon />}
                    label="Contact support"
                    link={{ href: supportPortalUrl, target: '_blank', type: 'external' }}
                    iconSize="medium"
                    startIcon={
                        <Tooltip title="Contact support">
                            <QuestionAnswerOutlinedIcon />
                        </Tooltip>
                    }
                />
                <StyledButtonCard
                    data-pendo="slackCommunity"
                    endIcon={<KeyboardArrowRightIcon />}
                    label="Slack community"
                    link={{ href: slackCommunityUrl, target: '_blank', type: 'external' }}
                    iconSize="medium"
                    startIcon={
                        <Tooltip title="Slack community">
                            <ProductIcon name="Slack" />
                        </Tooltip>
                    }
                />
            </StyledSectionMain>
        </StyledSectionContainer>
    );
};
