import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledMainActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
}));

export const StyledEditorViewContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 512,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
}));

export const StyledEditorMainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(2),
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
}));

export const StyledEditorToolbar = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

export const StyledEditorToolbarActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    minWidth: 'fit-content',
}));

export const StyledEmptyStateBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: theme.spacing(3),
}));
