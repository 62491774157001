import { Outlet } from 'react-location';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SRConnectIcon } from '../../icons/SRConnectIcon';
import { StyledPageOuterContainer } from '../../layout/PageComponents';

interface OnboardingPageProps {
    useRouter?: boolean;
}

const StyledTitleContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

const StyledPageInnerContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    width: '100%',
}));

export const OnboardingPage: React.FC<OnboardingPageProps> = ({ useRouter = true }) => {
    return (
        <StyledPageOuterContainer>
            <StyledPageInnerContainer>
                <StyledTitleContainer>
                    <SRConnectIcon />
                    <Typography variant="h5">ScriptRunner Connect</Typography>
                </StyledTitleContainer>
                {useRouter && <Outlet />}
            </StyledPageInnerContainer>
        </StyledPageOuterContainer>
    );
};
