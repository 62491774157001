import { useObservableState } from 'observable-hooks';
import { loadingWorkspaceResources$ } from '../../store/workspace';
import { EmptyWorkspaceView } from '../../components/workspace-layouts/EmptyWorkspaceView';
import { openHelpAndSupportModalAction$ } from '../../store/helpAndSupport';

export const DefaultWorkspaceViewContainer: React.FC = () => {
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);

    if (loadingWorkspaceResources) {
        return null;
    }

    return <EmptyWorkspaceView onOpenHelpAndSupportDialog={() => openHelpAndSupportModalAction$.next()} />;
};
