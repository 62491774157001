export const advancedWorkspaceViewTutorial = {
    content: (
        <iframe
            width="968"
            height="544"
            src="https://demo.arcade.software/mOElG7xElJdDywZwNsIV?embed&embed_desktop=inline&show_copy_link=true"
            title="Advanced workspace view walkthrough"
            frameBorder="0"
            loading="lazy"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen"
            style={{
                colorScheme: 'light',
            }}
        />
    ),
    id: 'advanced-workspace-view-walkthrough',
    title: 'Tutorial: Advanced workspace view',
};
