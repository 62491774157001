/* eslint-disable sonarjs/no-nested-template-literals */
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '../../../common/buttons/Button';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { StyledBorderBox, StyledBorderBoxContent } from '../../../layout/BorderBoxComponents';
import { useState, useEffect } from 'react';
import { EventListenerInstructionsStep } from '../../EventListenerInstructionsStep';
import { CodeBlock } from '../../../app-main/ai-assistance-new/AiAssistanceComponents';
import ReactMarkdown from 'react-markdown';

interface ServiceNowEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

interface BusinessRuleExecutionOperation {
    insert: boolean;
    update: boolean;
    delete: boolean;
    query: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    margin: 0,
    paddingRight: theme.spacing(3),
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiButtonBase-root': {
        margin: 0,
    },
}));

const verifiedTableNames = [
    'incident',
    'sys_attachment',
    'sys_script',
    'sys_scope',
    'sys_rest_message',
    'sys_rest_message_headers',
    'sys_rest_message_fn',
    'sys_rest_message_fn_headers',
    'sys_rest_message_fn_parameters',
    'sys_rest_message_fn_param_defs',
    'sys_rest_message_fn_test',
];
const verifiedContentParameters = [
    {
        group: 'Default',
        key: 'title',
        value: `'Business Rule Script: " + webhookName + "'`,
    },
    { group: 'Default', key: 'user', value: 'gs.getUser().getID()' },
    {
        group: 'Default',
        key: 'trigger',
        value: 'current.getDisplayValue()',
    },
    { group: 'Default', key: 'incident', value: 'current.table_sys_id' },
];

export const ServiceNowEventListenerSetupDialogV2: React.FC<ServiceNowEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const [businessRuleTable, setBusinessRuleTable] = useState('incident');
    const [businessRuleExecutionOperations, setBusinessRuleExecutionOperations] =
        useState<BusinessRuleExecutionOperation>({
            insert: true,
            update: false,
            delete: false,
            query: false,
        });

    const handleBusinessRuleExecutionOperationChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setBusinessRuleExecutionOperations((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
    };

    const [contentParameters, setContentParameters] = useState(verifiedContentParameters.slice(0, 3));
    const [customContentParameters, setCustomContentParameters] = useState<typeof verifiedContentParameters>([]);
    const [hideCustomProperties, setHideCustomProperties] = useState(true);
    const [propertyName, setPropertyName] = useState('');
    const [propertyValue, setPropertyValue] = useState('');

    const [generatedScript, setGeneratedScript] = useState('');

    const resetProperty = (): void => {
        setPropertyName('');
        setPropertyValue('');
    };

    useEffect(() => {
        if (open) {
            setBusinessRuleTable('incident');
            setBusinessRuleExecutionOperations({ insert: true, update: false, delete: false, query: false });
            setContentParameters(verifiedContentParameters.slice(0, 3));
            setCustomContentParameters([]);
            setHideCustomProperties(true);
            resetProperty();
            setGeneratedScript('');
        }
    }, [open]);

    const handGenerateScript = (): void => {
        setGeneratedScript('');
        setTimeout(() => {
            const script = generateWebhookSetupScript(
                webhookUrl,
                businessRuleTable.toLocaleLowerCase(),
                businessRuleExecutionOperations,
                contentParameters
            );
            setGeneratedScript(script);
        }, 200);
    };

    const appName = 'ServiceNow';
    const webhookUrl = `${webhookBaseUrl}/${webhookUrlId}`;

    const generatedScriptWebhookSetupRestMessageAdvancedInstructions = [
        setupBaseUrl ? (
            <Typography>
                If the script has been already executed, but you need to update the Rest Message, then open{' '}
                <Link target="_blank" href={`${setupBaseUrl}/sys_rest_message_list.do`}>
                    Rest Messages
                </Link>
                .
            </Typography>
        ) : (
            <Typography>
                If the script has been already executed, but you need to update the Rest Message, then open{' '}
                <strong>https://[YOUR_SERVICENOW_INSTANCE]/sys_rest_message_list.do</strong>.
            </Typography>
        ),
        <Typography>
            Select the created Rest Message, then select the created Post method under <strong>HTTP Methods</strong>{' '}
            section.
        </Typography>,
        <Typography>
            Change values in the <strong>HTTP Request</strong> tab and/or in the <strong>Variable Substitutions</strong>{' '}
            section.
        </Typography>,
    ];

    const generatedScriptWebhookSetupBusinessRuleAdvancedInstructions = [
        setupBaseUrl ? (
            <Typography>
                If the script has been already executed, but you need to update the Business Rule, then open{' '}
                <Link target="_blank" href={`${setupBaseUrl}/sys_script_list.do`}>
                    Business Rules
                </Link>
                .
            </Typography>
        ) : (
            <Typography>
                If the script has been already executed, but you need to update the Business Rule, then open{' '}
                <strong>https://[YOUR_SERVICENOW_INSTANCE]/sys_script_list.do</strong>.
            </Typography>
        ),
        <Typography>Select the created Business Rule.</Typography>,
        <Typography>
            Change values in the <strong>When to run</strong> and <strong>Advanced</strong> tabs.
        </Typography>,
    ];

    const generatedScriptWebhookSetupInstructions = [
        <Typography>Choose a table.</Typography>,
        makeNonListItem(
            <Autocomplete
                freeSolo
                options={verifiedTableNames}
                value={businessRuleTable}
                onInputChange={(_e, value) => setBusinessRuleTable(value ? value : 'incident')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Table"
                        sx={{
                            '& .MuiInputBase-root': {
                                width: '100%',
                            },
                        }}
                    />
                )}
            />,
            'servicenow-generated-webhook-business-rule-table'
        ),
        <Typography>Select operations that send the event.</Typography>,
        makeNonListItem(
            <FormGroup row sx={{ justifyContent: 'space-between' }}>
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            checked={businessRuleExecutionOperations.insert}
                            onChange={handleBusinessRuleExecutionOperationChecked}
                            name="insert"
                        />
                    }
                    label="Insert"
                />
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            checked={businessRuleExecutionOperations.update}
                            onChange={handleBusinessRuleExecutionOperationChecked}
                            name="update"
                        />
                    }
                    label="Update"
                />
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            checked={businessRuleExecutionOperations.delete}
                            onChange={handleBusinessRuleExecutionOperationChecked}
                            name="delete"
                        />
                    }
                    label="Delete"
                />
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            checked={businessRuleExecutionOperations.query}
                            onChange={handleBusinessRuleExecutionOperationChecked}
                            name="query"
                        />
                    }
                    label="Query"
                />
            </FormGroup>,
            'servicenow-generated-webhook-business-rule-operation'
        ),
        <Typography>Add properties to event payload.</Typography>,
        makeNonListItem(
            <Box>
                <Autocomplete
                    multiple
                    filterSelectedOptions
                    disableClearable
                    disableCloseOnSelect
                    limitTags={2}
                    options={[...verifiedContentParameters, ...customContentParameters]}
                    getOptionLabel={(option) => option.key}
                    groupBy={(option) => option.group}
                    onChange={(_event, value) => setContentParameters(value)}
                    value={contentParameters}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: 'unset',
                                    width: '100%',
                                },
                            }}
                            label="Parameters"
                        />
                    )}
                />
                {hideCustomProperties && (
                    <Button variant="text" onClick={() => setHideCustomProperties(false)}>
                        Add custom parameters
                    </Button>
                )}
                {!hideCustomProperties && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                label="Property name"
                                required
                                onChange={(event) => setPropertyName(event.target.value ?? '')}
                                value={propertyName}
                                sx={{
                                    width: '50%',
                                    '& .MuiInputBase-root': {
                                        width: '75%',
                                    },
                                }}
                            />
                            <TextField
                                label="Property value"
                                required
                                onChange={(event) => setPropertyValue(event.target.value ?? '')}
                                value={propertyValue}
                                sx={{
                                    width: '50%',
                                    '& .MuiInputBase-root': {
                                        width: '75%',
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    setHideCustomProperties(true);
                                    resetProperty();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                disabled={!propertyName || !propertyValue}
                                onClick={() => {
                                    const newValue = { group: 'Custom', key: propertyName, value: propertyValue };
                                    setCustomContentParameters((crt) => [
                                        ...crt.filter((current) => current.key !== propertyName),
                                        newValue,
                                    ]);
                                    setContentParameters((crt) => [
                                        ...crt.filter(
                                            (current) => current.group !== 'Custom' || current.key !== propertyName
                                        ),
                                        newValue,
                                    ]);
                                    resetProperty();
                                }}
                            >
                                Add
                            </Button>
                        </Box>
                    </>
                )}
            </Box>,
            'servicenow-generated-webhook-http-method-content-parameters'
        ),
        <Typography>Generate the script.</Typography>,
        makeNonListItem(
            <Button onClick={handGenerateScript} variant="outlined">
                Generate script
            </Button>,
            'servicenow-generated-webhook-generate-script-button'
        ),
        makeNonListItem(
            <ReactMarkdown
                components={{
                    code: (props) => <CodeBlock {...props} onCopy={undefined} codeBlockHeight={150} />,
                }}
            >
                {`\`\`\`javascript\n${generatedScript}\n\`\`\``}
            </ReactMarkdown>,
            'servicenow-generated-webhook-generate-script'
        ),
        setupBaseUrl ? (
            <Typography>
                Navigate to{' '}
                <Link target="_blank" href={`${setupBaseUrl}/sys.scripts.modern.do`}>
                    script editor
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>
                Open <strong>https://[YOUR_SERVICENOW_INSTANCE]/sys.scripts.modern.do</strong> in your browser.
            </Typography>
        ),
        <Typography>
            Copy and paste the script into the <strong>Script Editor</strong>.
        </Typography>,
        <Typography>
            Choose <strong>global</strong> scope and click <strong>Run Script</strong>. (This script will create a Rest
            Message with a Post HTTP Method and a Business Rule in your ServiceNow instance.)
        </Typography>,
        makeNonListItem(
            <EventListenerInstructionsStep
                content={generatedScriptWebhookSetupRestMessageAdvancedInstructions}
                id="servicenow-webhook-rest-message-advanced-instructions"
                title="How to update Rest Message (Advanced)"
            />,
            'servicenow-generated-webhook-rest-message-advanced-instructions'
        ),
        makeNonListItem(
            <EventListenerInstructionsStep
                content={generatedScriptWebhookSetupBusinessRuleAdvancedInstructions}
                id="servicenow-webhook-business-rule-advanced-instructions"
                title="How to update Business Rule (Advanced)"
            />,
            'servicenow-generated-webhook-business-rule-advanced-instructions'
        ),
    ];

    return (
        <Dialog
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title="Event listener setup"
            subtitle={appName}
            onClose={onClose}
            loading={loading}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" component="h6" mb={0.5}>
                        How to set up an event listener
                    </Typography>
                    <Typography color="text.secondary">
                        Create a webhook in {appName} instance to listen to events.
                    </Typography>
                </Box>
                <StyledBorderBox>
                    <StyledBorderBoxContent
                        gap={1.5}
                        sx={{
                            '& pre': {
                                margin: 0,
                            },
                        }}
                    >
                        <OrderedList
                            content={generatedScriptWebhookSetupInstructions}
                            id="servicenow-generate-webhook-setup"
                        />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </>
        </Dialog>
    );
};

const generateWebhookSetupScript = (
    webhookUrl: string,
    brTable: string,
    brExecutionOperation: BusinessRuleExecutionOperation,
    contentParameters: typeof verifiedContentParameters
): string => `var webhookName = 'Webhook ' + new Date().toISOString();
var webhookUrl = '${webhookUrl}';
var restMethodName = 'HTTP method: ' + webhookName;
(function () {
	// Create REST Message
	var restMsgGr = new GlideRecord('sys_rest_message');
	restMsgGr.initialize();
	restMsgGr.name = webhookName;
	restMsgGr.rest_endpoint = webhookUrl;
	restMsgGr.insert();
	// Get the Sys ID of the newly created REST message
	var restMsgSysId = restMsgGr.getUniqueValue();
	// Create REST Method
	var restMethodGr = new GlideRecord('sys_rest_message_fn');
	restMethodGr.initialize();
	restMethodGr.function_name = restMethodName;
	restMethodGr.rest_message = restMsgSysId;
	restMethodGr.http_method = 'post';
	restMethodGr.rest_endpoint = webhookUrl;
	restMethodGr.content = JSON.stringify({
        eventType: "generic_triggered",
        ${contentParameters.map((parameter) => `${parameter.key}: "\${${parameter.key}}",`).join('\n')}
	});
	restMethodGr.insert();
	// Get the Sys ID of the newly created REST method
	var restMethodSysId = restMethodGr.getUniqueValue();
	// Create HTTP Header
	var httpHeaderGr = new GlideRecord('sys_rest_message_fn_headers');
	httpHeaderGr.initialize();
	httpHeaderGr.rest_message_function = restMethodSysId;
	httpHeaderGr.name = 'Content-Type';
	httpHeaderGr.value = 'application/json; charset=UTF-8';
	httpHeaderGr.insert();
	// Variable Substitutions
    ${contentParameters
        .map(
            (parameter) => `var ${parameter.key}VariableGr = new GlideRecord('sys_rest_message_fn_parameters');
	${parameter.key}VariableGr.initialize();
	${parameter.key}VariableGr.rest_message_function = restMethodSysId;
	${parameter.key}VariableGr.name = '${parameter.key}';
	${parameter.key}VariableGr.insert();`
        )
        .join('\n')}
	gs.info('REST Message and Method created successfully');
})();
(function () {
	// Create Business Rule
	var brGr = new GlideRecord('sys_script');
	brGr.initialize();
	brGr.name = 'BR ' + webhookName;
	brGr.collection = '${brTable}';
	brGr.order = 100;
	brGr.when = 'after';
	brGr.action_insert = ${brExecutionOperation.insert};
    brGr.action_update = ${brExecutionOperation.update};
	brGr.action_delete = ${brExecutionOperation.delete};
	brGr.action_query = ${brExecutionOperation.query};
	brGr.active = true;
	brGr.script = "try {\\n" +
		"	var r = new sn_ws.RESTMessageV2('" + webhookName + "', '" + restMethodName + "');\\n" +
        ${contentParameters
            .map((parameter) => `"	r.setStringParameterNoEscape('${parameter.key}', ${parameter.value});\\n" +`)
            .join('\n')}
		"	var response = r.executeAsync();\\n" +
		"	var responseBody = response.getBody();\\n" +
		"	var httpStatus = response.getStatusCode();\\n" +
		"} catch (ex) {\\n" +
		"	var message = ex.message;\\n" +
		"}";
	brGr.insert();
	gs.info('Business Rule created successfully');
})();`;
