import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { InfoIcon } from '../../icons/InfoIcon';
import { ProductIcon } from '../../icons/ProductIcon';
import { StyledResourceListItemButton, StyledResourceInfo } from './ResourceTreeComponents';
import { MouseEvent } from 'react';

interface ResourceTreeApiHandlerProps {
    appName?: string;
    connectionName?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    existingRecommendedApiVersion?: boolean;
    needsSetup?: boolean;
    path?: string;
    readOnlyMode?: boolean;
    selectedApiHandlerLibrary?: {
        uid: string;
        label?: string | null;
        deprecated: boolean;
        recommended: boolean;
    } | null;
    selectedNode?: string;
    uid: string;
    onDelete(uid: string, fullPath?: string): void;
    onPathCopy?(uid: string): void;
}

export const ResourceTreeApiHandler: React.FC<ResourceTreeApiHandlerProps> = ({
    appName,
    connectionName,
    deleting = false,
    deployedEnvironmentMode = false,
    needsSetup = false,
    path,
    readOnlyMode = false,
    existingRecommendedApiVersion = false,
    selectedApiHandlerLibrary,
    selectedNode,
    uid,
    onDelete,
    onPathCopy,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selected = selectedNode === uid;
    const showSetupWarning = needsSetup && !readOnlyMode;
    const canCopyPath = !deployedEnvironmentMode && !!path && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;
    const recommendedApiVersionAvailable =
        existingRecommendedApiVersion &&
        selectedApiHandlerLibrary &&
        selectedApiHandlerLibrary.label &&
        !selectedApiHandlerLibrary.recommended &&
        !selectedApiHandlerLibrary.deprecated;

    const handleCopyImportPath = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onPathCopy?.(uid);
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onDelete(uid);
    };

    return (
        <StyledResourceListItemButton
            key={uid}
            endIcons={[
                ...(canCopyPath
                    ? [
                          <IconButton
                              aria-label="Copy import path statement"
                              icon={<ContentCopyIcon />}
                              size="small"
                              tooltip="Copy import path statement"
                              onClick={handleCopyImportPath}
                          />,
                      ]
                    : []),
                ...(canDelete
                    ? [
                          <IconButton
                              aria-label="Delete API connection"
                              busy={deleting}
                              icon={<DeleteOutlineIcon />}
                              size="small"
                              tooltip="Delete API connection"
                              onClick={handleDelete}
                          />,
                      ]
                    : []),
            ]}
            label={readOnlyMode ? appName ?? 'API connection' : connectionName ?? 'New API connection'}
            link={{ href: `./api/${uid}`, type: 'router' }}
            selected={selected}
            startIcon={
                <Tooltip title={appName} placement="top">
                    <ProductIcon name={appName} />
                </Tooltip>
            }
        >
            {path && (
                <Tooltip title={`./api/${path}`} placement="top-start">
                    <StyledResourceInfo>
                        <LinkIcon />
                        <Typography>
                            API path:{' '}
                            <Typography color="primary.main" display="inline">
                                {path}
                            </Typography>
                        </Typography>
                    </StyledResourceInfo>
                </Tooltip>
            )}
            {showSetupWarning && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Complete API connection setup</em>
                    </Typography>
                </StyledResourceInfo>
            )}
            {!showSetupWarning && !readOnlyMode && selectedApiHandlerLibrary?.deprecated && (
                <StyledResourceInfo>
                    <WarningOutlinedIcon color="warning" />
                    <Typography color="warning.main">
                        <em>Using deprecated Vendor API</em>
                    </Typography>
                </StyledResourceInfo>
            )}
            {!showSetupWarning && !readOnlyMode && recommendedApiVersionAvailable && (
                <StyledResourceInfo>
                    <InfoIcon size="small" />
                    <Typography>
                        <em>Recommended Vendor API available</em>
                    </Typography>
                </StyledResourceInfo>
            )}
        </StyledResourceListItemButton>
    );
};
