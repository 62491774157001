import { styled } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { Button } from '../../common/buttons/Button';
import { Dialog } from '../../common/dialogs/Dialog';
import { IconCircle } from '../../common/IconCircle';
import { OnboardingAppItem } from '../../onboarding/wizard/app-selector/OnboardingAppItem';
import { StyledPageGridContainer } from '../../layout/PageComponents';
import { handleKeyDown } from '../../../utils/input';

interface AppDialogProps {
    apps: {
        uid: string;
        name: string;
    }[];
    open: boolean;
    userSelectedApps: string[];
    onClose: () => void;
    onUpdate: (apps: string[]) => void;
}

const StyledAppsContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    padding: theme.spacing(2, 0),
}));

export const AppDialog: React.FC<AppDialogProps> = ({ apps, open = false, userSelectedApps, onClose, onUpdate }) => {
    const [selectedApps, setSelectedApps] = useState<string[]>(userSelectedApps ?? []);

    const handleSelectApp = (uid: string): void => {
        if (selectedApps.includes(uid)) {
            setSelectedApps(selectedApps.filter((sa) => sa !== uid));
        } else {
            setSelectedApps([...selectedApps, uid]);
        }
    };

    const appItems = apps
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((app) => (
            <OnboardingAppItem
                key={app.uid}
                name={app.name}
                selected={selectedApps.includes(app.uid)}
                uid={app.uid}
                onSelect={handleSelectApp}
            />
        ));

    const updateButtonText = `Update ${selectedApps.length > 0 ? '(' + selectedApps.length + ')' : ''}`;

    const buttons = [
        <Button variant="outlined" onClick={onClose}>
            Close
        </Button>,
        <Button
            onClick={() => {
                onUpdate(selectedApps);
                onClose();
            }}
        >
            {updateButtonText}
        </Button>,
    ];

    return (
        <Dialog
            buttons={buttons}
            icon={<IconCircle icon={<AppsOutlinedIcon />} />}
            open={open}
            subtitle="Tell us which apps you use so we can suggest improvements for you"
            title="What apps do you use?"
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, escFn: onClose })}
            sx={{ '& .MuiPaper-root': { width: 900 } }} // TODO: Fix that by introducing dialog sizes
        >
            <Typography variant="subtitle1">Select all that apply</Typography>
            <StyledAppsContainer>
                <StyledPageGridContainer>{appItems}</StyledPageGridContainer>
            </StyledAppsContainer>
        </Dialog>
    );
};
