import { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import { SortOrder, Table } from '../common/Table';
import { WorkspacePackages } from '../../data/package-manager';
import { handleKeyDown } from '../../utils/input';
import { sortAlphabetically } from '../../utils/miscellaneous';

export interface PackageUpgrade {
    documentationLink: string;
    installedVersion: string;
    latestVersion?: string;
    majorUpdate?: boolean;
    name: string;
    npmLink: string;
    type: string;
}

interface PackageUpgradeEvent {
    packages: WorkspacePackages;
    changelogClickCount: number;
}

interface PackageUpgradeDialogProps {
    deployedEnvironmentMode?: boolean;
    errors?: string;
    loading?: boolean;
    open?: boolean;
    packages: PackageUpgrade[];
    saving?: boolean;
    loadingTypeDeclarations?: boolean;
    loadingTypesMessage?: string;
    onApplyChanges(event: PackageUpgradeEvent): void;
    onCancel(changelogClickCount: number): void;
}

type SortBy = 'name' | 'type';

export const PackageUpgradeDialog: React.FC<PackageUpgradeDialogProps> = ({
    deployedEnvironmentMode = false,
    errors,
    open = false,
    packages,
    saving = false,
    loadingTypeDeclarations = false,
    loadingTypesMessage,
    onApplyChanges,
    onCancel,
}) => {
    const [selectedPackages, setSelectedPackages] = useState<WorkspacePackages>([]);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [sortOrder, setSortOrder] = useState<{ name: SortOrder; type: SortOrder }>({ name: 'asc', type: 'asc' });
    const [changelogClicks, setChangelogClicks] = useState<string[]>([]);

    useEffect(() => {
        setSelectedPackages([]);
    }, [packages]);

    const handleSelectPackage = (pkgName: string): void => {
        const selectedPackage = packages.find((p) => p.name === pkgName);

        if (selectedPackage) {
            if (selectedPackages.some((sPkg) => sPkg.name === selectedPackage.name)) {
                setSelectedPackages(selectedPackages.filter((sp) => sp.name !== selectedPackage.name));
            } else {
                setSelectedPackages([
                    ...selectedPackages,
                    {
                        name: selectedPackage.name,
                        type: selectedPackage.type,
                        version: selectedPackage.latestVersion ?? selectedPackage.installedVersion,
                    },
                ]);
            }
        }
    };

    const handleSelectAll = (checked: boolean): void => {
        if (checked) {
            setSelectedPackages(
                packages.map((p) => ({ name: p.name, type: p.type, version: p.latestVersion ?? p.installedVersion }))
            );
            return;
        }
        setSelectedPackages([]);
    };

    const handleSort = (selectedSortBy: SortBy): void => {
        if (selectedSortBy === sortBy) {
            setSortOrder({ ...sortOrder, [selectedSortBy]: sortOrder[selectedSortBy] === 'asc' ? 'desc' : 'asc' });
        } else {
            setSortBy(sortBy === 'name' ? 'type' : 'name');
        }
    };

    const handleClickChangelog = (event: React.MouseEvent<HTMLSpanElement>, link: string): void => {
        event.stopPropagation();
        if (!changelogClicks.includes(link)) {
            setChangelogClicks([...changelogClicks, link]);
        }
    };

    const handleSave = (): void => {
        onApplyChanges({
            packages: selectedPackages,
            changelogClickCount: changelogClicks.length,
        });
    };

    const majorUpdates = packages.filter((p) => p.majorUpdate);
    const mainAlertTitle = `Outdated Package${packages.length !== 1 ? 's' : ''} Detected`;
    const warningAlertTitle = `Major Package Update${majorUpdates.length !== 1 ? 's' : ''}`;
    const saveButtonText = `Apply Changes ${selectedPackages.length > 0 ? '(' + selectedPackages.length + ')' : ''}`;
    const allOptionsSelected = selectedPackages.length === packages.length;
    const canSave = !saving && selectedPackages.length > 0 && !loadingTypeDeclarations;

    const sortedPackages = sortAlphabetically(packages, sortBy, sortOrder[sortBy]);

    const columns = [
        {
            id: 'name',
            title: 'Name',
            sortable: true,
        },
        {
            id: 'type',
            title: 'Type',
            sortable: true,
        },
        {
            id: 'installedVersion',
            title: 'Installed Version',
        },
        {
            id: 'latestVersion',
            title: 'Latest Version',
        },
        {
            id: 'majorUpdate',
        },
        {
            id: 'documentation',
            title: 'Documentation',
        },
    ];

    const rows = sortedPackages.map((p) => ({
        columns: {
            name: (
                <Typography maxWidth={200}>
                    <Link href={p.npmLink} target="_blank" onClick={(e) => e.stopPropagation()}>
                        {p.name}
                    </Link>
                </Typography>
            ),
            type: p.type,
            installedVersion: p.installedVersion ?? '',
            latestVersion: p.latestVersion ?? '',
            majorUpdate: p.majorUpdate ? (
                <Tooltip title="Major version upgrade, will most likely introduce breaking changes.">
                    <WarningAmberIcon sx={{ color: 'warning.light' }} />
                </Tooltip>
            ) : (
                ''
            ),
            documentation: (
                <Typography>
                    <Link
                        noWrap
                        href={p.documentationLink}
                        target="_blank"
                        onClick={(e) => handleClickChangelog(e, p.documentationLink)}
                    >
                        View Changelog
                    </Link>
                </Typography>
            ),
        },
        'data-testid': `package-upgrade-dialog-package-${p.name}`,
        id: p.name,
    }));

    const buttons = [
        <Button variant="outlined" onClick={() => onCancel(changelogClicks.length)}>
            Cancel
        </Button>,
        <Button onClick={handleSave} disabled={!canSave} busy={saving}>
            {saveButtonText}
        </Button>,
    ];

    return (
        <Dialog
            errors={errors}
            buttons={buttons}
            open={open}
            size="medium"
            title="Package updates available"
            onClose={() => onCancel(changelogClicks.length)}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canSave,
                    enterFn: handleSave,
                    escFn: () => onCancel(changelogClicks.length),
                })
            }
        >
            <Alert
                title={mainAlertTitle}
                text="Ensure software security, stability and performance by regularly updating packages."
                severity="info"
            />
            {loadingTypeDeclarations && (
                <Alert
                    title={loadingTypesMessage}
                    text="Package updates can only be applied when the editor has finished loading types."
                    severity="info"
                />
            )}
            {deployedEnvironmentMode && (
                <Alert
                    title="Package updates will only be applied for the HEAD version."
                    text="You will need to release a new version into the current environment to apply package updates."
                    severity="warning"
                />
            )}
            <Table
                columns={columns}
                rows={rows}
                selectable="multiple"
                selectedItems={selectedPackages.map((sp) => sp.name)}
                sortBy={sortBy}
                sortOrder={sortOrder}
                onSort={({ by }) => handleSort(by as SortBy)}
                onSelectAll={() => handleSelectAll(!allOptionsSelected)}
                onSelectItem={(pkgName) => handleSelectPackage(pkgName)}
            />
            {majorUpdates.length > 0 && (
                <Alert
                    title={warningAlertTitle}
                    text="Please carefully review each changelog for information regarding potential breaking changes, as they are inevitable."
                    severity="warning"
                />
            )}
        </Dialog>
    );
};
