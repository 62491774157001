import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';

interface StatuspageEventListenerSetupDialogProps {
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const StatuspageEventListenerSetupDialog: React.FC<StatuspageEventListenerSetupDialogProps> = ({
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Statuspage';

    const listContent = [
        <Typography>
            Log into your
            <Link target="_blank" href="https://manage.statuspage.io/">
                {' '}
                {connectionTypeName} instance
            </Link>
        </Typography>,
        'On the left side menu, make sure that you have selected the organization and page which you want to subscribe to.',
        <Typography>
            Click <strong>Subscribers</strong>.
        </Typography>,
        <Typography>
            Make sure that you have webhooks enabled. To do that click on <strong>Options</strong> to the right of the
            search box, select <strong>Settings</strong> and on the appearing modal, make sure that you have the{' '}
            <strong>Webhook</strong> option checked.
        </Typography>,
        <Typography>
            To create a new webhook, click <strong>Options</strong> again and select <strong>Add subscriber</strong>.
        </Typography>,
        <Typography>
            Under <strong>Subscriber type</strong> select <strong>Webhook</strong>.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>Endpoint URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'statuspage-webhook-url'
        ),
        <Typography>
            Enter any email into the <strong>Email address</strong> field. This is where {connectionTypeName} will send
            notifications should the endpoint fail.
        </Typography>,
        <Typography>
            Click <strong>Add subscriber</strong>.
        </Typography>,
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={connectionTypeName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={connectionTypeName}
                onClose={onClose}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="statuspage" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
