import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';

interface SlackEventListenerSetupDialogProps {
    eventTypeCategory: string;
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const SlackEventListenerSetupDialog: React.FC<SlackEventListenerSetupDialogProps> = ({
    eventTypeCategory,
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const appName = 'Slack';
    const isSlashCommand = eventTypeName === 'Slash Command';

    const isInteractiveComponentEvent = eventTypeCategory === 'Interactive Component';

    const isSlackEvent = !isSlashCommand && !isInteractiveComponentEvent;

    const eventPath = isSlashCommand
        ? '/slash-commands'
        : isInteractiveComponentEvent
        ? '/interactive-messages'
        : '/event-subscriptions';

    const eventType = isSlashCommand
        ? 'Slash Commands'
        : isInteractiveComponentEvent
        ? 'Interactivity & Shortcuts'
        : 'Event Subscriptions';

    const listContent = [
        setupBaseUrl ? (
            <Typography>
                Visit the{' '}
                <Link target="_blank" href={`${setupBaseUrl}${eventPath}`}>
                    {eventType}
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>
                Open https://api.slack.com/apps/[YOUR_SLACK_APP_ID]{`${eventPath}`} URL in your browser
            </Typography>
        ),
        ...(isSlashCommand
            ? [
                  <Typography>
                      Click Create <strong>New Command</strong> or edit an existing command if the slash command already
                      exists.
                  </Typography>,
                  <Typography>
                      Enter a slash command beginning with <strong>/</strong> into the <strong>Command</strong> field.
                  </Typography>,
                  <Typography>
                      Paste the copied URL into the <strong>Request URL</strong> field.
                  </Typography>,
                  makeNonListItem(
                      <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
                      'slack-slash-command-webhook-url'
                  ),

                  <Typography>
                      Enter a short description of the command in the <strong>Short Description</strong> field.
                  </Typography>,
                  <Typography>
                      Then click <strong>Save</strong>.
                  </Typography>,
              ]
            : []),
        ...(isSlackEvent
            ? [
                  <Typography>
                      Switch on the <strong>Enable Events</strong> toggle.
                  </Typography>,
                  <Typography>
                      Paste the copied URL into the <strong>Request URL</strong> field. Slack will now verify the URL.
                      If it fails, click on retry. If it fails again, please contact support.
                  </Typography>,
                  makeNonListItem(
                      <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
                      'slack-event-webhook-url'
                  ),
                  <Typography>
                      Scroll down to <strong>Subscribe to bot events</strong>.
                  </Typography>,
                  <Typography>
                      Click on <strong>Add Bot User Event</strong>.
                  </Typography>,
                  <Typography>
                      Add the event: <strong>{eventTypeName.toLowerCase().replaceAll(' ', '_')}</strong>.
                  </Typography>,
                  <Typography>
                      Click on <strong>Save Changes</strong>.
                  </Typography>,
                  'Re-install the app if you are prompted to do so. This will trigger Slack to add missing bot permissions to your Slack app.',
              ]
            : []),
        ...(isInteractiveComponentEvent
            ? [
                  <Typography>
                      Switch on the <strong>Interactivity</strong> toggle.
                  </Typography>,
                  <Typography>
                      Paste the copied URL into the <strong>Request URL</strong> field. Slack will now verify the URL.
                      If it fails, click on retry. If it fails again, please contact support.
                  </Typography>,
                  makeNonListItem(
                      <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
                      'slack-interactive-component-webhook-url'
                  ),
                  <Typography>
                      If you want to listen to your Slack apps's shortcut events then scroll down to{' '}
                      <strong>Shortcuts</strong>. Click on <strong>Create New Shortcut</strong> and follow the Slack
                      instructions.
                  </Typography>,
              ]
            : []),
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <>
                            {isSlashCommand && (
                                <Typography color="text.secondary">
                                    Configure the slash command in your Slack app in order to listen to events.
                                </Typography>
                            )}
                            {isInteractiveComponentEvent && (
                                <Typography color="text.secondary">
                                    Enable interactivity in your Slack app in order to listen to interaction and
                                    shortcut events.
                                </Typography>
                            )}
                            {isSlackEvent && (
                                <Typography color="text.secondary">
                                    Enable event subscriptions in your Slack app in order to listen to Slack events.
                                </Typography>
                            )}
                        </>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="slack" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
