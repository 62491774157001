import { useObservableState } from 'observable-hooks';
import { closeConfirmDialogAction$, confirmDialogDetails$, confirmDialogOpen$ } from '../../store/confirm';
import { Button } from '../../components/common/buttons/Button';
import { Dialog } from '../../components/common/dialogs/Dialog';
import { IconCircle } from '../../components/common/IconCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { handleKeyDown } from '../../utils/input';

export const ConfirmDialogContainer: React.FC = () => {
    const { cancelButton, icon, messages, proceedButton, subtitle, title } =
        useObservableState(confirmDialogDetails$) ?? {};
    const open = useObservableState(confirmDialogOpen$);

    const displayedButtons = [
        ...(cancelButton
            ? [
                  <Button
                      color={cancelButton?.severity}
                      variant="outlined"
                      onClick={() => void cancelButton?.onClick?.()}
                  >
                      {cancelButton?.label ?? 'Cancel'}
                  </Button>,
              ]
            : []),
        <Button color={proceedButton?.severity} onClick={() => void proceedButton?.onClick?.()}>
            {proceedButton?.label}
        </Button>,
    ];

    const getDisplayedIcon = (): JSX.Element | undefined => {
        switch (icon) {
            case 'success':
                return (
                    <IconCircle
                        size={subtitle ? 'medium' : 'small'}
                        icon={<CheckCircleOutlineIcon />}
                        severity="success"
                    />
                );
            case 'info':
                return <IconCircle size={subtitle ? 'medium' : 'small'} icon={<InfoOutlinedIcon />} severity="info" />;
            case 'warning':
                return (
                    <IconCircle
                        size={subtitle ? 'medium' : 'small'}
                        icon={<WarningAmberOutlinedIcon />}
                        severity="warning"
                    />
                );
            case 'error':
                return (
                    <IconCircle size={subtitle ? 'medium' : 'small'} icon={<ReportOutlinedIcon />} severity="error" />
                );
            default:
                return icon;
        }
    };

    return (
        <Dialog
            buttons={displayedButtons}
            icon={getDisplayedIcon()}
            messages={messages}
            open={open}
            subtitle={subtitle}
            title={title ?? ''}
            onClose={() => closeConfirmDialogAction$.next()}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterFn: () => void proceedButton?.onClick?.(),
                    escFn: () => closeConfirmDialogAction$.next(),
                })
            }
        />
    );
};
