import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { SecretTextField } from '../../../common/inputs/custom/SecretTextField';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { ZoomEventListenerWizardStage, ZoomEventListenerWizardSteps } from './ZoomEventListenerWizardSteps';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { APP } from '@avst-stitch/repository-lib/constants';

interface ZoomEventListenerSetupDialogProps {
    errors?: string;
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    saving?: boolean;
    stage: ZoomEventListenerWizardStage;
    webhookBaseUrl: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
    onSave: (token: string) => void;
    onSetStage: (stage: ZoomEventListenerWizardStage) => void;
}

export const ZoomEventListenerSetupDialog: React.FC<ZoomEventListenerSetupDialogProps> = ({
    errors,
    eventTypeName,
    loading = false,
    open = false,
    saving = false,
    stage,
    webhookBaseUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
    onSave,
    onSetStage,
}) => {
    const appName = APP.ZOOM.NAME;

    const [token, setToken] = useState('');

    useEffect(() => {
        setToken('');
    }, [open]);

    const canSaveToken = !!token && !loading && !saving;

    const saveTokenInstructions = [
        <Typography>
            Visit the{' '}
            <Link target="_blank" href="https://marketplace.zoom.us/user/build">
                Zoom Apps Marketplace
            </Link>{' '}
            in your browser. If you are not signed in, please sign in first.
        </Typography>,
        <Typography>Select the {appName} app you want to setup the event listener for.</Typography>,
        <Typography>
            Click on <strong>Feature</strong>.
        </Typography>,
        <Typography>
            Copy the <strong>Secret Token</strong> token and paste it in the <strong>Secret Token</strong> field below.
        </Typography>,
        makeNonListItem(
            <SecretTextField
                disabled={workspaceLocked}
                label="Secret Token"
                placeholder="Enter secret token"
                value={token}
                onChange={(e) => setToken(e.target.value.trim())}
            />,
            'zoom-secret-token'
        ),
        <Typography>
            Click <strong>Save</strong> below.
        </Typography>,
    ];

    const addEventInstructions = [
        <Typography>
            Scroll down to <strong>General Features</strong> and enable <strong>Event Subscriptions</strong>.
        </Typography>,
        <Typography>
            Click on <strong>Add Event Subscription</strong>.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Subscription name</strong> field.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>Event notification endpoint URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'zoom-webhook-url'
        ),
        <Typography>
            Click on <strong>Validate</strong> so {appName} can validate the URL.{' '}
            <em>Note that you must have saved the token in the previous step</em>.
        </Typography>,
        <Typography>
            Click on <strong>Add Events</strong>.
        </Typography>,
        <Typography>
            Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
        </Typography>,
        <Typography>
            Click <strong>Save</strong>.
        </Typography>,
    ];

    switch (stage) {
        case ZoomEventListenerWizardStage.SAVE_TOKEN:
            return (
                <Dialog
                    buttons={[
                        <Button disabled={loading} onClick={onClose} variant="outlined">
                            Cancel
                        </Button>,
                        <Button busy={saving} disabled={saving || !token} onClick={() => onSave(token)}>
                            Save and continue
                        </Button>,
                    ]}
                    leftButton={
                        <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                            Learn more
                        </Button>
                    }
                    icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                    open={open}
                    title="Event listener setup"
                    subtitle={appName}
                    onClose={onClose}
                    loading={loading}
                    onKeyDown={(event) =>
                        handleKeyDown({
                            event,
                            enterCondition: canSaveToken,
                            enterFn: () => onSave(token),
                            escFn: onClose,
                        })
                    }
                    progress={50}
                >
                    <ZoomEventListenerWizardSteps stage={stage} />
                    {errors && <Alert severity="error" title={errors} />}
                    <Box>
                        <Typography variant="subtitle1" mb={0.5}>
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} instance to listen to events.
                        </Typography>
                    </Box>
                    <StyledBorderBox>
                        <StyledBorderBoxSubHeader>
                            <Typography variant="subtitle2">Step 1: Save Secret Token</Typography>
                        </StyledBorderBoxSubHeader>
                        <Divider />
                        <StyledBorderBoxContent gap={1.5}>
                            <OrderedList content={saveTokenInstructions} id="zoom-save-token" />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </Dialog>
            );

        case ZoomEventListenerWizardStage.ADD_EVENT:
            return (
                <Dialog
                    buttons={[
                        <Button
                            disabled={loading}
                            onClick={() => onSetStage(ZoomEventListenerWizardStage.SAVE_TOKEN)}
                            variant="outlined"
                        >
                            Back
                        </Button>,
                        <Button onClick={onClose}>Save changes</Button>,
                    ]}
                    leftButton={
                        <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                            Learn more
                        </Button>
                    }
                    icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                    open={open}
                    title="Event listener setup"
                    subtitle={appName}
                    onClose={onClose}
                    loading={loading}
                    onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
                    progress={100}
                >
                    <ZoomEventListenerWizardSteps stage={stage} />
                    <Box>
                        <Typography variant="subtitle1" mb={0.5}>
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} instance to listen to events.
                        </Typography>
                    </Box>
                    <StyledBorderBox>
                        <StyledBorderBoxSubHeader>
                            <Typography variant="subtitle2">Step 2: Add Event</Typography>
                        </StyledBorderBoxSubHeader>
                        <Divider />
                        <StyledBorderBoxContent gap={1.5}>
                            <OrderedList content={addEventInstructions} id="zoom-add-event" startCounter={6} />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </Dialog>
            );
    }
};
