import { sendMessageToAIAssistance as sendMessageToAIAssistanceRpc } from '@avst-stitch/repository-lib/lib';
import { saveAIAssistanceResponse as saveAIAssistanceResponseRpc } from '@avst-stitch/repository-lib/lib';
import { updateAiAssistanceOnboardingCompletion as updateAiAssistanceOnboardingCompletionRpc } from '@avst-stitch/repository-lib/lib';
import { getAiAssistanceChatConversations as getAiAssistanceChatConversationsRpc } from '@avst-stitch/repository-lib/lib';
import { rateAiAssistanceResponse as rateAiAssistanceResponseRpc } from '@avst-stitch/repository-lib/lib';
import { renameAiAssistanceChatConversationTitle as renameAiAssistanceChatConversationTitleRpc } from '@avst-stitch/repository-lib/lib';
import { deleteAiAssistanceChatConversation as deleteAiAssistanceChatConversationRpc } from '@avst-stitch/repository-lib/lib';
import { getAiAssistanceChatConversationContent as getAiAssistanceChatConversationContentRpc } from '@avst-stitch/repository-lib/lib';
import { Request as sendMessageToAIRequest } from '@avst-stitch/repository-lib/lib/rpcs/sendMessageToAIAssistance';
import { Request as SaveAIResponseRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveAIAssistanceResponse';
import { Request as RateMessageRequest } from '@avst-stitch/repository-lib/lib/rpcs/rateAiAssistanceResponse';
import { Request as RenameTitleRequest } from '@avst-stitch/repository-lib/lib/rpcs/renameAiAssistanceChatConversationTitle';
import { Request as DeleteConversationRequest } from '@avst-stitch/repository-lib/lib/rpcs/deleteAiAssistanceChatConversation';
import { Request as ChatContentRequest } from '@avst-stitch/repository-lib/lib/rpcs/getAiAssistanceChatConversationContent';
import { Response as ChatHistory } from '@avst-stitch/repository-lib/lib/rpcs/getAiAssistanceChatConversations';
import { Response as ChatContent } from '@avst-stitch/repository-lib/lib/rpcs/getAiAssistanceChatConversationContent';
import { repositoryInvoker } from '../utils/repository';
export type {
    RateMessageRequest,
    ChatHistory,
    RenameTitleRequest,
    DeleteConversationRequest,
    ChatContentRequest,
    ChatContent,
};
export const sendMessageToAIAssistance = async (request: sendMessageToAIRequest): Promise<void> => {
    return await sendMessageToAIAssistanceRpc(repositoryInvoker, request);
};

export const saveAIAssistanceResponse = async (request: SaveAIResponseRequest): Promise<void> => {
    return await saveAIAssistanceResponseRpc(repositoryInvoker, request);
};

export const updateAiAssistanceOnboardingCompletion = async (request: { completed: boolean }): Promise<void> => {
    return await updateAiAssistanceOnboardingCompletionRpc(repositoryInvoker, request);
};

export const rateAiAssistanceResponse = async (request: RateMessageRequest): Promise<void> => {
    return await rateAiAssistanceResponseRpc(repositoryInvoker, request);
};

export const getAiAssistanceChatConversations = async (): Promise<ChatHistory> => {
    return await getAiAssistanceChatConversationsRpc(repositoryInvoker);
};

export const renameAiAssistanceChatConversationTitle = async (request: RenameTitleRequest): Promise<void> => {
    await renameAiAssistanceChatConversationTitleRpc(repositoryInvoker, request);
};

export const deleteAiAssistanceChatConversation = async (request: DeleteConversationRequest): Promise<void> => {
    await deleteAiAssistanceChatConversationRpc(repositoryInvoker, request);
};

export const getAiAssistanceChatConversationContent = async (request: ChatContentRequest): Promise<ChatContent> => {
    return await getAiAssistanceChatConversationContentRpc(repositoryInvoker, request);
};
