import { styled } from '@mui/material/styles';
import ReactHtmlParser from 'react-html-parser';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getSanitizedParsedMarkdown } from '../../../utils/readme';

export interface FullScreenReadmeProps {
    content: string;
}

const StyledReadme = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
}));

const StyledReadmeHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 66,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledReadmeMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(2),
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledReadmeContent = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2),
    overflow: 'auto',
}));

const StyledReadmePreviewContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

export const FullScreenReadme: React.FC<FullScreenReadmeProps> = ({ content }) => {
    return (
        <StyledReadme>
            <StyledReadmeHeader>
                <Typography variant="h5">README</Typography>
            </StyledReadmeHeader>
            <Divider />
            <StyledReadmeMain>
                <StyledReadmePreviewContent>
                    {ReactHtmlParser(getSanitizedParsedMarkdown(content))}
                </StyledReadmePreviewContent>
            </StyledReadmeMain>
        </StyledReadme>
    );
};
