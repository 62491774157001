/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useRef, useState } from 'react';
import { Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { IconButton } from '../../common/buttons/IconButton';
import { InformationChip } from '../../common/chips/InformationChip';
import { TextField } from '../../common/inputs/TextField';
import { emptyWarning } from './EnvironmentVariableComponents';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxHeaderActions,
} from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { autoFocus } from '../../../utils/input';
import { handleKeyDown } from '../../../utils/input';

export interface UpdateEnvironmentVariableChoiceEvent {
    isDefault?: boolean;
    label?: string;
    value?: string;
}

export interface Choice {
    editDisabled?: boolean;
    editMode?: boolean;
    hasBeenEdited?: boolean;
    isDefault?: boolean;
    label?: string;
    selected?: boolean;
    type: 'MULTIPLE_CHOICES' | 'SINGLE_CHOICE';
    value?: string;
    variableReadOnlyMode?: boolean;
}

interface EnvironmentVariableChoiceDetailsProps extends Choice {
    existingValues?: string[];
    onDelete?(): void;
    onSelect?(): void;
    onToggleEditMode?(editMode: boolean): void;
    onUpdate?(event: UpdateEnvironmentVariableChoiceEvent): void;
}

const StyledChoice = styled(StyledBorderBox)(() => ({
    flex: '1 1 100%',
    minHeight: 40,
    overflow: 'hidden',
}));

const StyledReadOnlyChoiceContent = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.spacing(0.5, 1.5),
    width: '100%',
}));

const StyledReadOnlyChoiceLabel = styled(Box)(() => ({
    wordBreak: 'break-word',
}));

const StyledDefaultValueControl = styled(FormControlLabel)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    height: 40,
    margin: 0,
    '& .MuiCheckbox-root': {
        margin: 0,
    },
}));

export const EnvironmentVariableChoiceDetails: React.FC<EnvironmentVariableChoiceDetailsProps> = ({
    editDisabled = false,
    editMode = false,
    existingValues = [],
    hasBeenEdited = false,
    isDefault = false,
    label = '',
    selected = false,
    type,
    value = '',
    variableReadOnlyMode = false,
    onDelete,
    onSelect,
    onToggleEditMode,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentLabel, setCurrentLabel] = useState(label);
    const [currentValue, setCurrentValue] = useState(value);
    const [currentDefault, setCurrentDefault] = useState(isDefault);

    const labelInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        autoFocus(labelInputRef);
    }, [editMode]);

    useEffect(() => {
        setCurrentDefault(isDefault);
    }, [isDefault]);

    const handleCancel = (): void => {
        setCurrentLabel(label);
        setCurrentValue(value);
        setCurrentDefault(isDefault);
        onToggleEditMode?.(false);
    };

    const handleUpdate = (event?: React.MouseEvent | React.KeyboardEvent): void => {
        event?.stopPropagation();
        onUpdate?.({
            isDefault: currentDefault,
            label: currentLabel,
            value: currentValue,
        });
    };

    const hasChanged = currentDefault !== isDefault || currentLabel !== label || currentValue !== value;
    const isValueDuplicate = !!existingValues.length && !!currentValue && existingValues.includes(currentValue);

    const isLabelDuplicate =
        !!existingValues.length && !currentValue && !!currentLabel && existingValues.includes(currentLabel);

    const defaultValueSelectionLabel =
        type === 'MULTIPLE_CHOICES' ? 'Also use as a default value' : 'Also use as the default value';

    const canUpdate = !isValueDuplicate && !isLabelDuplicate && (!hasBeenEdited || hasChanged);

    return (
        <StyledChoice
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canUpdate && editMode,
                    enterFn: () => handleUpdate(event),
                    escFn: handleCancel,
                })
            }
        >
            {editMode && !variableReadOnlyMode ? (
                <>
                    <StyledBorderBoxHeader>
                        <Typography variant="subtitle2">{`${hasBeenEdited ? 'Edit' : 'Create'} Choice`}</Typography>
                    </StyledBorderBoxHeader>
                    <Divider />
                    <StyledBorderBoxContent>
                        <Alert
                            sx={{ minWidth: 0 }}
                            scrollIntoViewDisabled
                            severity="warning"
                            text="If no value is specified, the label will be used as value instead."
                        />
                        <TextField
                            fullWidth
                            label="Label"
                            inputRef={labelInputRef}
                            required
                            placeholder="Enter label"
                            error={isLabelDuplicate}
                            helperText={
                                isLabelDuplicate
                                    ? 'This label is already used as a value for another choice'
                                    : !currentLabel
                                    ? 'Please enter label'
                                    : undefined
                            }
                            size="small"
                            value={currentLabel}
                            onChange={(e) => setCurrentLabel(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Value"
                            placeholder="Enter value"
                            error={isValueDuplicate}
                            helperText={isValueDuplicate && 'Choice with this value already exists'}
                            size="small"
                            value={currentValue}
                            onChange={(e) => setCurrentValue(e.target.value)}
                        />
                        <Divider>
                            <Typography color="text.secondary" textTransform="uppercase" variant="body2">
                                Developer Options
                            </Typography>
                        </Divider>
                        <StyledDefaultValueControl
                            control={
                                <Checkbox
                                    checked={currentDefault}
                                    onChange={() => setCurrentDefault(!currentDefault)}
                                />
                            }
                            label={defaultValueSelectionLabel}
                        />
                    </StyledBorderBoxContent>
                    <Divider />
                    <StyledMainActions>
                        <Button variant="outlined" size="small" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={!canUpdate} size="small" onClick={handleUpdate}>
                            {hasBeenEdited ? 'Update' : 'Create'}
                        </Button>
                    </StyledMainActions>
                </>
            ) : (
                <StyledReadOnlyChoiceContent>
                    {variableReadOnlyMode ? (
                        <FormControlLabel
                            disabled={true}
                            control={
                                type === 'SINGLE_CHOICE' ? (
                                    <Radio checked={selected} />
                                ) : (
                                    <Checkbox checked={selected} />
                                )
                            }
                            label={
                                !label && !value ? (
                                    emptyWarning(true)
                                ) : (
                                    <StyledReadOnlyChoiceLabel>
                                        <Typography>{label}</Typography>
                                        {value && <Typography>{value}</Typography>}
                                    </StyledReadOnlyChoiceLabel>
                                )
                            }
                        />
                    ) : (
                        <FormControlLabel
                            control={
                                type === 'SINGLE_CHOICE' ? (
                                    <Radio
                                        disabled={!value && !label}
                                        checked={selected}
                                        onChange={() => onSelect?.()}
                                    />
                                ) : (
                                    <Checkbox
                                        disabled={!value && !label}
                                        checked={selected}
                                        onChange={() => onSelect?.()}
                                    />
                                )
                            }
                            label={
                                !currentLabel && !currentValue ? (
                                    emptyWarning(true)
                                ) : (
                                    <StyledReadOnlyChoiceLabel>
                                        <Typography>{currentLabel}</Typography>
                                        {currentValue && (
                                            <Typography color="text.secondary" variant="body2">
                                                {currentValue}
                                            </Typography>
                                        )}
                                    </StyledReadOnlyChoiceLabel>
                                )
                            }
                        />
                    )}
                    {isDefault && <InformationChip label="Default" severity="grey" />}
                    {!variableReadOnlyMode && (
                        <StyledBorderBoxHeaderActions>
                            <IconButton
                                aria-label="Edit choice"
                                disabled={editDisabled}
                                icon={<EditOutlinedIcon />}
                                size="small"
                                tooltip="Edit choice"
                                onClick={() => onToggleEditMode?.(true)}
                            />
                            <IconButton
                                aria-label="Delete choice"
                                disabled={editDisabled}
                                icon={<DeleteOutlineIcon />}
                                size="small"
                                tooltip="Delete choice"
                                onClick={() => onDelete?.()}
                            />
                        </StyledBorderBoxHeaderActions>
                    )}
                </StyledReadOnlyChoiceContent>
            )}
        </StyledChoice>
    );
};
