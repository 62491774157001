import { styled } from '@mui/material';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { cloneElement } from 'react';

interface OrderedListProps extends ListProps {
    content?: (string | JSX.Element)[];
    counterType?: 'numeric' | 'alpha';
    id: string;
    startCounter?: number;
}

export const makeNonListItem = (element: JSX.Element, key: string): JSX.Element =>
    cloneElement(element, { nonListItem: true, key });

const StyledOrderedList = styled(List, {
    shouldForwardProp: (prop) => prop !== 'startCounter' && prop !== 'counterType',
})<{ startCounter: number; counterType?: 'numeric' | 'alpha' }>(({ theme, startCounter = 1, counterType }) => ({
    counterReset: `step-counter ${startCounter - 1}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    listStyle: 'none',
    margin: 0,
    overflow: 'hidden',
    padding: 0,
    '& .MuiListItem-root:before': {
        content: counterType === 'alpha' ? 'counter(step-counter, upper-alpha)' : 'counter(step-counter)',
    },
    '& .MuiFormControl-root': {
        margin: theme.spacing(1, 0),
    },
}));

const StyledOrderedListItem = styled(ListItem)(({ theme }) => ({
    alignItems: 'center',
    counterIncrement: 'step-counter',
    padding: 0,
    '&:before': {
        alignItems: 'center',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], // TODO: Need new color in theme
        borderRadius: theme.constants.radiusCircle,
        display: 'inline-flex',
        flexShrink: 0,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        height: theme.spacing(3),
        justifyContent: 'center',
        marginRight: theme.spacing(1.5),
        lineHeight: 22,
        width: theme.spacing(3),
    },
}));

export const OrderedList: React.FC<OrderedListProps> = ({
    content = [],
    counterType = 'numeric',
    id,
    startCounter = 1,
    ...props
}) => {
    const mappedContent = content.map((c, i) =>
        typeof c === 'string' || !c.props.nonListItem ? (
            <StyledOrderedListItem key={`${id}-${i}`}>{c}</StyledOrderedListItem>
        ) : (
            c
        )
    );

    return (
        <StyledOrderedList counterType={counterType} startCounter={startCounter} {...props}>
            {mappedContent}
        </StyledOrderedList>
    );
};
