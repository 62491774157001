import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import { IconCircle } from '../common/IconCircle';
import { handleKeyDown } from '../../utils/input';
import { ResendStatus } from '../../utils/account';

interface VerifyAccountDialogProps {
    resendLoading?: boolean;
    resendStatus: ResendStatus;
    onResend(): void;
}

export const VerifyAccountDialog: React.FC<VerifyAccountDialogProps> = ({
    resendLoading = false,
    resendStatus,
    onResend,
}) => {
    const buttons = [
        <Button
            busy={resendLoading}
            onClick={onResend}
            variant="outlined"
            data-pendo={'resendEmailVerificationRequested'}
        >
            Resend Verification Email
        </Button>,
    ];

    const messages = [];
    if (resendStatus === 'failed') {
        messages.push(
            <Typography key={'resendVerificationFailed'}>
                Could not send the verification link. Please click on <strong>Resend Verification Email</strong> to try
                again. If the issue persists, please contact support.
            </Typography>
        );
    } else if (resendStatus === 'pending' || resendStatus === 'completed') {
        messages.push(
            <Typography key={'resendVerificationPending1'}>
                A verification link is being sent to your email. It may take a few minutes to appear in your inbox. If
                you don't see the email, please check your spam or junk folder. If the issue persists, please contact
                support.
            </Typography>,
            <Typography key={'resendVerificationPending2'}>
                Didn't receive an email? Click on <strong>Resend Verification Email</strong> to resend the verification
                link.
            </Typography>
        );
    } else {
        messages.push(
            <Typography key={'resendVerificationInitial1'}>
                To complete your registration, please check your email for a verification link. Click on the link in the
                email to verify your account. It may take a few minutes for the email to arrive. If you don't see the
                email, please check your spam or junk folder.
            </Typography>,
            <Typography key={'resendVerificationInitial2'}>
                Didn't receive an email? Click on <strong>Resend Verification Email</strong> to resend the verification
                link.
            </Typography>
        );
    }

    return (
        <Dialog
            buttons={buttons}
            icon={<IconCircle icon={<WarningAmberIcon />} severity="warning" />}
            messages={messages}
            open={true}
            title="Email verification required"
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onResend })}
        ></Dialog>
    );
};
