import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    getOrganizationsReport,
    OrganizationsReportRequest,
    OrganizationsReportResponse,
} from '../../data/report/organizations';
import {
    changeOrganizationPlanAsAdmin,
    getOrganizationPlanLimits,
    OrganisationDetails,
    OrganisationPlanLimits,
    OrganisationPlanLimitsRequest,
    ChangeOrganisationPlanRequest,
    ChangeOrganisationPlanRequestV2,
    getOrganisationDetails,
    changeOrganizationPlanAsAdminV2,
    changeOrganizationPartnerCompany,
    ChangeOrganizationPartnerCompanyRequest,
    sendOrganizationToHubSpot,
    SendOrganizationToHubSpotRequest,
} from '../../data/report/organisationDetails';
import { monitor } from '../monitor';
import { InformativeError, PermissionError } from '../../utils/error';

export const organizationsReport$ = monitor(
    'organizationsReport$',
    new BehaviorSubject<OrganizationsReportResponse | undefined>(undefined)
);

export const selectedOrganisationDetails$ = monitor(
    'selectedOrganisationDetails$',
    new BehaviorSubject<OrganisationDetails | undefined>(undefined)
);

export const organizationReportLoading$ = monitor('organizationReportLoading$', new BehaviorSubject(false));

export const organizationReportErrors$ = monitor(
    'organizationReportErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedOrganisationPlanLimits$ = monitor(
    'selectedOrganisationPlanLimits$',
    new BehaviorSubject<OrganisationPlanLimits | undefined>(undefined)
);

export const organisationPlanLimitsLoading$ = monitor(
    'organisationPlanLimitsLoading$',
    new BehaviorSubject<boolean>(false)
);

export const organisationPlanLimitsError$ = monitor(
    'organisationPlanLimitsError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const getOrganisationPlanLimitsAction$ = monitor(
    'getOrganisationPlanLimitsAction$',
    new Subject<OrganisationPlanLimitsRequest>()
);

export const changeOrganisationPlanLimitsAction$ = monitor(
    'changeOrganisationPlanLimitsAction$',
    new Subject<ChangeOrganisationPlanRequest>()
);

export const changeOrganisationPlanLimitsActionV2$ = monitor(
    'changeOrganisationPlanLimitsActionV2$',
    new Subject<ChangeOrganisationPlanRequestV2>()
);

export const changeOrganisationPartnerCompanyAction$ = monitor(
    'changeOrganisationPartnerCompanyAction$',
    new Subject<ChangeOrganizationPartnerCompanyRequest>()
);

export const getOrganizationsReportAction$ = monitor(
    'getOrganizationsReportAction$',
    new Subject<OrganizationsReportRequest>()
);

export const sendOrganisationToHubspotAction$ = monitor(
    'sendOrganisationToHubspotAction$',
    new Subject<SendOrganizationToHubSpotRequest>()
);

const organisationPlanLimitsCache = new Map<string, OrganisationPlanLimits>();

getOrganizationsReportAction$
    .pipe(
        map(async (event) => {
            organizationReportErrors$.next(undefined);
            organizationReportLoading$.next(true);

            try {
                const organizationsReport = await getOrganizationsReport({
                    userType: event.userType,
                    status: event.status,
                    plan: event.plan,
                    planPeriod: event.planPeriod,
                    cursor: event.cursor,
                });

                const currentOrganizations = organizationsReport$.value?.organizations ?? [];

                organizationsReport$.next({
                    organizations: [...currentOrganizations, ...organizationsReport.organizations],
                    cursor: organizationsReport.cursor,
                });
            } catch (error) {
                organizationReportErrors$.next(
                    'Failed to query for teams, please try again, if the issue persists please contact support.'
                );
                console.error('Error loading teams', error);
            }
            organizationReportLoading$.next(false);
        })
    )
    .subscribe();

getOrganisationPlanLimitsAction$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                let limits = organisationPlanLimitsCache.get(event.plan);
                if (!limits || event.plan === 'custom') {
                    limits = await getOrganizationPlanLimits(event);
                    organisationPlanLimitsCache.set(event.plan, limits);
                }

                selectedOrganisationPlanLimits$.next(limits);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while getting plan limits. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();

changeOrganisationPlanLimitsAction$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                await changeOrganizationPlanAsAdmin(event);

                const details = selectedOrganisationDetails$.value;

                // eslint-disable-next-line no-unused-vars
                const { organizationUid, period, ...limits } = event;

                if (details) {
                    selectedOrganisationDetails$.next({
                        ...details,
                        ...limits,
                        planPeriod: period,
                    });
                }
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while changing plan limits. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();

changeOrganisationPlanLimitsActionV2$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                await changeOrganizationPlanAsAdminV2(event);

                const [details, limits] = await Promise.all([
                    getOrganisationDetails({
                        organisationUid: event.organizationUid,
                    }),
                    getOrganizationPlanLimits({
                        organisationUid: event.organizationUid,
                        plan: 'custom',
                    }),
                ]);

                selectedOrganisationDetails$.next(details);
                selectedOrganisationPlanLimits$.next(limits);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while changing plan limits. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();

changeOrganisationPartnerCompanyAction$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);
            selectedOrganisationPlanLimits$.next(undefined);

            try {
                await changeOrganizationPartnerCompany(event);

                const details = await getOrganisationDetails({
                    organisationUid: event.organizationUid,
                });
                selectedOrganisationDetails$.next(details);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while changing partner company. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();

sendOrganisationToHubspotAction$
    .pipe(
        map(async (event) => {
            organisationPlanLimitsLoading$.next(true);
            organisationPlanLimitsError$.next(undefined);

            try {
                await sendOrganizationToHubSpot(event);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    organisationPlanLimitsError$.next(e.message);
                } else {
                    organisationPlanLimitsError$.next(
                        'Error occurred while sending team data to HubSpot. Please try again, if the issue persists please contact support.'
                    );
                }
            }

            organisationPlanLimitsLoading$.next(false);
        })
    )
    .subscribe();
