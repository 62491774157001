import { BehaviorSubject, map, Subject } from 'rxjs';
import { getApps } from '../data/apps';
import { getTemplate, Template } from '../data/template';
import { getTemplates, TemplateCategories, Templates } from '../data/templates';
import { apps$ } from './apps';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';

export const templates$ = monitor('templates$', new BehaviorSubject<Templates>([]));
export const templateCategories$ = monitor(
    'templateCategories$',
    new BehaviorSubject<TemplateCategories | undefined>(undefined)
);
export const selectedReadOnlyTemplate$ = monitor(
    'selectedReadOnlyTemplate$',
    new BehaviorSubject<Template | undefined>(undefined)
);

export const navigateToWorkspaceFromTemplateEditAction$ = monitor(
    'navigateToWorkspaceFromTemplateEditAction$',
    new Subject<Template>()
);
export const viewTemplateAction$ = monitor('viewTemplateAction$', new Subject<string>());
export const navigateToReadOnlyTemplateFromViewTemplateAction$ = monitor(
    'navigateToReadOnlyTemplateFromViewTemplateAction$',
    new Subject<string>()
);

viewTemplateAction$
    .pipe(
        map(async (templateUid) => {
            try {
                const template = await getTemplate(templateUid);
                selectedReadOnlyTemplate$.next(template);
                navigateToReadOnlyTemplateFromViewTemplateAction$.next(templateUid);
            } catch (error) {
                console.error('Error while loading template', error);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Could not load template. Please try again, if the issue persists please contact support',
                    noToast: true,
                });
            }
        })
    )
    .subscribe();

export const loadTemplates = async (bypassCache = true): Promise<void> => {
    if (templates$.value.length === 0 || bypassCache) {
        const templates = await getTemplates();
        templates$.next(templates);
        if (apps$.value.length === 0) {
            const apps = await getApps();
            apps$.next(apps);
        }
    }
};
