import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';

interface AtlassianOnPremiseEventListenerSetupDialogProps {
    connectionType?: 'Jira' | 'Confluence' | 'Jira Service Management';
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const AtlassianOnPremiseEventListenerSetupDialog: React.FC<AtlassianOnPremiseEventListenerSetupDialogProps> = ({
    connectionType,
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const isIssueTransitionedEvent = eventTypeName === 'Issue Transitioned';

    const listContent = [
        setupBaseUrl ? (
            <Typography>
                Visit your{' '}
                <Link target="_blank" href={`${setupBaseUrl}/plugins/servlet/webhooks`}>
                    WebHooks
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>
                Open https://[YOUR{connectionType ? `_${connectionType.toUpperCase()}` : ''}
                _INSTANCE]/plugins/servlet/webhooks URL in your browser
            </Typography>
        ),
        <Typography>
            Click <strong>Create a WebHook</strong>.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Name</strong> field.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'atlassian-on-premise-webhook-url'
        ),
        ...(!isIssueTransitionedEvent
            ? [
                  <Typography>
                      Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                  </Typography>,
                  <Typography>
                      Then click <strong>Create</strong>.
                  </Typography>,
              ]
            : [
                  <Typography>
                      Then click <strong>Create</strong>.
                  </Typography>,
                  setupBaseUrl ? (
                      <Typography>
                          Visit your{' '}
                          <Link target="_blank" href={`${setupBaseUrl}/secure/admin/workflows/ListWorkflows.jspa`}>
                              Workflows
                          </Link>{' '}
                          page.
                      </Typography>
                  ) : (
                      <Typography>
                          Open https://[YOUR{connectionType ? `_${connectionType.toUpperCase()}` : ''}
                          _INSTANCE]/secure/admin/workflows/ListWorkflows.jspa URL in your browser
                      </Typography>
                  ),
                  <Typography>
                      Click <strong>Edit</strong> next to the relevant workflow.
                  </Typography>,
                  <Typography>
                      In the Workflow Designer, select the transition and click <strong>Post functions</strong> in the
                      properties panel.
                  </Typography>,
                  <Typography>
                      Click <strong>Add post function</strong>, select <strong>Trigger a Webhook</strong> and click{' '}
                      <strong>Add</strong>.
                  </Typography>,
                  <Typography>
                      Select the created webhook and click <strong>Add</strong>.
                  </Typography>,
                  <Typography>
                      Repeat steps <strong>8 through 10</strong> for each transition you want to get an event.
                  </Typography>,
                  <Typography>
                      Click <strong>Publish</strong> to publish the workflow.
                  </Typography>,
              ]),
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={`${connectionType} On-Premise`} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={loading ? '' : `${connectionType} On-Premise`}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionType} On-Premise instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="atlassian-on-premise" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
