import { LoaderFn } from 'react-location';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { LocationGenerics } from '../../router';
import { loadApps } from '../../store/apps';
import { loadTemplates, templateCategories$ } from '../../store/templates';
import { loadErrorPage } from '../../store/error';
import { loggedInUserDetails$ } from '../../store/user';
import { getTemplateCategories } from '../../data/templates';

export const templatesLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        const [categories] = await Promise.all([getTemplateCategories(), loadTemplates(), loadApps()]);
        segmentAnalyticsTrack('TemplateSelector Viewed', {
            userOrigin: loggedInUserDetails$.value?.userOrigin,
        });

        templateCategories$.next(categories);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Templates',
            genericMessage: 'Failed to load templates.',
        });

        throw e;
    }

    return {};
};
