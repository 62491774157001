import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { RouterLink } from '../RouterLink';
import { ButtonLinkProps, ForwardedLinkProps } from './Button';

export interface CustomButtonCardProps extends ButtonBaseProps {
    endIcon?: JSX.Element;
    iconSize?: 'small' | 'medium';
    label: string | JSX.Element;
    link?: ButtonLinkProps;
    selected?: boolean;
    startIcon?: JSX.Element;
    useRouter?: boolean;
}

const StyledButtonCard = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'iconSize' })<
    {
        selected: boolean;
        iconSize: 'small' | 'medium';
    } & ForwardedLinkProps
>(({ iconSize, selected, theme }) => {
    return {
        backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
        border: selected ? theme.constants.borderSelected : `1px solid ${theme.palette.divider}`,
        borderRadius: theme.constants.borderRadius,
        color: selected ? theme.palette.primary.main : undefined,
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        textAlign: 'start',
        width: '100%',
        '& .MuiSvgIcon-root': {
            height: iconSize === 'small' ? 16 : 24,
            width: iconSize === 'small' ? 16 : 24,
        },
        '& .MuiTypography-root': selected
            ? {
                  color: theme.palette.primary.main,
                  fontWeight: theme.typography.fontWeightBold,
              }
            : undefined,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            textDecoration: 'none',
        },
    };
});

const StyledButtonCardLeft = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    overflow: 'hidden',
}));

export const ButtonCard: React.FC<CustomButtonCardProps> = ({
    endIcon,
    iconSize = 'small',
    label,
    link,
    selected = false,
    startIcon,
    useRouter = true,
    ...props
}) => {
    const regularButton = (
        <StyledButtonCard selected={selected} iconSize={iconSize} {...props}>
            <StyledButtonCardLeft>
                {startIcon}
                {typeof label === 'string' ? <Typography>{label}</Typography> : label}
            </StyledButtonCardLeft>
            {endIcon}
        </StyledButtonCard>
    );

    const externalLinkButton = (
        <StyledButtonCard
            component={Link}
            href={link?.href}
            iconSize={iconSize}
            selected={selected}
            target={link?.target}
            {...props}
        >
            <StyledButtonCardLeft>
                {startIcon}
                {typeof label === 'string' ? <Typography>{label}</Typography> : label}
            </StyledButtonCardLeft>
            {endIcon}
        </StyledButtonCard>
    );

    const routerLinkButton = (
        <StyledButtonCard
            component={RouterLink}
            iconSize={iconSize}
            selected={selected}
            target={link?.target}
            to={link?.href}
            {...props}
        >
            <StyledButtonCardLeft>
                {startIcon}
                {typeof label === 'string' ? <Typography>{label}</Typography> : label}
            </StyledButtonCardLeft>
            {endIcon}
        </StyledButtonCard>
    );

    return !link || !useRouter ? regularButton : link.type === 'external' ? externalLinkButton : routerLinkButton;
};
