import { StyledPageOuterContainer } from '../layout/PageComponents';
import { TemplateBox, TemplateBoxProps } from './TemplateBox';

export const TemplatePreviewScreen: React.FC<TemplateBoxProps> = (props) => {
    return (
        <StyledPageOuterContainer>
            <TemplateBox {...props} />
        </StyledPageOuterContainer>
    );
};
