import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import {
    CreditsChip,
    PromptInput,
    renderPrompts,
    StyledFooter,
    StyledPageOneBlockImageContainer,
} from './AiAssistanceComponents';
import { useRef } from 'react';
import { IconButton } from '../../common/buttons/IconButton';

interface PromptLibraryScreenProps {
    availableCredits: number;
    busy: boolean;
    prompt: string;
    setPrompt(prompt: string): void;
    onClose(): void;
    onBack(): void;
    onSend(prompt: string): void;
    onCredits(): void;
}

const documentationPrompts = [
    'How to set up an event listener?',
    'Explain scheduled triggers',
    `What's an API connection?`,
];

const codingPrompts = [
    `When a Bitbucket Cloud pull request created event is triggered, add a comment to the Jira Cloud issue "PROJ-20" with the request's title`,
    `Create a Jira Cloud issue. Use the project, type, summary, description and priority as follows: "DEMO", "Task", "Test summary", "Test description", "Lowest".`,
    'How to create a ticket in Zendesk?',
    "Please explain, what `console.log('Hello world!')` does?",
];

const templatesPrompts = [
    'Is there a template to connect Confluence and Jira Cloud?',
    'Show me all the templates for Slack that you have in the documentation',
];

export const StyledBlockImageContainer = styled(StyledPageOneBlockImageContainer)({
    height: 180,
});

const StyledBoxPromptCategoryIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    height: 40,
    width: 40,
    borderRadius: theme.constants.borderRadius,
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    marginRight: theme.spacing(1.5),
}));

export const PromptLibraryScreen: React.FC<PromptLibraryScreenProps> = ({
    availableCredits,
    busy,
    prompt,
    setPrompt,
    onClose,
    onBack,
    onSend,
    onCredits,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handlePrompt = (prompt: string): void => {
        setPrompt(prompt);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleAsk = (prompt: string): void => {
        onSend(prompt);
        onBack();
    };

    return (
        <>
            <StyledBorderBoxHeader>
                <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            height: 20,
                            width: 20,
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        icon={<ArrowBackOutlinedIcon />}
                        tooltip="Back"
                        onClick={onBack}
                        aria-label="Back button"
                    />
                    <Typography variant="h6">Prompt library</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                    <CreditsChip availableCredits={availableCredits} onClick={onCredits} />
                    <IconButton
                        size="small"
                        icon={<CloseIcon />}
                        tooltip="Close AI assistant"
                        onClick={onClose}
                        aria-label="Close AI assistant button"
                    />
                </Box>
            </StyledBorderBoxHeader>
            <Divider />
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                p={2}
                gap={1.5}
                className="no-drag"
                sx={{
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}
            >
                <Box display="flex" alignItems="center">
                    <StyledBoxPromptCategoryIcon
                        sx={(theme) => ({
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.secondary.main,
                        })}
                    >
                        <MenuBookOutlinedIcon />
                    </StyledBoxPromptCategoryIcon>
                    <Typography variant="subtitle1">Documentation</Typography>
                </Box>
                {renderPrompts(documentationPrompts, 'Documentation', <MenuBookOutlinedIcon />, busy, handlePrompt)}
                <Box display="flex" alignItems="center" mt={0.5}>
                    <StyledBoxPromptCategoryIcon
                        sx={(theme) => ({
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.info.main,
                        })}
                    >
                        <CodeOutlinedIcon />
                    </StyledBoxPromptCategoryIcon>
                    <Typography variant="subtitle1">Coding</Typography>
                </Box>
                {renderPrompts(codingPrompts, 'Coding', <CodeOutlinedIcon />, busy, handlePrompt)}
                <Box display="flex" alignItems="center" mt={0.5}>
                    <StyledBoxPromptCategoryIcon
                        sx={(theme) => ({
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.success.main,
                        })}
                    >
                        <LocalLibraryOutlinedIcon />
                    </StyledBoxPromptCategoryIcon>
                    <Typography variant="subtitle1">Templates</Typography>
                </Box>
                {renderPrompts(templatesPrompts, 'Templates', <LocalLibraryOutlinedIcon />, busy, handlePrompt)}
            </Box>
            <Divider />
            <StyledFooter>
                <PromptInput ref={inputRef} prompt={prompt} onSend={handleAsk} busy={busy} setPrompt={setPrompt} />
                <Typography variant="body2" color="text.secondary" mt={1}>
                    AI messages might be wrong. Check important facts yourself.
                </Typography>
            </StyledFooter>
        </>
    );
};
