import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { Dropdown } from '../../common/inputs/dropdown/Dropdown';
import { IconCircle } from '../../common/IconCircle';
import { InformationChip } from '../../common/chips/InformationChip';
import { InfoIcon } from '../../icons/InfoIcon';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxHeaderSmall,
    StyledBorderBoxSectionContainer,
    StyledBorderBoxTitleContainer,
} from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { StyledStepContainer } from '../../setup-guide/SetupGuideComponents';
import { TextField } from '../../common/inputs/TextField';
import { autoFocus } from '../../../utils/input';

interface ApiHandlerConnection {
    authorized?: boolean;
    name: string;
    uid: string;
}

interface VendorApiVersion {
    deprecated: boolean;
    name: string;
    recommended: boolean;
    value: string;
}

export interface SaveApiHandlerEvent {
    apiHandlerLibraryUid?: string;
    connectionUid?: string;
    path?: string;
    uid: string;
}

interface ApiConnectionDetailsProps {
    appName: string;
    connections?: ApiHandlerConnection[];
    errors?: string;
    path?: string;
    saving?: boolean;
    selectedApiVersionUid?: string;
    selectedConnectionUid?: string;
    templateMode?: boolean;
    uid: string;
    vendorApiVersions: VendorApiVersion[];
    environmentDeployed?: boolean;
    hasImplicitlySharedConnectionAttached?: boolean;
    createdConnectionUid?: string;
    workspaceLocked?: boolean;
    templatePreviewMode?: boolean;
    onCancel?: () => void;
    onNewConnection?: () => void;
    onSave?: (event: SaveApiHandlerEvent) => void;
}

const StyledTextAdornment = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
}));

const StyledBorderBoxContentWithMargin = styled(StyledBorderBoxContent)(({ theme }) => ({
    '& .MuiFormControl-root': {
        margin: theme.spacing(1, 0),
    },
}));

export const ApiConnectionDetails: React.FC<ApiConnectionDetailsProps> = ({
    appName,
    connections = [],
    createdConnectionUid,
    errors,
    path,
    saving = false,
    selectedApiVersionUid = '',
    selectedConnectionUid = '',
    templateMode = false,
    uid,
    vendorApiVersions,
    environmentDeployed = false,
    hasImplicitlySharedConnectionAttached = false,
    workspaceLocked = false,
    templatePreviewMode = false,
    onCancel,
    onNewConnection,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const recommendedVendorApiVersion = vendorApiVersions.find((v) => v.recommended);
    const defaultLibraryUid = vendorApiVersions.length === 1 ? vendorApiVersions[0]?.value : undefined;

    const [vendorApiSelectionMode, setVendorApiSelectionMode] = useState<'default' | 'custom'>(
        templateMode ||
            (recommendedVendorApiVersion?.value && selectedApiVersionUid !== recommendedVendorApiVersion.value) ||
            (defaultLibraryUid && selectedApiVersionUid !== defaultLibraryUid)
            ? 'custom'
            : 'default'
    );
    const [currentSelectedConnectionUid, setCurrentSelectedConnectionUid] = useState(
        createdConnectionUid ?? selectedConnectionUid ?? ''
    );
    const [currentSelectedApiVersionUid, setCurrentSelectedApiVersionUid] = useState(selectedApiVersionUid ?? '');
    const [currentPath, setCurrentPath] = useState(path);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const selectRef = useRef<HTMLInputElement | null>(null);

    useEffect(
        () => setCurrentSelectedConnectionUid(createdConnectionUid ?? selectedConnectionUid ?? ''),
        [createdConnectionUid, selectedConnectionUid]
    );

    const multipleVendorApiVersions = vendorApiVersions.length > 1;
    const hasUnsavedChanges =
        currentPath !== path ||
        currentSelectedApiVersionUid !== selectedApiVersionUid ||
        currentSelectedConnectionUid !== selectedConnectionUid;

    const canSave =
        !!currentSelectedConnectionUid &&
        !!currentPath &&
        !workspaceLocked &&
        !saving &&
        hasUnsavedChanges &&
        (!multipleVendorApiVersions || !!currentSelectedApiVersionUid);
    const foundConnection = connections.find((con) => con.uid === currentSelectedConnectionUid);
    const isConnectionUnauthorized = !!foundConnection && !foundConnection.authorized;

    const selectedLibrary = vendorApiVersions.find((v) => v.value === currentSelectedApiVersionUid);
    const existingRecommendedApiVersion = vendorApiVersions.some((v) => v.recommended);

    const typographyColor = templateMode || workspaceLocked || templatePreviewMode ? 'text.disabled' : undefined;

    useEffect(() => {
        autoFocus(inputRef);
    }, []);

    useEffect(() => {
        if (vendorApiSelectionMode === 'custom') {
            autoFocus(selectRef);
        }
    }, [vendorApiSelectionMode]);

    const actions = (
        <>
            <Button variant="outlined" onClick={onCancel}>
                Back
            </Button>
            {!templateMode && (
                <Button
                    busy={saving}
                    disabled={!canSave}
                    onClick={() =>
                        onSave?.({
                            apiHandlerLibraryUid:
                                vendorApiSelectionMode === 'default'
                                    ? recommendedVendorApiVersion?.value ??
                                      defaultLibraryUid ??
                                      currentSelectedApiVersionUid
                                    : currentSelectedApiVersionUid,
                            connectionUid: currentSelectedConnectionUid,
                            path: currentPath,
                            uid,
                        })
                    }
                >
                    Save
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <StyledBorderBox marginTop={templateMode ? undefined : 4}>
                <StyledBorderBoxHeader>
                    <StyledBorderBoxTitleContainer>
                        <IconCircle icon={<ProductIcon name={appName} />} size="small" />
                        <Typography variant="h6" component="h4">
                            {`Configure ${appName} API connection`}
                        </Typography>
                    </StyledBorderBoxTitleContainer>
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent gap={3}>
                    {!templatePreviewMode && errors && <Alert severity="error" title={errors} />}
                    {!templatePreviewMode &&
                        !templateMode &&
                        path &&
                        !!selectedConnectionUid &&
                        selectedConnectionUid !== currentSelectedConnectionUid &&
                        environmentDeployed && (
                            <Alert
                                severity="warning"
                                title="Changing the connector will take effect immediately after saving."
                            />
                        )}
                    {!templatePreviewMode && isConnectionUnauthorized && (
                        <Alert severity="warning" title="Selected connector is not authorized." />
                    )}
                    {!templatePreviewMode && multipleVendorApiVersions && selectedLibrary?.deprecated && (
                        <Alert
                            severity="warning"
                            title="You are using deprecated vendor API version, please consider upgrading to recommended version as soon as possible."
                        />
                    )}
                    {!templatePreviewMode && !templateMode && hasImplicitlySharedConnectionAttached && (
                        <Alert
                            severity="info"
                            title={`This API connection is currently using implicitly shared connector owned by someone else, if you change it, you won't be able to change it back on your own.`}
                        />
                    )}
                    {!templatePreviewMode &&
                        multipleVendorApiVersions &&
                        selectedLibrary &&
                        existingRecommendedApiVersion &&
                        !selectedLibrary?.recommended &&
                        !selectedLibrary?.deprecated && (
                            <Alert
                                severity="info"
                                title="Recommended vendor API Version is available, please consider selecting the recommended option."
                            />
                        )}
                    <StyledBorderBoxSectionContainer>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle1">
                                {templateMode ? 'Select connector' : 'Connector'}
                            </Typography>
                            <InfoIcon tooltip="Connector is used to make sure proper authentication headers are passed along when you use API connection." />
                        </StyledBorderBoxTitleContainer>
                        {!templatePreviewMode ? (
                            <Dropdown
                                disabled={templateMode || workspaceLocked}
                                fullWidth
                                items={connections.map((c) => ({
                                    icon: <ProductIcon name={appName} />,
                                    name: c.name,
                                    value: c.uid,
                                }))}
                                label="Uses connector"
                                required
                                selectedItem={currentSelectedConnectionUid}
                                onCreateNew={onNewConnection}
                                onSelectItem={(value) => setCurrentSelectedConnectionUid(value)}
                            />
                        ) : (
                            <Dropdown disabled fullWidth items={[]} label="Uses connector" required />
                        )}
                    </StyledBorderBoxSectionContainer>
                    <StyledBorderBoxSectionContainer>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle1">
                                {templateMode ? 'Define API path' : 'API path'}
                            </Typography>
                            <InfoIcon tooltip="Path is a unique identifier and is also used to import API connection in scripts for consumption." />
                        </StyledBorderBoxTitleContainer>
                        <TextField
                            disabled={templateMode || workspaceLocked || environmentDeployed || templatePreviewMode}
                            fullWidth
                            inputRef={templatePreviewMode ? undefined : inputRef}
                            label="Path"
                            placeholder="Enter import path"
                            required
                            startIcon={
                                <>
                                    <LinkOutlinedIcon />
                                    <StyledTextAdornment>./api/</StyledTextAdornment>
                                </>
                            }
                            value={currentPath}
                            onChange={(e) => setCurrentPath(e.target.value)}
                        />
                    </StyledBorderBoxSectionContainer>
                    {multipleVendorApiVersions ? (
                        !templateMode && !templatePreviewMode && !environmentDeployed ? (
                            <StyledBorderBoxSectionContainer>
                                <StyledBorderBoxTitleContainer>
                                    <Typography color={typographyColor} variant="subtitle1">
                                        {templateMode ? 'Define vendor API version' : 'Vendor API version'}
                                    </Typography>
                                    <InfoIcon tooltip="Choose the vendor API version you want the API connection to use." />
                                </StyledBorderBoxTitleContainer>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={vendorApiSelectionMode === 'default'}
                                                    onClick={() => {
                                                        setCurrentSelectedApiVersionUid(
                                                            recommendedVendorApiVersion?.value ??
                                                                defaultLibraryUid ??
                                                                currentSelectedApiVersionUid
                                                        );
                                                        setVendorApiSelectionMode('default');
                                                    }}
                                                />
                                            }
                                            label="Use default vendor API version"
                                        />
                                        {!templateMode && <InformationChip label="Recommended" severity="success" />}
                                    </StyledBorderBoxHeaderSmall>
                                </StyledBorderBox>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={vendorApiSelectionMode === 'custom'}
                                                    disabled={workspaceLocked}
                                                    onClick={() => setVendorApiSelectionMode('custom')}
                                                />
                                            }
                                            label="Use custom vendor API version"
                                        />
                                        <InformationChip label="Advanced" severity="info" />
                                    </StyledBorderBoxHeaderSmall>
                                    <Divider />
                                    <StyledBorderBoxContentWithMargin>
                                        <Dropdown
                                            disabled={vendorApiSelectionMode === 'default' || workspaceLocked}
                                            fullWidth
                                            inputRef={selectRef}
                                            items={vendorApiVersions.map((v) => ({ name: v.name, value: v.value }))}
                                            label="Select vendor API version"
                                            selectedItem={currentSelectedApiVersionUid}
                                            onSelectItem={(value) => setCurrentSelectedApiVersionUid(value)}
                                        />
                                    </StyledBorderBoxContentWithMargin>
                                </StyledBorderBox>
                            </StyledBorderBoxSectionContainer>
                        ) : (
                            <StyledBorderBoxSectionContainer>
                                <StyledBorderBoxTitleContainer>
                                    <Typography color={typographyColor} variant="subtitle1">
                                        {templateMode ? 'Define vendor API version' : 'Vendor API version'}
                                    </Typography>
                                    <InfoIcon tooltip="Choose the vendor API version you want the API connection to use." />
                                </StyledBorderBoxTitleContainer>
                                <Dropdown
                                    disabled
                                    fullWidth
                                    items={vendorApiVersions.map((v) => ({ name: v.name, value: v.value }))}
                                    label="Select vendor API version"
                                    selectedItem={selectedApiVersionUid}
                                />
                            </StyledBorderBoxSectionContainer>
                        )
                    ) : null}
                </StyledBorderBoxContent>
                {!templatePreviewMode && <Divider />}
                {!templatePreviewMode && <StyledMainActions>{actions}</StyledMainActions>}
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
