import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

type IndicatorState = 'active' | 'authorized' | 'needsSetup';

interface IndicatorProps {
    left: {
        icon: JSX.Element;
    };
    right?: {
        icon: JSX.Element;
        state?: IndicatorState;
    };
}

const StyledIndicatorContainer = styled(Box)(() => ({
    display: 'flex',
}));

const StyledIndicator = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'center',
    padding: theme.spacing(1),
    '& img': {
        height: 24,
        width: 24,
    },
}));

const StyledLeftIndicator = styled(StyledIndicator)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: `${theme.constants.borderRadius}px 0 0 ${theme.constants.borderRadius}px`,
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledCenterIndicator = styled(StyledIndicator)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledDivider = styled(Divider, { shouldForwardProp: (prop) => prop !== 'state' })<{ state?: IndicatorState }>(
    ({ state, theme }) => ({
        borderColor:
            state === 'authorized'
                ? theme.palette.success.main
                : state === 'needsSetup'
                ? theme.palette.warning.main
                : theme.palette.divider,
    })
);

const StyledRightIndicator = styled(StyledIndicator, {
    shouldForwardProp: (prop) => prop !== 'state',
})<{ state: IndicatorState }>(({ state, theme }) => {
    const authorized = state === 'authorized';
    const needsSetup = state === 'needsSetup';

    const border = authorized
        ? `1px solid ${theme.palette.success.main}`
        : needsSetup
        ? `1px solid ${theme.palette.warning.main}`
        : `1px solid ${theme.palette.divider}`;

    return {
        ...theme.typography.flexAlignCenter,
        backgroundColor: authorized
            ? alpha(theme.palette.success.light, 0.1)
            : needsSetup
            ? alpha(theme.palette.warning.light, 0.1)
            : theme.palette.background.paper,
        borderBottom: border,
        borderRadius: `0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px 0`,
        borderRight: border,
        borderTop: border,
    };
});

export const Indicator: React.FC<IndicatorProps> = ({ left, right }) => {
    return (
        <StyledIndicatorContainer>
            {right ? (
                <>
                    <StyledLeftIndicator>{left.icon}</StyledLeftIndicator>
                    <StyledDivider flexItem orientation="vertical" state={right.state} />
                    <StyledRightIndicator state={right.state ?? 'active'}>{right.icon}</StyledRightIndicator>
                </>
            ) : (
                <StyledCenterIndicator>{left.icon}</StyledCenterIndicator>
            )}
        </StyledIndicatorContainer>
    );
};
