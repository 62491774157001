import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '../../common/buttons/Button';
import { StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { StyledMainActions } from '../../layout/LayoutComponents';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { LearnAboutCreditsContent, CreditsChip, NewTabLink } from './AiAssistanceComponents';
import { limitsAndQuotasDocumentationUrl, supportPortalUrl } from '../../../utils/documentation';
import { IconButton } from '../../common/buttons/IconButton';

interface LearnAboutCreditsScreenProps {
    onClose(): void;
    onBack(): void;
    availableCredits: number;
}

export const LearnAboutCreditsScreen: React.FC<LearnAboutCreditsScreenProps> = ({
    onClose,
    onBack,
    availableCredits,
}) => {
    return (
        <>
            <StyledBorderBoxHeader>
                <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            height: 20,
                            width: 20,
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        icon={<ArrowBackOutlinedIcon />}
                        tooltip="Back"
                        onClick={onBack}
                        aria-label="Back button"
                    />
                    <Typography variant="h6">Learn about credits</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                    <CreditsChip availableCredits={availableCredits} disabled />
                    <IconButton
                        size="small"
                        icon={<CloseIcon />}
                        tooltip="Close AI assistant"
                        onClick={onClose}
                        aria-label="Close AI assistant button"
                    />
                </Box>
            </StyledBorderBoxHeader>
            <Divider />
            <Box display="flex" height="100%" p={2}>
                <LearnAboutCreditsContent />
            </Box>
            <Divider />
            <StyledMainActions>
                <Button
                    LinkComponent={NewTabLink}
                    href={limitsAndQuotasDocumentationUrl}
                    startIcon={<OpenInNewOutlinedIcon />}
                    variant="outlined"
                >
                    View AI credit limits
                </Button>
                <Button LinkComponent={NewTabLink} href={supportPortalUrl} startIcon={<QuestionAnswerOutlinedIcon />}>
                    Contact support
                </Button>
            </StyledMainActions>
        </>
    );
};
