import { useCallback, useEffect, useState } from 'react';
import { HandlerProps, ReflexSplitter } from 'react-reflex';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier, closestCorners } from '@dnd-kit/core';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import { Button } from '../../common/buttons/Button';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { EmptyState } from '../../common/EmptyState';
import { EnvironmentVariable } from './EnvironmentVariable';
import { GhostVariable } from './EnvironmentVariableComponents';
import { IconButton } from '../../common/buttons/IconButton';
import { ReadmeFileDetails } from '../readme/ReadmeFileDetails';
import { SearchField } from '../../common/inputs/custom/SearchField';
import {
    StyledEditorMainContainer,
    StyledEditorToolbar,
    StyledEditorToolbarActions,
    StyledEditorViewContainer,
    StyledMainActions,
} from '../../layout/LayoutComponents';
import { StyledReflexContainer, StyledReflexElement } from '../../reflex/ReflexComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    FullEnvironmentVariable,
    RepositionVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
    UpdateVariableEvent,
} from './types';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { filterVariablesBySearchTerm } from './utils';
import { StyledBorderBoxContent } from '../../layout/BorderBoxComponents';

interface RepositionedVariable extends FullEnvironmentVariable {
    index: number;
    parentId?: string;
}

interface EnvironmentVariableDetailsProps {
    documentationUrl: string;
    errors?: string;
    hasUnsavedChanges?: boolean;
    isReadmeDefault?: boolean;
    loading?: boolean;
    saving?: boolean;
    templateMode?: boolean;
    variables?: FullEnvironmentVariable[];
    workspaceLocked?: boolean;
    environmentDeployed?: boolean;
    workspaceUid: string;
    readmeContent: string;
    fullScreenReadmeLink: string;
    onChangeVariableType(event: ChangeVariableTypeEvent): void;
    onCreateFolder(): void;
    onCreateVariable(parentId?: string): void;
    onDeleteVariable(event: DeleteVariableEvent): void;
    onDiscardChanges(): void;
    onRepositionVariable(event: RepositionVariableEvent): void;
    onSaveChanges(variables: FullEnvironmentVariable[]): void;
    onToggleVariableEditMode(event: ToggleVariableEditModeEvent): void;
    onToggleVariableExpand(event: ToggleVariableExpandEvent): void;
    onUpdateVariable(event: UpdateVariableEvent): void;
}

const StyledMainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
}));

const StyledVariablesToolbar = styled(StyledEditorToolbar)(() => ({
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const StyledToolbarUpper = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: '100%',
}));

const StyledFormContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(1.5),
    overflowY: 'auto',
    padding: theme.spacing(3),
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const platform = (navigator as any).userAgentData ? (navigator as any).userAgentData.platform : navigator.platform;

const SAVE_TOOLTIP = `Save changes (${platform.toLowerCase().includes('mac') ? 'COMMAND + S' : 'CTRL + S'})`;

export const EnvironmentVariableDetails: React.FC<EnvironmentVariableDetailsProps> = ({
    documentationUrl,
    errors,
    hasUnsavedChanges = false,
    isReadmeDefault = false,
    saving = false,
    templateMode,
    variables = [],
    workspaceLocked,
    environmentDeployed = false,
    workspaceUid,
    readmeContent,
    fullScreenReadmeLink,
    onChangeVariableType,
    onCreateFolder,
    onCreateVariable,
    onDeleteVariable,
    onDiscardChanges,
    onRepositionVariable,
    onSaveChanges,
    onToggleVariableEditMode,
    onToggleVariableExpand,
    onUpdateVariable,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const readmeFlexGrowKey = `environmentVariablesReadmeFlexGrow-${workspaceUid}`;
    const readmeDefaultFlexGrow = 0.5;
    const readmeOpenKey = `isEnvironmentVariablesReadmeOpen-${workspaceUid}`;
    const isReadmeOpenInitially = templateMode || readLocalStorage(readmeOpenKey, !isReadmeDefault);

    const [searchTerm, setSearchTerm] = useState('');
    const [draggedItem, setDraggedItem] = useState<RepositionedVariable | null>(null);
    const [readmeOpen, setReadmeOpen] = useState(isReadmeOpenInitially);
    const [readmeFlexGrow, setReadmeFlexGrow] = useState(
        templateMode ? readmeDefaultFlexGrow : readLocalStorage(readmeFlexGrowKey, readmeDefaultFlexGrow)
    );

    const canSave = !saving && !workspaceLocked && hasUnsavedChanges;

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [saving, workspaceLocked, hasUnsavedChanges, templateMode]);

    const handleKeyDown = useCallback(
        (event) => {
            if (
                (window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) &&
                (event.keyCode == 83 || (event.key && event.key.toLowerCase() === 's'))
            ) {
                event.preventDefault();
                if (!templateMode && canSave) {
                    onSaveChanges(variables);
                }
            }
        },
        [saving, workspaceLocked, hasUnsavedChanges, templateMode]
    );

    const handleReadmeResize = (e: HandlerProps): void => {
        if (e.domElement instanceof Element) {
            const flexGrowCurrent = +window.getComputedStyle(e.domElement).flexGrow;
            setReadmeFlexGrow(flexGrowCurrent);
            saveLocalStorage(readmeFlexGrowKey, flexGrowCurrent);
        }
    };

    const handleToggleReadme = (): void => {
        setReadmeOpen(!readmeOpen);
        if (!templateMode && !isReadmeDefault) {
            saveLocalStorage(readmeOpenKey, !readmeOpen);
        }
    };

    const isDraggedVariableDuplicate =
        !!draggedItem?.keyName &&
        !!draggedItem.parentId &&
        variables.some((v) => v.keyName && v.keyName === draggedItem.keyName);

    const findVariable = (id?: UniqueIdentifier): RepositionedVariable | undefined => {
        for (const variable of variables) {
            if (variable.id === id) {
                const index = variables.findIndex((v) => v.id === id);
                return { ...variable, id, index, keyName: variable.keyName };
            }

            if (variable.childVariables) {
                const subVariable = variable.childVariables?.find((cv) => cv.id === id);
                const subIndex = variable.childVariables?.findIndex((cv) => cv.id === id);

                if (subVariable && subIndex !== -1) {
                    return {
                        ...subVariable,
                        id: subVariable.id,
                        index: subIndex,
                        keyName: subVariable.keyName,
                        parentId: variable.id,
                    };
                }
            }
        }
    };

    const getGhostVariable = (id?: UniqueIdentifier): RepositionedVariable | undefined => {
        if (id === 'root-last') {
            return { id, index: variables.length };
        }

        const parentId = id?.toString().split('-')[0] ?? '';
        const childVariables = variables.find((v) => v.id === parentId)?.childVariables;

        if (id) {
            return { id: id.toString(), index: childVariables ? childVariables.length : 0, parentId };
        }
    };

    const getVariableRepositionData = (
        activeId?: UniqueIdentifier,
        overId?: UniqueIdentifier
    ): RepositionVariableEvent | undefined => {
        const isHoveringOverGhost = overId?.toString()?.includes('-last');
        const activeItem = findVariable(activeId);
        const overItem = isHoveringOverGhost ? getGhostVariable(overId) : findVariable(overId);

        if (activeItem && overItem) {
            const isOnSameLevel = activeItem.parentId === overItem.parentId;
            const newIndex =
                !isOnSameLevel || isHoveringOverGhost || overItem.index < activeItem.index
                    ? overItem.index
                    : overItem.index - 1;

            return {
                newIndex,
                newParentId: overItem.parentId,
                originalIndex: activeItem.index,
                originalParentId: activeItem.parentId,
            };
        }
    };

    const handleDragStart = ({ active }: DragStartEvent): void => {
        const activeItem = findVariable(active.id);
        if (activeItem) {
            setDraggedItem(activeItem);
        }
    };

    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        const repositionData = getVariableRepositionData(active.id, over?.id);

        if (repositionData) {
            onRepositionVariable(repositionData);
        }

        setDraggedItem(null);
    };

    const filteredVariables = filterVariablesBySearchTerm(variables, searchTerm);

    const variableList = filteredVariables.map((v) => (
        <EnvironmentVariable
            key={v.id}
            childVariables={v.childVariables}
            choices={v.choices}
            defaultValue={v.defaultValue}
            description={v.description}
            disabled={workspaceLocked}
            editMode={v.editMode}
            expanded={v.expanded}
            hasBeenEdited={v.hasBeenEdited}
            id={v.id}
            keyName={v.keyName}
            passwordValueFilled={v.passwordValueFilled}
            required={v.required}
            sameLevelKeyNames={variables.map((es) => es.keyName ?? '')}
            readOnlyTemplateMode={templateMode}
            type={v.type}
            value={v.value}
            onAddVariable={(parentId) => handleCreateVariable(parentId)}
            onChangeType={onChangeVariableType}
            onDelete={onDeleteVariable}
            onToggleEditMode={onToggleVariableEditMode}
            onToggleExpand={onToggleVariableExpand}
            onUpdate={onUpdateVariable}
        />
    ));

    const handleCreateFolder = (): void => {
        setSearchTerm('');
        onCreateFolder();
    };

    const handleCreateVariable = (parentId?: string): void => {
        setSearchTerm('');
        onCreateVariable(parentId);
    };

    const showWelcomeScreen = !templateMode && !variables.length && !hasUnsavedChanges;

    return (
        <StyledEditorViewContainer>
            <StyledVariablesToolbar>
                <StyledToolbarUpper>
                    <Typography variant="h5">Parameters</Typography>
                    <StyledEditorToolbarActions>
                        {!templateMode && (
                            <IconButton
                                aria-label="Save parameters"
                                border
                                busy={saving}
                                disabled={!canSave}
                                icon={<SaveOutlinedIcon />}
                                tooltip={SAVE_TOOLTIP}
                                onClick={() => onSaveChanges(variables)}
                            />
                        )}
                        <IconButton
                            aria-label="Documentation"
                            border
                            icon={<MenuBookOutlinedIcon />}
                            link={{ href: documentationUrl, target: '_blank', type: 'external' }}
                            tooltip="Documentation"
                        />
                        <IconButton
                            aria-label={readmeOpen ? 'Hide README' : 'Show README'}
                            border
                            icon={<IntegrationInstructionsOutlinedIcon />}
                            selected={readmeOpen}
                            tooltip={readmeOpen ? 'Hide README' : 'Show README'}
                            onClick={handleToggleReadme}
                        />
                        {!templateMode && (
                            <>
                                <Button
                                    startIcon={<AddIcon />}
                                    disabled={workspaceLocked}
                                    variant="outlined"
                                    onClick={handleCreateFolder}
                                >
                                    Folder
                                </Button>
                                <Button
                                    startIcon={<AddIcon />}
                                    disabled={workspaceLocked}
                                    variant="outlined"
                                    onClick={() => handleCreateVariable()}
                                >
                                    Parameter
                                </Button>
                            </>
                        )}
                    </StyledEditorToolbarActions>
                </StyledToolbarUpper>
                <SearchField
                    fullWidth
                    placeholder="Search by key name"
                    size="small"
                    searchTerm={searchTerm}
                    onChangeSearchTerm={(e) => setSearchTerm(e.target.value)}
                />
            </StyledVariablesToolbar>
            <Divider />
            <StyledEditorMainContainer sx={{ padding: 0 }}>
                <StyledReflexContainer orientation="vertical" data-test-id="environment-variables">
                    <StyledReflexElement minSize={410}>
                        <StyledMainContainer>
                            <>
                                {showWelcomeScreen ? (
                                    <StyledBorderBoxContent>
                                        <EmptyState
                                            borderBox
                                            buttons={[
                                                <Button
                                                    variant="text"
                                                    key="learn-more"
                                                    onClick={() => window.open(documentationUrl, '_blank')}
                                                >
                                                    Learn more
                                                </Button>,
                                                <Button
                                                    key="create-folder"
                                                    disabled={workspaceLocked}
                                                    variant="outlined"
                                                    onClick={handleCreateFolder}
                                                >
                                                    Create folder
                                                </Button>,
                                                <Button
                                                    key="create-variable"
                                                    disabled={workspaceLocked}
                                                    onClick={() => handleCreateVariable()}
                                                >
                                                    Create parameter
                                                </Button>,
                                            ]}
                                            icon={<TokenOutlinedIcon />}
                                            iconState="incomplete"
                                            subtitle="Parameters provide a way to separate configuration from your scripts."
                                            title="No parameters created"
                                        />
                                    </StyledBorderBoxContent>
                                ) : filteredVariables.length ? (
                                    <>
                                        <StyledFormContent>
                                            {isDraggedVariableDuplicate && (
                                                <DialogAlert
                                                    sx={{ margin: 0 }}
                                                    severity="warning"
                                                    text="Duplicate parameter detected - Cannot add parameter to root level."
                                                />
                                            )}
                                            {errors && (
                                                <DialogAlert sx={{ margin: 0 }} severity="error" title={errors} />
                                            )}
                                            <DndContext
                                                collisionDetection={closestCorners}
                                                onDragStart={handleDragStart}
                                                onDragCancel={() => setDraggedItem(null)}
                                                onDragEnd={handleDragEnd}
                                            >
                                                {variableList}
                                                {draggedItem && !isDraggedVariableDuplicate && (
                                                    <GhostVariable id="root-last" />
                                                )}
                                                <DragOverlay
                                                    dropAnimation={{
                                                        duration: 200,
                                                        easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                                                    }}
                                                >
                                                    {draggedItem && (
                                                        <EnvironmentVariable
                                                            childVariables={draggedItem.childVariables}
                                                            choices={draggedItem.choices}
                                                            description={draggedItem.description}
                                                            dragOverlay={true}
                                                            id={draggedItem.id}
                                                            keyName={draggedItem.keyName}
                                                            required={draggedItem.required}
                                                            type={draggedItem.type}
                                                            value={draggedItem.value}
                                                        />
                                                    )}
                                                </DragOverlay>
                                            </DndContext>
                                        </StyledFormContent>
                                    </>
                                ) : (
                                    <StyledBorderBoxContent>
                                        <EmptyState
                                            borderBox
                                            icon={<TokenOutlinedIcon />}
                                            subtitle={
                                                searchTerm
                                                    ? 'Try a different search term.'
                                                    : !templateMode
                                                    ? 'Create a new key or folder to start, as there are currently no parameters.'
                                                    : ''
                                            }
                                            title={searchTerm ? 'No parameters found' : 'No parameters created'}
                                        />
                                    </StyledBorderBoxContent>
                                )}
                            </>
                        </StyledMainContainer>
                    </StyledReflexElement>
                    {readmeOpen && ( // Cannot move under same clause or functionality breaks
                        <ReflexSplitter>
                            <DragIndicatorIcon />
                        </ReflexSplitter>
                    )}
                    {readmeOpen && (
                        <StyledReflexElement flex={readmeFlexGrow} onStopResize={handleReadmeResize}>
                            <ReadmeFileDetails
                                content={readmeContent}
                                environmentVariablesMode={true}
                                fullScreenReadmeLink={fullScreenReadmeLink}
                                templatePreviewMode={templateMode}
                                workspaceLocked={workspaceLocked || environmentDeployed}
                                onClose={() => setReadmeOpen(false)}
                            />
                        </StyledReflexElement>
                    )}
                </StyledReflexContainer>
            </StyledEditorMainContainer>
            <Divider />
            <StyledMainActions>
                {hasUnsavedChanges && (
                    <Typography color="text.secondary" variant="subtitle1">
                        Unsaved changes
                    </Typography>
                )}
                <Button disabled={!hasUnsavedChanges} variant="outlined" onClick={onDiscardChanges}>
                    Discard
                </Button>
                <Button busy={saving} disabled={!canSave} onClick={() => onSaveChanges(variables)}>
                    Save changes
                </Button>
            </StyledMainActions>
        </StyledEditorViewContainer>
    );
};
