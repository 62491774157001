import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledBorderBox, StyledBorderBoxContent } from '../layout/BorderBoxComponents';

export const StyledTemplateBox = styled(StyledBorderBox)(() => ({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: 1200,
    overflowY: 'hidden',
    width: '100%',
}));

export const StyledTemplateBoxLeftSide = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flex: '0 1 280px',
    flexDirection: 'column',
    maxWidth: 280,
    minWidth: 180,
    width: '100%',
}));

export const StyledTemplateBoxRightSide = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: '0 1 932px',
    flexDirection: 'column',
    minWidth: 200,
    width: '100%',
}));

export const StyledTemplateBoxHeaderRight = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '0 0 120px',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
}));

export const StyledTemplateBoxContentRight = styled(StyledBorderBoxContent)(() => ({
    flexGrow: 1,
    minHeight: 300,
    overflowY: 'auto',
}));
