import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CreditsChip, StyledPageOneBlockImageContainer } from './AiAssistanceComponents';
import CardMedia from '@mui/material/CardMedia';
import { OrderedList } from '../../common/lists/OrderedList';
import { IconButton } from '../../common/buttons/IconButton';

interface BestPracticesScreenProps {
    onClose(): void;
    onBack(): void;
    onCredits(): void;
    availableCredits: number;
}

export const StyledBlockImageContainer = styled(StyledPageOneBlockImageContainer)({
    height: 180,
});

const practicesList = [
    // {
    //     title: 'Using AI Assistant from your workspace, pre-create connectors',
    //     text: 'Ensure app connectors are pre-created in your Workspace for correct import paths.',
    // },
    {
        title: 'Specify the type of app you are interested in',
        text: 'Be specific about the application type, such as Jira Cloud instead of Jira, to avoid guesswork.',
    },
    {
        title: 'Be specific about what you want the code to do',
        text: 'Define tasks precisely: get Jira issue DEMO-63, delete attachments, use functions.',
    },
    {
        title: 'Go step by step',
        text: 'Build solutions step-by-step, starting with basic logic, then add business specifics.',
    },
    {
        title: 'Rephrase and ask again',
        text: 'Modify and test prompts several times to achieve the desired results.',
    },
].map(({ title, text }, i) => {
    return (
        <Box key={i} display="flex" flexDirection="column">
            <Typography variant="subtitle2">{title}</Typography>
            <Typography variant="body1" color="text.secondary">
                {text}
            </Typography>
        </Box>
    );
});

export const BestPracticesScreen: React.FC<BestPracticesScreenProps> = ({
    onClose,
    onBack,
    onCredits,
    availableCredits,
}) => {
    return (
        <>
            <StyledBorderBoxHeader>
                <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            height: 20,
                            width: 20,
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        icon={<ArrowBackOutlinedIcon />}
                        tooltip="Back"
                        onClick={onBack}
                        aria-label="Back button"
                    />
                    <Typography variant="h6">Best practices</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                    <CreditsChip availableCredits={availableCredits} onClick={onCredits} />
                    <IconButton
                        size="small"
                        icon={<CloseIcon />}
                        tooltip="Close AI assistant"
                        onClick={onClose}
                        aria-label="Close AI assistant button"
                    />
                </Box>
            </StyledBorderBoxHeader>
            <Divider />
            <Box display="flex" flexDirection="column" height="100%" p={2}>
                <StyledBlockImageContainer>
                    <CardMedia
                        component="img"
                        image={process.env.PUBLIC_URL + '/ai-assistant-best-practices.png'}
                        alt="AI assistant welcome screen image"
                    />
                </StyledBlockImageContainer>
                <Typography variant="subtitle1" pb={2}>
                    How to improve your chances of getting a working script:
                </Typography>
                <OrderedList id="best-practices-list" content={practicesList} />
            </Box>
        </>
    );
};
