/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { Button } from '../../common/buttons/Button';
import { Editor } from './editor/Editor';
import { LegacyPackageDialog } from '../../workspace-dialogs/LegacyPackageDialog';
import { Listener } from './editor/Listener';
import { ReplayInvocationDialog } from '../../workspace-dialogs/ReplayInvocationDialog';
import { SetModel } from './editor/SetModel';
import { Shortcut, ScriptHelperPopup } from './ScriptHelperPopup';
import { WorkspaceEnvironments } from '../../../data/workspace';
import { IconButton } from '../../common/buttons/IconButton';
import { Alert } from '../../common/alerts/Alert';
import {
    StyledEditorMainContainer,
    StyledEditorToolbar,
    StyledEditorToolbarActions,
    StyledEditorViewContainer,
    StyledMainActions,
} from '../../layout/LayoutComponents';
import { AiExplainSelectedCodeDialog } from '../../workspace-dialogs/AiExplainSelectedCodeDialog';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';

export interface Position {
    lineNumber: number;
    column: number;
}

export interface ScriptPosition {
    key: string;
    position: Position;
}

export interface ScriptDetailsProps {
    content: string;
    editorAlertMessage?: string;
    editorReadOnly: boolean;
    environmentUid?: string;
    executing: boolean;
    hasUnsavedChanges: boolean;
    impersonating: boolean;
    legacyImports: string[] | null;
    legacyPackagesDialogErrors?: string;
    legacyPackagesDialogLoading?: boolean;
    legacyPackagesDialogOpen: boolean;
    readOnlyTemplateMode: boolean;
    replayInvocationDialogOpen: boolean;
    replayInvocationPayload: string;
    replayInvocationPayloadLoading: boolean;
    saving: boolean;
    scriptName: string;
    scriptStoredPosition?: Position;
    scriptUid: string;
    selectedEnvironment?: WorkspaceEnvironments[number];
    scriptHelperPopupOpen?: boolean;
    scriptHelperPopupVisible?: boolean;
    workspaceLocked: boolean;
    onAssumeWorkspaceEditControlForReplayInvocation(): void;
    onClickMondayCodeExamplesUrl(): void;
    onCloseReplayInvocationDialog(): void;
    onContentChange(content: string): void;
    onCopyImports(imports: string): void;
    onDiscardChanges(): void;
    onEditorDisableReadOnly(): void;
    onImportApiHandler(): void;
    onRenameScript(): void;
    onLegacyPackagesDialogOpen(isOpen: boolean): void;
    onPackageNameReplacement(content: string): void;
    onReplayInvocationTriggered(payload: string): void;
    onSave(): void;
    onScriptHelperPopupClose(): void;
    onScriptHelperPopupOpen(): void;
    onStoreScriptPosition?(scriptPosition: ScriptPosition): void;
    onTriggerScript(uid: string): void;
    onAskAi(code: string, language: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const platform = (navigator as any).userAgentData ? (navigator as any).userAgentData.platform : navigator.platform;
const shortcuts: Shortcut[] = [
    { combinations: ['CTRL + Space'], text: 'to open/hide auto-complete suggestions.' },
    {
        combinations: platform.toLowerCase().includes('mac') ? ['COMMAND + .'] : ['CTRL + .'],
        text: 'to show import suggestions.',
    },
    { combinations: ['F1', 'Fn + F1'], text: 'to view all commands.' },
];

export const ScriptDetails: React.FC<ScriptDetailsProps> = ({
    content,
    editorAlertMessage,
    editorReadOnly,
    environmentUid,
    executing,
    hasUnsavedChanges,
    impersonating,
    legacyImports,
    legacyPackagesDialogErrors,
    legacyPackagesDialogLoading,
    legacyPackagesDialogOpen,
    readOnlyTemplateMode,
    replayInvocationDialogOpen,
    replayInvocationPayload,
    replayInvocationPayloadLoading,
    saving,
    scriptName,
    scriptUid,
    selectedEnvironment,
    scriptHelperPopupOpen,
    scriptHelperPopupVisible,
    scriptStoredPosition,
    workspaceLocked,
    onAssumeWorkspaceEditControlForReplayInvocation,
    onCloseReplayInvocationDialog,
    onContentChange,
    onCopyImports,
    onDiscardChanges,
    onEditorDisableReadOnly,
    onImportApiHandler,
    onRenameScript,
    onLegacyPackagesDialogOpen,
    onPackageNameReplacement,
    onReplayInvocationTriggered,
    onSave,
    onScriptHelperPopupClose,
    onScriptHelperPopupOpen,
    onStoreScriptPosition,
    onTriggerScript,
    onAskAi,
}) => {
    const [currentScriptName, setCurrentScriptName] = useState(scriptName);
    const [renameDialogShown, setRenameDialogShown] = useState(false);
    const [openConfirmAiExplainSelectedCode, setOpenConfirmAiExplainSelectedCodeDialog] = useState(false);
    const [selectedCode, setSelectedCode] = useState('');
    const [currentLanguage, setCurrentLanguage] = useState('');
    const [aiExplainSelectedCodeConfirmationChecked, setAiExplainSelectedCodeConfirmationChecked] = useState(
        readLocalStorage('aiExplainSelectedCodeConfirmationChecked', false)
    );

    const isContentNeedsPackageReplacement =
        legacyImports &&
        (!renameDialogShown || scriptName !== currentScriptName) &&
        !workspaceLocked &&
        !readOnlyTemplateMode &&
        !impersonating &&
        !selectedEnvironment?.deployment?.version;

    useEffect(() => {
        if (isContentNeedsPackageReplacement) {
            onLegacyPackagesDialogOpen(true);
        }
        setRenameDialogShown(true);

        setCurrentScriptName(scriptName);
    }, [scriptName]);

    const handleContentChange = (value: string): void => {
        onContentChange(value);
    };

    const handleScriptHelperPopup = (): void => {
        if (scriptHelperPopupOpen) {
            onScriptHelperPopupClose();
        } else {
            onScriptHelperPopupOpen();
        }
    };

    const handlePackageNameReplacement = (): void => {
        onPackageNameReplacement(content);
    };

    const handleReplayInvocation = (payload: string): void => {
        onReplayInvocationTriggered(payload);
    };
    const environmentDeployed = selectedEnvironment?.deployment !== undefined;

    const handleAskAi = (code: string, language: string): void => {
        setSelectedCode(code);
        setCurrentLanguage(language);
        if (!readLocalStorage('aiExplainSelectedCodeConfirmationChecked', false)) {
            setOpenConfirmAiExplainSelectedCodeDialog(true);
        } else {
            onAskAi(code, language);
        }
    };

    return (
        <>
            <StyledEditorViewContainer data-test-id="script-details">
                <StyledEditorToolbar>
                    <Typography variant="h5">{scriptName}</Typography>
                    {!readOnlyTemplateMode && (
                        <StyledEditorToolbarActions>
                            <IconButton
                                aria-label="Save changes"
                                border
                                icon={<SaveOutlinedIcon />}
                                busy={saving}
                                disabled={saving || !hasUnsavedChanges || workspaceLocked}
                                tooltip="Save changes"
                                onClick={onSave}
                            />
                            <IconButton
                                aria-label="Trigger script"
                                border
                                icon={<PlayCircleOutlineOutlinedIcon />}
                                busy={executing}
                                disabled={executing || workspaceLocked}
                                tooltip="Trigger script"
                                onClick={() => onTriggerScript(scriptUid)}
                            />
                            <IconButton
                                aria-label="Import API connection"
                                border
                                icon={<DownloadOutlinedIcon />}
                                disabled={editorReadOnly || workspaceLocked || environmentDeployed}
                                tooltip="Import API connection"
                                onClick={onImportApiHandler}
                            />
                            <IconButton
                                aria-label="Rename script"
                                border
                                icon={<EditOutlinedIcon />}
                                disabled={editorReadOnly || workspaceLocked || environmentDeployed}
                                tooltip="Rename script"
                                onClick={onRenameScript}
                            />
                            <IconButton
                                aria-label="Display keyboard shortcuts"
                                border
                                icon={<KeyboardOutlinedIcon />}
                                tooltip="Display keyboard shortcuts"
                                onClick={handleScriptHelperPopup}
                            />
                        </StyledEditorToolbarActions>
                    )}
                </StyledEditorToolbar>
                <Divider />
                {!readOnlyTemplateMode && editorAlertMessage && (
                    <>
                        {editorReadOnly ? (
                            <Alert
                                severity="info"
                                title={editorAlertMessage}
                                action={
                                    <Button variant="contained" onClick={onEditorDisableReadOnly}>
                                        Disable Read-only Mode
                                    </Button>
                                }
                                text={`Editor won't be able to display correct feedback until types have been loaded. Disable read-only mode if you feel brave.`}
                            />
                        ) : (
                            <Alert
                                severity="info"
                                title={editorAlertMessage}
                                text={`Editor won't be able to display correct feedback until types have been loaded.`}
                            />
                        )}
                    </>
                )}
                <StyledEditorMainContainer>
                    <Editor
                        hasUnsavedChanges={hasUnsavedChanges}
                        scriptUid={scriptUid}
                        value={content}
                        name={scriptName}
                        uid={scriptUid}
                        readOnly={editorReadOnly || readOnlyTemplateMode || workspaceLocked || environmentDeployed}
                        onSave={onSave}
                        storedPosition={scriptStoredPosition}
                        onStoreScriptPosition={onStoreScriptPosition}
                        environmentUid={environmentUid}
                        onAskAi={handleAskAi}
                    >
                        {scriptName && <SetModel scriptName={scriptName} />}
                        <Listener onChange={handleContentChange} />
                    </Editor>
                </StyledEditorMainContainer>
                <Divider />
                <StyledMainActions>
                    {hasUnsavedChanges && (
                        <Typography color="text.secondary" variant="subtitle1">
                            Unsaved changes
                        </Typography>
                    )}
                    <Button disabled={!hasUnsavedChanges} variant="outlined" onClick={onDiscardChanges}>
                        Discard
                    </Button>
                    <Button busy={saving} disabled={!hasUnsavedChanges} onClick={onSave}>
                        Save changes
                    </Button>
                </StyledMainActions>
            </StyledEditorViewContainer>
            <ScriptHelperPopup
                onClose={onScriptHelperPopupClose}
                open={scriptHelperPopupOpen && scriptHelperPopupVisible}
                shortcuts={shortcuts}
            />
            <LegacyPackageDialog
                open={legacyPackagesDialogOpen}
                onDialogOpen={onLegacyPackagesDialogOpen}
                onPackageNameReplacement={handlePackageNameReplacement}
                legacyImports={legacyImports}
                onCopyImports={onCopyImports}
                saving={legacyPackagesDialogLoading}
                errors={legacyPackagesDialogErrors}
            />
            <ReplayInvocationDialog
                open={replayInvocationDialogOpen}
                onTrigger={handleReplayInvocation}
                content={replayInvocationPayload}
                onClose={onCloseReplayInvocationDialog}
                loading={replayInvocationPayloadLoading}
                workspaceLocked={workspaceLocked}
                onAssumeWorkspaceEditControl={onAssumeWorkspaceEditControlForReplayInvocation}
            />
            <AiExplainSelectedCodeDialog
                open={openConfirmAiExplainSelectedCode}
                checked={aiExplainSelectedCodeConfirmationChecked}
                onCheck={() => setAiExplainSelectedCodeConfirmationChecked(true)}
                onClose={() => setOpenConfirmAiExplainSelectedCodeDialog(false)}
                onAskAi={() => {
                    if (aiExplainSelectedCodeConfirmationChecked) {
                        saveLocalStorage('aiExplainSelectedCodeConfirmationChecked', true);
                    }
                    onAskAi(selectedCode, currentLanguage);
                }}
            />
        </>
    );
};
