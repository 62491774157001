import { configTopic$, env } from '../store/config';
import { Config } from '../store/config/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const overrideConfigs: Record<string, any> = {
    local: {},
    staging: {},
    production: {},
    'us-production': {},
};

export const fetchConfig = async (): Promise<Config> => {
    const metaBasePath = process.env.PUBLIC_URL.substring(1).length ? process.env.PUBLIC_URL : '';
    const metaPath = `${metaBasePath}/meta/stitch.json`;
    const url = new URL(metaPath, document.location.href).href;
    try {
        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();
            const config = {
                ...data,
                ...overrideConfigs[env],
            };
            configTopic$.next(config);

            return config;
        } else {
            throw Error(`Invalid status code while fetching config: ${response.status}`);
        }
    } catch (e) {
        console.error('Error whilst fetching config', e);
        throw e;
    }
};
