import { MouseEvent, useRef } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { ListItemButton } from '../../common/buttons/ListItemButton';
import { InvocationsLimitAlert } from './InvocationLimitAlert';
import { ResourceTreeSection } from './ResourceTreeSection';
import {
    ApiHandlerResource,
    EventListenerResource,
    FileResource,
    ScheduledTriggerResource,
    ScriptResource,
    SelectedResource,
    TreeResource,
    WorkspaceLanguage,
} from './types';

export interface ResourceTreeProps {
    apiHandlers: ApiHandlerResource[];
    deployedEnvironmentMode?: boolean;
    environmentVariablesHaveMissingInformation?: boolean;
    environmentVariablesHaveUnsavedChanges?: boolean;
    environmentVariablesSaving?: boolean;
    eventListeners: EventListenerResource[];
    externalTriggerBaseUrl?: string;
    loading: boolean;
    open?: boolean;
    readmeFile: FileResource;
    readmeFileContent?: string;
    readOnlyMode?: boolean;
    remainingInvocations?: number;
    scheduledTriggers: ScheduledTriggerResource[];
    scripts: Omit<ScriptResource, 'type'>[];
    selectedNode?: SelectedResource;
    showInvocationsLimitAlert?: boolean;
    workspaceLanguage?: WorkspaceLanguage;
    workspaceUid: string;
    onCloseInvocationsLimitAlert?(): void;
    onCopyApiHandlerPath(uid: string): void;
    onCopyEventListenerUrl(uid: string): void;
    onCreateNewApiHandler(): void;
    onCreateNewEventListener(): void;
    onCreateNewScheduledTrigger(): void;
    onCreateNewScript(): void;
    onDeleteApiHandler(uid: string): void;
    onDeleteEventListener(uid: string): void;
    onDeleteScheduledTrigger(uid: string): void;
    onDeleteScript(uid: string): void;
    onSaveEnvironmentVariables(): void;
    onSaveReadme(): void;
    onSaveScript(uid: string): void;
    onToggleResourceManager(open: boolean): void;
    onTriggerEventListener(uid: string): void;
    onTriggerScript(uid: string): void;
    onUpgradePlan?(): void;
}

export const resourceTreeIconWrapperSize = 24;
export const resourceTreeActionButtonIconWrapperSize = 30;
export const resourceTreeIconSize = 20;
export const resourceTreeInfoIconSize = 16;

const StyledMainArea = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
}));

const StyledTitleArea = styled(Box, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{ collapsed: boolean }>(
    ({ collapsed, theme }) => ({
        ...theme.typography.flexAlignCenter,
        justifyContent: collapsed ? 'center' : 'space-between',
        padding: collapsed ? theme.spacing(1) : theme.spacing(1, 1, 1, 2),
        width: '100%',
    })
);

const StyledUpperSelection = styled(Box)(({ theme }) => ({
    minWidth: 0,
    padding: theme.spacing(0, 1, 1),
    width: '100%',
}));

export const ResourceTree: React.FC<ResourceTreeProps> = ({
    apiHandlers = [],
    deployedEnvironmentMode = false,
    environmentVariablesHaveMissingInformation = false,
    environmentVariablesHaveUnsavedChanges = false,
    environmentVariablesSaving = false,
    eventListeners = [],
    externalTriggerBaseUrl,
    open = true,
    readOnlyMode = false,
    readmeFile,
    readmeFileContent,
    remainingInvocations,
    scheduledTriggers = [],
    scripts = [],
    selectedNode,
    showInvocationsLimitAlert = false,
    workspaceLanguage,
    workspaceUid,
    onCloseInvocationsLimitAlert,
    onCopyApiHandlerPath,
    onCopyEventListenerUrl,
    onCreateNewApiHandler,
    onCreateNewEventListener,
    onCreateNewScheduledTrigger,
    onCreateNewScript,
    onDeleteApiHandler,
    onDeleteEventListener,
    onDeleteScheduledTrigger,
    onDeleteScript,
    onSaveEnvironmentVariables,
    onSaveReadme,
    onSaveScript,
    onToggleResourceManager,
    onTriggerScript,
    onTriggerEventListener,
    onUpgradePlan,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scriptsRef = useRef<HTMLDivElement>(null);
    const apiConnectionsRef = useRef<HTMLDivElement>(null);
    const eventListenersRef = useRef<HTMLDivElement>(null);

    const createTreeNode = (
        scriptName: string,
        path: string,
        script: Omit<ScriptResource, 'type'>,
        tree: TreeResource[]
    ): void => {
        const splitPath = scriptName.split('/');
        const name = splitPath.shift() ?? '';
        const fullPath = path ? path + '/' + name : name;
        if (splitPath.length < 1) {
            tree.push({ ...script, type: 'SCRIPT' });
        } else {
            const folder = tree.find((f) => f.name === name && f.type === 'FOLDER');

            if (!folder) {
                tree.push({ fullPath, name, subItems: [], type: 'FOLDER', workspaceUid });
            }
            const newScript = { ...script, fullPath, name: splitPath[0] ?? '' };

            const child = tree[tree.length - 1];

            if (child) {
                createTreeNode(splitPath.join('/'), fullPath, newScript, child.type === 'FOLDER' ? child.subItems : []);
            }
        }
    };

    const handleFolders = (scripts: Omit<ScriptResource, 'type'>[]): TreeResource[] => {
        const tree: TreeResource[] = [];
        scripts.forEach((script) => {
            const scriptName = script.name ?? '';
            createTreeNode(scriptName, '', script, tree);
        });
        return tree;
    };

    const sortTree = (tree: TreeResource[]): TreeResource[] => {
        tree.sort((left, right) => {
            const leftIsFolder = left.type === 'FOLDER';
            const rightIsFolder = right.type === 'FOLDER';
            if (leftIsFolder && rightIsFolder) {
                const leftName = left.name ?? '';
                const rightName = right.name ?? '';
                return leftName.localeCompare(rightName);
            }
            return leftIsFolder ? -1 : rightIsFolder ? 1 : 0;
        });
        tree.forEach((el) => {
            if (el.type === 'FOLDER') {
                sortTree(el.subItems);
            }
        });
        return tree;
    };

    const tree = sortTree(handleFolders(scripts));

    const getStartedIsSelected = selectedNode?.resourceType === 'DEFAULT';
    const readmeIsSelected = selectedNode?.uid === readmeFile?.uid;
    const environmentVariablesIsSelected = selectedNode?.resourceType === 'ENVIRONMENT_VARIABLES';
    const showSaveIconForReadme = readmeFile.unsaved && !readOnlyMode;
    const showSaveIconForEnvironmentVariables = !readOnlyMode && environmentVariablesHaveUnsavedChanges;
    const showWarningIconForEnvironmentVariables = !readOnlyMode && environmentVariablesHaveMissingInformation;

    const handleSaveReadme = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onSaveReadme();
    };

    const handleSaveEnvironmentVariables = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onSaveEnvironmentVariables();
    };

    return (
        <StyledMainArea data-test-id="resource-tree" ref={containerRef}>
            <StyledTitleArea collapsed={!open}>
                {open && (
                    <Typography variant="subtitle1" component="h2" color="text.primary">
                        Resource manager
                    </Typography>
                )}
                <IconButton
                    aria-label={open ? 'Close resource manager' : 'Open resource manager'}
                    icon={
                        <KeyboardTabOutlinedIcon
                            sx={{ color: 'text.primary', transform: open ? 'rotate(180deg)' : undefined }}
                        />
                    }
                    tooltip={open ? 'Close resource manager' : 'Open resource manager'}
                    onClick={() => onToggleResourceManager(!open)}
                />
            </StyledTitleArea>
            {open && (
                <>
                    <StyledUpperSelection>
                        {showInvocationsLimitAlert && (
                            <InvocationsLimitAlert
                                remainingInvocations={remainingInvocations}
                                onClose={() => onCloseInvocationsLimitAlert?.()}
                                onUpgradePlan={() => onUpgradePlan?.()}
                            />
                        )}
                        {!readOnlyMode && (
                            <ListItemButton
                                label="Get started"
                                link={{ href: '.', type: 'router' }}
                                selected={getStartedIsSelected}
                                startIcon={<FlagOutlinedIcon color="primary" />}
                            />
                        )}
                        <ListItemButton
                            endIcons={[
                                ...(showSaveIconForReadme
                                    ? [
                                          <IconButton
                                              aria-label="Save README"
                                              busy={readmeFile.saving}
                                              disabled={!readmeFileContent}
                                              icon={<SaveOutlinedIcon />}
                                              size="small"
                                              tooltip="Save README"
                                              onClick={handleSaveReadme}
                                          />,
                                      ]
                                    : []),
                            ]}
                            label="README"
                            link={{ href: `./readme/${readmeFile.uid ?? ''}`, type: 'router' }}
                            selected={readmeIsSelected}
                            startIcon={<IntegrationInstructionsOutlinedIcon color="primary" />}
                        />
                        <ListItemButton
                            endIcons={[
                                ...(showWarningIconForEnvironmentVariables
                                    ? [
                                          <Tooltip title="Some parameters are missing essential information">
                                              <WarningOutlinedIcon color="warning" />
                                          </Tooltip>,
                                      ]
                                    : []),
                                ...(showSaveIconForEnvironmentVariables
                                    ? [
                                          <IconButton
                                              aria-label="Save parameters"
                                              busy={environmentVariablesSaving}
                                              icon={<SaveOutlinedIcon />}
                                              size="small"
                                              tooltip="Save parameters"
                                              onClick={handleSaveEnvironmentVariables}
                                          />,
                                      ]
                                    : []),
                            ]}
                            label="Parameters"
                            link={{ href: './parameters', type: 'router' }}
                            selected={environmentVariablesIsSelected}
                            startIcon={<TokenOutlinedIcon color="primary" />}
                        />
                    </StyledUpperSelection>
                    <Divider />
                    <ResourceTreeSection
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        innerRef={scriptsRef}
                        language={workspaceLanguage}
                        noResourcesCreated={!scripts.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<CodeIcon />}
                        resourceName="Scripts"
                        selectedNode={selectedNode?.uid}
                        tooltipText="Scripts allow you to describe your business logic."
                        treeNodes={tree}
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewScript}
                        onDelete={onDeleteScript}
                        onSaveScript={onSaveScript}
                        onTrigger={onTriggerScript}
                    />
                    <Divider />
                    <ResourceTreeSection
                        apiHandlers={apiHandlers}
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        innerRef={apiConnectionsRef}
                        noResourcesCreated={!apiHandlers.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<UploadOutlinedIcon />}
                        resourceName="API connections"
                        selectedNode={selectedNode?.uid}
                        tooltipText="API connections allow you to call APIs of external services."
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewApiHandler}
                        onDelete={onDeleteApiHandler}
                        onPathCopy={onCopyApiHandlerPath}
                    />
                    <Divider />
                    <ResourceTreeSection
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        eventListeners={eventListeners}
                        externalTriggerBaseUrl={externalTriggerBaseUrl}
                        innerRef={eventListenersRef}
                        noResourcesCreated={!eventListeners.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<DownloadOutlinedIcon />}
                        resourceName="Event listeners"
                        selectedNode={selectedNode?.uid}
                        tooltipText="Event listeners allow you to listen to events triggered by external services."
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewEventListener}
                        onDelete={onDeleteEventListener}
                        onTrigger={onTriggerEventListener}
                        onUrlCopy={onCopyEventListenerUrl}
                    />
                    <Divider />
                    <ResourceTreeSection
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        noResourcesCreated={!scheduledTriggers.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<ScheduleOutlinedIcon />}
                        resourceName="Scheduled triggers"
                        scheduledTriggers={scheduledTriggers}
                        selectedNode={selectedNode?.uid}
                        tooltipText="Scheduled triggers allow you to trigger scripts based on a schedule."
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewScheduledTrigger}
                        onDelete={onDeleteScheduledTrigger}
                    />
                </>
            )}
        </StyledMainArea>
    );
};
