import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Alert } from '../../common/alerts/Alert';
import { AppDialog } from './AppDialog';
import { Avatar } from '../../common/avatars/Avatar';
import { Button } from '../../common/buttons/Button';
import { Dropdown } from '../../common/inputs/dropdown/Dropdown';
import { InfoIcon } from '../../icons/InfoIcon';
import { InformationChip } from '../../common/chips/InformationChip';
import { RadioGroup } from '../../common/radio/RadioGroup';
import { TextField } from '../../common/inputs/TextField';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxTitleContainer,
} from '../../layout/BorderBoxComponents';
import {
    StyledPageHeader,
    StyledPageHeaderRow,
    StyledPageInnerContainer,
    StyledPageOuterContainer,
    StyledPageTitleContainer,
} from '../../layout/PageComponents';
import { autoFocus, handleKeyDown } from '../../../utils/input';

export interface ProfileSettingsProps {
    apps: {
        uid: string;
        name: string;
    }[];
    company?: string;
    customRole?: string;
    deleteDate?: Date;
    email: string;
    emailNotificationsEnabled: boolean;
    errors?: string;
    firstName?: string;
    lastName?: string;
    mfaEnabled: boolean;
    roles: {
        value: string;
        name: string;
    }[];
    roleUid?: string;
    saving?: boolean;
    scriptingFamiliarities: {
        value: string;
        name: string;
    }[];
    scriptingFamiliarityUid: string;
    selectedApps: string[];
    showPasswordReset: boolean;
    supportPortalUrl: string;
    onCancelDelete(): void;
    onDelete(): void;
    onDiscard(): void;
    onPasswordReset(): void;
    onUpdate(event: UpdateProfileEvent): void;
}

export interface UpdateProfileEvent {
    company?: string;
    customRole?: string;
    enableEmailNotifications: boolean;
    enableMfa: boolean;
    firstName?: string;
    lastName?: string;
    roleUid?: string;
    scriptingFamiliarityUid?: string;
    userAppPreference: string[];
}

const StyledHeaderContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    width: '100%',
}));

const StyledResetBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledNameContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    '& .MuiFormControl-root': {
        flexGrow: 1,
    },
}));

const StyledChipContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
}));

const StyledAppsTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        cursor: 'pointer',
        height: 'unset',
    },
    '& .MuiInputBase-input': {
        pointerEvents: 'none',
    },
    '& .MuiInputAdornment-root': {
        height: 'unset',
        maxHeight: 'unset',
    },
    '& .MuiInputAdornment-positionStart': {
        flexGrow: 1,
    },
    '& textarea': {
        height: 0,
        width: 0,
    },
}));

const StyledRadioContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
}));

const StyledMfaContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
}));

const StyledDeleteButtonContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexGrow: 1,
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
}));

export const ProfileSettings: React.FC<ProfileSettingsProps> = ({
    apps,
    company,
    customRole,
    deleteDate,
    email,
    emailNotificationsEnabled = false,
    errors,
    firstName,
    lastName,
    mfaEnabled = false,
    roleUid,
    roles,
    saving = false,
    showPasswordReset,
    supportPortalUrl,
    scriptingFamiliarities,
    scriptingFamiliarityUid,
    selectedApps,
    onCancelDelete,
    onDelete,
    onDiscard,
    onPasswordReset,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentFirstName, setFirstName] = useState(firstName);
    const [currentLastName, setLastName] = useState(lastName);
    const [currentRole, setCurrentRole] = useState(!customRole ? roleUid : undefined);
    const [currentCompany, setCompany] = useState(company);
    const [currentCustomRole, setCurrentCustomRole] = useState(customRole);
    const [currentEmailNotificationsEnabled, setCurrentEmailNotificationsEnabled] = useState(emailNotificationsEnabled);
    const [currentMfaEnabled, setCurrentMfaEnabled] = useState(mfaEnabled);
    const [currentScriptingFamiliarity, setCurrentScriptingFamiliarity] = useState(scriptingFamiliarityUid);
    const [currentSelectedApps, setSelectedApps] = useState<string[]>(selectedApps ?? []);
    const [openAppDialog, setOpenAppDialog] = useState<boolean>(false);

    const hasDifferentValues = (arr1: string[], arr2: string[]): boolean =>
        arr1.some((item) => !arr2.includes(item)) || arr2.some((item) => !arr1.includes(item));

    const isDifferentSelectedApps = hasDifferentValues(currentSelectedApps, selectedApps);

    const isChanged =
        currentFirstName !== firstName ||
        currentLastName !== lastName ||
        currentRole !== roleUid ||
        currentCompany !== company ||
        currentCustomRole !== customRole ||
        currentEmailNotificationsEnabled !== emailNotificationsEnabled ||
        currentMfaEnabled !== mfaEnabled ||
        currentScriptingFamiliarity !== scriptingFamiliarityUid ||
        isDifferentSelectedApps;

    const canSave = isChanged && !saving && !!(currentRole !== 'Other' || currentCustomRole);

    useEffect(() => {
        if (currentCustomRole) {
            setCurrentRole('Other');
        }
    }, [currentCustomRole]);

    useEffect(() => {
        if (currentRole && currentRole !== 'Other') {
            setCurrentCustomRole('');
        } else if (!currentCustomRole) {
            autoFocus(inputRef);
        }
    }, [currentRole]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const selectableRoles = [...roles, { name: 'Other', value: 'Other' }];

    const hasChips = currentSelectedApps.length > 0;

    return (
        <>
            <StyledPageOuterContainer data-testid="profile-page">
                <StyledPageInnerContainer maxWidth={688}>
                    <StyledPageHeader data-testid="profile-page-header">
                        <StyledPageHeaderRow>
                            <StyledPageTitleContainer>
                                <Typography data-testid="profile-page-title" variant="h5">
                                    Profile settings
                                </Typography>
                            </StyledPageTitleContainer>
                        </StyledPageHeaderRow>
                    </StyledPageHeader>
                    {errors && <Alert severity="error" title={errors} />}
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <Typography variant="h6">Avatar</Typography>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <Avatar
                                credentials={{
                                    firstName: currentFirstName ?? '',
                                    lastName: currentLastName ?? '',
                                    email,
                                }}
                                data-testid="profile-page-avatar"
                                size="large"
                            />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <Typography variant="h6">Personal information</Typography>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <StyledNameContainer>
                                <TextField
                                    data-testid="profile-page-first-name-input"
                                    label="First name"
                                    placeholder="Enter first name"
                                    value={currentFirstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <TextField
                                    data-testid="profile-page-last-name-input"
                                    label="Last name"
                                    placeholder="Enter last name"
                                    value={currentLastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </StyledNameContainer>
                            <TextField
                                data-testid="profile-page-email-input"
                                disabled
                                fullWidth
                                helperText={
                                    <Typography variant="body2">
                                        If you need to update your email, please{' '}
                                        <Link href={supportPortalUrl} target="_blank">
                                            contact our support team
                                        </Link>{' '}
                                        for assistance.
                                    </Typography>
                                }
                                label="Email"
                                placeholder="Enter an email"
                                required
                                value={email}
                            />
                            {showPasswordReset && (
                                <StyledResetBox>
                                    <Button
                                        data-testid="profile-page-reset-password-button"
                                        variant="text"
                                        onClick={onPasswordReset}
                                    >
                                        Reset password
                                    </Button>
                                    <InfoIcon tooltip="Reset your password if you would like to change your password or have forgotten it." />
                                </StyledResetBox>
                            )}
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <Typography variant="h6">Work information</Typography>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <TextField
                                data-testid="profile-page-company-name-input"
                                fullWidth
                                label="Company Name"
                                placeholder="Enter company name"
                                value={currentCompany}
                                onChange={(e) => setCompany(e.target.value)}
                            />
                            <Dropdown
                                data-testid="profile-page-role-select"
                                fullWidth
                                items={selectableRoles}
                                label="Profession"
                                selectedItem={currentRole}
                                onSelectItem={(value) => setCurrentRole(value)}
                            />
                            {currentRole === 'Other' && (
                                <TextField
                                    data-testid="profile-page-custom-role-input"
                                    fullWidth
                                    inputRef={inputRef}
                                    label="Role Name"
                                    placeholder="Enter role"
                                    required
                                    value={currentCustomRole}
                                    onChange={(e) => setCurrentCustomRole(e.target.value)}
                                />
                            )}
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <Box width="100%">
                                <StyledHeaderContainer>
                                    <Typography variant="h6">Skills and preference</Typography>
                                    <InformationChip label="New" severity="success" />
                                </StyledHeaderContainer>
                                <Typography color="text.secondary">
                                    Be sure to update these sections as your preferences change
                                </Typography>
                            </Box>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <Dropdown
                                data-testid="profile-page-scripting-familiarity-select"
                                fullWidth
                                items={scriptingFamiliarities}
                                label="JavaScript proficiency"
                                selectedItem={currentScriptingFamiliarity}
                                onSelectItem={(value) => setCurrentScriptingFamiliarity(value)}
                            />
                            <StyledAppsTextField
                                data-testid="profile-page-apps-select"
                                endIcon={<EditOutlinedIcon />}
                                fullWidth
                                label="Favourite apps"
                                multiline={hasChips ? true : false}
                                placeholder={''}
                                startIcon={
                                    <StyledChipContainer>
                                        {currentSelectedApps.map((value) => (
                                            <InformationChip
                                                key={value}
                                                label={apps.find((option) => option.uid === value)?.name ?? ''}
                                                severity="grey"
                                                sx={{ pointerEvents: 'none' }}
                                            />
                                        ))}
                                    </StyledChipContainer>
                                }
                                onClick={(event) => {
                                    event.preventDefault();
                                    setOpenAppDialog(true);
                                }}
                                onKeyDown={(event) => handleKeyDown({ event, enterFn: () => setOpenAppDialog(true) })}
                            />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <Box width="100%">
                                <StyledHeaderContainer>
                                    <StyledBorderBoxTitleContainer>
                                        <Typography variant="h6">Email notifications</Typography>
                                        <InfoIcon tooltip="This will enable email notifications keeping you up to date on the status of your integrations." />
                                    </StyledBorderBoxTitleContainer>
                                    <InformationChip label="New" severity="success" />
                                </StyledHeaderContainer>
                                <Typography color="text.secondary">
                                    Recieve updates on the status of your integrations
                                </Typography>
                            </Box>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <StyledRadioContainer>
                                <Typography variant="subtitle1">Script failure</Typography>
                                <RadioGroup
                                    border
                                    buttons={[
                                        {
                                            'data-testid': 'profile-page-script-failure-radio-group-off-button',
                                            label: 'On',
                                            value: 'true',
                                        },
                                        {
                                            'data-testid': 'profile-page-script-failure-radio-group-off-button',
                                            label: 'Off',
                                            value: 'false',
                                        },
                                    ]}
                                    data-testid="profile-page-script-failure-radio-group"
                                    row
                                    value={currentEmailNotificationsEnabled ? 'true' : 'false'}
                                    onChange={(value) => setCurrentEmailNotificationsEnabled(value === 'true')}
                                />
                            </StyledRadioContainer>
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <StyledBorderBox>
                        <StyledBorderBoxHeader>
                            <Box width="100%">
                                <StyledHeaderContainer>
                                    <StyledBorderBoxTitleContainer>
                                        <Typography variant="h6">Multi-factor authentication</Typography>
                                    </StyledBorderBoxTitleContainer>
                                    <InformationChip label="New" severity="success" />
                                </StyledHeaderContainer>
                                <Typography color="text.secondary">
                                    Enhances security by requiring multiple verification methods to confirm your
                                    identity.
                                </Typography>
                            </Box>
                        </StyledBorderBoxHeader>
                        <Divider />
                        <StyledBorderBoxContent>
                            <StyledRadioContainer>
                                <StyledMfaContainer>
                                    <Typography variant="subtitle1">Enable MFA</Typography>
                                    <InfoIcon tooltip="This will enrol you to MFA the next time you login" />
                                </StyledMfaContainer>
                                <RadioGroup
                                    border
                                    buttons={[
                                        {
                                            'data-testid': 'profile-page-mfa-radio-group-on-button',
                                            label: 'On',
                                            value: 'true',
                                        },
                                        {
                                            'data-testid': 'profile-page-mfa-radio-group-off-button',
                                            label: 'Off',
                                            value: 'false',
                                        },
                                    ]}
                                    data-testid="profile-page-mfa-radio-group"
                                    row
                                    value={currentMfaEnabled ? 'true' : 'false'}
                                    onChange={(value) => setCurrentMfaEnabled(value === 'true')}
                                />
                            </StyledRadioContainer>
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                    <StyledActions>
                        <StyledDeleteButtonContainer>
                            {deleteDate ? (
                                <>
                                    <Button
                                        data-testid="profile-page-cancel-delete-account-button"
                                        disabled={saving}
                                        color="error"
                                        variant="text"
                                        onClick={onCancelDelete}
                                    >
                                        Cancel account deletion
                                    </Button>
                                    <InfoIcon
                                        tooltip={`Account will be deleted at the latest of ${new Date(
                                            new Date(deleteDate).getTime() + 1000 * 60 * 60 * 24 * 31
                                        ).toLocaleString()}`}
                                    />
                                </>
                            ) : (
                                <Button
                                    data-testid="profile-page-delete-account-button"
                                    disabled={saving}
                                    color="error"
                                    variant="text"
                                    onClick={onDelete}
                                >
                                    Delete account
                                </Button>
                            )}
                        </StyledDeleteButtonContainer>
                        <Button
                            data-testid="profile-page-discard-button"
                            disabled={!canSave}
                            variant="outlined"
                            onClick={onDiscard}
                        >
                            Discard
                        </Button>
                        <Button
                            busy={saving}
                            data-testid="profile-page-save-button"
                            disabled={!canSave}
                            onClick={() => {
                                onUpdate({
                                    firstName: currentFirstName,
                                    lastName: currentLastName,
                                    roleUid: !currentCustomRole ? currentRole : undefined,
                                    company: currentCompany,
                                    customRole: currentCustomRole,
                                    enableEmailNotifications: currentEmailNotificationsEnabled,
                                    enableMfa: currentMfaEnabled,
                                    scriptingFamiliarityUid: currentScriptingFamiliarity,
                                    userAppPreference: currentSelectedApps,
                                });
                            }}
                        >
                            Save Changes
                        </Button>
                    </StyledActions>
                </StyledPageInnerContainer>
            </StyledPageOuterContainer>
            <AppDialog
                apps={apps}
                data-testid="select-apps-dialog"
                open={openAppDialog}
                userSelectedApps={currentSelectedApps}
                onClose={() => setOpenAppDialog(false)}
                onUpdate={(e) => setSelectedApps(e)}
            />
        </>
    );
};
