import { styled } from '@mui/material';
import { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import SouthWestOutlinedIcon from '@mui/icons-material/SouthWestOutlined';
import { Avatar } from '../../common/avatars/Avatar';
import { IconButton } from '../../common/buttons/IconButton';
import { Indicator } from '../../common/Indicator';
import { ProductIcon } from '../../icons/ProductIcon';
import { InformationChip } from '../../common/chips/InformationChip';
import { RouterLink } from '../../common/RouterLink';
import { ForwardedLinkProps } from '../../common/buttons/Button';
import { Workspace } from './types';

interface WorkspaceSelectorCardProps extends Workspace {
    basePath?: string;
    onDelete(uid: string): void;
    onDuplicate(uid: string): void;
}

const StyledCard = styled(ButtonBase)<ForwardedLinkProps>(({ theme }) => ({
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    height: 338,
    width: 380,
    '&:hover': {
        border: theme.constants.borderSelected,
    },
}));

const StyledCardUpper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    textAlign: 'start',
}));

const StyledCardUpperRow = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
}));

const StyledEnvironmentContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledTextBlock = styled(Box)(() => ({
    height: 98,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
    marginBottom: theme.spacing(0.5),
    WebkitLineClamp: 2,
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
    WebkitLineClamp: 2,
}));

const StyledAppContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledAppRow = styled(Box)(() => ({
    alignItems: 'stretch',
    display: 'flex',
}));

const StyledAppRowLeft = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    borderRight: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(0.5),
    padding: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

const StyledAppRowLeftTop = styled(StyledAppRowLeft)(({ theme }) => ({
    borderTopLeftRadius: theme.constants.borderRadius,
}));

const StyledAppRowLeftBottom = styled(StyledAppRowLeft)(({ theme }) => ({
    borderBottomLeftRadius: theme.constants.borderRadius,
}));

const StyledAppRowRight = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexGrow: 1,
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

const StyledCardFooter = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
}));

export const StyledAvatarContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiAvatar-root:not(:first-of-type)': {
        marginLeft: theme.spacing(-0.5),
    },
}));

const StyledCardActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

export const WorkspaceSelectorCard: React.FC<WorkspaceSelectorCardProps> = ({
    basePath,
    description,
    environments,
    incoming = [],
    outgoing = [],
    template,
    title,
    uid,
    users,
    onDelete,
    onDuplicate,
}) => {
    const handleDuplicate = (event: MouseEvent<HTMLButtonElement>, uid: string): void => {
        event.preventDefault();
        onDuplicate(uid);
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>, uid: string): void => {
        event.preventDefault();
        onDelete(uid);
    };

    const maxAvatars = 4;

    const userAvatars = users
        .slice(0, maxAvatars)
        .map((u) => (
            <Avatar key={u.email} credentials={{ email: u.email, firstName: u.firstName, lastName: u.lastName }} />
        ));

    const undisplayedUsers = users.length > maxAvatars ? users.length - maxAvatars : undefined;
    const selectedEnvironmentUid = environments.find((e) => e.selected)?.uid ?? environments[0]?.uid ?? '';

    return (
        <StyledCard
            component={RouterLink}
            data-testid="workspaces-page-workspace-card"
            to={`${basePath}workspace/${uid}/environment/${selectedEnvironmentUid}`}
        >
            <StyledCardUpper>
                <StyledCardUpperRow>
                    <Indicator
                        left={{ icon: <Inventory2OutlinedIcon /> }}
                        right={{
                            icon: (
                                <StyledEnvironmentContainer>
                                    <DisplaySettingsOutlinedIcon />
                                    <Typography>{environments.length}</Typography>
                                </StyledEnvironmentContainer>
                            ),
                        }}
                    />
                    {template && <InformationChip label="Template" severity="info" />}
                </StyledCardUpperRow>
                <StyledTextBlock>
                    <Tooltip title={title}>
                        <StyledTitle variant="subtitle1">{title}</StyledTitle>
                    </Tooltip>
                    <Tooltip title={description}>
                        <StyledDescription color="text.secondary">{description}</StyledDescription>
                    </Tooltip>
                </StyledTextBlock>
                <StyledAppContainer>
                    <StyledAppRow>
                        <StyledAppRowLeftTop>
                            <SouthWestOutlinedIcon />
                            <Typography variant="body2">Incoming</Typography>
                        </StyledAppRowLeftTop>
                        <StyledAppRowRight>
                            {incoming.slice(0, 8).map((i) => (
                                <ProductIcon key={i} name={i} tooltip />
                            ))}
                        </StyledAppRowRight>
                    </StyledAppRow>
                    <Divider />
                    <StyledAppRow>
                        <StyledAppRowLeftBottom>
                            <NorthEastOutlinedIcon />
                            <Typography variant="body2">Outgoing</Typography>
                        </StyledAppRowLeftBottom>
                        <StyledAppRowRight>
                            {outgoing.slice(0, 8).map((o) => (
                                <ProductIcon key={o} name={o} tooltip />
                            ))}
                        </StyledAppRowRight>
                    </StyledAppRow>
                </StyledAppContainer>
            </StyledCardUpper>
            <Divider />
            <StyledCardFooter>
                <StyledAvatarContainer>
                    {userAvatars}
                    {undisplayedUsers && (
                        <Typography ml={0.5} color="text.secondary">{`+${undisplayedUsers}`}</Typography>
                    )}
                </StyledAvatarContainer>
                <StyledCardActions>
                    <IconButton
                        aria-label="Duplicate workspace"
                        border
                        data-testid={`workspaces-page-workspace-card-${uid}-duplicate-button`}
                        icon={<CopyAllOutlinedIcon />}
                        tooltip="Duplicate workspace"
                        onClick={(event) => handleDuplicate(event, uid)}
                    />
                    <IconButton
                        aria-label="Delete workspace"
                        border
                        data-testid={`workspaces-page-workspace-card-${uid}-delete-button`}
                        icon={<DeleteOutlineIcon />}
                        tooltip="Delete workspace"
                        onClick={(event) => handleDelete(event, uid)}
                    />
                </StyledCardActions>
            </StyledCardFooter>
        </StyledCard>
    );
};
