import { Fragment, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import IconButton from '@mui/material/IconButton';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import Box from '@mui/material/Box';
import useResizeObserver from 'use-resize-observer';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import { Dialog } from '../../common/dialogs/Dialog';
import { Button } from '../../common/buttons/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';

export interface LogProps {
    severity: LogSeverity;
    text?: JSX.Element;
    columns?: Columns[];
    logTitle: LogTitleProps;
    plainText?: string;
    onAskAi(data: { error: string; scriptName: string }): void;
}

export interface LogTitleProps {
    time: number;
    level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS';
    source?: string;
    environmentName?: string;
    invocationId?: string;
}

interface Columns {
    name: string;
    checked: boolean;
}

export type LogSeverity = 'error' | 'warning' | 'info' | 'success' | 'debug';

const StyledWarningAmberOutlinedIcon = styled(WarningAmberOutlinedIcon)(({ theme }) => ({
    color: theme.palette.warning.main,
}));

const iconMapping = {
    success: <CheckCircleOutlineIcon />,
    error: <ReportOutlinedIcon />,
    warning: <StyledWarningAmberOutlinedIcon />,
    info: <InfoOutlinedIcon />,
    debug: <BugReportOutlinedIcon />,
};

const StyledLog = styled(MuiAlert)(({ theme }) => ({
    minWidth: '100%',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    padding: theme.spacing(0.5),
    '&.MuiAlert-severityDebug': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.disabledBackground,
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
        },
    },
    '& .MuiAlert-message, .MuiAlertTitle-root': {
        ...theme.typography.body1,
        '& .MuiSvgIcon-root': {
            height: 16,
            width: 16,
        },
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    '& .MuiAlert-message': {
        padding: theme.spacing(0),
    },
    '& .MuiAlert-icon': {
        padding: 0,
        marginRight: theme.spacing(1),
    },
    '& .MuiTypography-root': {
        marginBottom: theme.spacing(0),
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
        width: 30,
        height: 30,
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        margin: theme.spacing(0.625),
    },
}));

const StyledSpan = styled('span')(({ theme }) => ({
    margin: theme.spacing(0, 0.5),
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Log: React.FC<LogProps> = ({ severity, text, columns = [], logTitle, plainText, onAskAi, ...props }) => {
    const messageRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [initialHeight, setInitialHeight] = useState(0);
    const [openConfirmAiDialog, setOpenConfirmAiDialog] = useState(false);
    const [aiExplainErrorConfirmationChecked, setAiExplainErrorConfirmationChecked] = useState(
        readLocalStorage('aiExplainErrorConfirmationChecked', false)
    );

    const overflowBlock = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

    useResizeObserver({
        ref: messageRef,
        onResize: () => {
            if (messageRef.current && !expanded) {
                const hasOverflow = messageRef.current.scrollWidth > messageRef.current.clientWidth;
                setIsOverflowing(hasOverflow);
            }

            if (messageRef.current && expanded && messageRef.current.clientHeight === initialHeight) {
                const hasOverflow = messageRef.current.scrollWidth > messageRef.current.clientWidth;
                setExpanded(false);
                setIsOverflowing(hasOverflow);
            }
        },
    });

    const addLogLevel = (arr: Columns[]): Columns[] => {
        const index = arr.findIndex((column) => column.name === 'Environment Name');
        const logLevel = { name: 'Log Level', checked: true };

        return [...arr.slice(0, index + 1), logLevel, ...arr.slice(index + 1)];
    };

    const alertSeverity = severity === 'debug' ? undefined : severity;
    const customClass = severity === 'debug' ? 'MuiAlert-severityDebug' : '';
    const displayText = expanded || !plainText ? text : plainText;
    const updatedColumns = addLogLevel(columns);

    return (
        <StyledLog
            {...props}
            severity={alertSeverity}
            iconMapping={iconMapping}
            icon={
                <Box display="flex">
                    {((plainText && plainText?.includes('\n')) || (isOverflowing && plainText)) && (
                        <Tooltip title={expanded ? 'Collapse log' : 'Expand log'}>
                            <StyledIconButton
                                onClick={() => {
                                    setExpanded((prev) => !prev);
                                    setInitialHeight(messageRef.current?.clientHeight ?? 0);
                                }}
                            >
                                {expanded ? <ArrowDropDownOutlinedIcon /> : <ArrowRightOutlinedIcon />}
                            </StyledIconButton>
                        </Tooltip>
                    )}
                    <Box pt={0.5}>{iconMapping[severity]}</Box>
                </Box>
            }
            className={customClass}
        >
            <Box display="flex" alignItems="center" gap={1}>
                <AlertTitle>
                    <FilteredColumns columnsChecked={updatedColumns.filter((c) => c.checked)} logTitle={logTitle} />
                </AlertTitle>
                {severity === 'error' && plainText?.includes('\n') && (
                    <Box>
                        <Tooltip title="Ask the AI assistant to explain the error" placement="top">
                            <IconButton
                                onClick={() => {
                                    if (!aiExplainErrorConfirmationChecked) {
                                        setOpenConfirmAiDialog(true);
                                    } else {
                                        onAskAi({ error: plainText ?? '', scriptName: logTitle.source ?? '' });
                                    }
                                }}
                                data-pendo={'aiExplainErrorShortcut'}
                            >
                                <AssistantOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                <Dialog
                    open={openConfirmAiDialog}
                    buttons={[
                        <Button variant="outlined" onClick={() => setOpenConfirmAiDialog(false)}>
                            Cancel
                        </Button>,
                        <Button
                            onClick={() => {
                                setOpenConfirmAiDialog(false);
                                onAskAi({ error: plainText ?? '', scriptName: logTitle.source ?? '' });
                            }}
                        >
                            Continue
                        </Button>,
                    ]}
                    title="Would you like to proceed?"
                    onClose={() => setOpenConfirmAiDialog(false)}
                >
                    <Typography color="text.secondary">
                        This feature will submit related script to the AI for processing to help it better understand
                        the context, including the error message.
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={aiExplainErrorConfirmationChecked}
                                    onChange={() => {
                                        saveLocalStorage('aiExplainErrorConfirmationChecked', true);
                                        setAiExplainErrorConfirmationChecked(true);
                                    }}
                                    title="Don't ask me this again"
                                />
                            }
                            label="Don't ask me this again"
                        />
                    </FormGroup>
                </Dialog>
            </Box>
            <Box ref={messageRef} sx={!expanded ? overflowBlock : undefined}>
                {severity === 'error' ? <strong>{displayText}</strong> : displayText}
            </Box>
        </StyledLog>
    );
};

const FilteredColumns: React.FC<{
    logTitle: LogTitleProps;
    columnsChecked: Columns[];
}> = ({ logTitle, columnsChecked }) => {
    const date = new Date(logTitle.time as number);

    const renderColumn = (name: string): JSX.Element | string | undefined => {
        switch (name) {
            case 'Date':
                return date.toLocaleDateString();
            case 'Time':
                return date.toLocaleTimeString();
            case 'Source':
                return (
                    <>
                        <SourceOutlinedIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: 4 }} />
                        {logTitle.source ?? 'Workspace'}
                    </>
                );
            case 'Log Level':
                return logTitle.level;
            case 'Environment Name':
                return logTitle.environmentName ? (
                    <>
                        <DisplaySettingsOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
                        {logTitle.environmentName}
                    </>
                ) : undefined;
            case 'Invocation ID':
                return logTitle.invocationId;
            default:
                return;
        }
    };

    const columnsToDisplay = columnsChecked
        .map((column) => renderColumn(column.name))
        .filter((content) => content !== undefined);

    return (
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
            {columnsToDisplay.map((content, i) => (
                <Fragment key={i}>
                    {i !== 0 && <StyledSpan>·</StyledSpan>}
                    {content}
                </Fragment>
            ))}
        </Box>
    );
};
