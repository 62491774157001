import ReactHtmlParser from 'react-html-parser';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import { Button } from '../common/buttons/Button';
import { ButtonCard } from '../common/buttons/ButtonCard';
import { IconCircle } from '../common/IconCircle';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { ProductIcon } from '../icons/ProductIcon';
import { StyledBorderBoxContent } from '../layout/BorderBoxComponents';
import {
    StyledTemplateBox,
    StyledTemplateBoxContentRight,
    StyledTemplateBoxHeaderRight,
    StyledTemplateBoxLeftSide,
    StyledTemplateBoxRightSide,
} from './TemplateComponents';
import {
    documentationUrl,
    scriptingDocumentationUrl,
    supportPortalUrl,
    videoTutorialsUrl,
} from '../../utils/documentation';
import { getSanitizedParsedMarkdown } from '../../utils/readme';

export type IframeThemeMode = 'light' | 'dark';

export interface TemplateBoxProps {
    apps?: string[];
    basePath: string;
    customId?: string;
    description?: string;
    iframeThemeMode: IframeThemeMode;
    name: string;
    onboardingMode?: boolean;
    readmeContent?: string;
    uid: string;
    onGoBack?(): void;
    onUseTemplate(): void;
}

const StyledSideNavigation = styled(StyledBorderBoxContent)(() => ({
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
}));

const StyledIframe = styled('iframe')(() => ({
    border: 'none',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
}));

const StyledReadmeContent = styled(Box)(() => ({
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
}));

const StyledActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: 0,
}));

export const TemplateBox: React.FC<TemplateBoxProps> = ({
    apps = [],
    basePath,
    customId,
    description,
    iframeThemeMode,
    name,
    onboardingMode = false,
    readmeContent = '',
    uid,
    onGoBack,
    onUseTemplate,
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (loading) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            // Test if the iframe URL is reachable and OK
            fetch(iframeUrl, {
                mode: 'cors',
            })
                .then((res) => {
                    if (!res.ok) {
                        console.log('External template status check failed', res.status);
                        setError(true);
                    }
                })
                .catch((e) => {
                    console.log('External template reachability check failed', e);
                    setError(true);
                });
        }
    }, [loading]);

    const icons = apps.map((a) => <IconCircle size="medium" key={a} icon={<ProductIcon name={a} />} severity="grey" />);
    const appNames = apps.length > 0 && <Typography color="text.secondary">{apps.join(' · ')}</Typography>;

    const iframeUrl = `https://templates.scriptrunnerconnect.com/template/${
        customId ?? uid
    }?embedded=true&theme=${iframeThemeMode}`;

    return (
        <StyledTemplateBox>
            <StyledTemplateBoxLeftSide>
                <StyledSideNavigation>
                    <Button
                        link={!onboardingMode ? { href: `${basePath}templates`, type: 'router' } : undefined}
                        size="small"
                        startIcon={<ArrowBackOutlinedIcon />}
                        variant="text"
                        onClick={onboardingMode ? onGoBack : undefined}
                    >
                        Back
                    </Button>
                    <Box width="100%">
                        <Typography variant="subtitle1" mb={2}>
                            Having trouble with a template?
                        </Typography>
                        <StyledList>
                            <ButtonCard
                                data-pendo="videos"
                                endIcon={
                                    <Tooltip title="Video tutorials">
                                        <OndemandVideoOutlinedIcon />
                                    </Tooltip>
                                }
                                iconSize="medium"
                                label="Video tutorials"
                                link={{ href: videoTutorialsUrl, target: '_blank', type: 'external' }}
                            />

                            <ButtonCard
                                data-pendo="docs"
                                endIcon={
                                    <Tooltip title="Documentation">
                                        <MenuBookOutlinedIcon />
                                    </Tooltip>
                                }
                                iconSize="medium"
                                label="Documentation"
                                link={{ href: documentationUrl, target: '_blank', type: 'external' }}
                            />
                            <ButtonCard
                                data-pendo="scriptingHelp"
                                endIcon={
                                    <Tooltip title="Need help scripting?">
                                        <TerminalOutlinedIcon />
                                    </Tooltip>
                                }
                                iconSize="medium"
                                label="Need help scripting?"
                                link={{ href: scriptingDocumentationUrl, target: '_blank', type: 'external' }}
                            />
                            <ButtonCard
                                data-pendo="support"
                                endIcon={
                                    <Tooltip title="Contact support">
                                        <QuestionAnswerOutlinedIcon />
                                    </Tooltip>
                                }
                                iconSize="medium"
                                label="Contact support"
                                link={{ href: supportPortalUrl, target: '_blank', type: 'external' }}
                            />
                        </StyledList>
                    </Box>
                </StyledSideNavigation>
            </StyledTemplateBoxLeftSide>
            <StyledTemplateBoxRightSide>
                <StyledTemplateBoxHeaderRight sx={{ flex: 0 }}>
                    {icons.length > 0 && <StyledIconContainer>{icons}</StyledIconContainer>}
                    <Box>
                        {appNames}
                        <Typography mb={1} variant="h6">
                            {name}
                        </Typography>
                        <Typography fontWeight="normal" variant="subtitle1">
                            {description}
                        </Typography>
                    </Box>
                </StyledTemplateBoxHeaderRight>
                <Divider />
                {loading ? (
                    <StyledTemplateBoxContentRight position="relative">
                        <LoadingSpinner />
                    </StyledTemplateBoxContentRight>
                ) : error ? (
                    <StyledTemplateBoxContentRight position="relative">
                        <StyledReadmeContent tabIndex={0}>
                            {ReactHtmlParser(getSanitizedParsedMarkdown(readmeContent))}
                        </StyledReadmeContent>
                    </StyledTemplateBoxContentRight>
                ) : null}
                <StyledIframe
                    sx={{ display: loading || error ? 'none' : undefined }}
                    src={iframeUrl}
                    title={name}
                    onLoadCapture={() => setLoading(false)}
                    onLoadStart={() => setLoading(true)}
                    onError={() => setError(true)}
                />
                <Divider />
                <StyledActions>
                    {!onboardingMode && (
                        <Button
                            link={{ href: `${basePath}template/${uid}/advanced`, type: 'router' }}
                            variant="outlined"
                        >
                            Advanced view
                        </Button>
                    )}
                    <Button onClick={onUseTemplate}>Use template</Button>
                </StyledActions>
            </StyledTemplateBoxRightSide>
        </StyledTemplateBox>
    );
};
