import { useNavigate } from 'react-location';

// To ensure older external template links redirect to correct url-s
export const TemplateRedirectContainer: React.FC = () => {
    const navigate = useNavigate();

    navigate({ to: './advanced' });

    return <></>;
};
