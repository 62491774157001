import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export interface AiExplainSelectedCodeDialogProps {
    open?: boolean;
    checked: boolean;
    onCheck(): void;
    onClose(): void;
    onAskAi(): void;
}

export const AiExplainSelectedCodeDialog: React.FC<AiExplainSelectedCodeDialogProps> = ({
    open = false,
    checked,
    onCheck,
    onClose,
    onAskAi,
}) => {
    return (
        <Dialog
            open={open}
            buttons={[
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    onClick={() => {
                        onClose();
                        onAskAi();
                    }}
                >
                    Continue
                </Button>,
            ]}
            title="Would you like to proceed?"
            onClose={onClose}
        >
            <Typography color="text.secondary">
                This feature will submit your highlighted code to AI for processing.
            </Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={onCheck} title="Don't ask me this again" />}
                    label="Don't ask me this again"
                />
            </FormGroup>
        </Dialog>
    );
};
