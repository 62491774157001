import Box from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { StyledBorderBoxHeader } from '../../layout/BorderBoxComponents';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { CreditsChip, NewTabLink, StyledPageOneBlockImageContainer } from './AiAssistanceComponents';
import { IconButton } from '../../common/buttons/IconButton';
import { TextField } from '../../common/inputs/TextField';
import { Button } from '../../common/buttons/Button';
import { ChatHistoryItemContextMenu } from './ChatHistoryItemContextMenu';
import { useState } from 'react';
import { Dialog } from '../../common/dialogs/Dialog';
import { LoadingSpinner } from '../../common/LoadingSpinner';
import { EmptyState } from '../../common/EmptyState';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { aiAssistanceDocumentationUrl } from '../../../utils/documentation';
import { DeleteConversationRequest, RenameTitleRequest } from '../../../data/ai-assistance';

interface ChatHistoryScreenProps {
    availableCredits: number;
    chats?: Chat[];
    chatHistoryLoading: boolean;
    renameOrDeleteLoading: boolean;
    onClose(): void;
    onBack(): void;
    onNewChat(): void;
    onSend(prompt: string): void;
    onCredits(): void;
    onChatSelected(uid: string): void;
    onRename(update: RenameTitleRequest): void;
    onDelete(update: DeleteConversationRequest): void;
}

interface ChatHistoryItemProps {
    title: string;
    conversationUid: string;
    searchTerm: string;
    busy: boolean;
    onChatSelected(uid: string): void;
    onRename(update: RenameTitleRequest): void;
    onDelete(update: DeleteConversationRequest): void;
}

interface ChatHistoryListProps {
    chats: Chat[];
    searchTerm: string;
    busy: boolean;
    onChatSelected(uid: string): void;
    onRename(update: RenameTitleRequest): void;
    onDelete(update: DeleteConversationRequest): void;
}

interface Chat {
    uid: string;
    title: string | null;
    date: Date;
}

interface ChatGrouping {
    recent: Chat[];
    yesterday: Chat[];
    week: Chat[];
    older: Record<number, Chat[]>;
}

export const StyledBlockImageContainer = styled(StyledPageOneBlockImageContainer)({
    height: 180,
});

const StyledSearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    gap: theme.spacing(1),
    '& .MuiChip-root': {
        height: 35,
    },
}));
const StyledHistorySectionTitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5, 1.5),
}));

const StyledChatHistoryItem = styled(ButtonBase)<ButtonBaseProps<'a', { component?: 'a' | React.ElementType }>>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 42,
        flexShrink: 0,
        cursor: 'pointer',
        border: `1px solid transparent`,
        borderRadius: theme.constants.borderRadius,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1),
        '&:hover': {
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.default,
        },
    })
);

const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
    title,
    searchTerm,
    conversationUid,
    busy,
    onChatSelected,
    onRename,
    onDelete,
}) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [currentTitle, setCurrentTitle] = useState(title);

    const highlightTitle = (title: string, searchTerm: string): JSX.Element | string => {
        if (!searchTerm) {
            return title;
        } else {
            const lowerTitle = title.toLowerCase();
            const lowerSearch = searchTerm.toLowerCase();
            const index = lowerTitle.indexOf(lowerSearch);

            if (index === -1) {
                return title;
            }

            return (
                <>
                    {title.substring(0, index)}
                    <span className="highlighted-text">{title.substring(index, index + searchTerm.length)}</span>
                    {title.substring(index + searchTerm.length)}
                </>
            );
        }
    };

    return (
        <>
            <StyledChatHistoryItem
                component="div"
                onClick={() => onChatSelected(conversationUid)}
                sx={(theme) => ({
                    '& .highlighted-text': {
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], // Change this
                    },
                })}
            >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {highlightTitle(title, searchTerm)}
                </Typography>
                <IconButton
                    size="small"
                    icon={<MoreVertOutlinedIcon />}
                    tooltip="More actions"
                    onClick={(event) => {
                        event.stopPropagation();
                        setAnchor(anchor ? null : event.currentTarget);
                    }}
                    aria-label="More actions button"
                    tooltipPlacement="top"
                />
                <ChatHistoryItemContextMenu
                    anchor={anchor}
                    setAnchor={setAnchor}
                    onRenameDialogOpen={() => setOpenRenameDialog(true)}
                    onDeleteDialogOpen={() => setOpenDeleteDialog(true)}
                />
            </StyledChatHistoryItem>
            <Dialog
                className="no-drag"
                size="small"
                buttons={[
                    <Button variant="outlined" onClick={() => setOpenRenameDialog(false)}>
                        Cancel
                    </Button>,
                    <Button
                        disabled={!currentTitle.trim()}
                        busy={busy}
                        onClick={() => onRename({ uid: conversationUid, title: currentTitle })}
                    >
                        Rename
                    </Button>,
                ]}
                open={openRenameDialog}
                title="Rename chat"
                onClose={() => setOpenRenameDialog(false)}
            >
                <TextField
                    label="Chat name"
                    value={currentTitle}
                    placeholder={currentTitle}
                    multiline
                    maxRows={2}
                    size="small"
                    onChange={(e) => setCurrentTitle(e.currentTarget.value)}
                />
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                buttons={[
                    <Button variant="outlined" onClick={() => setOpenDeleteDialog(false)}>
                        Cancel
                    </Button>,
                    <Button busy={busy} color="error" onClick={() => onDelete({ uid: conversationUid })}>
                        Delete
                    </Button>,
                ]}
                messages={['This will permanently delete the chat. This action is irreversible.']}
                title="Delete chat"
                onClose={() => setOpenDeleteDialog(false)}
            />
        </>
    );
};

const ChatHistoryList: React.FC<ChatHistoryListProps> = ({
    chats,
    searchTerm,
    busy,
    onChatSelected,
    onRename,
    onDelete,
}) => {
    const groupedChats = chats?.reduce<ChatGrouping>(
        (acc, chat) => {
            const chatDate = new Date(chat.date);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            if (chatDate >= today) {
                acc.recent.push(chat);
            } else if (chatDate >= yesterday) {
                acc.yesterday.push(chat);
            } else if (chatDate >= new Date(today.setDate(today.getDate() - 6))) {
                acc.week.push(chat);
            } else {
                const year = chatDate.getFullYear();
                acc.older[year] = [...(acc.older[year] ?? []), chat];
            }

            return acc;
        },
        {
            recent: [],
            yesterday: [],
            week: [],
            older: {},
        }
    );

    const handleRenderChatItems = ({ uid, title }: Chat): JSX.Element => (
        <ChatHistoryItem
            busy={busy}
            key={uid}
            conversationUid={uid}
            title={title ?? ''}
            searchTerm={searchTerm}
            onChatSelected={onChatSelected}
            onRename={onRename}
            onDelete={onDelete}
        />
    );

    const handleSort = (a: Chat, b: Chat): number => new Date(b.date).getTime() - new Date(a.date).getTime();
    const olderChats = Object.keys(groupedChats.older);

    return (
        <>
            {groupedChats.recent.length > 0 && (
                <>
                    <StyledHistorySectionTitleContainer>
                        <Typography variant="subtitle2">Recent</Typography>
                    </StyledHistorySectionTitleContainer>
                    {groupedChats.recent.sort(handleSort).map(handleRenderChatItems)}
                </>
            )}

            {groupedChats.yesterday.length > 0 && (
                <>
                    <StyledHistorySectionTitleContainer>
                        <Typography variant="subtitle2">Yesterday</Typography>
                    </StyledHistorySectionTitleContainer>
                    {groupedChats.yesterday.sort(handleSort).map(handleRenderChatItems)}
                </>
            )}

            {groupedChats.week.length > 0 && (
                <>
                    <StyledHistorySectionTitleContainer>
                        <Typography variant="subtitle2">Last 7 days</Typography>
                    </StyledHistorySectionTitleContainer>
                    {groupedChats.week.sort(handleSort).map(handleRenderChatItems)}
                </>
            )}
            {olderChats.length > 0 &&
                olderChats
                    .sort((a, b) => +b - +a)
                    .map((year) => (
                        <Box key={year} display="flex" flexDirection="column" width="100%">
                            <StyledHistorySectionTitleContainer>
                                <Typography variant="subtitle2">{year}</Typography>
                            </StyledHistorySectionTitleContainer>
                            {groupedChats.older[+year]?.sort(handleSort)?.map(handleRenderChatItems)}
                        </Box>
                    ))}
        </>
    );
};

export const ChatHistoryScreen: React.FC<ChatHistoryScreenProps> = ({
    availableCredits,
    chats = [],
    chatHistoryLoading,
    renameOrDeleteLoading,
    onClose,
    onBack,
    onNewChat,
    onCredits,
    onChatSelected,
    onRename,
    onDelete,
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredChats = chats.filter((c) => c.title?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()));

    const emptyState = (
        <EmptyState
            borderBox
            icon={<ReportOutlinedIcon />}
            title="No chats created"
            subtitle="Start a conversation with our AI assistant to find helpful information."
            buttons={[
                <Button LinkComponent={NewTabLink} href={aiAssistanceDocumentationUrl} variant="outlined">
                    Learn more
                </Button>,
                <Button onClick={onNewChat} startIcon={<AddOutlinedIcon />}>
                    New chat
                </Button>,
            ]}
        />
    );

    return (
        <>
            <StyledBorderBoxHeader>
                <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            height: 20,
                            width: 20,
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        icon={<ArrowBackOutlinedIcon />}
                        tooltip="Back"
                        onClick={onBack}
                        aria-label="Back button"
                    />
                    <Typography variant="h6">Chat history</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                    <CreditsChip availableCredits={availableCredits} onClick={onCredits} />
                    <IconButton
                        size="small"
                        icon={<CloseIcon />}
                        tooltip="Close AI assistant"
                        onClick={onClose}
                        aria-label="Close AI assistant button"
                    />
                </Box>
            </StyledBorderBoxHeader>
            <Divider />
            <Box display="flex" alignItems="center" p={2} gap={2} className="no-drag">
                <StyledSearchContainer>
                    <TextField
                        size="small"
                        fullWidth
                        aria-label="Search by title"
                        label={null}
                        placeholder="Search by title"
                        startIcon={<SearchOutlinedIcon />}
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                    />
                </StyledSearchContainer>
                <Box>
                    <Button variant="outlined" startIcon={<AddOutlinedIcon />} onClick={onNewChat}>
                        New chat
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box
                className="no-drag"
                display="flex"
                flexDirection="column"
                height="100%"
                p={2}
                sx={{
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}
            >
                {chatHistoryLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {chats.length === 0 ? (
                            emptyState
                        ) : (
                            <ChatHistoryList
                                busy={renameOrDeleteLoading}
                                searchTerm={searchTerm}
                                chats={filteredChats}
                                onChatSelected={onChatSelected}
                                onRename={onRename}
                                onDelete={onDelete}
                            />
                        )}
                    </>
                )}
            </Box>
        </>
    );
};
