/* eslint-disable sonarjs/no-duplicate-string */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import { IconCircle } from '../../common/IconCircle';
import Link from '@mui/material/Link';
import { supportPortalUrl } from '../../../utils/documentation';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NorthWestOutlinedIcon from '@mui/icons-material/NorthWestOutlined';
import { InfoIcon } from '../../icons/InfoIcon';
import { handleKeyDown } from '../../../utils/input';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
// import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { forwardRef, RefObject, useState } from 'react';
import { AiChatResponseRating } from '@avst-stitch/repository-lib/lib/models';
import { useTheme } from '@mui/material/styles';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs, vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReactMarkdown from 'react-markdown';
import { IconCopyButton } from '../../common/IconCopyButton';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

export const StyledLink = styled(Link)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

export const StyledChipContainer = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.75),
    padding: theme.spacing(0.375, 1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 15,
    '& .MuiSvgIcon-root': {
        height: 20,
    },
    '& .credits-info-icon': {
        height: 16,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
}));

export const StyledPageOneBlockImageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    borderRadius: theme.constants.borderRadius,
    marginBottom: theme.spacing(2),
    width: 584,
    height: 214,
}));

export const StyledPageThreeBlockImageContainer = styled(StyledPageOneBlockImageContainer)({
    height: 180,
});

export const StyledTermsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    padding: theme.spacing(1.5),
}));

export const StyledWelcomeScreenCaseContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
}));

export const CreditsChip: React.FC<{ availableCredits: number; onClick?(): void; disabled?: boolean }> = ({
    availableCredits,
    onClick,
    disabled = false,
}) => {
    return (
        <StyledChipContainer
            disabled={disabled}
            sx={(theme) => ({
                color: availableCredits === 0 ? theme.palette.error.main : 'inherit',
                borderColor: availableCredits === 0 ? theme.palette.error.main : 'inherit',
            })}
            onClick={onClick}
        >
            {availableCredits === 0 ? <ReportOutlinedIcon /> : <LocalActivityOutlinedIcon />}
            <Typography variant="subtitle2">{availableCredits} credits</Typography>
            <InfoIcon
                className="credits-info-icon"
                tooltip="A single question costs one credit. Your credits renew every month."
            />
        </StyledChipContainer>
    );
};

export const LearnAboutCreditsContent: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column">
            <StyledPageThreeBlockImageContainer>
                <CardMedia
                    component="img"
                    image={process.env.PUBLIC_URL + '/ai-assistant-100-credits.png'}
                    alt="AI assistant welcome screen image"
                />
            </StyledPageThreeBlockImageContainer>
            <Box display="flex" flexDirection="column" gap={2}>
                <StyledWelcomeScreenCaseContainer>
                    <IconCircle icon={<LocalActivityOutlinedIcon />} size="small" severity="grey" />
                    <Box flexDirection="column">
                        <Typography variant="subtitle2">What is a credit?</Typography>
                        <Typography variant="body1" color="text.secondary">
                            A credit is the currency you use to ask the AI assistant a question.
                        </Typography>
                    </Box>
                </StyledWelcomeScreenCaseContainer>
                <StyledWelcomeScreenCaseContainer>
                    <IconCircle icon={<SendOutlinedIcon />} size="small" severity="grey" />
                    <Box flexDirection="column">
                        <Typography variant="subtitle2">How are credits used?</Typography>
                        <Typography variant="body1" color="text.secondary">
                            Each time you ask a question, you use one credit. For example, asking "Explain scheduled
                            triggers" counts as one credit.
                        </Typography>
                    </Box>
                </StyledWelcomeScreenCaseContainer>
                <StyledWelcomeScreenCaseContainer>
                    <IconCircle icon={<CalendarTodayOutlinedIcon />} size="small" severity="grey" />
                    <Box flexDirection="column">
                        <Typography variant="subtitle2">When do my credits renew?</Typography>
                        <Typography variant="body1" color="text.secondary">
                            Your credits are renewed after each monthly cycle. They will be reset to 100 or the limit
                            allocated to your plan.
                        </Typography>
                    </Box>
                </StyledWelcomeScreenCaseContainer>
                <StyledWelcomeScreenCaseContainer>
                    <IconCircle icon={<AddOutlinedIcon />} size="small" severity="grey" />
                    <Box flexDirection="column">
                        <Typography variant="subtitle2">How can I buy more credits?</Typography>
                        <Typography variant="body1" color="text.secondary">
                            <StyledLink href={supportPortalUrl} target="_blank">
                                Contact support
                            </StyledLink>{' '}
                            if you need more credits.
                        </Typography>
                    </Box>
                </StyledWelcomeScreenCaseContainer>
            </Box>
        </Box>
    );
};

export const StyledExample = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'start',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    width: '100%',
    padding: theme.spacing(1.5),
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
}));

export const StyledBoxExamplePromptIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    marginRight: theme.spacing(1.5),
}));

export const StyledExamplePromptLinkIconBox = styled(Box)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
        color: theme.palette.action.active,
    },
}));

export const renderPrompts = (
    prompts: string[],
    promptType: string,
    categoryIcon: JSX.Element,
    busy: boolean,
    onPrompt: (prompt: string) => void
): JSX.Element[] => {
    return prompts.map((prompt, i) => {
        return (
            <StyledExample
                key={promptType + i}
                onClick={() => {
                    if (!busy) {
                        onPrompt(prompt);
                    }
                }}
                onKeyDown={(e) => {
                    if (!busy) {
                        handleKeyDown({ event: e, enterFn: (): void => onPrompt(prompt) });
                    }
                }}
            >
                <Box display="flex">
                    <StyledBoxExamplePromptIcon>{categoryIcon}</StyledBoxExamplePromptIcon>
                    <Box>
                        <Typography variant="body1" color="text.secondary">
                            {promptType}
                        </Typography>
                        <Typography variant="subtitle2">{prompt}</Typography>
                    </Box>
                </Box>
                <StyledExamplePromptLinkIconBox>
                    <NorthWestOutlinedIcon />
                </StyledExamplePromptLinkIconBox>
            </StyledExample>
        );
    });
};

interface PromptInputProps {
    prompt: string;
    busy: boolean;
    ref?: RefObject<HTMLInputElement>;
    onSend(prompt: string): void;
    setPrompt(prompt: string): void;
    onStopAiAssistanceResponse?(): void;
}

export const PromptInput = forwardRef<HTMLInputElement, PromptInputProps>(
    ({ prompt, busy, onSend, setPrompt }, ref) => {
        const handleSend = (prompt: string): void => {
            if (!!prompt.trim() && !busy) {
                onSend(prompt);
                setPrompt('');
            }
        };

        return (
            <FormControl fullWidth variant="outlined" className="no-drag">
                <InputLabel htmlFor="outlined-adornment-password">Ask AI assistant</InputLabel>
                <OutlinedInput
                    multiline
                    maxRows={2}
                    inputRef={ref}
                    label="Ask AI assistant"
                    placeholder="Ask a question"
                    value={prompt}
                    onChange={(event) => setPrompt(event.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && !!prompt.trim() && !busy) {
                            e.preventDefault();
                            handleSend(prompt);
                        }
                    }}
                    endAdornment={
                        <InputAdornment
                            position="end"
                            sx={(theme) => ({
                                marginRight: theme.spacing(1),
                            })}
                        >
                            {/* TODO: to be implemented
                             {!busy ? (
                                <IconButton
                                    aria-label="Ask AI assistant a question"
                                    onClick={() => handleSend(prompt)}
                                    onMouseDown={() => handleSend(prompt)}
                                    edge="end"
                                >
                                    <SendOutlinedIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    aria-label="Stop response"
                                    onClick={onStopAiAssistanceResponse}
                                    onMouseDown={onStopAiAssistanceResponse}
                                    edge="end"
                                >
                                    <StopCircleOutlinedIcon />
                                </IconButton>
                            )} */}
                            <IconButton
                                aria-label="Ask AI assistant a question"
                                onClick={() => handleSend(prompt)}
                                onMouseDown={() => handleSend(prompt)}
                                edge="end"
                                disabled={!!prompt.trim() === false || busy}
                            >
                                <SendOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        );
    }
);

export const StyledFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '100%',
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        minHeight: 72,
    },
    '& .MuiFormControl-root': {
        height: 72,
        margin: 0,
    },
}));

export const StyledBotMessage = styled(Box)(({ theme }) => ({
    width: '100%',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    p: {
        margin: 0,
    },
}));

export const StyledBotMessageButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    alignItems: 'center',
    '& .MuiButtonBase-root': {
        padding: theme.spacing(2),
    },
}));

const StyledBoxCodeBlockHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderTopLeftRadius: theme.constants.borderRadius,
    borderTopRightRadius: theme.constants.borderRadius,
}));

export const StyledBoxCopyCodeIconButton = styled(Box)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
    '& .MuiButtonBase-root': {
        padding: theme.spacing(2),
    },
}));

export const StyledRatingIconButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

export const StyledRatingIconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
        color: theme.palette.primary.main,
    },
}));

export const CodeBlock: React.FC<
    CodeProps & { onCopy?(): void; codeBlockHeight?: number; isUserMessage?: boolean }
> = ({ onCopy, codeBlockHeight, isUserMessage = false, ...props }) => {
    const darkTheme = useTheme().palette.mode === 'dark';
    const { children, className, inline } = props;
    const match = /language-(\w+)/.exec(className || '');
    const content = String(children).replace(/\n$/, '');
    return match ? (
        <>
            <StyledBoxCodeBlockHeader
                onClick={() => onCopy?.()}
                sx={(theme) => ({
                    backgroundColor: isUserMessage ? theme.palette.background.paper : theme.palette.background.default,
                    border: darkTheme ? 'none' : `1px solid ${theme.palette.divider}`,
                    borderBottom: 'none',
                })}
            >
                <Typography>
                    {match[1] && match[1]?.substring(0, 1)?.toLocaleUpperCase() + match[1]?.substring(1)}
                </Typography>
                <StyledBoxCopyCodeIconButton>
                    <IconCopyButton size="small" tooltip="Copy code" value={content} data-pendo={'codeCopied'} />
                </StyledBoxCopyCodeIconButton>
            </StyledBoxCodeBlockHeader>
            <SyntaxHighlighter
                lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                wrapLines
                inline={inline}
                PreTag="div"
                children={content}
                language={match[1]}
                style={darkTheme ? vscDarkPlus : vs}
                codeTagProps={{
                    style: {
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                    },
                }}
                customStyle={{
                    marginTop: 0,
                    lineHeight: '1.4em',
                    height: codeBlockHeight,
                }}
            />
        </>
    ) : (
        <code className={className}>{children}</code>
    );
};

export interface AiAssistanceResponseMessageProps {
    aiAssistanceIsLongResponse: boolean;
    message: string;
    uid: string;
    rating?: AiChatResponseRating;
    onCopy?(): void;
    onRateAiAssistanceMessage(request: { uid: string; rating: AiChatResponseRating }): void;
}

export const AiAssistanceResponseMessage: React.FC<AiAssistanceResponseMessageProps> = ({
    aiAssistanceIsLongResponse,
    message,
    uid,
    rating = null,
    onCopy,
    onRateAiAssistanceMessage,
}) => {
    const [localRating, updateRating] = useState<AiChatResponseRating | null>(rating);

    const handleRating = (rating: AiChatResponseRating): void => {
        updateRating(rating);
        onRateAiAssistanceMessage({
            uid,
            rating,
        });
    };

    const messageRatings = {
        positive: 'GOOD' as const,
        negative: 'BAD' as const,
    };

    return (
        <StyledBotMessage
            sx={{
                borderBottom: aiAssistanceIsLongResponse ? 'none' : 'auto',
                borderBottomLeftRadius: aiAssistanceIsLongResponse ? 0 : 'auto',
                borderBottomRightRadius: aiAssistanceIsLongResponse ? 0 : 'auto',
            }}
        >
            <ReactMarkdown
                components={{
                    code: (props) => <CodeBlock {...props} onCopy={onCopy} />,
                    a: (props) => (
                        <span>
                            <Link {...props} target="_blank">
                                {props.children}
                            </Link>{' '}
                            <strong>(Please check the safety of the link before clicking!)</strong>
                        </span>
                    ),
                    img: (props) => <span>{props.src}</span>,
                }}
            >
                {message}
            </ReactMarkdown>
            <StyledBotMessageButtonsContainer>
                <StyledBoxCopyCodeIconButton>
                    <IconCopyButton size="small" tooltip="Copy message" value={message} data-pendo={'messageCopied'} />
                </StyledBoxCopyCodeIconButton>
                {!localRating && (
                    <StyledRatingIconButtonsContainer>
                        <Tooltip title="Good response">
                            <IconButton onClick={() => handleRating(messageRatings.positive)} data-pendo={'goodRating'}>
                                <ThumbUpOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Bad response">
                            <IconButton onClick={() => handleRating(messageRatings.negative)} data-pendo={'badRating'}>
                                <ThumbDownOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </StyledRatingIconButtonsContainer>
                )}
                <StyledRatingIconWrapper>
                    {localRating === messageRatings.positive && <ThumbUpIcon />}
                    {localRating === messageRatings.negative && <ThumbDownIcon />}
                </StyledRatingIconWrapper>
            </StyledBotMessageButtonsContainer>
        </StyledBotMessage>
    );
};

export const NewTabLink = forwardRef<
    HTMLAnchorElement,
    DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>((props, ref) => <a target="_blank" ref={ref} {...props} />);
