import { styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { LinkProps } from 'react-location';

export const StyledOrganizationAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'ownedDefaultOrganization',
})<{ ownedDefaultOrganization?: boolean }>(({ ownedDefaultOrganization = false, theme }) => ({
    backgroundColor: ownedDefaultOrganization ? theme.palette.primary.dark : theme.palette.text.primary,
    borderRadius: theme.constants.borderRadius,
    flex: '0 0 auto',
    fontSize: theme.typography.body2.fontSize,
    height: 24,
    width: 24,
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
        width: 260,
    },
    '& .MuiList-root': {
        padding: 0,
    },
}));

export const StyledMenuItem = styled(MenuItem)<LinkProps & { component?: React.ElementType }>(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(0.5),
    },
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
})) as typeof MenuItem;

export const StyledMenuItemContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    position: 'relative',
    alignItems: 'center',
}));

export const StyledMenuItemText = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(1),
    maxWidth: 172,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));
