import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { InformationChip } from '../../common/chips/InformationChip';
import { StyledMainActions } from '../../layout/LayoutComponents';
import { SetupGuideReviewScreenApiHandlers } from './resources/SetupGuideReviewScreenApiHandlers';
import { SetupGuideReviewScreenConnections } from './resources/SetupGuideReviewScreenConnections';
import { SetupGuideReviewScreenEnvironmentVariables } from './resources/SetupGuideReviewScreenEnvironmentVariables';
import { SetupGuideReviewScreenEventListeners } from './resources/SetupGuideReviewScreenEventListeners';
import { SetupGuideReviewScreenScheduledTriggers } from './resources/SetupGuideReviewScreenScheduledTriggers';
import { SetupGuideReviewScreenScripts } from './resources/SetupGuideReviewScreenScripts';
import { SetupGuideReviewScreenSummary } from './SetupGuideReviewScreenSummary';
import { StyledStepContainer } from '../SetupGuideComponents';
import {
    FullEnvironmentVariable,
    ToggleVariableExpandEvent,
} from '../../workspace-resources/environment-variables/types';
import { Language } from '../../workspace-dialogs/language-selector';
import {
    SetupGuideAppInstance,
    SetupGuideApiHandler,
    SetupGuideEventListener,
    SetupGuideScheduledTrigger,
    SetupGuideScript,
    SetupGuideStep,
    SetupGuideStepName,
    SetupGuideWorkspace,
} from '../types';

export interface SetupGuideReviewScreenProps {
    appInstances?: SetupGuideAppInstance[];
    apiHandlers?: SetupGuideApiHandler[];
    errors?: string;
    eventListeners?: SetupGuideEventListener[];
    environmentVariables?: FullEnvironmentVariable[];
    language: Language;
    nextStepName?: string;
    saving?: boolean;
    scheduledTriggers?: SetupGuideScheduledTrigger[];
    scripts?: SetupGuideScript[];
    steps: SetupGuideStep[];
    workspace: SetupGuideWorkspace;
    workspaceLocked?: boolean;
    onContinue: () => void;
    onGoBack: () => void;
    onNavigateToStep: (stepName: SetupGuideStepName) => void;
    onToggleVariableExpand(event: ToggleVariableExpandEvent): void;
}

const StyledResourceContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const SetupGuideReviewScreen: React.FC<SetupGuideReviewScreenProps> = ({
    apiHandlers = [],
    appInstances = [],
    environmentVariables = [],
    errors,
    eventListeners = [],
    language,
    nextStepName,
    saving = false,
    scheduledTriggers = [],
    scripts = [],
    steps,
    workspace,
    workspaceLocked,
    onContinue,
    onGoBack,
    onNavigateToStep,
    onToggleVariableExpand,
}) => {
    const getSetupGuideReviewScreenResource = (step: SetupGuideStep): JSX.Element | null => {
        switch (step.name) {
            case 'CONNECTIONS':
                return appInstances.length > 0 ? (
                    <StyledResourceContainer key="connections-resource">
                        <InformationChip label={`${step.number}. For secure login details use...`} severity="grey" />
                        <SetupGuideReviewScreenConnections
                            appInstances={appInstances}
                            onEdit={() => onNavigateToStep('CONNECTIONS')}
                        />
                    </StyledResourceContainer>
                ) : null;
            case 'API_HANDLERS':
                return appInstances.length > 0 ? (
                    <StyledResourceContainer key="api-handlers-resource">
                        <InformationChip label={`${step.number}. To make outgoing calls use...`} severity="grey" />
                        <SetupGuideReviewScreenApiHandlers
                            apiHandlers={apiHandlers}
                            onEdit={() => onNavigateToStep('API_HANDLERS')}
                        />
                    </StyledResourceContainer>
                ) : null;
            case 'TRIGGERS':
                return eventListeners.length > 0 || scheduledTriggers.length > 0 ? (
                    <StyledResourceContainer key="triggers-resource">
                        <InformationChip label={`${step.number}. To respond to events use...`} severity="grey" />
                        {eventListeners.length > 0 && (
                            <SetupGuideReviewScreenEventListeners
                                eventListeners={eventListeners}
                                onEdit={() => onNavigateToStep('TRIGGERS')}
                            />
                        )}
                        {scheduledTriggers.length > 0 && (
                            <SetupGuideReviewScreenScheduledTriggers
                                scheduledTriggers={scheduledTriggers}
                                onEdit={() => onNavigateToStep('TRIGGERS')}
                            />
                        )}
                    </StyledResourceContainer>
                ) : null;
            case 'ENVIRONMENT_VARIABLES':
                return environmentVariables.length > 0 ? (
                    <StyledResourceContainer key="environment-variables-resource">
                        <InformationChip
                            label={`${step.number}. To customise your integration use...`}
                            severity="grey"
                        />
                        <SetupGuideReviewScreenEnvironmentVariables
                            environmentVariables={environmentVariables}
                            onEdit={() => onNavigateToStep('ENVIRONMENT_VARIABLES')}
                            onToggleVariableExpand={onToggleVariableExpand}
                        />
                    </StyledResourceContainer>
                ) : null;

            case 'SCRIPTS':
                return scripts.length > 0 ? (
                    <StyledResourceContainer key="scripts-resource">
                        <InformationChip
                            label={`${step.number}. To define your business logic use...`}
                            severity="grey"
                        />
                        <SetupGuideReviewScreenScripts
                            language={language}
                            scripts={scripts}
                            onEdit={() => onNavigateToStep('SCRIPTS')}
                        />
                    </StyledResourceContainer>
                ) : null;
            default:
                return null;
        }
    };

    const displayedSteps = steps.map((s) => getSetupGuideReviewScreenResource(s));

    const nextStepPendoAttribute = nextStepName === 'Run' ? 'progressToRun' : undefined;

    return (
        <StyledStepContainer>
            <SetupGuideReviewScreenSummary steps={steps} workspace={workspace} />
            {displayedSteps}
            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
            <StyledMainActions>
                <Button variant="outlined" onClick={onGoBack}>
                    Back
                </Button>
                <Button
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    busy={saving}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            </StyledMainActions>
        </StyledStepContainer>
    );
};
