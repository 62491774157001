import { BehaviorSubject, map, Subject } from 'rxjs';
import { publishTemplate, unpublishTemplate } from '../../data/template';
import { InformativeError } from '../../utils/error';
import { promptQuestion } from '../confirm';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';

export const publishTemplateAction$ = monitor('publishTemplateAction$', new Subject<string>());
export const publishTemplateErrors$ = monitor(
    'publishTemplateErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const publishingTemplate$ = monitor('publishingTemplate$', new BehaviorSubject(false));

export const unpublishTemplateAction$ = monitor('unpublishTemplateAction$', new Subject<string>());
export const unpublishTemplateErrors$ = monitor(
    'unpublishTemplateErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const unpublishingTemplate$ = monitor('unpublishingTemplate$', new BehaviorSubject(false));
export const templatePublishedAction$ = monitor('templatePublishedAction$', new Subject<void>());
export const templateUnpublishedAction$ = monitor('templateUnpublishedAction$', new Subject<void>());

publishTemplateAction$
    .pipe(
        map(async (templateUid) => {
            const initiateTemplatePublish = async (): Promise<void> => {
                publishingTemplate$.next(true);
                publishTemplateErrors$.next(undefined);

                try {
                    await publishTemplate(templateUid);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Template published.',
                    });
                    templatePublishedAction$.next();
                } catch (error) {
                    if (error instanceof InformativeError) {
                        publishTemplateErrors$.next(error.message);
                    } else {
                        publishTemplateErrors$.next(
                            'Unknown error occurred while publishing template. Please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while publishing template', error);
                    }
                }
                publishingTemplate$.next(false);
            };

            promptQuestion({
                title: 'Are you sure you want to publish the template?',
                proceedButton: {
                    label: 'Publish',
                    onClick: async () => await initiateTemplatePublish(),
                },
            });
        })
    )
    .subscribe();

unpublishTemplateAction$
    .pipe(
        map(async (templateUid) => {
            const initiateTemplateUnPublish = async (): Promise<void> => {
                unpublishingTemplate$.next(true);
                unpublishTemplateErrors$.next(undefined);

                try {
                    await unpublishTemplate(templateUid);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Template unpublished.',
                    });
                    templateUnpublishedAction$.next();
                } catch (error) {
                    if (error instanceof InformativeError) {
                        unpublishTemplateErrors$.next(error.message);
                    } else {
                        unpublishTemplateErrors$.next(
                            'Unknown error occurred while unpublishing template. Please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while unpublish template', error);
                    }
                }
                unpublishingTemplate$.next(false);
            };

            promptQuestion({
                title: 'Are you sure you want to unpublish the template?',
                proceedButton: {
                    label: 'Unpublish',
                    severity: 'error',
                    onClick: async () => await initiateTemplateUnPublish(),
                },
            });
        })
    )
    .subscribe();
