import 'react-reflex/styles.css';
import { useEffect, useState } from 'react';
import { styled, Theme } from '@mui/material';
import cronstrue from 'cronstrue';
import Link from '@mui/material/Link';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button } from '../common/buttons/Button';
import { ResourceTree, ResourceTreeProps } from './resource-tree';
import {
    SizeAwareContainer,
    StyledReflexContainer,
    StyledReflexElement,
    StyledReflexFooter,
} from '../reflex/ReflexComponents';
import { WorkspaceHeader, WorkspaceHeaderProps } from './WorkspaceHeader';
import { Outlet } from 'react-location';
import { ReflexSplitter, HandlerProps } from 'react-reflex';
import { RemnantEnvironment } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceResources';
import { WorkspaceResources } from '../../data/workspace';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import { Alert } from '../common/alerts/Alert';
import { WorkspaceLockDetails } from '@avst-stitch/repository-lib/lib/types';
import { WorkspaceLanguage } from './resource-tree/types';
import { WorkspaceLockedAlert } from './WorkspaceLockedAlert';

export interface WorkspaceProps {
    apiHandlersBeingDeleted?: Record<string, boolean>;
    console?: JSX.Element;
    deploymentButtonDisabled?: WorkspaceHeaderProps['deploymentButtonDisabled'];
    environments?: WorkspaceHeaderProps['environments'];
    environmentVariablesHaveMissingInformation?: ResourceTreeProps['environmentVariablesHaveMissingInformation'];
    environmentVariablesHaveUnsavedChanges?: ResourceTreeProps['environmentVariablesHaveUnsavedChanges'];
    environmentVariablesSaving?: ResourceTreeProps['environmentVariablesSaving'];
    eventListenerExecutionInProgress: Record<string, boolean>;
    eventListenersBeingDeleted?: Record<string, boolean>;
    externalTriggerBaseUrl?: ResourceTreeProps['externalTriggerBaseUrl'];
    isConsoleFullScreen?: boolean;
    isDraft?: WorkspaceHeaderProps['isDraft'];
    isTemplate: WorkspaceHeaderProps['isTemplate'];
    loadingEnvironments?: WorkspaceHeaderProps['loadingEnvironments'];
    loadingWorkspaceResources: boolean;
    manageEnvironmentDeploymentTargetsButtonDisabled?: WorkspaceHeaderProps['manageEnvironmentDeploymentTargetsButtonDisabled'];
    organizationName?: string;
    readmeFile: ResourceTreeProps['readmeFile'];
    readmeFileContent?: ResourceTreeProps['readmeFileContent'];
    readOnlyTemplateMode?: boolean;
    remainingInvocations?: number;
    scheduledTriggersBeingDeleted?: Record<string, boolean>;
    scriptsBeingDeleted?: Record<string, boolean>;
    scriptsBeingSaved?: Record<string, boolean>;
    scriptExecutionInProgress: Record<string, boolean>;
    scriptHasUnsavedChanges: Record<string, boolean>;
    selectedEnvironmentUid?: WorkspaceHeaderProps['selectedEnvironmentUid'];
    selectedNode?: ResourceTreeProps['selectedNode'];
    selectedWorkspaceLanguage?: WorkspaceLanguage;
    selectedWorkspaceUid?: WorkspaceHeaderProps['selectedWorkspaceUid'];
    showInvocationsLimitAlert?: boolean;
    scriptHelperPopupVisible?: boolean;
    templateActionsInProgress?: WorkspaceHeaderProps['templateActionsInProgress'];
    useRouter?: boolean;
    workspaceName?: string;
    workspaceLockedBy?: WorkspaceLockDetails;
    workspaceResources: WorkspaceResources;
    workspaces?: WorkspaceHeaderProps['workspaces'];
    onChangeEnvironment?: WorkspaceHeaderProps['onChangeEnvironment'];
    onCloseInvocationsLimitAlert?(): void;
    onCreateNewApiHandler?: ResourceTreeProps['onCreateNewApiHandler'];
    onCreateNewEnvironment?: WorkspaceHeaderProps['onCreateNewEnvironment'];
    onCreateNewEventListener?: ResourceTreeProps['onCreateNewEventListener'];
    onCreateNewScheduledTrigger?: ResourceTreeProps['onCreateNewScheduledTrigger'];
    onCreateNewScript?: ResourceTreeProps['onCreateNewScript'];
    onCreateWorkspaceFromTemplate?: () => void;
    onCopyApiHandlerPath?: ResourceTreeProps['onCopyApiHandlerPath'];
    onCopyEventListenerUrl?: ResourceTreeProps['onCopyEventListenerUrl'];
    onDeleteApiHandler?: ResourceTreeProps['onDeleteApiHandler'];
    onDeleteEventListener?: ResourceTreeProps['onDeleteEventListener'];
    onDeleteScript?: ResourceTreeProps['onDeleteScript'];
    onDeleteScheduledTrigger?: ResourceTreeProps['onDeleteScheduledTrigger'];
    onDeploy?: WorkspaceHeaderProps['onDeploy'];
    onEditWorkspace?: WorkspaceHeaderProps['onEditWorkspace'];
    onOpenEnvironmentManager?: WorkspaceHeaderProps['onOpenEnvironmentManager'];
    onOpenLanguageSelector?: WorkspaceHeaderProps['onOpenLanguageSelector'];
    onOpenManageEnvironmentDeploymentTargets?: WorkspaceHeaderProps['onOpenManageEnvironmentDeploymentTargets'];
    onOpenPackageManager?: WorkspaceHeaderProps['onOpenPackageManager'];
    onPublishTemplate?: WorkspaceHeaderProps['onPublishTemplate'];
    onSaveEnvironmentVariables?: ResourceTreeProps['onSaveEnvironmentVariables'];
    onSaveReadme?: ResourceTreeProps['onSaveReadme'];
    onSaveScript?: ResourceTreeProps['onSaveScript'];
    onShareWorkspace?: WorkspaceHeaderProps['onShareWorkspace'];
    onSwitchWorkspace?: WorkspaceHeaderProps['onSwitchWorkspace'];
    onToggleScriptHelperPopupVisibility?: (open: boolean) => void;
    onTriggerEventListener?: ResourceTreeProps['onTriggerEventListener'];
    onTriggerScript?: ResourceTreeProps['onTriggerScript'];
    onUnpublishTemplate?: WorkspaceHeaderProps['onUnpublishTemplate'];
    onUnlockWorkspace?: () => void;
    onUpgradePlan?: () => void;
    onOpenHelpAndSupportDialog?: () => void;
}

const consoleFlexGrowKey = 'stitchConsoleHeight';
const consoleDefaultFlexGrow = 2;

const minColWidth = 32;
const mainFlexGrowKey = 'stitchMainHeight';
const treeFlexGrowKey = 'stitchResourceTreeWidth';
const outletFlexGrowKey = 'stitchOutletWidth';
const outletContentFlexGrowKey = 'stitchOutletContentWidth';
const treeIsOpenKey = 'stitchIsTreeOpen';
const mainDefaultFlexGrow = 7.5;
const treeDefaultFlexGrow = 3;
const outletDefaultFlexGrow = 9.5;

const getDeployedWorkspaceInformationText = (versionNumber: string): JSX.Element => (
    <span>
        Currently selected workspace environment is in read-only mode because you're seeing a workspace version as it
        was when the release <strong>{versionNumber}</strong> was created, except the environment specific configuration
        which you can still edit. To apply changes for this environment,{' '}
        <strong>make changes in an environment that is targeting the HEAD version</strong>, verify these changes in the
        other environment and then create a new release and deploy that release into the current environment, or if you
        wish to start editing in the current environment, re-target the current environment to the HEAD version using
        the Deployment Manager.{' '}
        <Link href="https://docs.adaptavist.com/src/latest/workspaces/deployments-and-environments" target="_blank">
            Learn more how environments and deployments work
        </Link>
        .
    </span>
);

const getHeadEnvironmentInformationText = (headEnvironments: string): JSX.Element => (
    <span>
        You are currently editing the HEAD version of the code, the same HEAD version is also running in the following
        environments: <strong>{headEnvironments}</strong>. If you make code changes in this environment, the same
        changes will apply instantly in other environments targeting the HEAD version as well. Unless this is
        intentional, consider targeting a non-HEAD version for your other environments that you do not wish to apply
        current changes for
    </span>
);

const openMixin = {
    transition: (theme: Theme) =>
        theme.transitions.create('flex', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
};
const closeMixin = {
    transition: (theme: Theme) =>
        theme.transitions.create('flex', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
};

const StyledHeader = styled('div')(() => ({
    overflow: 'hidden !important',
}));

export const Workspace: React.FC<WorkspaceProps> = ({
    apiHandlersBeingDeleted,
    console: consoleElement,
    deploymentButtonDisabled,
    environments,
    environmentVariablesHaveMissingInformation,
    environmentVariablesHaveUnsavedChanges,
    environmentVariablesSaving,
    eventListenersBeingDeleted,
    eventListenerExecutionInProgress,
    externalTriggerBaseUrl,
    isConsoleFullScreen = false,
    isTemplate,
    isDraft,
    loadingEnvironments,
    loadingWorkspaceResources,
    manageEnvironmentDeploymentTargetsButtonDisabled,
    readmeFile,
    readmeFileContent,
    readOnlyTemplateMode,
    remainingInvocations,
    organizationName,
    scheduledTriggersBeingDeleted,
    scriptsBeingDeleted,
    scriptsBeingSaved,
    scriptExecutionInProgress,
    scriptHasUnsavedChanges,
    selectedEnvironmentUid,
    selectedNode,
    selectedWorkspaceLanguage,
    selectedWorkspaceUid,
    showInvocationsLimitAlert,
    scriptHelperPopupVisible,
    templateActionsInProgress,
    useRouter = true,
    workspaceLockedBy,
    workspaceName,
    workspaceResources,
    workspaces,
    onChangeEnvironment,
    onCloseInvocationsLimitAlert,
    onCopyApiHandlerPath,
    onCopyEventListenerUrl,
    onCreateNewEnvironment,
    onCreateNewScript,
    onCreateNewApiHandler,
    onCreateNewEventListener,
    onCreateNewScheduledTrigger,
    onCreateWorkspaceFromTemplate,
    onDeleteApiHandler,
    onDeleteEventListener,
    onDeleteScheduledTrigger,
    onDeleteScript,
    onDeploy,
    onEditWorkspace,
    onOpenEnvironmentManager,
    onOpenLanguageSelector,
    onOpenPackageManager,
    onOpenManageEnvironmentDeploymentTargets,
    onSaveEnvironmentVariables,
    onSaveReadme,
    onSaveScript,
    onShareWorkspace,
    onSwitchWorkspace,
    onToggleScriptHelperPopupVisibility,
    onTriggerScript,
    onTriggerEventListener,
    onPublishTemplate,
    onUnlockWorkspace,
    onUnpublishTemplate,
    onUpgradePlan,
    onOpenHelpAndSupportDialog,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [consoleFlexGrow, setConsoleFlexGrow] = useState(
        readLocalStorage(consoleFlexGrowKey, consoleDefaultFlexGrow)
    );
    const [mainFlexGrow, setMainFlexGrow] = useState(readLocalStorage(mainFlexGrowKey, mainDefaultFlexGrow));
    const [treeOpen, setTreeOpen] = useState(readLocalStorage(treeIsOpenKey, true));
    const [treeFlexGrow, setTreeFlexGrow] = useState(
        treeOpen ? readLocalStorage(treeFlexGrowKey, treeDefaultFlexGrow) : 0
    );
    const [animating, setAnimating] = useState(false);
    const [outletFlexGrow, setOutletFlexGrow] = useState(readLocalStorage(outletFlexGrowKey, outletDefaultFlexGrow));
    const [infoBannerOpen, setInfoBannerOpen] = useState(true);
    const [noHeadWarningBannerOpen, setNoHeadWarningBannerOpen] = useState(true);
    const [originalMainFlexGrow, setOriginalMainFlexGrow] = useState(0);

    useEffect(() => {
        setConsoleFlexGrow(readLocalStorage(consoleFlexGrowKey, consoleDefaultFlexGrow));
        setMainFlexGrow(readLocalStorage(mainFlexGrowKey, mainDefaultFlexGrow));
        if (treeOpen) {
            setTreeFlexGrow(readLocalStorage(treeFlexGrowKey, treeDefaultFlexGrow));
            setOutletFlexGrow(readLocalStorage(outletFlexGrowKey, outletDefaultFlexGrow));
        }
    }, []);

    useEffect(() => {
        if (isConsoleFullScreen) {
            setOriginalMainFlexGrow(readLocalStorage(mainFlexGrowKey, mainDefaultFlexGrow));
            setMainFlexGrow(0);
        } else {
            if (originalMainFlexGrow !== 0) {
                setMainFlexGrow(readLocalStorage(mainFlexGrowKey, mainDefaultFlexGrow));
                setConsoleFlexGrow(readLocalStorage(consoleFlexGrowKey, consoleDefaultFlexGrow));
                setOriginalMainFlexGrow(0);
            }
        }
    }, [isConsoleFullScreen]);

    const handleReflexResize = (e: HandlerProps, key: string): void => {
        if (e.domElement instanceof Element) {
            const flexGrowCurrent = +window.getComputedStyle(e.domElement).flexGrow;
            saveLocalStorage(key, flexGrowCurrent);
        }
    };

    const handleToggleResourceManager = (open: boolean): void => {
        if (open) {
            setAnimating(true);
            setTreeOpen(true);
            saveLocalStorage(treeIsOpenKey, true);
            setTreeFlexGrow(readLocalStorage(treeFlexGrowKey, treeDefaultFlexGrow));
            setTimeout(() => {
                setAnimating(false);
            }, 300);
        } else {
            setAnimating(true);
            setTreeOpen(false);
            saveLocalStorage(treeIsOpenKey, false);
            setTreeFlexGrow(0);
            setTimeout(() => {
                setAnimating(false);
            }, 300);
        }
    };

    const handleScriptWidthChange = (width: number): void => {
        if (scriptHelperPopupVisible && width < 500) {
            onToggleScriptHelperPopupVisibility?.(false);
        } else if (!scriptHelperPopupVisible && width >= 500) {
            onToggleScriptHelperPopupVisibility?.(true);
        }
    };

    const getCronDescription = (cronExpression: string | undefined): string | undefined => {
        if (!cronExpression) {
            return undefined;
        }

        try {
            return cronstrue.toString(cronExpression);
        } catch (e) {
            console.error('Failed to translate CRON expression', cronExpression, e);
            return 'Error while translating CRON expression';
        }
    };

    const filterRemnantEnvironments = (remnantEnvironments: RemnantEnvironment[]): boolean =>
        remnantEnvironments.length === 0 ||
        remnantEnvironments.some((re) => re.environmentUid === selectedEnvironmentUid);

    const selectedEnvironment = environments?.find((en) => en.uid === selectedEnvironmentUid);
    const headEnvironments = (environments ?? []).filter((en) => !en.deployed);

    return (
        <>
            <StyledReflexContainer orientation="horizontal">
                <StyledHeader className="header">
                    {readOnlyTemplateMode ? (
                        <Alert
                            severity="info"
                            title={`This is a read-only view of the template: ${workspaceName}`}
                            action={
                                <Button
                                    data-pendo={'createWorkspaceFromTemplate'}
                                    variant="contained"
                                    onClick={onCreateWorkspaceFromTemplate}
                                >
                                    Create a Workspace
                                </Button>
                            }
                            text={
                                'You can create a workspace from this template. Creating a workspace from this template will copy over all of the template resources.'
                            }
                        />
                    ) : (
                        <WorkspaceHeader
                            deploymentButtonDisabled={deploymentButtonDisabled}
                            environments={environments}
                            isDraft={isDraft}
                            isTemplate={isTemplate}
                            manageEnvironmentDeploymentTargetsButtonDisabled={
                                manageEnvironmentDeploymentTargetsButtonDisabled
                            }
                            loadingEnvironments={loadingEnvironments}
                            locked={!!workspaceLockedBy}
                            organizationName={organizationName}
                            selectedEnvironmentUid={selectedEnvironmentUid}
                            selectedWorkspaceUid={selectedWorkspaceUid}
                            templateActionsInProgress={templateActionsInProgress}
                            workspaces={workspaces}
                            onChangeEnvironment={(uid) => onChangeEnvironment?.(uid)}
                            onDeploy={() => onDeploy?.()}
                            onCreateNewEnvironment={() => onCreateNewEnvironment?.()}
                            onEditWorkspace={() => onEditWorkspace?.()}
                            onOpenEnvironmentManager={() => onOpenEnvironmentManager?.()}
                            onOpenLanguageSelector={() => onOpenLanguageSelector?.()}
                            onOpenManageEnvironmentDeploymentTargets={() =>
                                onOpenManageEnvironmentDeploymentTargets?.()
                            }
                            onOpenPackageManager={() => onOpenPackageManager?.()}
                            onPublishTemplate={() => onPublishTemplate?.()}
                            onShareWorkspace={(workspaceUid) => onShareWorkspace?.(workspaceUid)}
                            onSwitchWorkspace={(workspaceUid, environmentUid) =>
                                onSwitchWorkspace?.(workspaceUid, environmentUid)
                            }
                            onUnpublishTemplate={() => onUnpublishTemplate?.()}
                            onOpenHelpAndSupportDialog={onOpenHelpAndSupportDialog}
                            showSetupGuideButton={false}
                        />
                    )}
                </StyledHeader>
                {!workspaceLockedBy &&
                    selectedEnvironment &&
                    headEnvironments.length === 0 &&
                    noHeadWarningBannerOpen && (
                        <Alert
                            severity="warning"
                            title="Warning"
                            text={
                                <span>
                                    None of your environments target a HEAD version, which means you cannot prepare new
                                    changes. It is recommended to always keep at least one environment targeting the
                                    HEAD version. You can switch any environment back to HEAD version by using the
                                    Deployment Manager.
                                </span>
                            }
                            onClose={() => setNoHeadWarningBannerOpen(false)}
                        />
                    )}
                {!workspaceLockedBy &&
                    selectedEnvironment?.deployed &&
                    selectedEnvironment.deploymentVersion &&
                    infoBannerOpen && (
                        <Alert
                            severity="info"
                            title="Information"
                            text={getDeployedWorkspaceInformationText(selectedEnvironment.deploymentVersion)}
                            onClose={() => setInfoBannerOpen(false)}
                        />
                    )}
                {!workspaceLockedBy && !selectedEnvironment?.deployed && headEnvironments.length > 1 && infoBannerOpen && (
                    <Alert
                        severity="warning"
                        title="Warning"
                        text={getHeadEnvironmentInformationText(
                            headEnvironments
                                .filter((en) => en.uid !== selectedEnvironment?.uid)
                                .map((en) => en.name.replace(' (HEAD)', ''))
                                .join(', ')
                        )}
                        onClose={() => setInfoBannerOpen(false)}
                    />
                )}
                {workspaceLockedBy && <WorkspaceLockedAlert onUnlockWorkspace={() => onUnlockWorkspace?.()} />}
                <StyledReflexElement
                    sx={{ overflowY: 'hidden' }}
                    onStopResize={(e) => handleReflexResize(e, mainFlexGrowKey)}
                    className="header"
                    flex={mainFlexGrow}
                >
                    <StyledReflexContainer
                        orientation="vertical"
                        sx={{ '& .reflex-size-aware': { overflow: 'hidden' } }}
                    >
                        <StyledReflexElement
                            onStopResize={(e) => handleReflexResize(e, treeFlexGrowKey)}
                            flex={treeFlexGrow}
                            minSize={280}
                            sx={(theme) => ({
                                minWidth: 50,
                                backgroundColor: 'background.paper',
                                ...(treeOpen && animating
                                    ? closeMixin
                                    : !treeOpen && animating
                                    ? { ...openMixin }
                                    : !treeOpen
                                    ? { borderRight: `1px solid ${theme.palette.divider}` }
                                    : null),
                            })}
                        >
                            <ResourceTree
                                apiHandlers={workspaceResources.apiHandlers
                                    .filter(({ remnantEnvironments }) => filterRemnantEnvironments(remnantEnvironments))
                                    .map((api) => ({
                                        ...api,
                                        deleting: apiHandlersBeingDeleted?.[api.uid] ?? false,
                                        remnant: api.remnantEnvironments.length > 0,
                                    }))}
                                deployedEnvironmentMode={!!selectedEnvironment?.deployed}
                                environmentVariablesHaveUnsavedChanges={environmentVariablesHaveUnsavedChanges}
                                environmentVariablesHaveMissingInformation={environmentVariablesHaveMissingInformation}
                                environmentVariablesSaving={environmentVariablesSaving}
                                eventListeners={workspaceResources.eventListeners
                                    .filter(({ remnantEnvironments }) => filterRemnantEnvironments(remnantEnvironments))
                                    .map((el) => ({
                                        ...el,
                                        scriptName: el.script?.name,
                                        deleting: eventListenersBeingDeleted?.[el.uid] ?? false,
                                        executing: !!eventListenerExecutionInProgress[el.uid],
                                        eventTypeName: el.eventType?.name,
                                        remnant: el.remnantEnvironments.length > 0,
                                    }))}
                                loading={loadingWorkspaceResources}
                                open={treeOpen}
                                readOnlyMode={readOnlyTemplateMode || !!workspaceLockedBy}
                                scheduledTriggers={workspaceResources.scheduledTriggers
                                    .filter(({ remnantEnvironments }) => filterRemnantEnvironments(remnantEnvironments))
                                    .map((st) => ({
                                        ...st,
                                        deleting: scheduledTriggersBeingDeleted?.[st.uid] ?? false,
                                        scriptName: st.script?.name,
                                        cronDescription: getCronDescription(st.cronExpression ?? ''),
                                        remnant: st.remnantEnvironments.length > 0,
                                    }))}
                                scripts={workspaceResources.scripts.map((script) => ({
                                    ...script,
                                    unsaved: !!scriptHasUnsavedChanges[script.uid],
                                    executing: !!scriptExecutionInProgress[script.uid],
                                    deleting: scriptsBeingDeleted?.[script.uid] ?? false,
                                    saving: scriptsBeingSaved?.[script.uid] ?? false,
                                }))}
                                showInvocationsLimitAlert={showInvocationsLimitAlert}
                                remainingInvocations={remainingInvocations}
                                workspaceLanguage={selectedWorkspaceLanguage}
                                selectedNode={selectedNode}
                                readmeFile={readmeFile}
                                readmeFileContent={readmeFileContent}
                                externalTriggerBaseUrl={externalTriggerBaseUrl}
                                workspaceUid={selectedWorkspaceUid ?? ''}
                                onCopyApiHandlerPath={(uid) => onCopyApiHandlerPath?.(uid)}
                                onCopyEventListenerUrl={(uid) => onCopyEventListenerUrl?.(uid)}
                                onCreateNewApiHandler={() => onCreateNewApiHandler?.()}
                                onCreateNewEventListener={() => onCreateNewEventListener?.()}
                                onCreateNewScheduledTrigger={() => onCreateNewScheduledTrigger?.()}
                                onCreateNewScript={() => onCreateNewScript?.()}
                                onDeleteApiHandler={(uid) => onDeleteApiHandler?.(uid)}
                                onDeleteEventListener={(uid) => onDeleteEventListener?.(uid)}
                                onDeleteScheduledTrigger={(uid) => onDeleteScheduledTrigger?.(uid)}
                                onDeleteScript={(uid) => onDeleteScript?.(uid)}
                                onSaveEnvironmentVariables={() => onSaveEnvironmentVariables?.()}
                                onSaveReadme={() => onSaveReadme?.()}
                                onSaveScript={(uid) => onSaveScript?.(uid)}
                                onToggleResourceManager={handleToggleResourceManager}
                                onTriggerEventListener={(uid) => onTriggerEventListener?.(uid)}
                                onTriggerScript={(uid) => onTriggerScript?.(uid)}
                                onCloseInvocationsLimitAlert={onCloseInvocationsLimitAlert}
                                onUpgradePlan={onUpgradePlan}
                            />
                        </StyledReflexElement>
                        {treeOpen && (
                            <ReflexSplitter>
                                <DragIndicatorIcon
                                    sx={{
                                        position: 'relative',
                                    }}
                                    aria-label="Vertical resize"
                                />
                            </ReflexSplitter>
                        )}

                        <StyledReflexElement
                            onStopResize={(e) => handleReflexResize(e, outletFlexGrowKey)}
                            flex={outletFlexGrow}
                        >
                            <StyledReflexContainer orientation="vertical">
                                <StyledReflexElement
                                    minSize={minColWidth}
                                    propagateDimensions
                                    propagateDimensionsRate={300}
                                    onStopResize={(e) => handleReflexResize(e, outletContentFlexGrowKey)}
                                >
                                    <SizeAwareContainer onDimensionsChange={handleScriptWidthChange}>
                                        {useRouter && <Outlet />}
                                    </SizeAwareContainer>
                                </StyledReflexElement>
                            </StyledReflexContainer>
                        </StyledReflexElement>
                    </StyledReflexContainer>
                </StyledReflexElement>
                {!readOnlyTemplateMode && !isConsoleFullScreen ? (
                    <ReflexSplitter>
                        <DragIndicatorIcon />
                    </ReflexSplitter>
                ) : null}
                {!readOnlyTemplateMode ? (
                    <StyledReflexFooter
                        onStopResize={(e) => handleReflexResize(e, consoleFlexGrowKey)}
                        className="footer"
                        flex={consoleFlexGrow}
                    >
                        {consoleElement !== undefined && consoleElement}
                    </StyledReflexFooter>
                ) : null}
            </StyledReflexContainer>
        </>
    );
};
