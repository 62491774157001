import { styled } from '@mui/material';
import { Outlet } from 'react-location';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { OrganizationTab } from '../../../containers/organizations/OrganizationContainer';

interface OrganizationDetailsProps {
    avatar?: string;
    canManagePlans: boolean; // If false, hide Plans tab
    canManageBillingDetails: boolean; // If false, hide Billing Details tab
    name: string; // Org name that you need to display above the tabs
    onSelectTab: (tab: OrganizationTab) => void;
    selectedTab: OrganizationTab;
    useRouter?: boolean;
}

const StyledOuterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    height: 35,
    minHeight: 35,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    height: 24,
    minHeight: 'unset',
    padding: theme.spacing(0, 2),
}));

const StyledTabPanel = styled('div')(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(4.25)})`,
    padding: theme.spacing(4, 0, 0, 0),
}));

export const StyledOrganizationAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'selectedTab',
})<{ selectedTab?: boolean }>(({ theme, selectedTab }) => ({
    backgroundColor: selectedTab ? theme.palette.primary.main : undefined,
    borderRadius: theme.constants.borderRadius,
    fontSize: theme.typography.body2.fontSize,
    height: 24,
    width: 24,
}));

const getTeamPageTitle = (subroute: string): string => {
    switch (subroute) {
        case 'details':
            return 'General';
        case 'members':
            return 'Members';
        case 'plan':
            return 'Plans';
        case 'billing':
            return 'Billing';
        default:
            return 'Usage';
    }
};

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
    canManageBillingDetails,
    canManagePlans,
    name,
    onSelectTab,
    selectedTab = 'usage',
    useRouter = true,
}) => {
    return (
        <StyledOuterContainer>
            <Typography variant="h4">{getTeamPageTitle(selectedTab)}</Typography>
            <Box>
                <StyledTabs value={selectedTab} onChange={(_event, value) => onSelectTab(value as OrganizationTab)}>
                    <StyledTab
                        icon={
                            <StyledOrganizationAvatar selectedTab={selectedTab === 'details'}>
                                {name.slice(0, 2).toLocaleUpperCase()}
                            </StyledOrganizationAvatar>
                        }
                        iconPosition="start"
                        label="GENERAL"
                        value="details"
                    />
                    <StyledTab icon={<DataUsageOutlinedIcon />} iconPosition="start" label="USAGE" value="usage" />
                    <StyledTab icon={<PeopleOutlinedIcon />} iconPosition="start" label="MEMBERS" value="members" />
                    {canManagePlans && (
                        <StyledTab icon={<StyleOutlinedIcon />} iconPosition="start" label="PLANS" value="plan" />
                    )}
                    {canManageBillingDetails && (
                        <StyledTab icon={<CreditCardIcon />} iconPosition="start" label="BILLING" value="billing" />
                    )}
                </StyledTabs>
                <StyledTabPanel>{useRouter && <Outlet />}</StyledTabPanel>
            </Box>
        </StyledOuterContainer>
    );
};
