import { useObservableState } from 'observable-hooks';
import {
    cancelOrganizationPlanSuccessStateDialogOpen$,
    cancelOrganizationPlanFeedbackDialogOpen$,
    cancelOrganizationPlanSuccessStatePlan$,
    closeCancelOrganizationPlanSuccessStateDialogAction$,
} from '../../../store/organization/cancelOrganizationPlan';
import { CancelOrganizationPlanSuccessStateDialog } from '../../../components/organizations/plan/cancel-organization-plan/CancelOrganizationPlanSuccessStateDialog';

export const CancelOrganizationPlanSuccessStateContainer: React.FC = () => {
    const plan = useObservableState(cancelOrganizationPlanSuccessStatePlan$);
    const open = useObservableState(cancelOrganizationPlanSuccessStateDialogOpen$);

    if (!plan) {
        return null;
    }

    const handleContinue = (): void => {
        closeCancelOrganizationPlanSuccessStateDialogAction$.next();
        cancelOrganizationPlanFeedbackDialogOpen$.next(true);
    };

    return (
        <CancelOrganizationPlanSuccessStateDialog
            open={open}
            planEndDate={plan.endDate}
            onContinue={handleContinue}
            onCancel={() => closeCancelOrganizationPlanSuccessStateDialogAction$.next()}
        />
    );
};
