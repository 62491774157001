import { Box, Divider, Link, Typography } from '@mui/material';
import { StyledSectionContainer, StyledSectionHeader } from '../dashboard/DashboardComponents';
import { styled } from '@mui/material';

const StyledOuterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minHeight: '100%',
    overflow: 'auto',
    padding: theme.spacing(4, 4, 0, 4),
}));

const StyledInnerContainer = styled(Box)(() => ({
    width: '100%',
    maxWidth: 900,
}));

const InnerStyledSectionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
}));

const StyledSectionMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledIndexSectionMain = styled(Box)(() => ({
    width: '100%',
}));

const ArcadeBox = styled(Box)(() => ({
    position: 'relative',
    paddingBottom: 'calc(48.5546875% + 41px)',
    height: 0,
    width: 'width: 100%;',
}));

export const VideoTutorials: React.FC = () => {
    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <Typography variant="h4" pb={3} component={'h5'}>
                    Video tutorials
                </Typography>
                <StyledSectionContainer>
                    <StyledSectionHeader>
                        <Typography variant="h6">Index</Typography>
                    </StyledSectionHeader>
                    <Divider />
                    <StyledIndexSectionMain>
                        <ul>
                            <li>Basics</li>
                            <ul>
                                <li>
                                    <Link href="#basic-connectors">Connectors</Link>
                                </li>
                                <li>Template setup guide</li>
                                <ul>
                                    <li>
                                        <Link href="#basic-template-setup-guide-triggers">Triggers</Link>
                                    </li>
                                    <li>
                                        <Link href="#basic-template-setup-guide-parameters">Parameters</Link>
                                    </li>
                                </ul>
                                <li>Advanced workspace view</li>
                                <ul>
                                    <li>
                                        <Link href="#basic-advanced-view-start-from-scratch">
                                            Start from scratch walkthrough & building first integration
                                        </Link>
                                    </li>
                                </ul>
                            </ul>
                            <li>Scripting</li>
                            <ul>
                                <li>Script editor</li>
                                <ul>
                                    <li>
                                        <Link href="#scripting-editor-shortcuts">Keyboard shortcuts</Link>
                                    </li>
                                    <li>
                                        <Link href="#scripting-editor-tips">Tips & tricks</Link>
                                    </li>
                                </ul>
                            </ul>
                            <li>Templates</li>
                            <ul>
                                <li>
                                    <Link href="#templates-tempo-cloud-worklogs-migration">
                                        Tempo cloud worklogs migration
                                    </Link>
                                </li>
                            </ul>
                        </ul>
                    </StyledIndexSectionMain>
                </StyledSectionContainer>
                <StyledSectionContainer>
                    <StyledSectionHeader>
                        <Typography variant="h6">Basics</Typography>
                    </StyledSectionHeader>
                    <Divider />
                    <StyledSectionMain>
                        <InnerStyledSectionContainer id="basic-connectors">
                            <StyledSectionHeader>
                                <Typography variant="h6">Connectors</Typography>
                            </StyledSectionHeader>
                            <Divider />
                            <StyledSectionMain>
                                <iframe
                                    width="100%"
                                    height="420"
                                    src="https://www.youtube.com/embed/Ahs3J3AxLw0?si=PZ10fp9aiHg5mkA8"
                                    title="How to use connectors"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                                <Box>
                                    Learn more about the{' '}
                                    <Link href="https://docs.adaptavist.com/src/latest/connectors" target="_blank">
                                        connectors
                                    </Link>
                                    .
                                </Box>
                            </StyledSectionMain>
                        </InnerStyledSectionContainer>
                        <InnerStyledSectionContainer>
                            <StyledSectionHeader>
                                <Typography variant="h6">Template setup guide</Typography>
                            </StyledSectionHeader>
                            <Divider />
                            <StyledSectionMain>
                                <InnerStyledSectionContainer id="basic-template-setup-guide-triggers">
                                    <StyledSectionHeader>
                                        <Typography variant="h6">Triggers</Typography>
                                    </StyledSectionHeader>
                                    <Divider />
                                    <StyledSectionMain>
                                        <iframe
                                            width="100%"
                                            height="420"
                                            src="https://www.youtube.com/embed/sq96PTNdA0g?si=vkFqPjeShEuKyEkb"
                                            title="Triggers"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                        <Box>
                                            Learn more about the{' '}
                                            <Link
                                                href="https://docs.adaptavist.com/src/latest/workspaces/event-listeners"
                                                target="_blank"
                                            >
                                                event listeners
                                            </Link>{' '}
                                            and the{' '}
                                            <Link
                                                href="https://docs.adaptavist.com/src/latest/workspaces/scheduled-triggers"
                                                target="_blank"
                                            >
                                                scheduled triggers
                                            </Link>
                                            .
                                        </Box>
                                    </StyledSectionMain>
                                </InnerStyledSectionContainer>
                                <InnerStyledSectionContainer id="basic-template-setup-guide-parameters">
                                    <StyledSectionHeader>
                                        <Typography variant="h6">Parameters</Typography>
                                    </StyledSectionHeader>
                                    <Divider />
                                    <StyledSectionMain>
                                        <iframe
                                            width="100%"
                                            height="420"
                                            src="https://www.youtube.com/embed/OD3cmjCI4qo?si=QevmLBYzcIZ6enrz"
                                            title="Parameters"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                        <Box>
                                            Learn more about the{' '}
                                            <Link
                                                href="https://docs.adaptavist.com/src/latest/workspaces/parameters"
                                                target="_blank"
                                            >
                                                parameters
                                            </Link>
                                            .
                                        </Box>
                                    </StyledSectionMain>
                                </InnerStyledSectionContainer>
                            </StyledSectionMain>
                        </InnerStyledSectionContainer>
                        <InnerStyledSectionContainer id="basic-advanced-view-start-from-scratch">
                            <StyledSectionHeader>
                                <Typography variant="h6">Advanced workspace view</Typography>
                            </StyledSectionHeader>
                            <Divider />
                            <StyledSectionMain>
                                <InnerStyledSectionContainer id="basic-template-setup-guide-parameters">
                                    <StyledSectionHeader>
                                        <Typography variant="h6">
                                            Start from scratch walkthrough & building first integration
                                        </Typography>
                                    </StyledSectionHeader>
                                    <Divider />
                                    <StyledSectionMain>
                                        <ArcadeBox>
                                            <iframe
                                                src="https://demo.arcade.software/mOElG7xElJdDywZwNsIV?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
                                                title="Advanced workspace view walkthrough"
                                                frameBorder="0"
                                                loading="lazy"
                                                allowFullScreen
                                                allow="clipboard-write"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    colorScheme: 'light',
                                                }}
                                            ></iframe>
                                        </ArcadeBox>
                                    </StyledSectionMain>
                                </InnerStyledSectionContainer>
                            </StyledSectionMain>
                        </InnerStyledSectionContainer>
                    </StyledSectionMain>
                </StyledSectionContainer>
                <StyledSectionContainer>
                    <StyledSectionHeader>
                        <Typography variant="h6">Scripting</Typography>
                    </StyledSectionHeader>
                    <Divider />
                    <StyledSectionMain>
                        <InnerStyledSectionContainer>
                            <StyledSectionHeader>
                                <Typography variant="h6">Script editor</Typography>
                            </StyledSectionHeader>
                            <Divider />
                            <StyledSectionMain>
                                <InnerStyledSectionContainer id="scripting-editor-shortcuts">
                                    <StyledSectionHeader>
                                        <Typography variant="h6">Keyboard shortcuts</Typography>
                                    </StyledSectionHeader>
                                    <Divider />
                                    <StyledSectionMain>
                                        <ArcadeBox>
                                            <iframe
                                                src="https://demo.arcade.software/P6bmJxFyQfnGAN1HEC2e?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
                                                title="Script editor shortcuts"
                                                frameBorder="0"
                                                loading="lazy"
                                                allowFullScreen
                                                allow="clipboard-write"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    colorScheme: 'light',
                                                }}
                                            ></iframe>
                                        </ArcadeBox>
                                    </StyledSectionMain>
                                </InnerStyledSectionContainer>
                                <InnerStyledSectionContainer id="scripting-editor-tips">
                                    <StyledSectionHeader>
                                        <Typography variant="h6">Tips & tricks</Typography>
                                    </StyledSectionHeader>
                                    <Divider />
                                    <StyledSectionMain>
                                        This is an older recording, but the content is still relevant.
                                        <iframe
                                            width="100%"
                                            height="420"
                                            src="https://www.youtube.com/embed/orjEJbwGwpc?si=yB9m2nSsi4SJB21e"
                                            title="Script editor trips & tricks"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </StyledSectionMain>
                                </InnerStyledSectionContainer>
                                <Box>
                                    Learn more about the{' '}
                                    <Link
                                        href="https://docs.adaptavist.com/src/latest/scripting/editor"
                                        target="_blank"
                                    >
                                        script editor
                                    </Link>
                                    .
                                </Box>
                            </StyledSectionMain>
                        </InnerStyledSectionContainer>
                    </StyledSectionMain>
                </StyledSectionContainer>
                <StyledSectionContainer>
                    <StyledSectionHeader>
                        <Typography variant="h6">Templates</Typography>
                    </StyledSectionHeader>
                    <Divider />
                    <StyledSectionMain>
                        <InnerStyledSectionContainer id="templates-tempo-cloud-worklogs-migration">
                            <StyledSectionHeader>
                                <Typography variant="h6">Tempo cloud worklogs migration</Typography>
                            </StyledSectionHeader>
                            <Divider />
                            <StyledSectionMain>
                                <iframe
                                    width="100%"
                                    height="420"
                                    src="https://www.youtube.com/embed/k04uyZzHpoc?si=QLdsBFNSfISh-hMi"
                                    title="Tempo cloud worklogs migration"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                            </StyledSectionMain>
                        </InnerStyledSectionContainer>
                    </StyledSectionMain>
                </StyledSectionContainer>
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
