import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { Dialog } from '../../../common/dialogs/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../layout/BorderBoxComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { Scope, getMappedWebhooks, getSetupInstructions, getWebhookOptions } from './utils';
import { APP } from '@avst-stitch/repository-lib/constants';

interface GithubEventListenerSetupDialogProps {
    open?: boolean;
    eventTypeName: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const GithubEventListenerSetupDialog: React.FC<GithubEventListenerSetupDialogProps> = ({
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    eventTypeName,
    onClose,
}) => {
    const mappedWebhooks = getMappedWebhooks(eventTypeName);

    const [scope, setScope] = useState<Scope | null>(mappedWebhooks?.scopes[0] ?? null);

    const handleScopeChange = (newScope: Scope): void => {
        setScope(newScope);
    };

    const webhookOptions = getWebhookOptions(eventTypeName, scope, webhookBaseUrl, webhookUrlId, handleScopeChange);
    const setupInstructions = (scope && getSetupInstructions(scope, eventTypeName, webhookBaseUrl, webhookUrlId)) ?? [];
    const appName = APP.GITHUB.NAME;

    useEffect(() => {
        setScope(mappedWebhooks?.scopes[0] ?? null);
    }, [open]);

    return (
        <Dialog
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title="Event listener setup"
            subtitle={appName}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
        >
            <>
                {mappedWebhooks && mappedWebhooks.scopes.length > 1 ? (
                    <>
                        <Box>
                            <Typography variant="subtitle1" mb={1}>
                                How to set up an event listener
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Create a webhook in GitHub instance to listen to events. Select from the following
                                options:
                            </Typography>
                        </Box>
                        {webhookOptions}
                    </>
                ) : (
                    <StyledBorderBox>
                        <StyledBorderBoxSubHeader>
                            <Typography variant="subtitle1" component="h6">
                                How to set up an event listener
                            </Typography>
                            <Typography color="text.secondary">This event is only available for {scope}</Typography>
                        </StyledBorderBoxSubHeader>
                        <Divider />
                        <StyledBorderBoxContent gap={1.5}>
                            <OrderedList content={setupInstructions} id="github" />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                )}
            </>
        </Dialog>
    );
};
