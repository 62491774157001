import { styled } from '@mui/material';
import Link from '@mui/material/Link';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type InfoIconSize = 'small' | 'medium' | 'large';

interface InfoIconProps extends SvgIconProps {
    'aria-label'?: string;
    href?: string;
    size?: InfoIconSize;
    tooltip?: string;
    tooltipPlacement?: TooltipProps['placement'];
}

export const StyledInfoIcon = styled(InfoOutlinedIcon, { shouldForwardProp: (prop) => prop !== 'clickable' })<{
    size: InfoIconSize;
    clickable?: boolean;
}>(({ clickable, size, theme }) => ({
    color: theme.palette.primary.dark,
    cursor: clickable ? 'pointer' : undefined,
    height: size === 'small' ? 16 : size === 'large' ? 24 : 20,
    width: 20,
}));

export const InfoIcon: React.FC<InfoIconProps> = ({
    'aria-label': ariaLabel = 'info',
    href,
    size = 'medium',
    tooltip,
    tooltipPlacement = 'top',
    ...props
}) => {
    return href ? (
        <Tooltip placement={tooltipPlacement} title={tooltip}>
            <Link href={href} target="_blank">
                <StyledInfoIcon aria-label={ariaLabel} clickable size={size} {...props} />
            </Link>
        </Tooltip>
    ) : (
        <Tooltip placement={tooltipPlacement} title={tooltip}>
            <StyledInfoIcon aria-label={ariaLabel} size={size} {...props} />
        </Tooltip>
    );
};
