import { useObservableState } from 'observable-hooks';
import { EnvironmentVariableDetails } from '../../../components/workspace-resources/environment-variables';
import {
    changeEnvironmentVariableTypeAction$,
    createEnvironmentVariableAction$,
    createEnvironmentVariableFolderAction$,
    deleteEnvironmentVariableAction$,
    discardEnvironmentVariableUnsavedChangesAction$,
    environmentVariablesFormErrors$,
    environmentVariablesFormSaving$,
    environmentVariablesHaveUnsavedChanges$,
    repositionEnvironmentVariableAction$,
    saveEnvironmentVariablesAction$,
    toggleEnvironmentVariableEditModeAction$,
    toggleEnvironmentVariableExpandAction$,
    unsavedEnvironmentVariables$,
    updateEnvironmentVariableAction$,
} from '../../../store/workspace/environment-variable';
import {
    loadingWorkspaceResources$,
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from '../../../store/workspace';
import { selectedReadOnlyTemplate$ } from '../../../store/templates';
import { environmentVariablesDocumentationUrl } from '../../../utils/documentation';
import {
    parsedReadmeFileContent$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../../store/workspace/readme';
import { getReadmeKey, getSanitizedParsedMarkdown } from '../../../utils/readme';
import { getBasePath } from '../../../utils/path';

export const EnvironmentVariableDetailsContainer: React.FC = () => {
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const hasUnsavedChanges = useObservableState(environmentVariablesHaveUnsavedChanges$);
    const variables = useObservableState(unsavedEnvironmentVariables$);
    const errors = useObservableState(environmentVariablesFormErrors$);
    const loading = useObservableState(loadingWorkspaceResources$);
    const saving = useObservableState(environmentVariablesFormSaving$);
    const environmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const { readmeFile } = useObservableState(selectedWorkspaceResources$);
    const parsedReadmeFileContent = useObservableState(parsedReadmeFileContent$);

    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);

    const readmeKey = getReadmeKey(selectedReadmeFileUid ?? '', environmentUid ?? '');

    const readmePlaceholder = getSanitizedParsedMarkdown(
        `## Title for your workspace\nThis is a markdown syntax, learn more about it [here](https://www.markdownguide.org/basic-syntax/).`
    );

    const parsedContent = parsedReadmeFileContent[`${readmeFile?.uid ?? ''}_${environmentUid ?? ''}`];
    const isReadmeDefault = readmePlaceholder === parsedContent;
    const templateMode = !!selectedReadOnlyTemplate?.templateUid;

    return (
        <EnvironmentVariableDetails
            documentationUrl={environmentVariablesDocumentationUrl}
            errors={errors}
            hasUnsavedChanges={hasUnsavedChanges}
            isReadmeDefault={isReadmeDefault}
            loading={loading}
            saving={saving}
            templateMode={templateMode}
            variables={variables}
            workspaceLocked={!!selectedWorkspace?.locked}
            environmentDeployed={selectedWorkspaceEnvironment?.deployment !== undefined}
            readmeContent={unsavedReadmeFileDetails$.value[readmeKey]?.content ?? ''}
            fullScreenReadmeLink={`${getBasePath()}fullscreen-readme/${selectedReadmeFileUid}/workspace/${selectedWorkspaceUid}/environment/${environmentUid}`}
            workspaceUid={templateMode ? selectedReadOnlyTemplate.templateUid : selectedWorkspaceUid ?? ''}
            onChangeVariableType={(event) => changeEnvironmentVariableTypeAction$.next(event)}
            onCreateFolder={() => createEnvironmentVariableFolderAction$.next()}
            onCreateVariable={(parentId) => createEnvironmentVariableAction$.next(parentId)}
            onDeleteVariable={(event) => deleteEnvironmentVariableAction$.next(event)}
            onDiscardChanges={() => discardEnvironmentVariableUnsavedChangesAction$.next()}
            onRepositionVariable={(event) => repositionEnvironmentVariableAction$.next(event)}
            onSaveChanges={() => saveEnvironmentVariablesAction$.next()}
            onToggleVariableEditMode={(event) => toggleEnvironmentVariableEditModeAction$.next(event)}
            onToggleVariableExpand={(event) => toggleEnvironmentVariableExpandAction$.next(event)}
            onUpdateVariable={(event) => updateEnvironmentVariableAction$.next(event)}
        />
    );
};
