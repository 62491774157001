import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledPageOuterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
    oveflowY: 'hidden',
    padding: theme.spacing(4),
}));

export const StyledPageInnerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '100%',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflowY: 'auto',
}));

export const StyledPageHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
}));

export const StyledPageHeaderRow = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'space-between',
}));

export const StyledPageTitleContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexGrow: 1,
    gap: theme.spacing(2),
}));

export const StyledPageGridContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    width: '100%',
}));
