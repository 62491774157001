import { useObservableState, useSubscription } from 'observable-hooks';
import { TemplateSelector, TemplateWithCategories } from '../../components/templates/template-selector';
import {
    viewTemplateAction$,
    navigateToWorkspaceFromTemplateEditAction$,
    navigateToReadOnlyTemplateFromViewTemplateAction$,
    templates$,
    templateCategories$,
} from '../../store/templates';
import { loggedInUserDetails$ } from '../../store/user';
import { getBasePath } from '../../utils/path';
import { useNavigate } from 'react-location';
import { Templates } from '../../data/templates';
import { apps$ } from '../../store/apps';

export const TemplatesContainer: React.FC = () => {
    const templates = useObservableState(templates$);
    const apps = useObservableState(apps$);
    const { complexities, useCases } = useObservableState(templateCategories$) ?? {};
    const navigate = useNavigate();
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const userCanWorkWithTemplates = !!(loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates);

    useSubscription(navigateToWorkspaceFromTemplateEditAction$, (template) =>
        navigate({ to: `${getBasePath()}workspace/${template.workspaceUid}/environment/${template.environmentUid}` })
    );

    useSubscription(navigateToReadOnlyTemplateFromViewTemplateAction$, (templateUid) =>
        navigate({ to: `${getBasePath()}template/${templateUid}/advanced` })
    );

    const sortByName = (templates: Templates): Templates => {
        return [...templates].sort((prev, next) => prev.name.localeCompare(next.name));
    };

    const getRemappedTemplates = (): TemplateWithCategories[] => {
        const displayedTemplates = userCanWorkWithTemplates ? templates : templates.filter((t) => !t.draft);
        return [
            ...sortByName(displayedTemplates.filter((t) => t.isNew)),
            ...sortByName(displayedTemplates.filter((t) => !t.isNew)),
        ].map((t) => ({
            apps: Array.from(new Set([...t.incomingApps, ...t.outgoingApps])),
            complexity: t.complexity,
            draft: t.draft,
            isNew: t.isNew,
            name: t.name,
            uid: t.uid,
            useCases: t.useCases,
        }));
    };

    const remappedTemplates = getRemappedTemplates();

    return (
        <TemplateSelector
            apps={apps.map((app) => app.name)}
            basePath={getBasePath()}
            complexities={complexities}
            templates={remappedTemplates}
            useCases={useCases}
            userCanWorkWithTemplates={userCanWorkWithTemplates}
            onSelect={(templateUid) => {
                const template = templates.find((temp) => temp.uid === templateUid);
                if (template) {
                    viewTemplateAction$.next(templateUid);
                }
            }}
        />
    );
};
