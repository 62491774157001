import { useObservableState, useSubscription } from 'observable-hooks';
import { selectedWorkspace$, selectedWorkspaceResources$ } from '../../../store/workspace';
import { loggedInUserConnections$ } from '../../../store/connections';
import {
    apiHandlerSavedAction$,
    saveApiHandlerAction$,
    savingSelectedApiHandler$,
    selectedApiHandlerDetails$,
    selectedApiHandlerErrors$,
} from '../../../store/workspace/api-handler';
import { getUserDisplayName } from '../../../utils/account';
import { useNavigate } from 'react-location';
import { ApiConnectionDetails } from '../../../components/workspace-resources/api-connections/ApiConnectionDetails';

export const SetupGuideEditApiHandlerScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const { apiHandlers } = useObservableState(selectedWorkspaceResources$);
    const connections = useObservableState(loggedInUserConnections$);
    const details = useObservableState(selectedApiHandlerDetails$);
    const errors = useObservableState(selectedApiHandlerErrors$);
    const saving = useObservableState(savingSelectedApiHandler$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    const vendorApiVersions = apiHandlers
        .flatMap((handler) =>
            handler.uid === details?.uid
                ? handler.libraries.map((library) => ({
                      value: library.uid,
                      name: library.deprecated
                          ? `${library.label} (deprecated)`
                          : library.recommended
                          ? `${library.label} (recommended)`
                          : library.label || '',
                      deprecated: library.deprecated,
                      recommended: library.recommended,
                  }))
                : []
        )
        .sort((l1, l2) => (l2.recommended ? 1 : 0) - (l1.recommended ? 1 : 0));

    const appName = connections.find((c) => c.connectionType.uid === details?.connectionTypeUid)?.connectionType.name;

    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            authorized: c.authorized,
            name: c.name,
            uid: c.uid,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            authorized: details.selectedSharedConnection.authorized,
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            uid: details.selectedSharedConnection.uid,
        });
    }

    useSubscription(apiHandlerSavedAction$, (uid) => {
        if (uid === details?.uid) {
            navigate({ to: '../' });
        }
    });

    return (
        <ApiConnectionDetails
            appName={appName ?? ''}
            connections={filteredConnections}
            errors={errors}
            path={details?.path || details?.defaultImportPath}
            saving={saving}
            selectedApiVersionUid={details?.apiHandlerLibraryUid}
            selectedConnectionUid={details?.connectionUid}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            vendorApiVersions={vendorApiVersions}
            uid={details?.uid ?? ''}
            workspaceLocked={!!selectedWorkspace?.locked}
            onCancel={() => navigate({ to: '../' })}
            onSave={(event) => saveApiHandlerAction$.next(event)}
        />
    );
};
