import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '../../common/buttons/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';
import { StyledBorderBox, StyledBorderBoxHeaderSmall } from '../../layout/BorderBoxComponents';
import { IconCircle } from '../../common/IconCircle';
import { IconButton } from '../../common/buttons/IconButton';

export interface InvocationsLimitAlertProps {
    remainingInvocations?: number;
    onClose(): void;
    onUpgradePlan(): void;
}

const StyledLeftContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flex: '1 0',
    gap: theme.spacing(1),
}));

const StyledActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '0 0',
    gap: theme.spacing(1),
}));

export const InvocationsLimitAlert: React.FC<InvocationsLimitAlertProps> = ({
    remainingInvocations = 0,
    onClose,
    onUpgradePlan,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const text =
        remainingInvocations > 0 ? 'Approaching script invocation limit ' : 'You have run out of script invocations ';
    return (
        <StyledBorderBox ref={containerRef} mb={1} minWidth={280}>
            <StyledBorderBoxHeaderSmall>
                <StyledLeftContainer>
                    <IconCircle
                        icon={
                            remainingInvocations > 0 ? (
                                <WarningAmberIcon color="warning" />
                            ) : (
                                <ErrorOutlineIcon color="error" />
                            )
                        }
                    />
                    <Typography variant="body1">
                        {text}(<strong>{remainingInvocations}</strong> remaining)
                    </Typography>
                </StyledLeftContainer>
                <StyledActions>
                    <Button size="small" onClick={onUpgradePlan} data-pendo={'upgradeFromWorkspace'}>
                        Upgrade
                    </Button>
                    {remainingInvocations > 0 && (
                        <IconButton
                            aria-label="Close alert"
                            icon={<CloseIcon />}
                            tooltip="Close alert"
                            onClick={onClose}
                        />
                    )}
                </StyledActions>
            </StyledBorderBoxHeaderSmall>
        </StyledBorderBox>
    );
};
