import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/dialogs/Dialog';
import { InformationChip } from '../common/chips/InformationChip';
import { TextField } from '../common/inputs/TextField';
import { handleKeyDown, isFocused } from '../../utils/input';

type EditorType = 'beginner' | 'expert';

export interface NewWorkspaceSaveEvent {
    description?: string;
    editorType: EditorType;
    name: string;
    template?: boolean;
}

interface NewWorkspaceDialogProps {
    description?: string;
    errors?: string;
    loading?: boolean;
    name?: string;
    open?: boolean;
    saving?: boolean;
    templateMode?: boolean;
    templateOption?: boolean;
    onCancel(): void;
    onSave(event: NewWorkspaceSaveEvent): void;
}

const StyledContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const StyledEditorTypeButtonContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
}));

const StyledEditorTypeButton = styled(ButtonBase)<{ disabled?: boolean; selected?: boolean }>(
    ({ disabled, selected, theme }) => ({
        backgroundColor: selected ? theme.palette.action.selected : 'auto',
        border: selected ? theme.constants.borderSelected : `1px solid ${theme.palette.divider}`,
        borderRadius: theme.constants.borderRadius,
        display: 'block',
        position: 'relative',
        '& .MuiTypography-root, .MuiSvgIcon-root': {
            color: selected ? theme.palette.primary.main : disabled ? theme.palette.text.disabled : 'auto',
        },
        '& .MuiDivider-root': {
            borderColor: selected ? theme.palette.primary.main : theme.palette.divider,
        },
    })
);

const StyledComingSoon = styled(Box)(() => ({
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

const StyledEditorTypeButtonHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
}));

const StyledEditorTypeButtonMainContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
}));

const StyledEditorTypeButtonMain = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.constants.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 150,
    '& .MuiSvgIcon-root': {
        height: 80,
        width: 80,
    },
}));

const isWorkspaceNameValid = (name?: string): boolean => {
    const regex = /^[A-Za-z0-9-. ]*$/;

    return name ? regex.test(name) : true;
};

export const NewWorkspaceDialog: React.FC<NewWorkspaceDialogProps> = ({
    description,
    errors,
    loading = false,
    name = '',
    open = false,
    saving = false,
    templateMode = false,
    templateOption = false,
    onCancel,
    onSave,
}) => {
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentName, setCurrentName] = useState(name);
    const [templateChecked, setTemplateChecked] = useState(false);

    const [currentEditorType, setCurrentEditorType] = useState<EditorType>(templateMode ? 'beginner' : 'expert');

    const nameRef = useRef<HTMLInputElement>(null);
    const beginnerButtonRef = useRef<HTMLButtonElement>(null);
    const expertButtonRef = useRef<HTMLButtonElement>(null);

    // To prevent race conditions
    useEffect(() => {
        setCurrentDescription(description);
        setCurrentEditorType(templateMode ? 'beginner' : 'expert');
        setCurrentName(name);
        setTemplateChecked(false);
    }, [description, name, templateMode, open]);

    const handleSave = (): void => {
        if (currentEditorType) {
            onSave({
                description: currentDescription,
                editorType: currentEditorType,
                name: currentName ?? '',
                template: templateChecked,
            });
        }
    };

    const canSave = !!currentName && !!currentEditorType;
    const isGuideSelected = currentEditorType === 'beginner';
    const isAdvancedSelected = currentEditorType === 'expert';

    const buttons = [
        <Button onClick={onCancel} variant="outlined">
            Cancel
        </Button>,
        <Button
            busy={saving}
            disabled={!canSave}
            onClick={handleSave}
            data-pendo={isGuideSelected ? 'workspaceCreatedFromSetup' : 'workspaceCreatedFromAdvancedView'}
        >
            Create
        </Button>,
    ];

    return (
        <Dialog
            buttons={buttons}
            errors={errors}
            loading={loading}
            open={open}
            title="New workspace"
            onClose={onCancel}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canSave && !saving && !isFocused(beginnerButtonRef) && !isFocused(expertButtonRef),
                    enterFn: handleSave,
                    escFn: onCancel,
                })
            }
        >
            <StyledContentContainer>
                <TextField
                    error={!isWorkspaceNameValid(currentName)}
                    fullWidth
                    helperText={
                        !isWorkspaceNameValid(currentName)
                            ? 'Workspace name can only contain alphanumeric characters, spaces, hyphen and period.'
                            : ''
                    }
                    inputRef={nameRef}
                    label="Workspace name"
                    placeholder="Enter a name"
                    required
                    value={currentName}
                    onChange={(e) => setCurrentName(e.target.value)}
                />
                <TextField
                    fullWidth
                    inputRef={nameRef}
                    label="Description"
                    multiline
                    placeholder="Enter a description"
                    rows={2}
                    required
                    value={currentDescription}
                    onChange={(e) => setCurrentDescription(e.target.value)}
                />
                {templateOption && (
                    <FormControlLabel
                        sx={{ marginTop: '-9px' }} // Temporary, since there are no designs atm
                        control={
                            <Checkbox checked={templateChecked} onChange={() => setTemplateChecked(!templateChecked)} />
                        }
                        label="Workspace is a template"
                    />
                )}
                <Box>
                    <Typography variant="subtitle1" mb={0.5}>
                        Select editor type
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Choose between the setup guide and expert view
                    </Typography>
                </Box>
                <StyledEditorTypeButtonContainer>
                    <StyledEditorTypeButton
                        aria-label="Guided setup"
                        disabled={!templateMode}
                        ref={beginnerButtonRef}
                        selected={isGuideSelected}
                        onClick={() => setCurrentEditorType('beginner')}
                    >
                        {!templateMode && (
                            <StyledComingSoon>
                                <InformationChip label="Coming soon" severity="success" />
                            </StyledComingSoon>
                        )}
                        <StyledEditorTypeButtonHeader>
                            <FormControlLabel label="Setup guide" control={<Radio checked={isGuideSelected} />} />
                            <InformationChip label="Beginner" severity={isGuideSelected ? 'primary' : 'grey'} />
                        </StyledEditorTypeButtonHeader>
                        <Divider />
                        <StyledEditorTypeButtonMainContainer>
                            <StyledEditorTypeButtonMain>
                                <RocketLaunchOutlinedIcon />
                            </StyledEditorTypeButtonMain>
                            <Typography color="text.secondary" mt={2}>
                                Build an integration using our step-by-step setup guide.
                            </Typography>
                        </StyledEditorTypeButtonMainContainer>
                    </StyledEditorTypeButton>
                    <StyledEditorTypeButton
                        aria-label="Advanced setup"
                        ref={expertButtonRef}
                        selected={isAdvancedSelected}
                        onClick={() => setCurrentEditorType('expert')}
                    >
                        <StyledEditorTypeButtonHeader>
                            <FormControlLabel label="Advanced view" control={<Radio checked={isAdvancedSelected} />} />
                            <InformationChip label="Expert" severity={isAdvancedSelected ? 'primary' : 'grey'} />
                        </StyledEditorTypeButtonHeader>
                        <Divider />
                        <StyledEditorTypeButtonMainContainer>
                            <StyledEditorTypeButtonMain>
                                <HandymanOutlinedIcon />
                            </StyledEditorTypeButtonMain>
                            <Typography color="text.secondary" mt={2}>
                                Access expert tools for detailed customisation and control.
                            </Typography>
                        </StyledEditorTypeButtonMainContainer>
                    </StyledEditorTypeButton>
                </StyledEditorTypeButtonContainer>
            </StyledContentContainer>
        </Dialog>
    );
};
