import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import {
    ConnectionModalSecretTextField,
    ConnectionModalTextField,
} from '../../../for-deprecation/textfield/ConnectionModalTextField';
import { AWSWizardTemporaryAuthStage, AWSWizardPermanentAuthStage, AWSWizardSteps, StageType } from './AWSWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { AWSIcon } from '../../../icons/product-icons/AWSIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from '@mui/system/styled';
import { ReadOnlyTextField } from '../../../for-deprecation/textfield/ReadOnlyTextField';

export interface AWSClientInfo {
    accessKey?: string;
    secretKey?: string;
    roleArn?: string;
    externalId?: string;
    duration?: number;
}

export interface AWSConnection {
    clientInfo: AWSClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: AWSWizardTemporaryAuthStage | AWSWizardPermanentAuthStage;
    setStage: (stage: AWSWizardTemporaryAuthStage | AWSWizardPermanentAuthStage) => void;
    onSave: (props: AWSClientInfo) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    currentAuthMode: AuthMode;
    setAuthMode: (mode: AuthMode) => void;
    rolePolicy?: string;
    generatePolicy: () => void;
}

export type AuthMode = 'TEMPORARY' | 'PERMANENT';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5),
    '&:first-of-type': {
        marginTop: theme.spacing(2),
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}));

const StageContent: StageType = (props) => {
    const [accessKey, setAccessKey] = useState(props.clientInfo.accessKey);
    const [secretKey, setSecretKey] = useState(props.clientInfo.secretKey);
    const [roleArn, setRoleArn] = useState(props.clientInfo.roleArn);
    const [duration, setDuration] = useState(props.clientInfo.duration ?? 3600);
    const authMode = props.authMode;
    const rolePolicy = props.rolePolicy;

    const handleChooseAuthMode = async (mode: AuthMode): Promise<void> => {
        if (mode === 'TEMPORARY') {
            setRoleArn(undefined);
            setDuration(3600);
        }
        props.setAuthMode(mode);
    };

    const handleSave = (): void => {
        props.onSave({
            accessKey,
            secretKey,
            roleArn,
            duration,
        });
    };

    if (authMode === 'PERMANENT') {
        switch (props.stage) {
            case AWSWizardPermanentAuthStage.SELECT_AUTHORIZATION_MODE:
                return (
                    <>
                        <DialogContent>
                            <StyledFormControl>
                                {authMode === 'PERMANENT' && (
                                    <DialogAlert
                                        severity="warning"
                                        text={
                                            'AWS best practices recommend using temporary credentials which will be renewed when necessary instead of permanent credentials'
                                        }
                                    />
                                )}
                                <DialogContentText>
                                    Do you want to authorize ScriptRunner Connect using temporary or permanent
                                    credentials?
                                </DialogContentText>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={authMode}
                                    onChange={(e) => {
                                        void handleChooseAuthMode(e.target.value as AuthMode);
                                    }}
                                >
                                    <StyledFormControlLabel
                                        value="TEMPORARY"
                                        control={<Radio />}
                                        label="Temporary credentials using session tokens and role policy"
                                    />
                                    <StyledFormControlLabel
                                        value="PERMANENT"
                                        control={<Radio />}
                                        label="Permanent credentials using access key id and secret"
                                    />
                                </RadioGroup>
                            </StyledFormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => props.onClose?.()}>
                                Close
                            </Button>
                            <Button
                                //eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={() => props.setStage(AWSWizardPermanentAuthStage.CREATE_USER)}
                            >
                                Next
                            </Button>
                        </DialogActions>
                    </>
                );
            case AWSWizardPermanentAuthStage.CREATE_USER:
                return (
                    <>
                        <DialogAlert
                            severity="info"
                            text={`If you already have a user or an access key and secret access key then skip this step by clicking on next`}
                        />
                        <DialogContent>
                            <DialogContentText component="ol">
                                <li>
                                    Visit the{' '}
                                    <OpenInNewLink url={'https://us-east-1.console.aws.amazon.com/iam/home#/users'}>
                                        Identity and Access Management (IAM) Users
                                    </OpenInNewLink>{' '}
                                    page in AWS.
                                </li>
                                <li>
                                    Click on <strong>Create user</strong> on the top right corner.
                                </li>
                                <li>
                                    Under <strong>User name</strong>, enter a username for the user and click{' '}
                                    <strong>Next</strong>.
                                </li>
                                <li>
                                    Grant the user the necessary permissions by adding the new user to an existing
                                    group, copying the permissions of a group or attaching the necessary policies.
                                </li>
                                <li>
                                    Click <strong>Next</strong> to review the user details then click{' '}
                                    <strong>Create user</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => props.setStage(AWSWizardPermanentAuthStage.SELECT_AUTHORIZATION_MODE)}
                            >
                                Back
                            </Button>
                            <Button onClick={() => props.setStage(AWSWizardPermanentAuthStage.GENERATE_CREDENTIALS)}>
                                Next
                            </Button>
                        </DialogActions>
                    </>
                );
            case AWSWizardPermanentAuthStage.GENERATE_CREDENTIALS:
                return (
                    <>
                        <DialogAlert
                            severity="info"
                            text={`If you already have an access key and secret access key then skip this step by clicking on next`}
                        />
                        <DialogContent>
                            <DialogContentText component="ol">
                                <li>Click on the newly created user you want to use to make API requests to AWS.</li>
                                <li>
                                    Click on the <strong>Security credentials</strong> tab and scroll down to{' '}
                                    <strong>Access keys</strong> and click on <strong>Create access key</strong>.
                                </li>
                                <li>
                                    Click on <strong>Other</strong> user case option. Then click <strong>Next</strong>.
                                </li>
                                <li>
                                    Provide a short description for your new credentials then click on{' '}
                                    <strong>Create access key</strong>.
                                </li>
                                <li>
                                    The access key will be displayed along with the obfuscated secret access key. Do not
                                    navigate from the page. Instead, proceed to the next step below.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => props.setStage(AWSWizardPermanentAuthStage.CREATE_USER)}
                            >
                                Back
                            </Button>
                            <Button onClick={() => props.setStage(AWSWizardPermanentAuthStage.INPUT_CREDENTIALS)}>
                                Next
                            </Button>
                        </DialogActions>
                    </>
                );
            case AWSWizardPermanentAuthStage.INPUT_CREDENTIALS:
                return (
                    <>
                        <DialogContent>
                            <DialogContentText component="ol">
                                <li>
                                    Copy the <strong>Access key</strong> and the <strong>Secret access key</strong> into
                                    the form below. <br />
                                </li>
                                <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                            </DialogContentText>
                            <ConnectionModalTextField
                                label="Access Key"
                                value={accessKey}
                                onUpdate={(e) => {
                                    if (props.errors) props.clearErrors();
                                    setAccessKey(e.target.value.trim());
                                }}
                            />
                            <ConnectionModalSecretTextField
                                label="Secret Access Key"
                                value={secretKey}
                                onUpdate={(e) => {
                                    if (props.errors) props.clearErrors();
                                    setSecretKey(e.target.value.trim());
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (props.errors) props.clearErrors();
                                    props.setStage(AWSWizardPermanentAuthStage.GENERATE_CREDENTIALS);
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                data-pendo={'connectorAuthorised'}
                                busy={props.saving}
                                disabled={!accessKey || !secretKey}
                                onClick={handleSave}
                            >
                                Done
                            </Button>
                        </DialogActions>
                    </>
                );
            default:
                return <></>;
        }
    } else {
        switch (props.stage) {
            case AWSWizardTemporaryAuthStage.SELECT_AUTHORIZATION_MODE:
                return (
                    <>
                        <DialogContent>
                            <StyledFormControl>
                                <DialogContentText>
                                    Do you want to authorize ScriptRunner Connect using temporary or permanent
                                    credentials?
                                </DialogContentText>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={authMode}
                                    onChange={(e) => {
                                        void handleChooseAuthMode(e.target.value as AuthMode);
                                    }}
                                >
                                    <StyledFormControlLabel
                                        value="TEMPORARY"
                                        control={<Radio />}
                                        label="Temporary credentials using session tokens and role policy"
                                    />
                                    <StyledFormControlLabel
                                        value="PERMANENT"
                                        control={<Radio />}
                                        label="Permanent credentials using access key id and secret"
                                    />
                                </RadioGroup>
                            </StyledFormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => props.onClose?.()}>
                                Close
                            </Button>
                            <Button
                                //eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={async () => {
                                    props.generatePolicy();
                                    props.setStage(AWSWizardTemporaryAuthStage.CONFIGURE_ROLE);
                                }}
                            >
                                Next
                            </Button>
                        </DialogActions>
                    </>
                );
            case AWSWizardTemporaryAuthStage.CONFIGURE_ROLE:
                return (
                    <>
                        <DialogAlert
                            severity="info"
                            text={`If you have already configured a role with the required policy then skip this step by clicking on next`}
                        />
                        <DialogContent>
                            <DialogContentText component="ol">
                                <li>
                                    Visit the{' '}
                                    <OpenInNewLink url={'https://us-east-1.console.aws.amazon.com/iam/home#/roles'}>
                                        Identity and Access Management (IAM) Roles
                                    </OpenInNewLink>{' '}
                                    page in AWS.
                                </li>
                                <li>
                                    Click on <strong>Create role</strong> on the top right corner.
                                </li>
                                <li>
                                    Select <strong>Custom trust policy</strong> when prompted to select the trusted
                                    entity.
                                </li>
                                <li>
                                    Under <strong>Custom trust policy</strong>, paste the trust policy below into the
                                    policy editor. This will allow ScriptRunner Connect to assume this role in order to
                                    generate temporary credentials which enables it to make AWS API calls in your AWS
                                    account.
                                </li>
                                <li>
                                    Click <strong>Next</strong> to configure the role permissions.
                                </li>
                                <li>
                                    Add the permissions you wish to grant to this role then click <strong>Next</strong>.
                                </li>
                                <li>
                                    Under <strong>Role details</strong>, give the role a name and description then click
                                    on <strong>Create role</strong>.
                                </li>
                                <ReadOnlyTextField label="Role Policy" value={props.rolePolicy} />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => props.setStage(AWSWizardTemporaryAuthStage.SELECT_AUTHORIZATION_MODE)}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!rolePolicy || !!props.errors}
                                busy={!rolePolicy && !props.errors}
                                onClick={() => props.setStage(AWSWizardTemporaryAuthStage.INPUT_ROLE_DETAILS)}
                            >
                                Next
                            </Button>
                        </DialogActions>
                    </>
                );
            case AWSWizardTemporaryAuthStage.INPUT_ROLE_DETAILS:
                return (
                    <>
                        <DialogContent>
                            <DialogContentText component="ol">
                                <li>Select the new role you just created.</li>
                                <li>
                                    Copy the role <strong>ARN</strong> into the form below.
                                </li>
                                <li>
                                    Edit the role summary if you wish to change the{' '}
                                    <strong>Maximum session duration</strong>. Copy the duration into the form below in
                                    seconds (1 hour = 3600 seconds). Note that the duration should be between a value
                                    between 900 seconds (15 minutes) and 43200 seconds (12 hours).
                                </li>
                            </DialogContentText>
                            <ConnectionModalTextField
                                label="Role ARN"
                                value={roleArn}
                                onUpdate={(e) => {
                                    if (props.errors) props.clearErrors();
                                    setRoleArn(e.target.value.trim());
                                }}
                            />
                            <ConnectionModalTextField
                                label="Maximum session duration"
                                value={`${duration}`}
                                onUpdate={(e) => {
                                    if (props.errors) props.clearErrors();
                                    if (Number.isInteger(+e.target.value.trim())) {
                                        const duration = +e.target.value.trim();
                                        if (duration < 900 || duration > 43200) {
                                            props.setError(
                                                'Maximum session duration must be an integer between 900 and 43200.'
                                            );
                                        }
                                        setDuration(+e.target.value.trim());
                                    } else {
                                        props.setError(
                                            'Maximum session duration must be an integer between 900 and 43200.'
                                        );
                                    }
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (props.errors) props.clearErrors();
                                    props.setStage(AWSWizardTemporaryAuthStage.CONFIGURE_ROLE);
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                data-pendo={'connectorAuthorised'}
                                busy={props.saving}
                                disabled={!roleArn || !duration}
                                onClick={handleSave}
                            >
                                Done
                            </Button>
                        </DialogActions>
                    </>
                );
            default:
                return <></>;
        }
    }
};

export const AWSConnectionAppConfigureDialog: React.FC<AWSConnection> = (props) => {
    const error = <DialogAlert severity="error" title={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<AWSIcon />} />
            {props.errors && error}
            <AWSWizardSteps stage={props.currentStage} authMode={props.currentAuthMode} />
            <StageContent
                {...props}
                stage={props.currentStage}
                setStage={props.setStage}
                authMode={props.currentAuthMode}
                setAuthMode={props.setAuthMode}
            />
        </Dialog>
    );
};
