import { useObservableState, useSubscription } from 'observable-hooks';
import {
    readmeFileBeingSaved$,
    readmeFileChangesRevertedAction$,
    readmeFileHasUnsavedChanges$,
    revertReadmeFileChangesAction$,
    saveReadmeFileAction$,
    selectedReadmeFileContentChangedAction$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../../store/workspace/readme';
import { selectedReadOnlyTemplate$ } from '../../../store/templates';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceUid$,
} from '../../../store/workspace';
import { ReadmeFileDetails } from '../../../components/workspace-resources/readme/ReadmeFileDetails';
import { getBasePath } from '../../../utils/path';
import { useNavigate } from 'react-location';
import { getReadmeKey } from '../../../utils/readme';

export const ReadmeFileDetailsContainer: React.FC = () => {
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const readmeFileBeingSaved = useObservableState(readmeFileBeingSaved$);
    const readmeFileHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const environmentUid = useObservableState(selectedEnvironmentUid$);

    const readOnlyMode = !!selectedReadOnlyTemplate?.templateUid;
    const environmentDeployed = selectedWorkspaceEnvironment?.deployment !== undefined;

    const readmeKey = getReadmeKey(selectedReadmeFileUid ?? '', environmentUid ?? '');

    const navigate = useNavigate();

    useSubscription(readmeFileChangesRevertedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(
            // eslint-disable-next-line sonarjs/no-identical-functions
            () =>
                navigate({
                    to: `${getBasePath()}workspace/${selectedWorkspaceUid}/environment/${environmentUid}/readme/${selectedReadmeFileUid}`,
                }),
            100
        );
    });

    return (
        <ReadmeFileDetails
            content={unsavedReadmeFileDetails$.value[readmeKey]?.content ?? ''}
            fullScreenReadmeLink={`${getBasePath()}fullscreen-readme/${selectedReadmeFileUid}/workspace/${
                selectedWorkspace?.uid
            }/environment/${environmentUid}`}
            hasUnsavedChanges={!!readmeFileHasUnsavedChanges[selectedReadmeFileUid ?? '']}
            templateMode={false}
            saving={readmeFileBeingSaved}
            templatePreviewMode={readOnlyMode || environmentDeployed}
            workspaceLocked={!!selectedWorkspace?.locked}
            onDiscard={() => revertReadmeFileChangesAction$.next()}
            onChangeContent={(content) => selectedReadmeFileContentChangedAction$.next(content)}
            onSave={() => saveReadmeFileAction$.next()}
        />
    );
};
