import { styled } from '@mui/material';
import { Dialog } from '../../../common/dialogs/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Button } from '../../../common/buttons/Button';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { IconCircle } from '../../../common/IconCircle';
import { handleKeyDown } from '../../../../utils/input';
import { OrderedList } from '../../../common/lists/OrderedList';

interface CancelOrganizationPlanSuccessStateDialogProps {
    open?: boolean;
    planEndDate?: Date;
    onContinue?: () => void;
    onCancel?: () => void;
}

const StyledInformationContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledBillingInfoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
}));

export const CancelOrganizationPlanSuccessStateDialog: React.FC<CancelOrganizationPlanSuccessStateDialogProps> = ({
    open = false,
    planEndDate,
    onContinue,
    onCancel,
}) => {
    const endDateString = new Date(planEndDate ?? '').toLocaleDateString(undefined, {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    });

    return (
        <Dialog
            buttons={[
                <Button variant="contained" onClick={onContinue}>
                    Continue
                </Button>,
            ]}
            icon={<IconCircle icon={<CheckCircleOutlineOutlinedIcon color="success" />} size="medium" />}
            open={open}
            title="Your plan has been cancelled"
            subtitle="Your team cancellation has been successfully processed"
            onClose={onCancel}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterFn: onCancel,
                    escFn: onCancel,
                })
            }
        >
            <StyledInformationContainer>
                <StyledBillingInfoContainer>
                    <Typography variant="subtitle1">What happens next?</Typography>
                </StyledBillingInfoContainer>
                <Divider />
                <OrderedList
                    id="plan-cancellation-success"
                    sx={{
                        padding: 2,
                    }}
                    content={[
                        <Typography variant="body1">
                            You will have access to all features until your plan changes on{' '}
                            <strong>{endDateString}.</strong>
                        </Typography>,
                        <Typography variant="body1">
                            After that, you&#8217;ll be able to continue using the platform for free.
                        </Typography>,
                        <Typography variant="body1">Thanks for trying ScriptRunner Connect.</Typography>,
                    ]}
                />
            </StyledInformationContainer>
            <StyledInformationContainer>
                <StyledBillingInfoContainer>
                    <Typography variant="subtitle1">What if I have changed my mind?</Typography>
                </StyledBillingInfoContainer>
                <Divider />
                <StyledBillingInfoContainer>
                    <Typography variant="body1">Return to the plans page and upgrade your plan.</Typography>
                </StyledBillingInfoContainer>
            </StyledInformationContainer>
        </Dialog>
    );
};
